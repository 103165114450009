import { useTranslation } from 'react-i18next'
import { EditorFormContainer } from '../editorFormContainer/EditorFormContainer'
import { ViewActions } from '../viewActions/ViewActions'
import { ViewActionsButton } from '../viewActions/ViewActionsButton'
import msgIds from '../../locales/msgIds'
import { TextField, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { UpdateProfileArgs } from '../../dal/DalAccount'
import * as dalAccount from '../../dal/DalAccount'
import { FePreferences } from '../../models/FePreferences'
import { Utils, ValType } from '../../shared/Utils'
import { IGeneralSettingsProps } from './GeneralSettings.types'
import { useSnackbar } from 'notistack'

export function GeneralSettings(props: IGeneralSettingsProps): JSX.Element {
  const { account, disabled, isEditMode } = props
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const [args, setArgs] = useState<UpdateProfileArgs>({})
  const [areUnsavedChanges, setAreUnsavedChanges] = useState(false)
  const [errors, setErrors] = useState<any>({})
  const profileId = account.profile?.profileId

  function initFields() {
    const fePeferences = account.profile?.fePreferences
    setArgs({
      fePreferences: {
        archiveCfg: {
          autoSaveTime: fePeferences?.archiveCfg?.autoSaveTime,
        },
      },
    })
  }

  useEffect(() => {
    initFields()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account])

  useEffect(() => {
    const check =
      args?.fePreferences?.archiveCfg?.autoSaveTime !== account.profile?.fePreferences?.archiveCfg?.autoSaveTime
    setAreUnsavedChanges(check)
  }, [args])

  function validateData(val: UpdateProfileArgs) {
    const errors = {
      autoSaveTime: Utils.validateNumber(val.fePreferences?.archiveCfg?.autoSaveTime, [
        { type: ValType.notEmpty },
        { type: ValType.higherOrEqualThan, value1: 1 },
      ]),
    }
    if (Object.values(errors).find((e) => !!e)) {
      return errors
    } else {
      return null
    }
  }

  async function onUpdateProfile() {
    if (!profileId) {
      return
    }

    const errors = validateData(args)
    setErrors(errors || {})
    if (errors) {
      enqueueSnackbar(t(msgIds.MSG_VAL_ERR_THERE_ARE_FORM_ERRORS), { variant: 'error' })
      return
    }

    try {
      const abortController = new AbortController()
      const updatedAccount = await dalAccount.updateProfile(abortController.signal, profileId, { ...args })
      account.profile = updatedAccount.profile
      setAreUnsavedChanges(false)
      props.onSave && props.onSave()
    } catch (error) {
      Utils.enqueueSnackbarError2(error, t)
    }
  }

  function onCancel() {
    initFields()
    props.onCancel && props.onCancel()
  }

  function onChange(value: number) {
    const _args: UpdateProfileArgs = {
      ...args,
      fePreferences: {
        archiveCfg: {
          autoSaveTime: value,
        },
      } as FePreferences,
    }
    setArgs(_args)
  }

  return (
    <EditorFormContainer>
      <Typography variant="body1" noWrap overflow="ellipsis">
        {t(msgIds.MSG_GENERAL_SETTINGS_AUTOSAVE_TIME_INFO)}
      </Typography>

      <TextField
        error={!!errors.autoSaveTime}
        helperText={t(errors.autoSaveTime)}
        disabled={disabled || !isEditMode}
        label={t(msgIds.MSG_GENERAL_SETTINGS_AUTOSAVE_TIME_INPUT_LABEL)}
        variant="outlined"
        value={args?.fePreferences?.archiveCfg?.autoSaveTime || '1'}
        onChange={(event) => onChange(parseInt(event.target.value))}
      />
      {isEditMode && (
        <ViewActions justifyContent={'center'} sx={{ paddingTop: 6 }}>
          <ViewActionsButton autoFocus defaultAction onClick={onUpdateProfile} disabled={!areUnsavedChanges}>
            {t(msgIds.MSG_SAVE)}
          </ViewActionsButton>
          <ViewActionsButton onClick={onCancel}>{t(msgIds.MSG_CANCEL)}</ViewActionsButton>
        </ViewActions>
      )}
    </EditorFormContainer>
  )
}
