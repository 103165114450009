import React, { useReducer, useEffect, useState, useRef } from 'react'
import axios, { AxiosResponse, InternalAxiosRequestConfig } from 'axios'

import log from '../shared/Logger'
import { Action } from '../shared/types/Action'
import { Account } from '../models/Account'
import { LOGIN_ENABLED, ProfileType } from '../shared/Constants'
import { useLocation, useNavigate } from 'react-router'

export interface LocationState {
  pathname: string
  state: any
}

export const axiosInstance = axios.create()

export interface IContextState {
  // context props
  loggedAccount?: Account
  loggedUserId?: number
  loggedProfileId?: number
  loggedProfileType?: ProfileType
  linkedStructureAccount?: Account
  linkedStructureProfileId?: number
  assistedAccount?: Account
  assistedAccountProfileId?: number
  isOnlyPageContentVisible: boolean

  // context actions
  setLoggedAccount: (account: Account) => void
  resetLoggedAccount: () => void
  setAssistedAccount: (account: Account) => void
  resetAssistedAccount: () => void
  setLinkedStructureAccount: (account: Account) => void
  resetLinkedStructureAccount: () => void
  setJwt: (jwt?: string) => void
  resetJwt: () => void
  navigateToLastLocationAsLogged: () => void
  setDestinationAfterLogin: (location: LocationState) => void
  getDestinationAfterLogin: () => LocationState | undefined
  showOnlyPageContent: () => void
  showCompletePage: () => void
}

const initialState: IContextState = {
  // context props
  loggedAccount: undefined,
  loggedUserId: undefined,
  loggedProfileId: undefined,
  loggedProfileType: undefined,
  linkedStructureAccount: undefined,
  linkedStructureProfileId: undefined,
  assistedAccount: undefined,
  assistedAccountProfileId: undefined,
  isOnlyPageContentVisible: false,

  // context actions
  setLoggedAccount: (account: Account) => {},
  resetLoggedAccount: () => {},
  setAssistedAccount: (account: Account) => {},
  resetAssistedAccount: () => {},
  setLinkedStructureAccount: (account: Account) => {},
  resetLinkedStructureAccount: () => {},
  setJwt: (jwt?: string) => {},
  resetJwt: () => {},
  navigateToLastLocationAsLogged: () => {},
  setDestinationAfterLogin: (location: LocationState) => {},
  getDestinationAfterLogin: () => {
    return {} as LocationState
  },
  showOnlyPageContent: () => {},
  showCompletePage: () => {},
}

type Actions =
  | Action<'SET_LOGGED_ACCOUNT', Account>
  | Action<'RESET_LOGGED_ACCOUNT', undefined>
  | Action<'SET_ASSISTED_ACCOUNT', Account>
  | Action<'RESET_ASSISTED_ACCOUNT', undefined>
  | Action<'SET_LINKED_STRUCTURE_ACCOUNT', Account>
  | Action<'RESET_LINKED_STRUCTURE_ACCOUNT', undefined>
  | Action<'HIDE_ALL_EXCEPT_PAGE_CONTENT', undefined>
  | Action<'SHOW_ALL_EXCEPT_PAGE_CONTENT', undefined>

type ReducerFunc = (state: IContextState, action: Actions) => IContextState
function reducer(state: IContextState, action: Actions): IContextState {
  switch (action.type) {
    case 'SET_LOGGED_ACCOUNT': {
      log.debug('SET_LOGGED_ACCOUNT')
      return {
        ...state,
        loggedAccount: action.payload,
        loggedUserId: action.payload?.user?.userId,
        loggedProfileId: action.payload?.profile?.profileId,
        loggedProfileType: action.payload?.profile?.type,
        linkedStructureAccount: action.payload?.linkedAccount,
        linkedStructureProfileId: action.payload?.linkedAccount?.profile?.profileId,
      }
    }
    case 'RESET_LOGGED_ACCOUNT': {
      return {
        ...state,
        loggedAccount: undefined,
        loggedUserId: undefined,
        loggedProfileId: undefined,
        loggedProfileType: undefined,
        linkedStructureAccount: undefined,
        linkedStructureProfileId: undefined,
      }
    }
    case 'SET_ASSISTED_ACCOUNT': {
      return {
        ...state,
        assistedAccount: action.payload,
        assistedAccountProfileId: action.payload?.profile?.profileId,
      }
    }
    case 'RESET_ASSISTED_ACCOUNT': {
      return {
        ...state,
        assistedAccount: undefined,
        assistedAccountProfileId: undefined,
      }
    }
    case 'SET_LINKED_STRUCTURE_ACCOUNT': {
      return {
        ...state,
        linkedStructureAccount: action.payload,
        linkedStructureProfileId: action.payload?.profile?.profileId,
      }
    }
    case 'RESET_LINKED_STRUCTURE_ACCOUNT': {
      return {
        ...state,
        linkedStructureAccount: undefined,
        linkedStructureProfileId: undefined,
      }
    }
    case 'HIDE_ALL_EXCEPT_PAGE_CONTENT': {
      return {
        ...state,
        isOnlyPageContentVisible: true,
      }
    }
    case 'SHOW_ALL_EXCEPT_PAGE_CONTENT': {
      return {
        ...state,
        isOnlyPageContentVisible: false,
      }
    }
  }
}

const injectActions = (dispatch: (arg: Actions) => void, state: IContextState) => {
  state.setLoggedAccount = (account: Account) => dispatch({ type: 'SET_LOGGED_ACCOUNT', payload: account })
  state.resetLoggedAccount = () => dispatch({ type: 'RESET_LOGGED_ACCOUNT' })
  state.setAssistedAccount = (account: Account) => dispatch({ type: 'SET_ASSISTED_ACCOUNT', payload: account })
  state.resetAssistedAccount = () => dispatch({ type: 'RESET_ASSISTED_ACCOUNT' })
  state.setLinkedStructureAccount = (account: Account) =>
    dispatch({ type: 'SET_LINKED_STRUCTURE_ACCOUNT', payload: account })
  state.resetLinkedStructureAccount = () => dispatch({ type: 'RESET_LINKED_STRUCTURE_ACCOUNT' })
  state.showOnlyPageContent = () => dispatch({ type: 'HIDE_ALL_EXCEPT_PAGE_CONTENT' })
  state.showCompletePage = () => dispatch({ type: 'SHOW_ALL_EXCEPT_PAGE_CONTENT' })
}

interface IProps {
  children?: React.ReactNode
}

const AuthContext = React.createContext<IContextState>(initialState)
export const AuthContextProvider: React.FC<IProps> = ({ children }) => {
  const [state, dispatch] = useReducer<ReducerFunc>(reducer, initialState)
  injectActions(dispatch, state)
  const location = useLocation()
  const jwtRef = useRef<string | undefined>(undefined)

  useEffect(() => {
    const reqInterceptor = axiosInstance.interceptors.request.use(
      function (config: InternalAxiosRequestConfig<any>) {
        if (jwtRef.current) {
          config.headers['authorization'] = `Bearer ${jwtRef.current}`
        }
        return config
      },
      function (error) {
        return Promise.reject(error)
      }
    )

    const resInterceptor = axiosInstance.interceptors.response.use(
      function (config: AxiosResponse<any, any>) {
        if (config.status === 401) {
          jwtRef.current = undefined
          return config
        }
        if (config.data.jwt) {
          jwtRef.current = config.data.jwt
        } else if (
          config.headers['authorization'] &&
          (config.headers['authorization'] as string).startsWith('Bearer')
        ) {
          const jwt = (config.headers['authorization'] as string).substring(7)
          jwtRef.current = jwt
        }
        return config
      },
      function (error) {
        if (error.response && error.response.data.message === 'Authorization token expired') {
          handleTokenExpired()
        }
        return Promise.reject(error)
      }
    )

    return () => {
      axiosInstance.interceptors.request.eject(reqInterceptor)
      axiosInstance.interceptors.response.eject(resInterceptor)
    }
  }, [])

  function setJwt(jwt?: string) {
    jwtRef.current = jwt
  }

  function resetJwt() {
    jwtRef.current = undefined
  }

  function handleTokenExpired() {
    jwtRef.current = undefined

    // // Salva l'ultima pagina prima del logout
    // const lastPage = window.location.pathname;
    // localStorage.setItem('lastPage', lastPage);

    // // Reindirizza l'utente alla pagina di login
    // navigate('/login');
  }

  const navigate = useNavigate()
  const lastLocationAsLoggedRef = useRef<LocationState | undefined>(undefined)
  useEffect(() => {
    if (
      !jwtRef.current &&
      !location.pathname.includes('login') &&
      !location.pathname.includes('regist') &&
      LOGIN_ENABLED
    ) {
      const locationState: LocationState = { pathname: location.pathname, state: location.state }
      lastLocationAsLoggedRef.current = locationState
      state.loggedProfileType ? navigate(`/login?profileType${state.loggedProfileType}`) : navigate(`/login}`)
    }
  }, [jwtRef.current, location.pathname, navigate])

  function navigateToLastLocationAsLogged() {
    const locationState = lastLocationAsLoggedRef.current
    if (locationState) {
      navigate(locationState.pathname, { state: locationState.state })
    } else {
      navigate('/')
    }
  }

  function setDestinationAfterLogin(locationState: LocationState) {
    lastLocationAsLoggedRef.current = locationState
  }

  function getDestinationAfterLogin() {
    return lastLocationAsLoggedRef.current
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        setJwt,
        resetJwt,
        navigateToLastLocationAsLogged,
        setDestinationAfterLogin,
        getDestinationAfterLogin,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuthContext = (): IContextState => React.useContext(AuthContext)
