import { useTranslation } from 'react-i18next'
import msgIds from '../../locales/msgIds'
import { IContractSectionEditorFormProps } from './ContracSectionEditorForm.types'
import { useState } from 'react'
import { Stack, useTheme } from '@mui/material'
import DataEditorHeader from '../dataEditorHeader/DataEditorHeader'
import { ArrowDownIco, ArrowUpIco, DeleteIco, EditIco } from '../icons'
import ContractSectionEditor from './ContractSectionEditor'
import { ICommand } from '../commandBar/CommandBar.types'
import { ContractType } from '../../shared/Constants'

export function ContractSectionEditorForm(props: IContractSectionEditorFormProps): JSX.Element {
  const { contractSection, contractType, sectionIndex, contractId, treatments, isAuthorizedToEdit } = props
  const { t } = useTranslation()
  const theme = useTheme()
  const [isEditMode, setIsEditMode] = useState(false)

  const commands: ICommand[] = []
  if (props.isAuthorizedToEdit) {
    if (contractType === ContractType.privacyPolicy ? sectionIndex > 1 : sectionIndex > 0) {
      commands.push({
        commandText: '',
        icon: <ArrowUpIco />,
        onClick: () => props.moveContractSection(sectionIndex, true),
        tooltipText: t(msgIds.MSG_MOVE_UP) ?? undefined,
        disabled: false,
      })
    }
    if (!((contractType === ContractType.privacyPolicy && sectionIndex === 0) || !contractSection.beforeOf)) {
      commands.push({
        commandText: '',
        icon: <ArrowDownIco />,
        onClick: () => props.moveContractSection(sectionIndex, false),
        tooltipText: t(msgIds.MSG_MOVE_DOWN) ?? undefined,
        disabled: false,
      })
    }
    if (!(contractType === ContractType.privacyPolicy && contractSection.isDataProcessingSection)) {
      commands.push({
        commandText: '',
        icon: <DeleteIco />,
        onClick: () => props.deleteContractSection(contractSection.id),
        tooltipText: t(msgIds.MSG_DELETE) ?? undefined,
        disabled: false,
      })
    }
    commands.push({
      commandText: '',
      icon: <EditIco />,
      onClick: () => setIsEditMode(true),
      tooltipText: t(msgIds.MSG_MODIFY) ?? undefined,
      disabled: false,
    })
  }

  return (
    <Stack paddingBottom={3} spacing={0}>
      <DataEditorHeader
        title={`${t(msgIds.MSG_CONTRACT_SECTION_TITLE)}: ${contractSection.reference}`}
        isEditMode={isEditMode}
        viewModeCommands={commands}
        editModeCommands={[]}
      />
      <ContractSectionEditor
        contractSection={contractSection}
        contractId={contractId}
        treatments={treatments}
        isAuthorizedToEdit={isAuthorizedToEdit}
        isEditMode={isEditMode}
        disabled={false}
        onSave={() => setIsEditMode(false)}
        onCancel={() => setIsEditMode(false)}
        getOtherSectionsTreatmentsIds={props.getOtherSectionsTreatmentsIds}
      />
    </Stack>
  )
}
