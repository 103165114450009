import { Account, AccountInfo } from '../../models/Account'
import { AccountType, ActionType, ProfileType, isOperator } from '../../shared/Constants'
import {
  AnagraphicIco,
  ChronologyIco,
  CompanyPresentationIco,
  DesktopIco,
  DiaryCustomerIco,
  DiaryStructureIco,
  DocReceivedIco,
  PersonPresentationIco,
  PrivacyCustomerIco,
  TreatmentIco,
  UserInfoIco,
} from '../icons'
import msgIds from '../../locales/msgIds'
import { IArchivePageInit } from '../../pages/ArchivePage/ArchivePage.types'
import { IAccountEditorPageProps } from '../../pages/AccountEditorPage/AccountEditorPage.types'
import { IAccountPresentationPageInit } from '../../pages/AccountPresentationPage/AccountPresentationPage.types'
import { ITreatmentsPageInit } from '../../pages/TreatmentsPage/TreatmentsPage.types'
import { IContractsPageInit } from '../../pages/ContractsPage/ContractsPage.types'
import { IEventHistoryPageInit } from '../../pages/EventHistoryPage/EventHistoryPage.types'
import { IAccountConfigurationsPageProps } from '../../pages/AccountConfigurationsPage/AccountConfigurationsPage.types'
import { IDesktopPageProps } from '../../pages/DesktopPage/DesktopPage.types'

/**
 * Group of items available for account
 */
export interface ILeftbarItemsArea {
  account: Account
  accountInfoToShow: AccountInfo[]
  items: ILeftbarItemsGroup[]
}

/**
 * Group of items available for an argument
 */
export interface ILeftbarItemsGroup {
  title: string
  items: ILeftbarItem[]
  isExpandedInit: boolean
  isFirst: boolean
}

/**
 * Item to navigate for a specific page
 */
export interface ILeftbarItem {
  id: LeftbarItemIds
  name: string
  path: string
  icon: React.ReactElement
  disabled: boolean
  init?:
    | IArchivePageInit
    | IAccountEditorPageProps
    | IAccountPresentationPageInit
    | ITreatmentsPageInit
    | IContractsPageInit
    | IEventHistoryPageInit
    | IAccountConfigurationsPageProps
    | IDesktopPageProps
}

export type LeftbarItemIds =
  | 'desktop'
  | 'customer_personal_archive'
  | 'customer_dox_received_archive'
  | 'customer_informations'
  | 'consumer_configurations'
  | 'operator_informations'
  | 'operator_presentation'
  | 'operator_configurations'
  | 'structure_informations'
  | 'structure_presentation'
  | 'structure_settings'
  | 'structure_configurations'
  | 'mop_treatments'
  | 'mop_privacy_policies'
  | 'mop_history'
  | 'structure_customer_archive'

export const getLeftbarData = (loggedAccount?: Account, assistedAccount?: Account): ILeftbarItemsArea[] => {
  const loggedProfileType = loggedAccount?.profile?.type
  const loggedAllowedActions = loggedAccount?.allowedActions
  const res: ILeftbarItemsArea[] = []

  if (loggedProfileType && loggedAllowedActions) {
    if (loggedProfileType === ProfileType.customer) {
      const customerArchiveManagement = getLeftBarData_CustomerArchiveManagement(loggedAccount)
      const customerSettings = getLeftBarData_CustomerSettings(loggedAccount)
      customerArchiveManagement.isFirst = true
      //customerArchiveManagement.isExpandedInit = true
      const customerArea: ILeftbarItemsArea = {
        account: loggedAccount,
        accountInfoToShow: ['main'],
        items: [customerArchiveManagement],
      }
      if (!loggedAccount.isGuest) {
        customerArea.items.push(customerSettings)
      }
      res.push(customerArea)
    } else if (isOperator(loggedProfileType)) {
      const operatorSettings = getLeftBarData_OperatorAdminPersonalSettings(loggedAccount)
      operatorSettings.isFirst = true
      //operatorSettings.isExpandedInit = true
      const operatorArea: ILeftbarItemsArea = {
        account: loggedAccount,
        accountInfoToShow: ['main'],
        items: [operatorSettings],
      }
      res.push(operatorArea)

      if (loggedAccount.linkedAccount && loggedAccount.linkedAccount.profile?.type === ProfileType.structure) {
        const linkedStructureArea: ILeftbarItemsArea = {
          account: loggedAccount.linkedAccount,
          accountInfoToShow: ['main'],
          items: [],
        }
        const structureSettings = getLeftBarData_OperatorAdminStructureSettings(loggedAccount)
        linkedStructureArea.items.push(structureSettings)
        if (loggedAccount.canDo(ActionType.viewPrivacyPlant) || loggedAccount.canDo(ActionType.updatePrivacyPlant)) {
          const structureMop = getLeftBarData_OperatorAdminStructureMop(loggedAccount)
          linkedStructureArea.items.push(structureMop)
        }
        res.push(linkedStructureArea)
      }

      if (assistedAccount && assistedAccount.profile?.type === ProfileType.customer) {
        const customerArchiveManagement = getLeftBarData_CustomerManagement(assistedAccount)
        const assistedAccountArea: ILeftbarItemsArea = {
          account: assistedAccount,
          accountInfoToShow: ['main'],
          items: [customerArchiveManagement],
        }
        res.push(assistedAccountArea)
      }
    }
  }

  return res
}

const getLeftBarData_CustomerArchiveManagement = (account: Account): ILeftbarItemsGroup => {
  return {
    title: msgIds.MSG_ARCHIVE_MANAGEMENT,
    isExpandedInit: false,
    isFirst: false,
    items: [
      {
        id: 'desktop',
        name: msgIds.MSG_DESKTOP,
        path: `/desktop`,
        icon: <DesktopIco />,
        disabled: false,
        init: {} as IDesktopPageProps,
      },
      {
        id: 'customer_personal_archive',
        name: msgIds.MSG_PERSONAL_ARCHIVE,
        path: `/archive`,
        icon: <DiaryCustomerIco />,
        disabled: false,
        init: {
          isPerspective: false,
          onlyReceived: false,
        } as IArchivePageInit,
      },
      {
        id: 'customer_dox_received_archive',
        name: msgIds.MSG_RECEIVED_DOX,
        path: `/archive`,
        icon: <DocReceivedIco />,
        disabled: false,
        init: {
          isPerspective: false,
          onlyReceived: true,
        } as IArchivePageInit,
      },
    ],
  }
}

const getLeftBarData_CustomerSettings = (account: Account): ILeftbarItemsGroup => {
  return {
    title: msgIds.MSG_PERSONAL_SETTINGS,
    isExpandedInit: false,
    isFirst: false,
    items: [
      {
        id: 'customer_informations',
        name: msgIds.MSG_PERSONAL_INFORMATIONS,
        path: `/account_editor`,
        icon: <AnagraphicIco />,
        disabled: false,
        init: {
          detailsOwnerProfileId: account.profile?.profileId,
          profileId: account.profile?.profileId,
        } as IAccountEditorPageProps,
      },
      {
        id: 'consumer_configurations',
        name: msgIds.MSG_CONFIGURATIONS,
        path: `/account_configurations`,
        icon: <UserInfoIco />,
        disabled: false,
        init: {
          profileId: account.profile?.profileId ?? 0,
        } as IAccountConfigurationsPageProps,
      },
    ],
  }
}

const getLeftBarData_OperatorAdminPersonalSettings = (account: Account): ILeftbarItemsGroup => {
  return {
    title: msgIds.MSG_PERSONAL_SETTINGS,
    isExpandedInit: false,
    isFirst: false,
    items: [
      {
        id: 'operator_informations',
        name: msgIds.MSG_PERSONAL_INFORMATIONS,
        path: `/account_editor`,
        icon: <AnagraphicIco />,
        disabled: false,
        init: {
          detailsOwnerProfileId: account.profile?.profileId,
          profileId: account.profile?.profileId,
        } as IAccountEditorPageProps,
      },
      // {
      //   id: 'operator_presentation',
      //   name: msgIds.MSG_PRESENTATION,
      //   path: `/account_presentation?${search}`,
      //   icon: <PersonPresentationIco />,
      //   disabled: false,
      //   init: {
      //     profileId: account.profile?.profileId,
      //   } as IAccountPresentationPageInit,
      // },
      {
        id: 'operator_configurations',
        name: msgIds.MSG_SETTINGS,
        path: `/account_configurations`,
        icon: <UserInfoIco />,
        disabled: false,
        init: {
          profileId: account.profile?.profileId ?? 0,
        } as IAccountConfigurationsPageProps,
      },
    ],
  }
}

const getLeftBarData_OperatorAdminStructureSettings = (account: Account): ILeftbarItemsGroup => {
  return {
    title: msgIds.MSG_STRUCTURE_SETTINGS,
    isExpandedInit: false,
    isFirst: false,
    items: [
      {
        id: 'structure_informations',
        name: msgIds.MSG_STRUCTURE_INFORMATIONS,
        path: `/account_editor`,
        icon: <AnagraphicIco />,
        disabled: false,
        init: {
          detailsOwnerProfileId: account.profile?.profileId,
          profileId: account.profile?.linkedProfileId,
        } as IAccountEditorPageProps,
      },
      // {
      //   id: 'structure_presentation',
      //   name: msgIds.MSG_PRESENTATION,
      //   path: `/structure_presentation`,
      //   icon: <CompanyPresentationIco />,
      //   disabled: false,
      //   init: {
      //     profileId: account.profile?.profileId,
      //   } as IAccountPresentationPageInit,
      // },
      {
        id: 'structure_configurations',
        name: msgIds.MSG_ARCHIVE_MANAGEMENT,
        path: `/account_configurations`,
        icon: <DiaryStructureIco />,
        disabled: false,
        init: {
          profileId: account.profile?.linkedProfileId ?? 0,
        } as IAccountConfigurationsPageProps,
      },
    ],
  }
}

const getLeftBarData_OperatorAdminStructureMop = (account: Account): ILeftbarItemsGroup => {
  return {
    title: msgIds.MSG_OPERATIVE_PRIVACY_MODEL,
    isExpandedInit: false,
    isFirst: false,
    items: [
      {
        id: 'mop_treatments',
        name: msgIds.MSG_TREATMENT_ACTIVITIES,
        path: `/treatments`,
        icon: <TreatmentIco />,
        disabled: false,
        init: {
          ownerProfileId: account.linkedAccount?.profile?.profileId,
        } as ITreatmentsPageInit,
      },
      {
        id: 'mop_privacy_policies',
        name: msgIds.MSG_PRIVACY_POLICIES,
        path: `/contracts`,
        icon: <PrivacyCustomerIco />,
        disabled: false,
        init: {
          isPublic: false,
          ownerProfileId: account.linkedAccount?.profile?.profileId,
        } as IContractsPageInit,
      },
      {
        id: 'mop_history',
        name: msgIds.MSG_CHANGE_HISTORY,
        path: `/history?type=mop`,
        icon: <ChronologyIco />,
        disabled: false,
        init: {
          historyType: 'mop',
        } as IEventHistoryPageInit,
      },
    ],
  }
}

const getLeftBarData_CustomerManagement = (assistedAccount: Account): ILeftbarItemsGroup => {
  const res: ILeftbarItemsGroup = {
    title: msgIds.MSG_USER_MANAGEMENT,
    isExpandedInit: false,
    isFirst: false,
    items: [
      {
        id: 'desktop',
        name: msgIds.MSG_DESKTOP,
        path: `/desktop`,
        icon: <DesktopIco />,
        disabled: false,
        init: {} as IDesktopPageProps,
      },
      {
        id: 'structure_customer_archive',
        name: msgIds.MSG_STRUCTURE_ARCHIVE,
        path: `/archive`,
        icon: <DiaryStructureIco />,
        disabled: false,
        init: {
          isPerspective: true,
          onlyReceived: false,
        } as IArchivePageInit,
      },
    ],
  }

  if (assistedAccount.user?.accountType !== AccountType.placeholderUser) {
    res.items.push({
      id: 'customer_personal_archive',
      name: msgIds.MSG_SHARED_ARCHIVE,
      path: `/archive`,
      icon: <DiaryCustomerIco />,
      disabled: false,
      init: {
        isPerspective: false,
        onlyReceived: false,
      } as IArchivePageInit,
    })
  }

  return res
}
