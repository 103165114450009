import { useTranslation } from 'react-i18next'
import msgIds from '../../locales/msgIds'
import * as dalAccount from '../../dal/DalAccount'
import { Utils } from '../../shared/Utils'
import { useEffect, useState } from 'react'
import { IAccountEditorIdentityProps } from './AccountEditorIdentity.types'
import { useSnackbar } from 'notistack'
import { ViewActions } from '../viewActions/ViewActions'
import { ViewActionsButton } from '../viewActions/ViewActionsButton'
import { EditorFormContainer } from '../editorFormContainer/EditorFormContainer'
import { useAccountEditorContext } from '../../contexts/AccountEditorContext'
import SimpleDialog from '../../dialogs/simpleDialog/SimpleDialog'
import { ISimpleDialogData } from '../../dialogs/simpleDialog/SimpleDialog.types'
import { useLayout } from '../../hooks/Layout'
import { AccountEditorIdentityFields } from './AccountEditorIdentityFields'
import { IIdentityArgs, validateIdentityArgs } from '../../shared/IdentityArgs'
import { Alert } from '@mui/material'
import { isStructure } from '../../shared/Constants'

export function AccountEditorIdentity(props: IAccountEditorIdentityProps): JSX.Element {
  const { account, disabled, isEditMode } = props
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const { isMobile } = useLayout()
  const accountEditorContext = useAccountEditorContext()
  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState<any>({})
  const [args, setArgs] = useState<IIdentityArgs>({})
  const [areUnsavedChanges, setAreUnsavedChanges] = useState(false)

  // dialogs
  const [simpleDialogData, setSimpleDialogData] = useState<ISimpleDialogData>()
  const [simpleDialogOpen, setSimpleDialogOpen] = useState(false)

  function initFields() {
    setArgs({
      email: account?.user?.email,
      lastname: account?.userDetails?.lastname,
      name: account?.userDetails?.name,
      gender: account?.userDetails?.gender,
      birthdate: account?.userDetails?.birthdate,
      birthplace: account?.userDetails?.birthplace,
      vatNumber: account?.userDetails?.vatNumber,
      fiscalCode: account?.userDetails?.fiscalCode,
    })
  }

  useEffect(() => {
    initFields()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account])

  useEffect(() => {
    const check =
      args?.email !== account?.user?.email ||
      args?.lastname !== account?.userDetails?.lastname ||
      args?.name !== account?.userDetails?.name ||
      args?.gender !== account?.userDetails?.gender ||
      args?.birthdate !== account?.userDetails?.birthdate ||
      args?.birthplace !== account?.userDetails?.birthplace ||
      args?.vatNumber !== account?.userDetails?.vatNumber ||
      args?.fiscalCode !== account?.userDetails?.fiscalCode
    setAreUnsavedChanges(check)
  }, [args])

  function validateData(val: IIdentityArgs) {
    return validateIdentityArgs(val, accountEditorContext.fieldsAcl, isStructure(account?.profile?.type))
  }

  async function onUpdateAccount() {
    const userId = account?.user?.userId
    if (!userId || !args) return

    const errors = validateData(args)
    setErrors(errors || {})
    if (errors) {
      enqueueSnackbar(t(msgIds.MSG_VAL_ERR_THERE_ARE_FORM_ERRORS), { variant: 'error' })
      return
    }

    setSimpleDialogOpen(true)
    setSimpleDialogData({
      title: t(msgIds.MSG_PROFILE_EDITOR_IDENTITY_CONFIRM_TITLE),
      content: t(msgIds.MSG_PROFILE_EDITOR_IDENTITY_CONFIRM_BODY),
      actionsStyle: 'yesNO',
      onClose: async (result2) => {
        setSimpleDialogOpen(false)
        if (result2.userChoice === 'yes') {
          try {
            setIsLoading(true)
            const abortController = new AbortController()
            const updatedUser = await dalAccount.updateUser(abortController.signal, userId, { ...args })
            account.user = updatedUser.user
            setAreUnsavedChanges(false)
            props.onSave && props.onSave()
            setSimpleDialogOpen(false)
          } catch (err) {
            Utils.enqueueSnackbarError2(err, t)
          } finally {
            setIsLoading(false)
          }
        }
      },
    })
  }

  function onCancel() {
    initFields()
    setErrors({})
    props.onCancel && props.onCancel()
  }

  return (
    <EditorFormContainer>
      {simpleDialogData && <SimpleDialog {...simpleDialogData} isOpen={simpleDialogOpen}></SimpleDialog>}
      <Alert severity="warning">{t(msgIds.MSG_PROFILE_EDITOR_IDENTITY_WARNING)}</Alert>
      {account && (
        <AccountEditorIdentityFields
          profileType={account.profile?.type}
          args={args}
          fieldsAcl={accountEditorContext.fieldsAcl}
          errors={errors}
          onChangeArgs={setArgs}
          disabled={disabled}
          isEditMode={isEditMode}
        />
      )}

      {isEditMode && (
        <ViewActions justifyContent={'center'} sx={{ paddingTop: 6 }}>
          <ViewActionsButton autoFocus defaultAction onClick={() => onUpdateAccount()} disabled={!areUnsavedChanges}>
            {t(msgIds.MSG_REQUEST_MODIFICATION)}
          </ViewActionsButton>
          <ViewActionsButton onClick={onCancel}>{t(msgIds.MSG_CANCEL)}</ViewActionsButton>
        </ViewActions>
      )}
    </EditorFormContainer>
  )
}
