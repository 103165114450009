import { Stack } from '@mui/material'
import DataEditorHeader from '../dataEditorHeader/DataEditorHeader'
import { useTranslation } from 'react-i18next'
import msgIds from '../../locales/msgIds'
import { EditIco } from '../icons'
import { useState } from 'react'
import { TotpEditor } from './TotpEditor'
import { ITotpEditorFormProps } from './TotpEditorForm.types'

export function TotpEditorForm(props: ITotpEditorFormProps): JSX.Element {
  const { t } = useTranslation()
  const [isEditMode, setIsEditMode] = useState(false)

  return (
    <Stack paddingBottom={3} spacing={0}>
      <DataEditorHeader
        title={t(msgIds.MSG_TOTP_EDITOR_TITLE)}
        isEditMode={isEditMode}
        viewModeCommands={[
          {
            commandText: '',
            icon: <EditIco />,
            onClick: () => setIsEditMode(true),
            tooltipText: t(msgIds.MSG_MODIFY) ?? undefined,
            disabled: false,
          },
        ]}
        editModeCommands={[]}
      />
      <TotpEditor isEditMode={isEditMode} onCancel={() => setIsEditMode(false)} onSave={() => setIsEditMode(false)} />
    </Stack>
  )
}
