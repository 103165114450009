import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  LinearProgress,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material'
import { IDocumentEditorProps } from './DocumentEditor.types'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  AddToDesktopIco,
  ArchiveIco,
  CloseIco,
  DoxIco,
  DraftIco,
  EditIco,
  ExpandMoreIco,
  InfoIco,
  PreviewIco,
  RetentionIco,
  SaveIco,
  VersionsIco,
} from '../icons'
import log from '../../shared/Logger'
import * as dalDocument from '../../dal/DalDocument'
import DocumentInfoForm from './DocumentInfoForm'
import DocumentInfoDetails from './DocumentInfoDetails'
import DocumentDoxDetails from './DocumentDoxDetails'
import DocumentContent from './DocumentContent'
import {
  ActionType,
  ArchiveItemSourceType,
  isBusiness,
  isOperator,
  isPersonalDoxMimetype,
} from '../../shared/Constants'
import MarkdownEditor from '../markdownEditor/MarkdownEditor'
import DocumentUpload from './DocumentUpload'
import DocumentRevisionsTable from './DocumentRevisionsTable'
import { useAuthContext } from '../../contexts/AuthContext'
import DocumentRetentions from './DocumentRetentions'
import msgIds from '../../locales/msgIds'
import { useTranslation } from 'react-i18next'
import { useLayout } from '../../hooks/Layout'
import { DocumentRevision } from '../../models/DocumentRevision'
import { createBlob, useDocumentCacheContext } from '../../contexts/DocumentCacheContext'
import _ from 'lodash'
import { IImageCommandProps } from '../imageCommand/ImageCommand.types'
import ImageCommand from '../imageCommand/ImageCommand'
import { Utils } from '../../shared/Utils'
import SimpleDialog from '../../dialogs/simpleDialog/SimpleDialog'
import { ISimpleDialogData } from '../../dialogs/simpleDialog/SimpleDialog.types'
import { Document } from '../../models/Document'
import { IDesktopDocument, useDesktopContext } from '../../contexts/DesktopContext'
import { useSnackbar } from 'notistack'
import { getDocumentEditorSavingInfo, getDocumentRevision } from './DocumentEditorUtils'
import { isSameDocument } from '../../contexts/DesktopContextProvider'

export default function DocumentEditor(props: IDocumentEditorProps): JSX.Element {
  const { isDesktop } = props
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const { isMobile } = useLayout()
  const [isTransmitting, setIsTransmitting] = useState(false)
  const authContext = useAuthContext()
  const [progress, setProgress] = useState(0)
  const isEditing = props.isEditing
  const setIsEditing = props.onChangeIsEditing
  const sectionIndex = props.sectionIndex
  const setSectionIndex = props.onChangeSectionIndex
  const document = props.document
  const setDocument = props.onChangeDocument
  const revisionId = props.revisionId
  const setRevisionId = props.onChangeRevisionId
  const setRevision = (revision: DocumentRevision | undefined) => {
    setRevisionId(revision ? revision.revisionId : 0)
  }
  const desktop = useDesktopContext()
  const draftRevision = props.draftRevision
  const setDraftRevision = props.onChangeDraftRevision
  const draftDocument = props.draftDocument
  const setDraftDocument = props.onChangeDraftDocument
  const isMarkdownContentChanged = props.isMarkdownContentChanged
  const setIsMarkdownContentChanged = props.onChangeMarkdownContent
  const markdownContentLen = props.markdownContentLen
  const setMarkdownContentLen = props.onChangeMarkdownContentLen
  const initialCommand = props.initialCommand
  const setInitialCommand = props.onChangeInitialCommand
  // dialogs
  const [simpleDialogData, setSimpleDialogData] = useState<ISimpleDialogData>()
  const [simpleDialogOpen, setSimpleDialogOpen] = useState(false)
  const revision = getDocumentRevision(document, revisionId, isEditing)
  const documentCacheContext = useDocumentCacheContext()
  const cachedDocument = documentCacheContext?.documentMap[document.documentId]

  // ********************
  // content loading management

  useEffect(() => {
    const documentId = document.documentId
    if (document === cachedDocument) {
      return
    }
    console.log({ DocumentEditorUseEffect: documentId })

    const loadDocument = async (abortSignal: AbortSignal) => {
      try {
        setIsTransmitting(true)
        const doc = await dalDocument.getDocument(abortSignal, documentId)
        documentCacheContext.addDocument(doc)
        props.onEditedDocumentAssignedDoxChanged(doc.doxIds)
        console.log({ loadeddocument: doc.doxIds })
        setDocument(doc)
      } catch (err) {
        Utils.enqueueSnackbarError2(err, t)
      } finally {
        setIsTransmitting(false)
      }
    }

    if (documentId !== 0) {
      if (!!cachedDocument) {
        setDocument(cachedDocument)
        setIsTransmitting(false)
      } else {
        const abortController = new AbortController()
        loadDocument(abortController.signal)

        return () => {
          abortController.abort()
        }
      }
    } else {
      setIsTransmitting(false)
    }
  }, [document, cachedDocument])

  useEffect(() => {
    if (
      document &&
      document.revisions.length > 0 &&
      document.revisions[0].mimetype &&
      revision &&
      revision.content &&
      (initialCommand === 'createDraft' || initialCommand === 'editDraft')
    ) {
      onClickAction(initialCommand)
      setInitialCommand(undefined)
    }
  }, [document, initialCommand, revision?.content])

  const revisionsLoading = useRef(new Set<string>())
  const fetchRevisionContent = useCallback(
    async (abortSignel: AbortSignal) => {
      if (!revision || revision.content || !revision.mimetype || !revision.documentId || !revision.revisionId) {
        return
      }
      if (!cachedDocument) {
        // DocumentCacheContext crash if it tries to load revision without document
        return
      }
      const manageContent = (content: BlobPart) => {
        revision.content = content
      }
      try {
        const loadingId = revision.documentId + '-' + revision.revisionId
        setIsTransmitting(true)
        const cachedRevision = cachedDocument?.getRevision(revision.revisionId)
        const cachedContent = cachedRevision?.content
        if (cachedRevision && cachedContent) {
          manageContent(cachedContent)
        } else if (isPersonalDoxMimetype(revision.mimetype) === true) {
          const rev = await dalDocument.getDocumentRevision(abortSignel, revision.documentId, revision.revisionId)
          if (documentCacheContext?.setRevisionContent) {
            documentCacheContext.setRevisionContent(revision.documentId, revision.revisionId, rev.content)
          }
          manageContent(rev.content)
        } else if (!revisionsLoading.current.has(loadingId)) {
          console.log({ DocumentEditor: 'getDocumentRevisionContent' })
          const arrayBuffer = await dalDocument.getDocumentRevisionContent(
            abortSignel,
            revision.documentId,
            revision.revisionId,
            true,
            setProgress
          )
          if (revision.checksum) {
            const checksum = Utils.getBinaryMD5Checksum(arrayBuffer)
            if (checksum !== revision.checksum) {
              return Promise.reject(new Error('Checksum mismatch'))
            }
          }
          if (documentCacheContext?.setRevisionContent) {
            const blob = createBlob([arrayBuffer], revision.mimetype)
            documentCacheContext.setRevisionContent(revision.documentId, revision.revisionId, blob)
          }
          manageContent(arrayBuffer)
        }
      } catch (err) {
        Utils.enqueueSnackbarError2(err, t, abortSignel.aborted)
      } finally {
        setIsTransmitting(false)
        setProgress(0)
      }
    },
    [documentCacheContext, cachedDocument, revision, revision?.mimetype, revision?.documentId, revision?.revisionId]
  )

  const canCreateDraft = document.authorProfileId === authContext.loggedProfileId
  const commands: IImageCommandProps[] = useMemo(() => {
    const commonComands = () => {
      const archivedRevision = document.getLastArchivedRevision()
      const draftRevision = document.getDraftRevision()
      if (isEditing) {
        return [closeDraftCommand, saveCommand, archiveCommand]
      } else {
        if (!archivedRevision && draftRevision) {
          return [contentCommand, infoCommand, doxCommand, revisionsCommand, editDraftCommand, archiveCommand]
        } else if (archivedRevision && draftRevision) {
          return [contentCommand, infoCommand, doxCommand, revisionsCommand, editDraftCommand, archiveCommand]
        } else if (archivedRevision && !draftRevision) {
          if (canCreateDraft) {
            return [contentCommand, infoCommand, doxCommand, revisionsCommand, createDraftCommand]
          } else {
            return [contentCommand, infoCommand, doxCommand, revisionsCommand]
          }
        }
      }
      return []
    }

    let _commands =
      isBusiness(authContext.loggedProfileType) && !isEditing
        ? commonComands().concat([retentionCommand])
        : commonComands()
    if (!props.isEditable) {
      _commands = _commands.filter((p) => !['createDraft', 'editDraft', 'closeDraft', 'save', 'archive'].includes(p.id))
      _commands.push(addToDesktopCommand)
    }

    return _commands
  }, [isEditing, document, document.draftRevisionId, canCreateDraft, authContext.loggedProfileType])

  const sections = useMemo(() => commands.filter(({ type }) => type === 'section'), [commands])
  useEffect(() => {
    setSectionIndex(sections.length > sectionIndex ? sectionIndex : 0)
  }, [sections.length])

  const section: IImageCommandProps = sections[sectionIndex] || contentCommand

  useEffect(() => {
    if (section.id !== 'content') {
      return
    }
    const loadContentAbortController = new AbortController()
    fetchRevisionContent(loadContentAbortController.signal)
  }, [section.id, fetchRevisionContent])

  const onSelectedRevisionChanged = useCallback(
    (revisionId: number, showContent: boolean) => {
      const _revision = document.revisions.find((p) => p.revisionId === revisionId)
      setRevision(_revision)
      if (showContent) {
        setSectionIndex(0)
      }
    },
    [document, setRevision, setSectionIndex]
  )

  // ********************
  // document changes management

  const enterEditMode = useCallback(() => {
    const draftRevision = document.getDraftRevision() || document.getLastArchivedRevision()
    setDraftRevision(draftRevision)
    setDraftDocument(document)
    setIsEditing(true)
    setSectionIndex(0)
  }, [document, setDraftRevision, setDraftDocument, setIsEditing, setSectionIndex])

  useEffect(() => {
    if (!isEditing && draftRevision) {
      setDraftRevision(undefined)
      setDraftDocument(undefined)
    } else if (isEditing && !draftRevision) {
      enterEditMode()
    }
  }, [isEditing, draftRevision, enterEditMode, setDraftDocument, setDraftRevision])

  const { isContentChanged, documentInfoFormErrors, canSave, canArchive } = getDocumentEditorSavingInfo(props)

  function canDo(commandId: string) {
    switch (commandId) {
      case 'save':
        return canSave
      case 'archive':
        return canArchive
      default:
        return true
    }
  }

  async function onClickAction(commandId: string) {
    if (commandId === 'createDraft') {
      // initialize new draft revision with last archived revision data
      const newDraftRevision = _.cloneDeep(document.getLastArchivedRevision())
      newDraftRevision!.revisionId = 0
      setDraftRevision(newDraftRevision)
      setDraftDocument(document)
      setIsEditing(true)
      setSectionIndex(0)
    } else if (commandId === 'editDraft') {
      const draftRevision = document.getDraftRevision()
      if (draftRevision) {
        setDraftRevision(draftRevision)
        setDraftDocument(document)
        setIsEditing(true)
        setSectionIndex(0)
      }
    } else if (commandId === 'closeDraft') {
      manageCloseDraftCommand()
    } else if (commandId === 'save') {
      await manageSaveCommand()
    } else if (commandId === 'archive') {
      await manageArchiveCommand()
    } else if (commandId === 'addToDesktop') {
      if (
        isOperator(authContext.loggedProfileType) &&
        document.targetProfileId !== authContext.assistedAccountProfileId
      ) {
        setSimpleDialogOpen(true)
        setSimpleDialogData({
          title: t(msgIds.MSG_DOCUMENT_SHOW_TITLE),
          content: t(msgIds.MSG_DOCUMENT_WRONG_ASSISTED_CUSTUMER),
          actionsStyle: 'Ok',
          onClose: async () => {
            setSimpleDialogOpen(false)
          },
        })
      } else {
        desktop.addDocument(document)
      }
    } else {
      log.error({ DocumentEditorOnClickAction: 'unknown command', commandId })
    }
  }

  // ********************
  // closeDraft
  function manageCloseDraftCommand() {
    if (isContentChanged) {
      setSimpleDialogOpen(true)
      setSimpleDialogData({
        title: t(msgIds.MSG_DOCUMENT),
        content: `${revision?.name}\n\n${t(msgIds.MSG_DOCUMENT_CHANGES_WILL_BE_LOST)}\n${t(msgIds.MSG_DO_YOU_CONFIRM)}`,
        actionsStyle: 'yesNO',
        onClose: (result: any) => {
          setSimpleDialogOpen(false)
          if (result.userChoice === 'yes') {
            RestoreOriginalDraftAndQuit()
          }
        },
      })
    } else {
      RestoreOriginalDraftAndQuit()
    }
  }

  function RestoreOriginalDraftAndQuit() {
    if (revision) {
      aquireDocumentAndRevision(document, revision)
    }
    setDraftRevision(undefined)
    setDraftDocument(undefined)
    if (document.documentId === 0) {
      props.onCloseDocumentEditor(true)
    } else {
      setIsEditing(false)
    }
  }

  // ********************
  // Save
  async function manageSaveCommand() {
    try {
      setIsTransmitting(true)
      if (!revision || !draftRevision || !draftDocument) {
        return
      }
      const _revisionToSave: DocumentRevision =
        draftDocument.sourceType === ArchiveItemSourceType.internalSource
          ? {
              ...draftRevision,
              content: markdownContentRef.current,
              checksum: Utils.getTextMD5Checksum(markdownContentRef.current),
            }
          : draftRevision

      const abortController = new AbortController()
      let doc: Document
      const isNewDocument = draftDocument.documentId === 0
      if (isNewDocument) {
        doc =
          draftDocument.sourceType === ArchiveItemSourceType.internalSource
            ? await dalDocument.createInternalDocument(abortController.signal, draftDocument, _revisionToSave)
            : await dalDocument.createExternalDocument(
                abortController.signal,
                draftDocument,
                draftRevision,
                setProgress
              )
      } else {
        doc =
          draftDocument.sourceType === ArchiveItemSourceType.internalSource
            ? await dalDocument.updateInternalDocument(abortController.signal, draftDocument, _revisionToSave)
            : await dalDocument.updateExternalDocument(
                abortController.signal,
                draftDocument,
                draftRevision,
                setProgress
              )
      }

      doc.doxIds = isNewDocument ? doc.doxIds : draftDocument.doxIds
      const _revision = doc.getDraftRevision()
      if (!_revision) {
        log.error('missing revision')
        return
      }
      _revision.content = _revisionToSave.content
      setDraftRevision(_revision)
      setDraftDocument(doc)
      setDocument(doc)
      setRevisionId(_revision.revisionId)

      if (draftDocument.sourceType === ArchiveItemSourceType.internalSource) {
        setMdEditorInitalText(_revisionToSave.content as string)
      }

      aquireDocumentAndRevision(doc, _revision)
      enqueueSnackbar(t(msgIds.MSG_DOCUMENT_SAVED_SUCCESSFULLY), { variant: 'success' })
    } catch (err) {
      Utils.enqueueSnackbarError2(err, t)
    } finally {
      setIsTransmitting(false)
      setProgress(0)
    }
  }

  // ********************
  // archive
  async function manageArchiveCommand() {
    if (!authContext.loggedAccount?.canDo(ActionType.updateDocuments)) {
      setSimpleDialogOpen(true)
      setSimpleDialogData({
        title: t(msgIds.MSG_DOCUMENT_EDITOR_COMMAND_ARCHIVE),
        content: t(msgIds.MSG_ERR_ACTION_TYPE_NOT_ALLOWED),
        actionsStyle: 'Ok',
        onClose: () => {
          setSimpleDialogOpen(false)
        },
      })
    } else {
      try {
        const abortController = new AbortController()
        const doc = await dalDocument.archiveDocument(abortController.signal, document.documentId)
        const _revision = revisionId === 0 ? doc.revisions[0] : doc.getRevision(revisionId)
        if (_revision && revision) {
          _revision.content = revision.content // response does not include revision content
          aquireDocumentAndRevision(doc, _revision)
        }
        setDocument(doc)
        setIsEditing(false)
      } catch (err) {
        Utils.enqueueSnackbarError2(err, t)
      }
    }
  }

  function aquireDocumentAndRevision(updatedDocument: Document, updatedRevision: DocumentRevision) {
    documentCacheContext.addDocument(updatedDocument)
    revision &&
      documentCacheContext.setRevisionContent(
        updatedDocument.documentId,
        updatedRevision.revisionId,
        updatedRevision.content
      )
  }

  // to avoid inizialization of markdown text ad every draftRevision refresh
  const [mdEditorInitalText, setMdEditorInitalText] = useState('')
  const lastCurrentDocumentIndexRef = useRef(0)
  const lastCurrentDesktopDocumentRef = useRef<IDesktopDocument>(desktop.desktopDocuments[desktop.currentDocumentIndex])
  useEffect(() => {
    if (!isDesktop) {
      if (draftRevision && isPersonalDoxMimetype(draftRevision.mimetype) === true) {
        setMdEditorInitalText((draftRevision?.content as string) ?? '')
      }
    } else {
      if (draftRevision && isPersonalDoxMimetype(draftRevision.mimetype) === true) {
        if (lastCurrentDocumentIndexRef.current !== desktop.currentDocumentIndex) {
          setMdEditorInitalText((draftRevision.content as string) ?? '')
          lastCurrentDocumentIndexRef.current = desktop.currentDocumentIndex
        } else {
          // when a new document is added that document have index = 0
          const currentDesktopDocument = desktop.desktopDocuments[desktop.currentDocumentIndex]
          const currentDocument = currentDesktopDocument.document
          const lastCurrentDocument = lastCurrentDesktopDocumentRef.current.document
          lastCurrentDesktopDocumentRef.current = currentDesktopDocument
          if (!isSameDocument(currentDocument, lastCurrentDocument)) {
            setMdEditorInitalText((draftRevision.content as string) ?? '')
          }
        }
      }
    }
  }, [desktop.currentDocumentIndex, draftRevision])

  const markdownContentRef = useRef('')

  const fontSize = isMobile ? 12 : undefined
  return (
    <Stack justifyContent="stretch" sx={{ flex: 1, overflow: 'hidden' }}>
      {simpleDialogData && <SimpleDialog {...simpleDialogData} isOpen={simpleDialogOpen}></SimpleDialog>}

      <Stack direction="row" alignItems="stretch">
        {!isEditing && (
          <>
            <Tabs
              value={sectionIndex}
              onChange={(_, index) => setSectionIndex(index)}
              sx={{ flexShrink: 0 }}
              scrollButtons="auto"
            >
              {commands
                .filter((c) => c.type === 'section')
                .map((command) => (
                  <Tab
                    key={command.id}
                    icon={command.icon}
                    wrapped={isMobile}
                    sx={{ fontSize, textTransform: 'none' }}
                    label={command.label ? t(command.label) : ''}
                  />
                ))}
            </Tabs>
            <Divider orientation="vertical" flexItem />
          </>
        )}
        {commands
          .filter((c) => c.type === 'action')
          .map((command) => (
            <Box key={command.id} display={'flex'} height={72}>
              <ImageCommand {...command} onClick={onClickAction} isEnabled={canDo(command.id)} />
            </Box>
          ))}
      </Stack>
      <Divider />
      {isTransmitting ? (
        <Stack padding={2} spacing={2} alignItems="stretch" justifyContent="center">
          <Typography align="center">{t(msgIds.MSG_DOCUMENT_EDITOR_TRANSMISSION_IN_PROGRESS)}</Typography>
          <LinearProgress variant="determinate" value={progress} />
        </Stack>
      ) : (
        <>
          {isEditing && draftRevision && draftDocument && revision ? (
            <>
              {section.id === 'content' && (
                <Stack
                  flex={1}
                  flexDirection={isMobile ? 'column-reverse' : 'row'}
                  alignItems="stretch"
                  sx={{ overflow: 'auto' }}
                >
                  {isPersonalDoxMimetype(draftRevision.mimetype) === true && (
                    <MarkdownEditor
                      height="unset"
                      style={{ flexGrow: 1, boxSizing: 'border-box' }}
                      initialValue={mdEditorInitalText}
                      onContentChanged={(md, isChanged, len) => {
                        markdownContentRef.current = md
                        setIsMarkdownContentChanged(isChanged)
                        setMarkdownContentLen(len)
                      }}
                    />
                  )}
                  {isPersonalDoxMimetype(draftRevision.mimetype) === false && (
                    <DocumentUpload
                      errors={{}}
                      style={{ flexGrow: 1 }}
                      revision={draftRevision}
                      onChangeRevision={setDraftRevision}
                      onRestoreSavedDraftContent={() => {
                        setDraftRevision({ ...draftRevision, content: revision.content })
                      }}
                    />
                  )}
                  {isPersonalDoxMimetype(draftRevision.mimetype) === undefined && (
                    <Box width="100%" height="100%">
                      <Typography>{t(msgIds.MSG_DOCUMENT_EDITOR_DRAFT_PREPARATION_IN_PROGRESS)}</Typography>
                    </Box>
                  )}
                  <Divider orientation="vertical" flexItem />

                  <Accordion elevation={0} defaultExpanded={true}>
                    <AccordionSummary expandIcon={<ExpandMoreIco />}>
                      <Typography variant="body1">
                        <strong>{t(msgIds.MSG_DOCUMENT_EDITOR_INFO)}</strong>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Stack style={{ overflowY: 'auto', width: isMobile ? 'auto' : '435px' }}>
                        <DocumentInfoForm
                          style={{ width: isMobile ? 'auto' : '400px' }}
                          errors={documentInfoFormErrors}
                          document={draftDocument}
                          onChangeDocument={setDraftDocument}
                          revision={draftRevision}
                          onChangeRevision={setDraftRevision}
                        />
                        <DocumentDoxDetails
                          applyChangesNow={!(isEditing && draftDocument.documentId === 0)}
                          document={draftDocument}
                          onEditedDocumentAssignedDoxChanged={props.onEditedDocumentAssignedDoxChanged}
                          isEditable={props.isEditable}
                        />
                      </Stack>
                    </AccordionDetails>
                  </Accordion>
                </Stack>
              )}
            </>
          ) : (
            <Stack justifyContent="stretch" alignItems="stretch" sx={{ flex: 1, overflow: 'auto' }}>
              {revision && section.id === 'info' && <DocumentInfoDetails document={document} revision={revision} />}
              {revision && section.id === 'dox' && (
                <DocumentDoxDetails
                  applyChangesNow={!(isEditing && document.documentId === 0)}
                  document={document}
                  onEditedDocumentAssignedDoxChanged={props.onEditedDocumentAssignedDoxChanged}
                  isEditable={props.isEditable}
                />
              )}
              {revision && section.id === 'content' && (
                <DocumentContent document={document} revision={revision} isLoading={isTransmitting} />
              )}
              {revision && section.id === 'revisions' && (
                <Box padding={2}>
                  <DocumentRevisionsTable
                    document={document}
                    revision={revision}
                    onChangeRevisionId={onSelectedRevisionChanged}
                  />
                </Box>
              )}
              {revision && section.id === 'retention' && (
                <Box padding={2}>
                  <DocumentRetentions
                    document={document}
                    isEditable={props.isEditable}
                    onCloseDocumentEditor={props.onCloseDocumentEditor}
                  />
                </Box>
              )}
            </Stack>
          )}
        </>
      )}
    </Stack>
  )
}
const infoCommand: IImageCommandProps = {
  id: 'info',
  icon: <InfoIco />,
  label: msgIds.MSG_DOCUMENT_EDITOR_COMMAND_INFO,
  type: 'section',
  orientation: 'column',
}
const doxCommand: IImageCommandProps = {
  id: 'dox',
  icon: <DoxIco />,
  label: msgIds.MSG_DOCUMENT_EDITOR_COMMAND_DOX,
  type: 'section',
  orientation: 'column',
}
const createDraftCommand: IImageCommandProps = {
  id: 'createDraft',
  icon: <EditIco sx={{ color: 'gray' }} />,
  label: msgIds.MSG_DOCUMENT_EDITOR_COMMAND_CREATE_DRAFT,
  type: 'action',
  orientation: 'column',
}
const editDraftCommand: IImageCommandProps = {
  id: 'editDraft',
  icon: <DraftIco sx={{ color: 'gray' }} />,
  label: msgIds.MSG_DOCUMENT_EDITOR_COMMAND_EDIT_DRAFT,
  type: 'action',
  orientation: 'column',
}
const closeDraftCommand: IImageCommandProps = {
  id: 'closeDraft',
  icon: <CloseIco />,
  label: msgIds.MSG_DOCUMENT_EDITOR_COMMAND_CLOSE_DRAFT,
  type: 'action',
  orientation: 'column',
}
const contentCommand: IImageCommandProps = {
  id: 'content',
  icon: <PreviewIco />,
  label: msgIds.MSG_DOCUMENT_EDITOR_COMMAND_CONTENT,
  type: 'section',
  orientation: 'column',
}
const revisionsCommand: IImageCommandProps = {
  id: 'revisions',
  icon: <VersionsIco />,
  label: msgIds.MSG_DOCUMENT_EDITOR_COMMAND_REVISIONS,
  type: 'section',
  orientation: 'column',
}
const retentionCommand: IImageCommandProps = {
  id: 'retention',
  icon: <RetentionIco />,
  label: msgIds.MSG_DOCUMENT_EDITOR_COMMAND_RETENTION,
  type: 'section',
  orientation: 'column',
}
const saveCommand: IImageCommandProps = {
  id: 'save',
  icon: <SaveIco />,
  label: msgIds.MSG_DOCUMENT_EDITOR_COMMAND_SAVE,
  type: 'action',
  orientation: 'column',
}
const archiveCommand: IImageCommandProps = {
  id: 'archive',
  icon: <ArchiveIco />,
  label: msgIds.MSG_DOCUMENT_EDITOR_COMMAND_ARCHIVE,
  type: 'action',
  orientation: 'column',
}
const addToDesktopCommand: IImageCommandProps = {
  id: 'addToDesktop',
  icon: <AddToDesktopIco />,
  label: msgIds.MSG_DOC_COMMAND_ADD_DOCUMENT_TO_DESKTOP,
  type: 'action',
  orientation: 'column',
}
