import { useTranslation } from 'react-i18next'
import msgIds from '../../locales/msgIds'
import { EditorFormContainer } from '../editorFormContainer/EditorFormContainer'
import { SwitchRow } from '../switchRow/SwitchRow'
import { ViewActions } from '../viewActions/ViewActions'
import { ViewActionsButton } from '../viewActions/ViewActionsButton'
import { ActionType } from '../../shared/Constants'
import { useEffect, useMemo, useState } from 'react'
import * as dalPermission from '../../dal/DalPermission'
import { Permission } from '../../models/Permission'
import { Utils } from '../../shared/Utils'
import { ActionsValuesMap, IPrivacyManagementProps } from './BooleanPermissionsManagment.types'

export function BooleanPermissionsManagement(props: IPrivacyManagementProps): JSX.Element {
  const { profileId, structureProfileId, actionsLabels, isEditMode, disabled } = props
  const { t } = useTranslation()
  const [actions, setActions] = useState<ActionsValuesMap>({})
  const [permissions, setPermissions] = useState<Permission[]>([])

  const actionsFromPermissions = useMemo(() => {
    const actionsTypes = actionsLabels.map((al) => al.action.toString())
    return permissions.reduce((actions, permission) => {
      if (actionsTypes.includes(permission.action)) {
        actions[permission.action] = true
      }
      return actions
    }, {} as ActionsValuesMap)
  }, [permissions, actionsLabels])

  const changed = useMemo(() => {
    return Object.keys(actions).reduce((changed, key) => {
      const currentValue = actions[key] || false
      const permissionValue = actionsFromPermissions[key] || false
      return changed || currentValue !== permissionValue
    }, false)
  }, [actionsFromPermissions, actions])

  useEffect(() => {
    setPermissions(props.permissions ?? [])
  }, [props.permissions])

  function initFields() {
    setActions({ ...actionsFromPermissions })
  }

  useEffect(() => {
    initFields()
  }, [permissions])

  function onChange(action: ActionType, value: boolean) {
    setActions((as) => {
      const newState = { ...as, [action]: value }
      if (value) {
        const toTurnOn = actionsLabels.filter((p) => p.onIfOthersOn?.includes(action))
        toTurnOn.forEach((p) => {
          newState[p.action] = true
        })
      } else {
        const toTurnOff = actionsLabels.filter((p) => p.offIfOthersOff?.includes(action))
        toTurnOff.forEach((p) => {
          newState[p.action] = false
        })
      }
      return newState
    })
  }

  async function onSave() {
    const { toDelete, toCreate } = Object.keys(actions).reduce(
      (res, key) => {
        const currentValue = actions[key] || false
        const permissionValue = actionsFromPermissions[key] || false
        if (currentValue === permissionValue) {
          return res
        }
        if (currentValue) {
          res.toCreate.push(key)
        } else {
          res.toDelete.push(key)
        }
        return res
      },
      { toDelete: [] as string[], toCreate: [] as string[] }
    )
    const abortController = new AbortController()
    const deletePromises = permissions
      .filter((p) => toDelete.includes(p.action))
      .map((p) => dalPermission.deletePermission(abortController.signal, p.id))
    const createPromises = toCreate.map((action) =>
      dalPermission.createPermission(abortController.signal, {
        profileId: profileId,
        action: action,
        targetId: structureProfileId,
        startAt: new Date(),
      })
    )
    try {
      const deleteIds = await Promise.all(deletePromises)
      const createdPermissions = await Promise.all(createPromises)
      setPermissions((ps) => {
        return ps.filter((p) => !deleteIds.includes(p.id)).concat(createdPermissions)
      })

      props.onSave && props.onSave()
    } catch (error) {
      Utils.enqueueSnackbarError2(error, t)
    }
  }

  function onCancel() {
    initFields()
    props.onCancel && props.onCancel()
  }

  return (
    <EditorFormContainer spacing={1}>
      {actionsLabels.map((al, i) => (
        <SwitchRow
          key={al.action}
          divider={false}
          disabled={disabled || !isEditMode}
          label={t(al.label)}
          checked={actions[al.action] || false}
          onChange={(_, checked) => onChange(al.action, checked)}
        />
      ))}
      {isEditMode && (
        <ViewActions justifyContent={'center'} sx={{ paddingTop: 6 }}>
          <ViewActionsButton autoFocus defaultAction onClick={onSave} disabled={!changed}>
            {t(msgIds.MSG_SAVE)}
          </ViewActionsButton>
          <ViewActionsButton onClick={onCancel}>{t(msgIds.MSG_CANCEL)}</ViewActionsButton>
        </ViewActions>
      )}
    </EditorFormContainer>
  )
}
