import { Avatar, Skeleton, Stack, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'

import msgIds from '../../locales/msgIds'
import { ProfileType } from '../../shared/Constants'
import { IAccountInfoIdentityProps } from './AccountInfoIdentity.types'
import FieldsPanel from '../fieldsPanel/FieldsPanel'
import FieldInfo from '../fieldInfo/FieldInfo'

export default function AccountInfoIdentity(props: IAccountInfoIdentityProps) {
  const theme = useTheme()
  const { t } = useTranslation()

  const hasLastname = () => {
    return props.account.userDetails?.lastname
  }
  const hasName = () => {
    return props.account.userDetails?.name
  }
  const hasAnyField = () => {
    return hasLastname() || hasName()
  }

  return (
    <>
      {props.isLoading && (
        <Stack direction="column" alignItems="center" padding={1} spacing={2}>
          <Skeleton variant="rectangular" width={'100%'} height={130} />
        </Stack>
      )}
      {!props.isLoading && hasAnyField() && (
        <Stack direction={'column'} alignItems={'stretch'} padding={1} spacing={3}>
          <FieldsPanel>
            <Avatar
              sx={{ width: 80, height: 80, alignSelf: 'center', border: `1px solid ${theme.palette.common.gray7}` }}
              alt={props.account.getIdentityInverse()}
              src={props.account.profile?.avatarImage}
            >
              {props.account.getInitials()}
            </Avatar>

            {(hasLastname() || hasName()) && (
              <FieldInfo
                title={
                  props.account.profile?.type === ProfileType.structure ? t(msgIds.MSG_NAME) : t(msgIds.MSG_FULLNAME)
                }
                text={props.account.getIdentityInverse()}
              />
            )}
          </FieldsPanel>
        </Stack>
      )}
    </>
  )
}
