import msgIds from './msgIds'

export default {
  [msgIds.MSG_PERSONALDOX]: 'PersonalDOX',
  [msgIds.MSG_OK]: 'Ok',
  [msgIds.MSG_YES]: 'Sì',
  [msgIds.MSG_NO]: 'No',
  [msgIds.MSG_ABORT]: 'Annulla',
  [msgIds.MSG_CONFIRM]: 'Conferma',
  [msgIds.MSG_CONTINUE]: 'Continua',
  [msgIds.MSG_DO_YOU_CONFIRM]: 'Confermi?',
  [msgIds.MSG_ADD]: 'Aggiungi',
  [msgIds.MSG_DATE]: '{{val, datetime}}',
  [msgIds.MSG_DRAFT]: 'Bozza',
  [msgIds.MSG_DOX]: 'Dox',
  [msgIds.MSG_DOCUMENT]: 'Documento',
  [msgIds.MSG_AUTHORIZATIONS]: 'Autorizzazioni',
  [msgIds.MSG_DIMENSION]: 'Dimensione',
  [msgIds.MSG_MYSELF]: 'Me stesso',
  [msgIds.MSG_FINALITY]: 'Finalità',
  [msgIds.MSG_RETENTION]: 'Conservazione',
  [msgIds.MSG_DAY]: 'giorno',
  [msgIds.MSG_DAYS]: 'giorni',
  [msgIds.MSG_WEEK]: 'settimana',
  [msgIds.MSG_WEEKS]: 'settimane',
  [msgIds.MSG_MONTH]: 'mese',
  [msgIds.MSG_MONTHS]: 'mesi',
  [msgIds.MSG_YEAR]: 'anno',
  [msgIds.MSG_YEARS]: 'anni',
  [msgIds.MSG_LOADING_IN_PROGRESS]: 'Caricamento in corso...',
  [msgIds.MSG_NECESSARY]: 'Necessarie',
  [msgIds.MSG_OPTIONAL]: 'Facoltative',
  [msgIds.MSG_BORN_ON]: 'Nato il',
  [msgIds.MSG_NEW_TREATMENT_FIELD]: 'Nuovo campo',
  [msgIds.MSG_MOVE_UP]: 'Sposta in alto',
  [msgIds.MSG_MOVE_DOWN]: 'Sposta in basso',
  [msgIds.MSG_DELETE]: 'Elimina',
  [msgIds.MSG_MODIFY]: 'Modifica',
  [msgIds.MSG_FROM]: 'Dal',
  [msgIds.MSG_TO]: 'Al',
  [msgIds.MSG_DISABLED]: 'Disabilitato',
  [msgIds.MSG_PROFILE_INFORMATIONS]: 'Informazioni profilo',
  [msgIds.MSG_MANDATORY]: 'Obbligatorio',
  [msgIds.MSG_OPTIONAL2]: 'Facoltativo',
  [msgIds.MSG_OPERATION_EXECUTED_SUCCESSFULLY]: 'Operazione effettuata con successo',
  [msgIds.MSG_LANGUAGE]: 'Lingua',
  [msgIds.MSG_LANGUAGE_ITALIAN]: 'Italiano',
  [msgIds.MSG_LANGUAGE_ENGLISH]: 'Inglese',
  [msgIds.MSG_FUNCTION_NOT_AVAILABLE]: "Questa funzione non è disponibile. Contattare l'amministratore",
  [msgIds.MSG_SUBSCRIPTION]: 'Sottoscrizione',
  [msgIds.MSG_LOGIN]: 'Connetti',
  [msgIds.MSG_LOGOUT]: 'Disconnetti',
  [msgIds.MSG_PRODUCTS]: 'Prodotti',
  [msgIds.MSG_INVOICES]: 'Fatture',
  [msgIds.MSG_VAT]: 'IVA',
  [msgIds.MSG_FIELD]: 'Campo',
  [msgIds.MSG_PRIVATE]: 'Privato',
  [msgIds.MSG_SHARED]: 'Condiviso',
  [msgIds.MSG_CONTRACT]: 'Contratto',

  // leftbar
  [msgIds.MSG_ARCHIVE_MANAGEMENT]: 'Gestione archivio',
  [msgIds.MSG_DESKTOP]: 'Scrivania',
  [msgIds.MSG_PERSONAL_ARCHIVE]: 'Archivio personale',
  [msgIds.MSG_DOX_SHARED_BY_WITH]: 'Dox condiviso da {{owner}} con {{guest}}',
  [msgIds.MSG_RECEIVED_DOX]: 'Dox ricevuti',
  [msgIds.MSG_SETTINGS]: 'Impostazioni',
  [msgIds.MSG_PERSONAL_SETTINGS]: 'Impostazioni personali',
  [msgIds.MSG_CONFIGURATIONS]: 'Configurazioni',
  [msgIds.MSG_PERSONAL_INFORMATIONS]: 'Informazioni',
  [msgIds.MSG_PRESENTATION]: 'Presentazione',
  [msgIds.MSG_STRUCTURE_SETTINGS]: 'Impostazioni struttura',
  [msgIds.MSG_STRUCTURE_INFORMATIONS]: 'Informazioni',
  [msgIds.MSG_OPERATIVE_PRIVACY_MODEL]: 'Modello Organizzativo Privacy',
  [msgIds.MSG_TREATMENT_ACTIVITIES]: 'Attività di trattamento',
  [msgIds.MSG_PRIVACY_POLICIES]: 'Informative privacy',
  [msgIds.MSG_CHANGE_HISTORY]: 'Cronologia eventi',
  [msgIds.MSG_USER_MANAGEMENT]: 'Gestione utente',
  [msgIds.MSG_SHARED_ARCHIVE]: 'Archivio condiviso',
  [msgIds.MSG_STRUCTURE_ARCHIVE]: 'Archivio aziendale',
  // users and profiles
  [msgIds.MSG_PRIVATE_USER]: 'Utente privato',
  [msgIds.MSG_ORGANIZATION]: 'Organizzazione',
  [msgIds.MSG_OPERATOR]: 'Operatore',
  [msgIds.MSG_COLLABORATOR]: 'Collaboratore',
  [msgIds.MSG_ADMIN_LO]: 'admin',
  [msgIds.MSG_INTERNAL_LO]: 'interno',
  [msgIds.MSG_EXTERNAL_LO]: 'esterno',
  [msgIds.MSG_STRUCTURE]: 'Struttura',
  [msgIds.MSG_USER]: 'Utente',
  [msgIds.MSG_USER_DETAILS]: 'Dettagli utente',
  // topbar
  [msgIds.MSG_USER_MENU]: 'Menu utente',
  [msgIds.MSG_STRUCTURES]: 'Strutture',
  [msgIds.MSG_OPERATORS]: 'Operatori',
  [msgIds.MSG_COLLABORATORS]: 'Collaboratori',
  [msgIds.MSG_USERS]: 'Utenti',
  [msgIds.MSG_NOTICE_BOARD]: 'Bacheca',
  [msgIds.MSG_NOTICE_GUIDE]: 'Guida',
  [msgIds.MSG_NOTIFICATIONS]: 'Notifiche',
  // search dialog
  [msgIds.MSG_SEARCH]: 'Cerca...',
  [msgIds.MSG_SEARCH_FOR_STRUCTURES]: 'Cerca strutture',
  [msgIds.MSG_SEARCH_FOR_OPERATORS]: 'Cerca operatori',
  [msgIds.MSG_SEARCH_FOR_COLLABORATORS]: 'Cerca collaboratori',
  [msgIds.MSG_SEARCH_FOR_CUSTOMERS]: 'Cerca utenti',
  [msgIds.MSG_LOAD_MORE_ELEMENTS]: 'Carica altri elementi...',
  [msgIds.MSG_CREATE_NEW_PLACEHOLDER_USER]: 'Crea nuovo utente',
  [msgIds.MSG_CREATE_NEW_PLACEHOLDER_USER_TITLE]: 'Creazione nuovo utente',
  [msgIds.MSG_CREATE_NEW_PLACEHOLDER_USER_BODY]:
    "Per aggiungere un nuovo utente devi prima pubblicare una informativa privacy.\nVai nell'area 'Modello Organizzativo Privacy', configura i trattamenti che vuoi fare sui dati dell'utente, redigi e pubblica l'informativa privacy.",
  // word confirm dialog
  [msgIds.MSG_WORD_CONFIRM_DIALOG_TITLE]: 'conferma',
  [msgIds.MSG_WORD_CONFIRM_DIALOG_BODY]: 'Per confermare scrivi la parola {{word}} e premi Ok',
  [msgIds.MSG_WORD_CONFIRM_FIELD_LABEL]: 'Parola per la conferma',
  [msgIds.MSG_WORD_CONFIRM_CLOSE]: 'CHIUDI',
  [msgIds.MSG_WORD_CONFIRM_INVALID]: 'La parola inserita non è valida',
  // fields
  [msgIds.MSG_LASTNAME]: 'Cognome',
  [msgIds.MSG_NAME]: 'Nome',
  [msgIds.MSG_COMPANY_NAME]: 'Ragione sociale',
  [msgIds.MSG_FULLNAME]: 'Cognome Nome',
  [msgIds.MSG_ADDRESS]: 'Indirizzo',
  [msgIds.MSG_PHONE]: 'Telefono',
  [msgIds.MSG_MOBILE1]: 'Cellulare 1',
  [msgIds.MSG_MOBILE2]: 'Cellulare 2',
  [msgIds.MSG_EMAIL]: 'E-Mail',
  [msgIds.MSG_EMAIL1]: 'Mail 1',
  [msgIds.MSG_EMAIL2]: 'Mail 2',
  [msgIds.MSG_EMAIL3]: 'Mail 3',
  [msgIds.MSG_EMAIL4]: 'Mail 4',
  [msgIds.MSG_PEC]: 'PEC',
  [msgIds.MSG_WEBSITE]: 'Sito web',
  [msgIds.MSG_OTP]: 'OTP',
  //
  [msgIds.MSG_DETAILS]: 'Dettagli',
  [msgIds.MSG_DETAILS_OWNED_BY_STRUCTURE]: 'Informazioni inserite dalla tua struttura',
  [msgIds.MSG_DETAILS_OWNED_BY_CUSTOMER]: "Informazioni inserite dall'utente",
  [msgIds.MSG_CLEAR]: 'Cancella',
  [msgIds.MSG_CLOSE]: 'Chiudi',
  [msgIds.MSG_SAVE]: 'Salva',
  [msgIds.MSG_CANCEL]: 'Annulla',
  [msgIds.MSG_REQUEST_MODIFICATION]: 'Richiedi modifica',
  [msgIds.MSG_DAYS_IN_ADVANCE]: 'Giorni di anticipo',
  [msgIds.MSG_QUANTITY]: 'Quantità',
  [msgIds.MSG_SEND]: 'Invia',
  [msgIds.MSG_COPY_LINK]: 'Copia collegamento',
  [msgIds.MSG_UNSHARE]: 'Annulla condivisione',

  // ProfileType
  [msgIds.MSG_PROFILE_TYPE_NONE]: 'Nessuno',
  [msgIds.MSG_PROFILE_TYPE_CUSTOMER]: 'Utente privato',
  [msgIds.MSG_PROFILE_TYPE_OPERATOR_ADMIN]: 'Amministratore di struttura',
  [msgIds.MSG_PROFILE_TYPE_OPERATOR_EXT]: 'Collaboratore esterno',
  [msgIds.MSG_PROFILE_TYPE_OPERATOR_INT]: 'Collaboratore interno',
  [msgIds.MSG_PROFILE_TYPE_STRUCTURE]: 'Struttura',
  [msgIds.MSG_PROFILE_TYPE_TUTOR]: 'Tutore',

  // ActionType
  [msgIds.MSG_ACTION_TYPE_VIEW_DOCUMENTS]: 'Visualizzare documenti',
  [msgIds.MSG_ACTION_TYPE_DOWNLOAD_DOCUMENTS]: 'Scaricare documenti',
  [msgIds.MSG_ACTION_TYPE_CREATE_DOCUMENTS]: 'Creare documenti',
  [msgIds.MSG_ACTION_TYPE_UPDATE_DOCUMENTS]: 'Modificare documenti',
  [msgIds.MSG_ACTION_TYPE_ARCHIVE_DOCUMENTS]: 'Archiviare documenti',
  [msgIds.MSG_ACTION_TYPE_DELETE_DOCUMENTS]: 'Eliminare documenti (se possibile)',
  [msgIds.MSG_ACTION_TYPE_VIEW_DOX]: 'Visualizzare dox',
  [msgIds.MSG_ACTION_TYPE_DOWNLOAD_DOX]: 'Scaricare dox',
  [msgIds.MSG_ACTION_TYPE_CREATE_DOX]: 'Creare dox',
  [msgIds.MSG_ACTION_TYPE_UPDATE_DOX]: 'Modificare/Spostare dox',
  [msgIds.MSG_ACTION_TYPE_DELETE_DOX]: 'Cancellare dox',

  // abbreviations
  MSG_PROFILE_TITLE_ABBREVIATION_ARCH: 'Arch.',
  MSG_PROFILE_TITLE_ABBREVIATION_AVV: 'Avv.',
  MSG_PROFILE_TITLE_ABBREVIATION_DR: 'Dr.',
  MSG_PROFILE_TITLE_ABBREVIATION_ING: 'Ing.',
  MSG_PROFILE_TITLE_ABBREVIATION_PROF: 'Prof.',

  // rest errors
  [msgIds.MSG_ERR_FORBIDDEN]: 'Operazione non consentita',
  [msgIds.MSG_AN_ERROR_HAS_OCCURRED]: 'Si è verificato un errore',
  [msgIds.MSG_ERR_INVALID_EMAIL_OR_PWD_DSC]: 'Credenziali di accesso errate',
  [msgIds.MSG_ERR_TREATMENT_CODE_UNIQUE_VIOLATION_DSC]: 'Codice interno già utilizzato',
  [msgIds.MSG_ERR_TREATMENT_CUSTOM_FIELD_NOT_UNIQUE_NAME_DSC]: 'Il nome di questo campo è già utilizzato',
  [msgIds.MSG_ERR_MISSING_DOX_WITH_RETENTION_DSC]:
    'Il documento deve essere associato ad almeno un dox configurato per la conservazione dei dati',
  [msgIds.MSG_ERR_DUPLICATED_PERMISSION_DSC]: 'Questa autorizzazione è già stata creata',
  [msgIds.MSG_ERR_TREATMENT_IN_USE_DSC]: "L'operazione non è consentita perchè il trattamento è in uso",
  [msgIds.MSG_ERR_INVALID_TOKEN_DSC]: 'Codice TOTP non valido',
  [msgIds.MSG_ERR_INVALID_PIN_DSC]: 'Codice PIN non valido',
  [msgIds.MSG_ERR_FEATURE_SET_LIMITED_DSC]: 'Contattare la struttura per maggiori informazioni',
  [msgIds.MSG_ERR_INVITATION_NOT_ACCEPTABLE_DSC]: "L'invito non è più disponibile",
  [msgIds.MSG_ERR_INVITATION_NOT_REVOKABLE_DSC]: "L'invito è già stato accettato",
  [msgIds.MSG_ERR_MISSING_EXPLANATION_TO_AVOID_NEW_ACKNOWLEDGMENT_DSC]:
    "È stato richiesto di accedere a informazioni aggiuntive. È necessario annotare il motivo per cui non si richiede una nuova presa visione dell'informativa.",
  [msgIds.MSG_ERR_MISSING_EXPLANATION_TO_AVOID_NEW_CONSENTS_DSC]:
    'È stato richiesto di accedere a informazioni aggiuntive. È necessario annotare il motivo per cui non si richiede un nuovo consenso.',
  [msgIds.MSG_ERR_MISSING_COMPANY_NAME_DSC]: "Manca il nome dell'azienda",
  // billing errors
  [msgIds.MSG_ERR_INVALID_COUPON_DSC]: 'Codice sconto non valido',
  [msgIds.MSG_ERR_INVALID_PROMOTION_CODE_DSC]: 'Codice sconto non valido',
  [msgIds.MSG_ERR_STRIPE_INVALID_CUSTOMER_DSC]: 'Codice cliente non trovato',
  [msgIds.MSG_ERR_EMAIL_ALREADY_USED_DSC]: 'E-Mail già utilizzata',
  [msgIds.MSG_ERR_INVALID_FISCAL_CODE_DSC]: 'Codice fiscale non valido',
  [msgIds.MSG_ERR_DUPLICATED_FISCAL_CODE_DSC]: 'Codice fiscale già utilizzato',
  [msgIds.MSG_ERR_DUPLICATED_VAT_NUMBER_DSC]: 'Partita iva già utilizzata',
  [msgIds.MSG_ERR_MISSING_MANDATORY_AGREEMENT_DSC]: 'Si è verificato un errore durante la procedura di registrazione',
  [msgIds.MSG_ERR_MISSING_PROFILE_TYPE_DSC]: 'Si è verificato un errore durante la procedura di registrazione',
  // payment method card errors
  [msgIds.MSG_ERR_INSUFFICIENT_FUNDS_DSC]: 'Carta rifiutata: fondi insufficienti',
  [msgIds.MSG_ERR_LOST_CARD_DSC]: 'Carta rifiutata: smarrita',
  [msgIds.MSG_ERR_STOLEN_CARD_DSC]: 'Carta rifiutata: rubata',
  [msgIds.MSG_ERR_CARD_DECLINED_DSC]: 'Carta rifiutata',
  [msgIds.MSG_ERR_EXPIRED_CARD_DSC]: 'Carta scaduta',
  [msgIds.MSG_ERR_INCORRECT_CVC_CODE_DSC]: 'Codice cvc errato',
  [msgIds.MSG_ERR_PROCESSING_ERROR_DSC]: 'Errore di elaborazione',
  [msgIds.MSG_ERR_INCORRECT_CARD_NUMBER_DSC]: 'Numero carta errato',
  [msgIds.MSG_ERR_REQUIRED_PAYMENT_METHOD_DSC]:
    'Iscrizione non completata: il pagamento non è andato a buon fine, verificare che la carta utilizzata sia idonea',
  [msgIds.MSG_ERR_CANNOT_CONFIR_PAYMENT_METHOD_DSC]:
    'Non riusciamo ad autenticare la tua modalità di pagamento. Scegli una diversa modalità di pagamento e riprova',
  [msgIds.MSG_ERR_TRIAL_ALREADY_USED_DSC]: 'Il periodo di prova è già stato attivato',
  // validation errors
  [msgIds.MSG_VAL_ERR_THERE_ARE_FORM_ERRORS]: 'Ci sono alcuni errori di compilazione del form',
  [msgIds.MSG_VAL_ERR_SOME_MANDATORY_CONSENTS_ARE_MISSING]: 'Mancano alcuni consensi obbligatori',
  [msgIds.MSG_VAL_ERR_REQUIRED_FIELD]: 'Campo necessario',
  [msgIds.MSG_VAL_ERR_WRONG_MAIL_FORMAT]: 'Formato email errato',
  [msgIds.MSG_VAL_ERR_WRONG_PHONE_FORMAT]: 'Formato numero di telefono errato',
  [msgIds.MSG_VAL_ERR_WRONG_WEBSITE_FORMAT]: 'Formato url errato',
  [msgIds.MSG_VAL_ERR_WRONG_FISCAL_CODE_FORMAT]: 'Formato codice fiscale errato',
  [msgIds.MSG_VAL_ERR_WRONG_VAT_NUMBER_FORMAT]: 'Formato partita iva errato',
  [msgIds.MSG_VAL_ERR_WRONG_SDI_CODE_FORMAT]: 'Formato codice SDI errato',
  [msgIds.MSG_VAL_ERR_TOO_SHORT]: 'La lunghezza minima è di caratteri ',
  [msgIds.MSG_VAL_ERR_LENGTH_MUST_BE]: 'La lunghezza deve essere di caratteri ',
  [msgIds.MSG_VAL_ERR_MAX_LENGTH_MUST_BE]: 'La lunghezza massima è di caratteri ',
  [msgIds.MSG_VAL_ERR_MIN_LENGTH_MUST_BE]: 'La lunghezza minima è di caratteri ',
  [msgIds.MSG_VAL_ERR_MAX_MUST_BE]: 'Il valore minimo è',
  [msgIds.MSG_VAL_ERR_MIN_MUST_BE]: 'Il valore massimo è ',
  [msgIds.MSG_VAL_ERR_MUST_CONTAIN_UPPERCASE]: 'Deve contenere almeno un carattere maiuscolo',
  [msgIds.MSG_VAL_ERR_MUST_CONTAIN_NUMBER]: 'Deve contenere almeno un numero',
  [msgIds.MSG_VAL_ERR_CANNOT_CONTAIN_SPACES]: 'Non può contenere spazi',
  [msgIds.MSG_VAL_ERR_WRONG_PASSWORD_FORMAT]: 'Formato password non consentito',
  [msgIds.MSG_VAL_ERR_INVALID_DATE]: 'Data non valida',
  [msgIds.MSG_VAL_ERR_DUPLICATED_NAME]: 'Nome duplicato',
  [msgIds.MSG_VAL_ERR_PASSOWRD_NOT_CONFIRMED]: 'Password non confermata',
  [msgIds.MSG_VAL_ERR_WRONG_DATE_RANGE]: 'Intervallo di date errato',
  // action type errors
  [msgIds.MSG_ERR_ACTION_TYPE_NOT_ALLOWED]: 'Non sei autorizzato a compiere questa operazione',
  [msgIds.MSG_ERR_SUBSCRIPTION_NOT_FOUNDED_PLAN]: 'Si è verificato un errore nella selezione del piano',
  // generic errors
  [msgIds.MSG_ERR_LOADING_PAGE_ERROR]: 'La pagina non può essere caricata correttamente',

  // Treatments
  [msgIds.MSG_NEW_TREATMENT]: 'Nuovo trattamento',
  [msgIds.MSG_NEW_TREATMENT_TOOLTIP_TEXT]: 'Crea un nuovo trattamento',
  [msgIds.MSG_TREATMENT_ACTIVITIES_CODE]: 'Codice',
  [msgIds.MSG_TREATMENT_FINALITY]: 'Finalità',
  [msgIds.MSG_TREATMENT_LEGAL_BASE]: 'Base giuridica',
  [msgIds.MSG_TREATMENT_RETENTION]: 'Conservazione',
  [msgIds.MSG_TREATMENT_NOT_ACTIVE]: 'Non attivo',
  [msgIds.MSG_TREATMENT_ACTIVITY_DETAILS]: "Dettagli dell'attivià di trattamento",
  [msgIds.MSG_SHOW_ONLY_ACTIVE_TREATMENTS]: 'Visualizza solo i trattamenti attivi',
  [msgIds.MSG_TREATMENT_DISABLE]: 'Disattiva trattamento',
  [msgIds.MSG_TREATMENT_ENABLE]: 'Attiva trattamento',
  [msgIds.MSG_TREATMENT_DELETE]: 'Elimina trattamento',
  [msgIds.MSG_TREATMENT_BASIC_FEATURES]: 'Caratteristiche di base',
  [msgIds.MSG_TREATMENT_INTRO]: 'In questa sezione vengono configurate le caratteristiche del trattamento dei dati.',
  [msgIds.MSG_TREATMENT_ATTENTION_WARNING]:
    "Queste impostazioni avranno effetto sul comportamento di PersonalDOX in merito alla gestione dell'archivio aziendale.",
  [msgIds.MSG_TREATMENT_PRIVACY_EXPERT_RECOMMENDATION]:
    'Se hai dubbi su come configurare questa sezione chiedi il supporto tecnico di un esperto privacy.',
  [msgIds.MSG_TREATMENT_INTERNAL_CODE]: 'Codice interno',
  [msgIds.MSG_TREATMENT_SHORT_DESCRIPTION]: 'Descrizione breve',
  [msgIds.MSG_TREATMENT_PURPOSE]: 'Finalità di questo trattamento',
  [msgIds.MSG_TREATMENT_LEGAL_BASES_TITLE]: 'Basi giuridiche',
  [msgIds.MSG_TREATMENT_ATTENTION]: 'Attenzione!',
  [msgIds.MSG_TREATMENT_LEGAL_BASE_DESCRIPTION]:
    "La base giuridica del trattamento descrive il motivo per il quale sei autorizzato a trattare i dati dell'utente",
  [msgIds.MSG_TREATMENT_DATA_RETENTION]: 'Conservazione dei dati',
  [msgIds.MSG_TREATMENT_DATA_MAINTAINED]: 'I dati trattati per questa finalità devono essere conservati',
  [msgIds.MSG_TREATMENT_STORAGE_PERIOD]: 'Periodo per la conservazione',
  [msgIds.MSG_TREATMENT_SETTINGS_AFFECT_DOX]:
    'Le seguenti impostazioni verranno applicate ai dox nei quali attiverai la conservazione con queste finalità di trattamento',
  [msgIds.MSG_TREATMENT_NOTIFY_END_OF_CONSERVATION]: 'Invia una notifica prima del termine della conservazione',
  [msgIds.MSG_TREATMENT_ACTION_AT_CONSERVATION_END]: 'Azione al termine della conservazione',
  [msgIds.MSG_TREATMENT_MANDATORY_FIELDS]: 'Campi obbligatori',
  [msgIds.MSG_TREATMENT_OPTIONAL_FIELDS]: 'Campi facoltativi',
  [msgIds.MSG_TREATMENT_CONFIRM_DELETE_TITLE]: 'Cancellazione trattamento',
  [msgIds.MSG_TREATMENT_CONFIRM_DELETE_TEXT]: 'Sei sicuro di voler eliminare questo trattamento?',
  [msgIds.MSG_TREATMENT_CONFIRM_DISABLE_TITLE]: 'Disattivazione trattamento',
  [msgIds.MSG_TREATMENT_CONFIRM_DISABLE_TEXT]:
    'Sei sicuro di voler disattivare questo trattamento?\nQuesta operazione può essere effettuata solamente se il trattamento non è utilizzato da PersonalDOX per la conservazione dei dati',
  [msgIds.MSG_TREATMENT_CONFIRM_ENABLE_TITLE]: 'Attivazione trattamento',
  [msgIds.MSG_TREATMENT_CONFIRM_ENABLE_TEXT]: 'Sei sicuro di voler attivare nuovamente questo trattamento?',
  [msgIds.MSG_TREATMENT_NO_SELECTABLE_ITEMS]: 'Non ci sono attività di trattamento selezionabili',
  [msgIds.MSG_TREATMENT_DELETE_CUSTOM_FIELD_TITLE]: 'Campi trattati',
  [msgIds.MSG_TREATMENT_DELETE_CUSTOM_FIELD_TEXT]: 'Sei sicuro di voler rimuovere questo campo?',

  [msgIds.MSG_TREATMENT_FIELD_NAME]: 'Nome',
  [msgIds.MSG_TREATMENT_FIELD_DESCRIPTION]: 'Descrizione',
  [msgIds.MSG_TREATMENT_FIELD_MANDATORY]: 'Necessario',

  [msgIds.MSG_LEGAL_BASE_EXPLICIT_CONSENT]: 'Consenso esplicito',
  [msgIds.MSG_LEGAL_BASE_CONTRACTUAL_OBLIGATION]: 'Obbligo contrattuale',
  [msgIds.MSG_LEGAL_BASE_LEGAL_OBLIGATION]: 'Obbligo legale',
  [msgIds.MSG_LEGAL_BASE_VITAL_INTEREST]: 'Interesse vitale',
  [msgIds.MSG_LEGAL_BASE_PUBLIC_INTEREST]: 'Interesse pubblico',
  [msgIds.MSG_LEGAL_BASE_LEGITIMATE_INTEREST]: 'Interesse legittimo',

  [msgIds.MSG_RETENTION_END_BEHAVIOR_NONE]: 'Nessuna azione',
  [msgIds.MSG_RETENTION_END_BEHAVIOR_TERMINATE]: 'Termina',
  [msgIds.MSG_RETENTION_END_BEHAVIOR_NOTIFY]: 'Notifica',
  [msgIds.MSG_RETENTION_END_BEHAVIOR_TERMINATE_AND_NOTIFY]: 'Termina e notifica',

  [msgIds.MSG_CUSTOMER_VISIBILITY_RULES]: "Periodo per l'accessibilità a queste informazioni",
  [msgIds.MSG_CUSTOMER_VISIBILITY_RULES_NONE]: 'Nessuno',
  [msgIds.MSG_CUSTOMER_VISIBILITY_RULES_DOCUMENTS_RETENTION]:
    'Finchè almeno un documento è conservato per questa finalità',
  [msgIds.MSG_CUSTOMER_VISIBILITY_RULES_COMPLETE_DURATION]:
    'Per tutto il periodo indicato nella sezione "Conservazione dei dati"',
  [msgIds.MSG_CUSTOMER_VISIBILITY_RULES_INFO]:
    'Questa impostazione serve per definire fino a quando l\'utente sarà visibile nella tua anagrafica.\n• Finchè almeno un documento è conservato per questa finalità:\nnel momento in cui non conservi più alcun documento dell\'utente per questa finalità, se non sussistono altri motivi per i quali hai diritto ad accedere ai suoi dati, non lo vedrai più presente nella tua anagrafica.\n• Per tutto il periodo indicato nella sezione "Conservazione dei dati":\npotrai accedere ai dati dell\'utente per il periodo di tempo che hai impostato nella sezione "Conservazione dei dati", anche se nel tuo archivio non conservi alcun documento riferito a lui. È utile ad esempio per i trattamenti finalizzati alle comunicazioni per scopi di marketing.',

  [msgIds.MSG_RETENTION_DURATION_NONE]: '',
  [msgIds.MSG_RETENTION_DURATION_DAY]: 'Giorno',
  [msgIds.MSG_RETENTION_DURATION_WEEK]: 'Settimana',
  [msgIds.MSG_RETENTION_DURATION_MONTH]: 'Mese',
  [msgIds.MSG_RETENTION_DURATION_YEAR]: 'Anno',

  [msgIds.MSG_RETENTION_DURATION_DAYS]: 'Giorni',
  [msgIds.MSG_RETENTION_DURATION_WEEKS]: 'Settimane',
  [msgIds.MSG_RETENTION_DURATION_MONTHS]: 'Mesi',
  [msgIds.MSG_RETENTION_DURATION_YEARS]: 'Anni',

  [msgIds.MSG_CONTRACT_ACCEPTANCE_STATUS_NONE]: 'Nessuno',
  [msgIds.MSG_CONTRACT_ACCEPTANCE_STATUS_TO_ACCEPT]: 'Da accettare',
  [msgIds.MSG_CONTRACT_ACCEPTANCE_STATUS_IMPLICITY_ACCEPTED_AND_CONFIRMED]: 'Accettato implicitamente e confermato',
  [msgIds.MSG_CONTRACT_ACCEPTANCE_STATUS_IMPLICITY_ACCEPTED_BUT_NOT_CONFIRMED]:
    'Accettato implicitamente ma non confermato',
  [msgIds.MSG_CONTRACT_ACCEPTANCE_STATUS_ACCEPTED]: 'Accettato',

  [msgIds.MSG_CONTRACT_PRIVACY_POLICY_NOT_AVAILABLE]: 'Informativa privacy non disponibile',
  [msgIds.MSG_CONTRACT_TERMS_OF_USE_NOT_AVAILABLE]: 'Contratto non disponibile',
  [msgIds.MSG_CONTRACT_DATA_PROCESSOR_NOT_AVAILABLE]: 'Nomina a responsabile del trattamento non disponibile',
  [msgIds.MSG_CONTRACT_NOT_AVAILABLE]: 'Documento non disponibile',
  [msgIds.MSG_CONTRACT_PRIVACY_POLICY_FOR_CONSUMER_TITLE]: 'Informativa privacy per i clienti',
  [msgIds.MSG_CONTRACT_TERMS_OF_USE_FOR_CONSUMER_TITLE]: "Condizioni d'uso per i clienti",
  [msgIds.MSG_CONTRACT_PRIVACY_POLICY_FOR_OPERATOR_TITLE]: 'Informativa privacy per gli operatori',
  [msgIds.MSG_CONTRACT_TERMS_OF_USE_FOR_OPERATOR_TITLE]: "Condizioni d'uso per gli operatori",
  [msgIds.MSG_CONTRACT_DATA_PROCESSOR_FOR_OPERATOR_TITLE]: 'Nomina a responsabile del trattamento',

  [msgIds.MSG_TREATED_FIELDS_GROUP_IDENTIFICATION]: 'Dati identificativi',
  [msgIds.MSG_TREATED_FIELDS_GROUP_RECOGNITION]: 'Dati per il riconoscimento',
  [msgIds.MSG_TREATED_FIELDS_GROUP_RESIDENCE]: 'Dati residenza',
  [msgIds.MSG_TREATED_FIELDS_GROUP_CONTACT]: 'Dati di contatto',

  [msgIds.MSG_TREATED_FIELDS_LASTNAME]: 'Cognome',
  [msgIds.MSG_TREATED_FIELDS_NAME]: 'Nome',
  [msgIds.MSG_TREATED_FIELDS_GENDER]: 'Genere',
  [msgIds.MSG_TREATED_FIELDS_BIRTH_DATE]: 'Data di nascita',
  [msgIds.MSG_TREATED_FIELDS_BIRTH_PLACE]: 'Luogo di nascita',
  [msgIds.MSG_TREATED_FIELDS_FISCAL_CODE]: 'Codice fiscale',
  [msgIds.MSG_TREATED_FIELDS_AVATAR]: 'Avatar',
  [msgIds.MSG_TREATED_FIELDS_COUNTRY]: 'Paese',
  [msgIds.MSG_TREATED_FIELDS_CITY]: 'Città',
  [msgIds.MSG_TREATED_FIELDS_PROVINCE]: 'Provincia',
  [msgIds.MSG_TREATED_FIELDS_STREET]: 'Via',
  [msgIds.MSG_TREATED_FIELDS_STREET_NUMBER]: 'Numero civico',
  [msgIds.MSG_TREATED_FIELDS_ZIP]: 'CAP',
  [msgIds.MSG_TREATED_FIELDS_PHONE]: 'Telefono',
  [msgIds.MSG_TREATED_FIELDS_MOBILE_PHONE]: 'Cellulare',
  [msgIds.MSG_TREATED_FIELDS_EMAIL]: 'Email',

  [msgIds.MSG_TREATED_FIELDS_TEXT1]: 'Dati sottoposti a trattamento',
  [msgIds.MSG_TREATED_FIELDS_TEXT2]: 'Altri dati sottoposti a trattamento',

  [msgIds.MSG_TREATED_CUSTOM_FIELD_TITLE]: 'Tipo di informazione trattata',

  [msgIds.MSG_FIELDNAME_AVATAR]: 'Avatar',
  [msgIds.MSG_FIELDNAME_CITY]: 'Città',
  [msgIds.MSG_FIELDNAME_COUNTRY]: 'Paese',
  [msgIds.MSG_FIELDNAME_PROVINCE]: 'Provincia',
  [msgIds.MSG_FIELDNAME_STREET]: 'Via',
  [msgIds.MSG_FIELDNAME_STREET_NUMBER]: 'Numero civico',
  [msgIds.MSG_FIELDNAME_ZIP]: 'CAP',
  [msgIds.MSG_FIELDNAME_EMAIL1]: 'Email 1',
  [msgIds.MSG_FIELDNAME_EMAIL2]: 'Email 2',
  [msgIds.MSG_FIELDNAME_EMAIL3]: 'Email 3',
  [msgIds.MSG_FIELDNAME_EMAIL4]: 'Email 4',
  [msgIds.MSG_FIELDNAME_MOBILE_PHONE1]: 'Cellulare 1',
  [msgIds.MSG_FIELDNAME_MOBILE_PHONE2]: 'Cellulare 2',
  [msgIds.MSG_FIELDNAME_PHONE]: 'Telefono',

  [msgIds.MSG_ACCOUNT_ATTACH_DIALOG_TITLE]: 'Campi da mantenere',

  [msgIds.MSG_CONTRACT_TYPE_NONE]: 'Nessuno',
  [msgIds.MSG_CONTRACT_TYPE_PRIVACY_INFO]: 'Informativa privacy',
  [msgIds.MSG_CONTRACT_TYPE_TERMS_OF_USE]: 'Termini di uso',
  [msgIds.MSG_CONTRACT_TYPE_DATA_PROCESSOR]: 'Nomina a responsabile del trattamento',

  [msgIds.MSG_CONTRACT_VERSION_STATE_NONE]: 'Nessuno',
  [msgIds.MSG_CONTRACT_VERSION_STATE_PUBLISHED]: 'Pubblicato',
  [msgIds.MSG_CONTRACT_VERSION_STATE_OBSOLETE]: 'Obsoleto',
  [msgIds.MSG_CONTRACT_VERSION_STATE_DRAFT]: 'Bozza',

  [msgIds.MSG_CONTRACT_VERSION_VIEWER_PAGE_DOWNLOAD_COMMAND]: 'Download',
  [msgIds.MSG_CONTRACT_VERSION_VIEWER_PAGE_SHOW_OBSOLETE_COMMAND]: 'Storico consensi',
  [msgIds.MSG_CONTRACT_VERSION_VIEWER_PAGE_SUSPEND_DATA_PROCESSING]: 'Sospendi condivisione dei dati',
  [msgIds.MSG_CONTRACT_VERSION_VIEWER_PAGE_ACTIVATE_DATA_PROCESSING]: 'Attiva condivisione dei dati',
  [msgIds.MSG_CONTRACT_VERSION_VIEWER_CONSENT_DATE]: 'Data consensi',
  [msgIds.MSG_CONTRACT_VERSION_VIEWER_CHANGES]: 'Modifiche apportate',
  [msgIds.MSG_CONTRACT_VERSION_VIEWER_NEW_ACKNOWLEDGMENT_REQUIRED]:
    "Questa struttura ti sta chiedendo di prendere nuovamente visione dell'informativa. Per farlo è necessario che tu metta la spunta nell'apposita casella",
  [msgIds.MSG_CONTRACT_VERSION_LIST_DIALOG_TITLE]: 'Seleziona una versione',
  [msgIds.MSG_CONTRACT_VERSION_ADD_MISSING_INFO_BUTTON]: 'Compila informazioni mancanti',

  [msgIds.MSG_CONSENT_CHANGE]: 'Presa visione e consensi',
  [msgIds.MSG_CONSENT_CHANGE_REVOKE_ALL_PRIVACY_POLICY_CONSENTS_CONFIRM]:
    'Alcune sezioni ad accettazione obbligatoria non hanno il tuo consenso. Se confermi, il tattamento dei dati, in tutte le forme previste da questo documento, non potrà essere effettuato.',
  [msgIds.MSG_CONSENT_CHANGE_REVOKE_ALL_CONTRACT_CONSENTS_CONFIRM]:
    'Alcune sezioni ad accettazione obbligatoria non hanno il tuo consenso. Se confermi, il presente accordo non potrà essere finalizzato.',
  [msgIds.MSG_CONSENT_CHANGE_ALL_MANDATORY_CONSENTS_NEEDED]:
    "E' necessario che vengano forniti i consensi per tutte le sezioni ad accettazione obbligatoria.",

  [msgIds.MSG_CONTRACT_NEW]: 'Nuovo contratto',
  [msgIds.MSG_CONTRACT_TITLE]: 'Contratti',
  [msgIds.MSG_CONTRACT_ID]: 'ID',
  [msgIds.MSG_CONTRACT_NAME]: 'Nome',
  [msgIds.MSG_CONTRACT_TYPE]: 'Tipo',
  [msgIds.MSG_CONTRACT_VERSION]: 'Versione',
  [msgIds.MSG_CONTRACT_DATE]: 'Data',
  [msgIds.MSG_CONTRACT_DOWNLOAD]: 'Download',

  [msgIds.MSG_CONTRACT_NEW_VERSION]: 'Nuova versione',
  [msgIds.MSG_CONTRACT_PRIVACY_POLICY]: 'Informativa privacy',
  [msgIds.MSG_CONTRACT_TERMS_OF_USE]: 'Condizioni uso servizio',
  [msgIds.MSG_CONTRACT_DATA_PROCESSOR]: 'Nomina a responsabile del trattamento',
  [msgIds.MSG_CONTRACT_STATE]: 'Stato',

  [msgIds.MSG_CONTRACT_ADD_SECTION]: 'Aggiungi sezione',
  [msgIds.MSG_CONTRACT_PUBLISH]: 'Pubblica',
  [msgIds.MSG_CONTRACT_DELETE_VERSION]: 'Cancella versione',
  [msgIds.MSG_CONTRACT_DELETE_SECTION]: 'Cancella sezione',
  [msgIds.MSG_CONTRACT_EDIT_VERSION_TITLE]: 'Modifica della versione',
  [msgIds.MSG_CONTRACT_SECTION_TITLE]: 'Sezione',
  [msgIds.MSG_CONTRACT_INFO_TITLE]: 'Informazioni',
  [msgIds.MSG_CONTRACT_MAIN_PRIVACY_POLICY_INFO]: "Sezione principale dell'informativa",
  [msgIds.MSG_CONTRACT_MAIN_PRIVACY_POLICY_TEXT]:
    "Questa sezione è obbligatoria e deve contenere le informazioni indicate negli art. 13 e 14 del GDPR.\nLa sua presa visione da parte dell'utente è indispensabile per attivare il trattamento dei dati e consentire a PersonalDOX di controllare l'accesso alle informazioni.",
  [msgIds.MSG_CONTRACT_TREATMENTS_LIST]: 'Elenco dei trattamenti',
  [msgIds.MSG_CONTRACT_ADD_DATA_TREATMENT]: 'Aggiungi trattamento dei dati',

  [msgIds.MSG_CONTRACT_ANNOTATIONS_VISIBLE]: 'Le seguenti annotazioni sono visibili ai destinatari di questo documento',
  [msgIds.MSG_CONTRACT_NOTES_VERSION]: 'Note su questa versione (facoltativo)',
  [msgIds.MSG_CONTRACT_INTERNAL_ANNOTATIONS]: 'Le seguenti annotazioni sono per uso interno',
  [msgIds.MSG_CONTRACT_NEW_ACK_REQUIRED]: 'Richiedi una nuova presa visione',
  [msgIds.MSG_CONTRACT_NEW_ACK_REQUIRED_INFO]:
    "Con questa opzione attivata l'utente dovrà prendere nuovamente visione dell'informativa. Fintanto che non l'avrà fatto, PersonalDOX continuerà a metterti a disposizione le informazioni che ti aveva già autorizzato, con le seguenti eccezioni:\n• se hai ridotto il numero di campi che desideri trattare tali campi da subito non ti saranno più visibili;\n• se hai aumentato il numero di campi che desideri trattare non ti saranno visibili finchè l'utente non avrà preso visione della nuova informativa.",
  [msgIds.MSG_CONTRACT_WHY_NO_NEW_ACK]: 'Spiega perchè non è necessaria una nuova presa visione',
  [msgIds.MSG_CONTRACT_NEW_CONSENTS_REQUIRED]: 'Richiedi nuovi consensi',
  [msgIds.MSG_CONTRACT_NEW_CONSENTS_REQUIRED_INFO]:
    "Con questa opzione attivata i consensi rilasciati in precedenza non saranno più validi e i tuoi utenti dovranno provvedere manualmente a rilasciarne di nuovi.\nSenza questa opzione i consensi forniti per la versione obsoleta verranno trasferiti automaticamente sulla nuova versione.\nNOTA: i consensi verranno trasferiti dalla versione obsoleta alla nuova seguendo il codice identificativo a 6 caratteri che identifica ogni sezione. Per questo motivo, se necessario, nella nuova versione puoi cambiare l'ordine delle sezioni.\nATTENZIONE: PersonalDOX non effettua alcun controllo sul contenuto delle sezioni perciò assicurati che l'oggetto di ciascuna di essere venga preservato nell'avanzare delle versioni pubblicate. In questo modo il consenso rilasciato dagli utenti continuerà a rispecchiare le autorizzazioni che intendono concederti.",
  [msgIds.MSG_CONTRACT_WHY_NO_NEW_CONSENTS]: 'Spiega perchè non sono necessari nuovi consensi',

  [msgIds.MSG_CONSENT_ACCEPTANCE_MODE_MANDATORY_EXPLANATION_PRIVACY_POLICY]:
    'La presa visione è necessaria per autorizzare il trattamento dei dati',
  [msgIds.MSG_CONSENT_ACCEPTANCE_MODE_MANDATOTY_EXPLANATION_OTHER]: "L'accettazione è obbligatoria",
  [msgIds.MSG_CONSENT_I_HAVE_READ]: 'Ho preso visione',
  [msgIds.MSG_CONSENT_AGREE]: 'Acconsento',
  [msgIds.MSG_CONSENT_NOT_AGREE]: 'Non acconsento',
  [msgIds.MSG_CONSENT_ACCEPT]: 'Accetto',
  [msgIds.MSG_CONSENT_NOT_ACCEPT]: 'Non accetto',
  [msgIds.MSG_CONSENT_MISSING_INFORMATIONS_TITLE]: "Mancano alcune informazioni richieste nell'informativa",

  [msgIds.MSG_CONTRACT_DELETE_VERSION_CONFIRM]: 'Sei sicuro di voler cancellare questa bozza di versione?',
  [msgIds.MSG_CONTRACT_DELETE_SECTION_CONFIRM]: 'Sei sicuro di voler cancellare la sezione?',
  [msgIds.MSG_CONTRACT_DATA_PROCESSING_SECTION_ABSENT]:
    "La prima sezione dell'informativa non è associata all'azione 'Trattamento dei dati'.\nPer risolvere crea una nuova sezione e associa ad essa le attività di trattamento che non richiedono un consenso esplicito",
  [msgIds.MSG_CONTRACT_PUBLICATION]: 'Pubblicazione',
  [msgIds.MSG_ARE_YOU_SURE_TO_PUBLISH_VERSION]: 'Sei sicuro di voler pubblicare questa versione?',
  [msgIds.MSG_ARE_YOU_SURE_TO_PUBLISH_PRIVACY_POLICY_VERSION_WITH_NEW_CONSENTS_REQUIRED]:
    'ATTENZIONE\nPer ciascun utente il trattamento dei dati sarà sospeso finchè non fornirà nuovi consensi.\nSei sicuro di voler pubblicare questa versione?',
  [msgIds.MSG_ARE_YOU_SURE_TO_PUBLISH_PRIVACY_POLICY_VERSION_WITHOUT_NEW_CONSENTS_REQUIRED]:
    "ATTENZIONE\nOgni trattamento dei dati rimarrà attivo.\nI consensi forniti dai tuoi utenti verranno riportati nella nuova versione dell'informativa.\nSei sicuro di voler pubblicare questa versione?",
  [msgIds.MSG_ARE_YOU_SURE_TO_PUBLISH_GENERIC_VERSION_WITH_NEW_CONSENTS_REQUIRED]:
    'Sei sicuro di voler pubblicare questa versione?',
  [msgIds.MSG_ARE_YOU_SURE_TO_PUBLISH_GENERIC_VERSION_WITHOUT_NEW_CONSENTS_REQUIRED]:
    'ATTENZIONE\nI consensi forniti dai tuoi utenti verranno riportati nella nuova versione del contratto.\nSei sicuro di voler pubblicare questa versione?',
  // Archive
  [msgIds.MSG_PERSONAL_ARCHIVE_ROOT_NAME]: 'ARCHIVIO PERSONALE',
  [msgIds.MSG_COMPANY_ARCHIVE_ROOT_NAME]: 'ARCHIVIO AZIENDALE',
  [msgIds.MSG_CUSTOMER_ARCHIVE_ROOT_NAME]: 'ARCHIVIO UTENTE',
  // Doxes menu
  [msgIds.MSG_DOXES_MENU_RELOAD_BUTTON]: 'Ricarica i dox',
  [msgIds.MSG_DOXES_MENU_PIN_BUTTON]: 'Chiudi il menu',
  [msgIds.MSG_DOXES_MENU_SHOW_BUTTON]: 'Mostra i dox',
  [msgIds.MSG_DOXES_SELECTION_MODE_LABEL]: 'Modalita del filtro per dox',
  [msgIds.MSG_DOXES_SELECTION_MODE_SINGLE]: 'Selezione singola',
  [msgIds.MSG_DOXES_SELECTION_MODE_MULTIPLE]: 'Selezione multipla',
  [msgIds.MSG_DOXES_SELECTION_MODE_HIERARCHICAL]: 'Selezione multipla con annidati',
  // Dox commands
  [msgIds.MSG_DOX_COMMAND_SHOW_AUTHORIZATIONS]: 'Autorizzazioni',
  [msgIds.MSG_DOX_COMMAND_CREATE_NEW_DOX]: 'Nuovo',
  [msgIds.MSG_DOX_COMMAND_CREATE_NEW_ORGANIZED_DOX]: 'Nuovi Dox organizzati',
  [msgIds.MSG_DOX_COMMAND_MODIFY_DOX]: 'Modifica',
  [msgIds.MSG_DOX_COMMAND_MOVE_DOX]: 'Sposta',
  [msgIds.MSG_DOX_COMMAND_DELETE_DOX]: 'Elimina',
  [msgIds.MSG_DOX_COMMAND_SHOW_DOX_CHRONOLOGY]: 'Cronologia',
  [msgIds.MSG_DOX_COMMAND_SHOW_DOX_DETAILS]: 'Dettagli',
  [msgIds.MSG_DOX_COMMAND_DOWNLOAD_DOX]: 'Download',
  [msgIds.MSG_DOX_COMMAND_TERMINATE_RETENTION]: 'Interrompi conservazione',
  [msgIds.MSG_DOX_COMMAND_DELIVER_DOX]: 'Consegna',
  [msgIds.MSG_DOX_COMMAND_SHARE_DOX]: 'Condivisione',
  // DocumentsTableFiltersBar
  [msgIds.MSG_DOCS_FILTERS_BAR_BUTTON]: 'Filtri',
  [msgIds.MSG_DOCS_FILTER_EDITED_FROM_KEY]: 'Dal',
  [msgIds.MSG_DOCS_FILTER_EDITED_TO_KEY]: 'al',
  [msgIds.MSG_DOCS_FILTER_STRUCTURE_OWNER_KEY]: 'Struttura proprietaria',
  [msgIds.MSG_DOCS_FILTER_OPERATOR_AUTHOR_KEY]: 'Operatore autore',
  [msgIds.MSG_DOCS_FILTER_CREATED_BY_ME_KEY]: 'Creato da me',
  [msgIds.MSG_DOCS_FILTER_ONLY_DRAFTS_KEY]: 'Solo bozze',
  [msgIds.MSG_DOCS_FILTER_SHOW_OBSOLETE_KEY]: 'Visualizza i documenti obsoleti',
  [msgIds.MSG_DOCS_FILTERS_BAR_PLACEHOLDER]: 'Nessun filtro applicato',
  // DocumentsFiltersDialog
  [msgIds.MSG_DOCS_FILTER_DIALOG_TITLE]: 'Filtri',
  // Document table
  [msgIds.MSG_DOCS_TABLE_HEADER_NAME]: 'Titolo',
  [msgIds.MSG_DOCS_TABLE_HEADER_AUTHOR]: 'Autore',
  [msgIds.MSG_DOCS_TABLE_HEADER_DATE]: 'Data',
  // Archive Page - command bar
  [msgIds.MSG_ARCHIVE_PAGE_SHARED_ARCHIVE_TITLE]: 'Archivio condiviso da {{identity}}',
  [msgIds.MSG_ARCHIVE_PAGE_ARCHIVE_FOR_CUSTOMER_TITLE]: 'Archivio aziendale {{identity}}',
  [msgIds.MSG_COMMAND_CREATE_DOCUMENT_FROM_TEMPLATE]: 'Scrivi documento da un modello',
  [msgIds.MSG_COMMAND_CREATE_DOCUMENT]: 'Scrivi documento',
  [msgIds.MSG_COMMAND_UPLOAD_DOCUMENT]: 'Carica documento',
  // Document commands
  [msgIds.MSG_DOC_COMMAND_ASSOCIATE_TO_DOX]: 'Associa a Dox',
  [msgIds.MSG_DOC_COMMAND_SHOW_AUTHORIZATIONS]: 'Autorizzazioni',
  [msgIds.MSG_DOC_COMMAND_REMOVE_FROM_STRUCTURE_ARCHIVE]: "Rimuovi dall'archivio aziendale",
  [msgIds.MSG_DOC_COMMAND_DOWNLOAD]: 'Download',
  [msgIds.MSG_DOC_COMMAND_MARK_AS_OBSOLETE]: 'Dichiara obsoleto',
  [msgIds.MSG_DOC_COMMAND_SHOW_CHRONOLOGY]: 'Cronologia',
  [msgIds.MSG_DOC_COMMAND_MODIFY]: 'Crea/Modifica bozza',
  [msgIds.MSG_DOC_COMMAND_DELETE]: 'Elimina',
  [msgIds.MSG_DOC_COMMAND_ARCHIVE]: 'Archivia',
  [msgIds.MSG_DOC_COMMAND_ADD_DOCUMENT_TO_DESKTOP]: 'Aggiungi alla scrivania',
  [msgIds.MSG_DOC_COMMAND_SET_ANONYMOUS_STATE]: 'Non contiene dati personali',
  [msgIds.MSG_DOC_COMMAND_UNSET_ANONYMOUS_STATE]: 'Contiene dati personali',

  // Document command confirms
  [msgIds.MSG_DOX_COMMAND_REMOVE_FROM_STRUCTURE_ARCHIVE_CONFIRM]:
    "Il documento selezionato sarà rimosso definitivamente dall'archivio aziendale.\nConfermi?",
  [msgIds.MSG_DOCUMENT_DELETE_CONFIRM]: 'Sei sicuro di voler cancellare questo documento?',
  [msgIds.MSG_DOCUMENT_MARK_AS_OBSOLETE_CONFIRM]:
    "Sei sicuro di voler rendere questo documento obsoleto?\nL'operazione sarà irreversibile",
  [msgIds.MSG_DOX_COMMAND_TERMINATE_RETENTION_CONFIRM]:
    'I documenti associati a questo dox e ai dox annidati saranno trattati nel seguente modo:\n\n• quelli conservati anche per altre finalità saranno ignorati;\n• quelli marcati come anonimi saranno ignorati;\n• quelli creati dalla tua struttura ma non consegnati saranno cancellati dagli archivi di PersonalDOX (operazione non reversibile);\n• quelli di proprietà di altri utenti saranno rimossi dal tuo archivio aziendale.\n\nNOTA: se al termine di questa operazione il dox non ha più documenti associati, sarà cancellato.\n\nConfermi di voler terminare la conservazione di questo dox?',
  [msgIds.MSG_DOX_COMMAND_DELIVER_DOX_CONFIRM]:
    'Sei sicuro di voler consegnare questo dox?\n\nNOTA: gli eventuali documenti in bozza mai archiviati verranno archiviati automaticamente.',

  // Document commands results
  [msgIds.MSG_DOCUMENT_DELETED_SUCCESSFULLY]: 'Documento eliminato con successo',
  [msgIds.MSG_DOCUMENT_ARCHIVED_SUCCESSFULLY]: 'Documento archiviato con successo',
  [msgIds.MSG_DOCUMENT_SAVED_SUCCESSFULLY]: 'Documento salvato con successo',
  [msgIds.MSG_DOCUMENT_NOW_IS_ANONYMOUS]: 'Il documento è marcato come "non contenente dati personali"',
  [msgIds.MSG_DOCUMENT_NOW_IS_NOT_ANONYMOUS]: 'Il documento è marcato come "contenente dati personali"',
  [msgIds.MSG_DOCUMENT_MARKED_AS_OBSOLETE_SUCCESSFULLY]: 'Il documento è stato marcato com obsoleto',
  [msgIds.MSG_RETENTION_TERMINATED_SUCCESFULLY]: 'Interruzione della conservazione effettuata con successo',
  [msgIds.MSG_SPECIFIC_RETENTION_TERMINATION_NOT_POSSIBLE_FROM_DOX]:
    'La conservazione applicata ai documenti associati a questo dox e ai dox annidati è specifica per ciascun documento. Per questo motivo non puoi interrompere la conservazione direttamente dal dox ma puoi interromperla singolarmente per ciascun documento tramite la scheda "Conservazione" dell\'anteprima.',
  [msgIds.MSG_DOX_DELIVERED_SUCCESSFULLY]: 'Dox consegnato con successo',
  [msgIds.MSG_DOX_CREATED_SUCCESSFULLY]: 'Dox creato con successo',
  [msgIds.MSG_DOX_UPDATED_SUCCESSFULLY]: 'Dox modificato con successo',
  [msgIds.MSG_DOX_DELETED_SUCCESSFULLY]: 'Dox cancellato con successo',

  // Document info
  [msgIds.MSG_DOCUMENT_SOURCE_PDX_EDITOR]: 'Editor di PersonalDOX',
  [msgIds.MSG_DOCUMENT_SOURCE_LOADED_FILE]: 'File caricato',
  [msgIds.MSG_DOCUMENT_ORIGINAL_FILE_NAME]: 'Nome originale del file',

  // DocumentEditor commands
  [msgIds.MSG_DOCUMENT_EDITOR_COMMAND_INFO]: 'Info',
  [msgIds.MSG_DOCUMENT_EDITOR_COMMAND_DOX]: 'Dox',
  [msgIds.MSG_DOCUMENT_EDITOR_COMMAND_CREATE_DRAFT]: 'Crea bozza',
  [msgIds.MSG_DOCUMENT_EDITOR_COMMAND_EDIT_DRAFT]: 'Modifica bozza',
  [msgIds.MSG_DOCUMENT_EDITOR_COMMAND_CLOSE_DRAFT]: 'Chiudi bozza',
  [msgIds.MSG_DOCUMENT_EDITOR_COMMAND_CONTENT]: 'Contenuto',
  [msgIds.MSG_DOCUMENT_EDITOR_COMMAND_REVISIONS]: 'Revisioni',
  [msgIds.MSG_DOCUMENT_EDITOR_COMMAND_RETENTION]: 'Conservazione',
  [msgIds.MSG_DOCUMENT_EDITOR_COMMAND_UNDO]: 'Annulla',
  [msgIds.MSG_DOCUMENT_EDITOR_COMMAND_REDO]: 'Ripristina',
  [msgIds.MSG_DOCUMENT_EDITOR_COMMAND_SAVE]: 'Salva',
  [msgIds.MSG_DOCUMENT_EDITOR_COMMAND_ARCHIVE]: 'Archivia',

  // DocumentEditor revision labels
  [msgIds.MSG_DOCUMENT_EDITOR_REVISION_NAME_LABEL]: 'Titolo',
  [msgIds.MSG_DOCUMENT_EDITOR_REVISION_EDITED_AT_LABEL]: 'Data',
  [msgIds.MSG_DOCUMENT_EDITOR_REVISION_CREATOR_IDENTITY_LABEL]: 'Autore',
  [msgIds.MSG_DOCUMENT_EDITOR_REVISION_MIMETYPE_LABEL]: 'Origine del documento',
  [msgIds.MSG_DOCUMENT_EDITOR_REVISION_ARCHIVED_AT_LABEL]: 'Data di archiviazione',

  // DocumentEditor revision values
  [msgIds.MSG_DOCUMENT_EDITOR_REVISION_MIMETYPE_UNKNOWN]: 'Sconosciuto',
  [msgIds.MSG_DOCUMENT_EDITOR_REVISION_MIMETYPE_PERSONALDOX]: 'Editor di PersonalDOX',

  // DocumentEditor dox
  [msgIds.MSG_DOCUMENT_EDITOR_DOX_OF]: 'Dox di ',
  [msgIds.MSG_DOCUMENT_EDITOR_NO_DOX]: 'Nessun dox associato',

  // DocumentEditor content
  [msgIds.MSG_DOCUMENT_EDITOR_NO_REVISION_SELECTED]: 'Nessuna revisione selezionata',
  [msgIds.MSG_DOCUMENT_EDITOR_DOWNLOAD_ERROR]: 'Errore durante il download',
  [msgIds.MSG_DOCUMENT_EDITOR_NOT_AVAILABLE_VIEWER]: 'Non è disponibile un visualizzatore per questo tipo di documento',
  [msgIds.MSG_DOCUMENT_EDITOR_INFO]: 'Informazioni sul documento',
  [msgIds.MSG_DOCUMENT_EDITOR_NEW_INTERNAL_DOCUMENT_NAME]: 'Nuovo documento',
  [msgIds.MSG_DOCUMENT_EDITOR_TRANSMISSION_IN_PROGRESS]: 'Trasmissione in corso...',
  [msgIds.MSG_DOCUMENT_EDITOR_DRAFT_PREPARATION_IN_PROGRESS]: 'Preparazione bozza in corso...',

  // DocumentEditor retention
  [msgIds.MSG_DOCUMENT_EDITOR_RETENTION_FINALITY_LABEL]: 'Finalità',
  [msgIds.MSG_DOCUMENT_EDITOR_RETENTION_START_AT_LABEL]: 'Inizio',
  [msgIds.MSG_DOCUMENT_EDITOR_RETENTION_END_AT_LABEL]: 'Fine',
  [msgIds.MSG_DOCUMENT_EDITOR_RETENTION_INTERVAL_DIALOG_TITLE]: 'Conservazione documento',

  // DocumentUpload
  [msgIds.MSG_DOCUMENT_UPLOAD_INPUT_FILE_LABEL]: 'Clicca o trascina un file per caricare',
  [msgIds.MSG_DOCUMENT_UPLOAD_FILE_NAME_LABEL]: 'Nome',
  [msgIds.MSG_DOCUMENT_UPLOAD_FILE_SIZE_LABEL]: 'Dimensione',
  [msgIds.MSG_DOCUMENT_CHANGES_WILL_BE_LOST]: 'Alcune modifiche non sono state salvate e verranno perse.',

  // Document Dialogs
  [msgIds.MSG_DOCUMENT_RETENTION_DIALOG_TITLE]: 'Conservazione documento',
  [msgIds.MSG_DOCUMENT_RETENTION_DIALOG_TERMINATE_DETACHED_BUT_VISIBLE_BODY]:
    'Questo documento è conservato per diverse finalità perciò verrà sganciato dal dox ma continuerà a essere visibile.\n\nConfermi di voler terminare questa conservazione?',
  [msgIds.MSG_DOCUMENT_RETENTION_DIALOG_TERMINATE_VISIBLE_TO_OWNER_BODY]:
    'Questo documento non è di proprietà della tua struttura perciò verrà rimosso dal tuo archivio aziendale. Rimarrà comunque visibile al suo proprietario.\n\nConfermi di voler terminare questa conservazione?',
  [msgIds.MSG_DOCUMENT_RETENTION_DIALOG_TERMINATE_VISIBLE_TO_TARGET_BY_DELIVER_BODY]:
    "Questo documento verrà rimosso dal tuo archivio aziendale ma rimarrà visibile all'utente cui l'hai consegnato.\n\nConfermi di voler terminare questa conservazione?",
  [msgIds.MSG_DOCUMENT_RETENTION_DIALOG_TERMINATE_DELETED_BODY]:
    'Questo documento è di proprietà della tua struttura e non è mai stato consegnato perciò verrà cancellato dagli archivi di PersonalDOX (operazione non reversibile).\n\nConfermi di voler terminare questa conservazione?',
  [msgIds.MSG_DOCUMENT_SHOW_TITLE]: 'Mostra documento',
  [msgIds.MSG_DOCUMENT_NOT_AVAILABLE]: 'Il documento non è disponibile',
  [msgIds.MSG_DOCUMENT_WRONG_ASSISTED_CUSTUMER]:
    'Il documento si riferisce ad un utente diverso da quello attualmente selezionato',

  // Dox Show Dialogs
  [msgIds.MSG_DOX_SHOW_TITLE]: 'Mostra dox',
  [msgIds.MSG_DOX_NOT_AVAILABLE]: 'Il dox non è disponibile',
  [msgIds.MSG_DOX_WRONG_ASSISTED_CUSTUMER]:
    'Il dox si riferisce ad un utente diverso da quello attualmente selezionato',

  // DoxDetails
  [msgIds.MSG_DOX_DETAILS_CONTENT_LABEL]: 'Contenuto',
  [msgIds.MSG_DOX_DETAILS_DELIVER_SECTION_LABEL]: 'Consegna',
  [msgIds.MSG_DOX_DETAILS_RETENTION_SECTION_LABEL]: 'Conservazione dei dati',
  [msgIds.MSG_DOX_DETAILS_PATH_LABEL]: 'Percorso',
  [msgIds.MSG_DOX_DETAILS_NAME_LABEL]: 'Nome',
  [msgIds.MSG_DOX_DETAILS_NOTES_LABEL]: 'Note',
  [msgIds.MSG_DOX_DETAILS_CONTENT_START_AT_LABEL]: 'Primo documento',
  [msgIds.MSG_DOX_DETAILS_CONTENT_END_AT_LABEL]: 'Ultimo documento',
  [msgIds.MSG_DOX_DETAILS_CONTENT_DOX_COUNT_LABEL]: 'N° di dox',
  [msgIds.MSG_DOX_DETAILS_CONTENT_DOCUMENT_COUNT_LABEL]: 'N° di documenti',
  [msgIds.MSG_DOX_DETAILS_CONTENT_DRAFT_DOCUMENT_COUNT_LABEL]: 'N° di documenti in bozza',
  [msgIds.MSG_DOX_DETAILS_DELIVERED_AT_LABEL]: 'Consegnato il',
  [msgIds.MSG_DOX_DETAILS_FINALITY_LABEL]: 'Finalità',
  [msgIds.MSG_DOX_DETAILS_RETENTION_RULES_LABEL]: 'Regole per la conservazione',
  [msgIds.MSG_DOX_DETAILS_RETENTION_START_AT_LABEL]: 'Data inizio',
  [msgIds.MSG_DOX_DETAILS_RETENTION_END_AT_LABEL]: 'Data fine',
  [msgIds.MSG_DOX_DETAILS_RETENTION_NOTICE_AT_LABEL]: 'Notifica prima della fine conservazione',
  [msgIds.MSG_DOX_DETAILS_RETENTION_END_TERMINATE_LABEL]: 'Termina la conservazione automaticamente',

  // DoxDetailsDialog
  [msgIds.MSG_DOX_DETAILS_DIALOG_TITLE]: 'Dettagli dox',

  // DoxSelectorDialog
  [msgIds.MSG_DOX_SELECTOR_DIALOG_TITLE]: 'Selezione di un Dox',
  [msgIds.MSG_DOX_SELECTOR_DIALOG_REMOVING_DOCUMENTS_CONFIRM]:
    "I documenti selezionati saranno rimossi definitivamente dall'archivio aziendale.\nConfermi?",
  [msgIds.MSG_DOX_SELECTOR_DIALOG_REMOVING_DOCUMENT_CONFIRM]:
    "Il documento selezionato sarà rimosso definitivamente dall'archivio aziendale.\nConfermi?",
  [msgIds.MSG_DOX_SELECTOR_DIALOG_CHANGES_MADE_SUCCESSFULLY]: 'Modifiche apportate con successo',

  // DoxEditorDialog
  [msgIds.MSG_DOX_EDITOR_DIALOG_NEW_DOX_TITLE]: 'Nuovo dox',
  [msgIds.MSG_DOX_EDITOR_DIALOG_MODIFY_DOX_TITLE]: 'Modifica dox',

  // DoxEditor
  [msgIds.MSG_DOX_EDITOR_NEW_DOX_NAME]: 'Nuovo dox',
  [msgIds.MSG_DOX_EDITOR_PARENT_LABEL]: 'Genitore',
  [msgIds.MSG_DOX_EDITOR_NAME_LABEL]: 'Nome',
  [msgIds.MSG_DOX_EDITOR_NOTES_LABEL]: 'Note',
  [msgIds.MSG_DOX_EDITOR_RETENTION_PLACEHOLDER]: 'Conservazione dei dati',
  [msgIds.MSG_DOX_EDITOR_RETENTION_TITLE]: 'Modalità di conservazione dei documenti',
  [msgIds.MSG_DOX_EDITOR_RETENTION_DESCRIPTION]:
    'Questa impostazione coinvolge tutti i documenti assegnati al dox e ai dox contenuti',
  [msgIds.MSG_DOX_EDITOR_RETENTION_CENTRALIZED_LABEL]: 'Uguale per tutti',
  [msgIds.MSG_DOX_EDITOR_RETENTION_SPECIFIC_LABEL]: 'Specifica per ciascuno',
  [msgIds.MSG_DOX_EDITOR_RETENTION_END_AT_INFO]:
    'La data di fine conservazione può non essere valorizzata solo nei casi in cui non sia possibile applicare un criterio per la sua determinazione.\nAd esempio, se i dati sono conservati in funzione di un contenzioso in tribunale, la data di fine conservazione non sarà determinabile finché il procedimento non sarà concluso.',

  // DoxShare
  [msgIds.MSG_DOX_SHARE_DIALOG_TITLE]: 'Condivisione del Dox',
  [msgIds.MSG_DOX_SHARE_EMAIL]: 'Email',
  [msgIds.MSG_DOX_SHARE_PIN]: 'PIN',
  [msgIds.MSG_DOX_SHARE_PIN_DISABLED]: 'Doppia autenticazione non attiva',
  [msgIds.MSG_DOX_SHARE_GENERATE_PIN]: 'Genera un nuovo PIN',
  [msgIds.MSG_DOX_SHARE_REMOVE_PIN]: 'Rimuovi la protezione con codice PIN',
  [msgIds.MSG_DOX_SHARE_REMOVE_PIN_CONFIRM_TITLE]: 'Sicurezza con PIN',
  [msgIds.MSG_DOX_SHARE_REMOVE_PIN_CONFIRM_BODY]: 'Confermi di voler rimuovere la protezione con codice PIN?',
  [msgIds.MSG_DOX_SHARE_USE_PIN]: 'Usa un codice PIN per proteggere la condivisione',
  [msgIds.MSG_DOX_SHARE_PIN_INFO]:
    'Il codice PIN ti permette di condividere questo Dox con maggiore sicurezza.\nInvia il PIN generato attraverso un canale sicuro alla persona con la quale stai condividendo questo Dox',
  [msgIds.MSG_DOX_SHARE_UNSHARE_CONFIRM_TITLE]: 'Condivisione Dox',
  [msgIds.MSG_DOX_SHARE_UNSHARE_CONFIRM_BODY]: 'Confermi di voler annullare la condivisione di questo Dox?',
  [msgIds.MSG_DOX_SHARE_INVITATION_SENDED]: 'Invito inviato con successo',
  [msgIds.MSG_DOX_SHARE_PERMISSION_NOT_ACTIVE]: 'Autorizzazione non attiva',
  [msgIds.MSG_DOX_SHARE_PERMISSION_ACTIVE_FOR_DAYS]: 'Autorizzazione attiva valida per giorni: {{daysDifference}}',
  [msgIds.MSG_DOX_SHARE_PERMISSION_FUTURE_FOR_DAYS]: 'Autorizzazione futura valida per giorni: {{daysDifference}}',
  [msgIds.MSG_DOX_SHARE_AUTHORIZATIONS_TITLE]: 'Azioni consentite',
  [msgIds.MSG_DOX_SHARE_AUTHORIZATIONS_VIEW]: 'Visualizzare i contenuti',
  [msgIds.MSG_DOX_SHARE_AUTHORIZATIONS_VIEW_INFO]:
    'Può visualizzare tutti i documenti e tutti i Dox interni al Dox che hai condiviso',
  [msgIds.MSG_DOX_SHARE_AUTHORIZATIONS_DOWNLOAD]: 'Scaricare i contenuti',
  [msgIds.MSG_DOX_SHARE_AUTHORIZATIONS_DOWNLOAD_INFO]:
    "Può scaricare tutti i documenti singolarmente o l'intero Dox che hai condiviso in una volta sola",
  [msgIds.MSG_DOX_SHARE_AUTHORIZATIONS_EDIT_DOCUMENTS]: 'Creare nuovi documenti',
  [msgIds.MSG_DOX_SHARE_AUTHORIZATIONS_EDIT_DOCUMENTS_INFO]:
    'Può creare nuovi documenti e in seguito, se possibile, modificarli o cancellarli',
  [msgIds.MSG_DOX_SHARE_AUTHORIZATIONS_EDIT_DOX]: 'Creare nuovi Dox',
  [msgIds.MSG_DOX_SHARE_AUTHORIZATIONS_EDIT_DOX_INFO]:
    "Può crere nuovi Dox all'interno del Dox che hai condiviso e poi modificarli o cancellarli. Può organizzare i documenti associandoli ai dox disponibili",

  // DoxDeleteConfirm
  [msgIds.MSG_DOX_DELETE_CONFIRM_TITLE]: 'Cancellazione Dox',
  [msgIds.MSG_DOX_DELETE_CONFIRM_WORD_LABEL]: 'Conferma cancellazione',
  [msgIds.MSG_DOX_DELETE_CONFIRM_WORD]: 'ELIMINA',
  [msgIds.MSG_DOX_DELETE_CONFIRM_ALERT]:
    'A questo dox sono associati diversi documenti. Cancellandolo verranno tutti associati al Dox di livello superiore.\nPer essere sicuri della tua decisione ti chiediamo di digitare la parola ELIMINA nel campo sottostante.',
  [msgIds.MSG_DOX_DELETE_CONFIRM_ALERT2]:
    "Per una migliore fruizione dei contenuti ti consigliamo di associare i documenti ricevuti ai dox del tuo archivio. I documenti che non organizzi nei tuoi dox ti saranno comunque visibili selezionando l'ARCHIVIO PERSONALE.",

  // AuthorizationsChangeDialog
  [msgIds.MSG_DOCUMENTS_VISIBILITY_CHANGES]: 'Modifiche alla visiblità dei documenti',

  // RetentionRules
  [msgIds.MSG_RETENTION_RULE_NONE]: 'Nessuna',
  [msgIds.MSG_RETENTION_RULE_CENTRALIZED]: 'Uguale per tutti i documenti',
  [msgIds.MSG_RETENTION_RULE_SPECIFIC]: 'Specifica per documento',

  // Authorizations
  [msgIds.MSG_AUTHORIZATIONS_VISUALIZATION_ALLOWED]: 'Permessa',
  [msgIds.MSG_AUTHORIZATIONS_VISUALIZATION_EXPIRED]: 'Scaduta',
  [msgIds.MSG_AUTHORIZATIONS_VISUALIZATION_REVOKED]: 'Revocata',
  [msgIds.MSG_AUTHORIZATIONS_VISUALIZATION_SUSPENDED]: 'Sospesa',
  [msgIds.MSG_AUTHORIZATIONS_VISUALIZATION_UNKNOWN]: 'Sconosciuta',

  [msgIds.MSG_AUTHORIZATIONS_ADD_STRUCT_LABEL]: 'Aggiungi struttura',
  [msgIds.MSG_AUTHORIZATIONS_ADD_STRUCT_BUTTON]: 'Aggiungi',

  [msgIds.MSG_AUTHORIZATION_ADDED_MESSAGE]: 'Struttura aggiunta con successo',

  // AuthorizationsDialog
  [msgIds.MSG_AUTHORIZATION_DELETE_DIALOG_TITLE]: 'Elimina autorizzazione',
  [msgIds.MSG_AUTHORIZATION_DELETE_DIALOG_CONTENT]: 'Sei sicuro di voler cancellare questa autorizzazione?',
  [msgIds.MSG_AUTHORIZATION_DELETED_MESSAGE]: 'Autorizzazione eliminata',

  // AuthorizationDetails dialog
  [msgIds.MSG_AUTHORIZATION_DETAILS_DIALOG_TITLE]: "Provenienza dell'autorizzazione",
  [msgIds.MSG_AUTHORIZATION_DETAILS_DIALOG_MESSAGE]:
    "L'autorizzazione non può essere cancellata perchè deriva dalle autorizzazioni concesse sui seguenti dox",

  // AuthorizationsChangeDialog
  [msgIds.MSG_AUTHORIZATIONS_CHANGE_LOST_AUTH_LABEL]: "Strutture che perdono l'accesso ai documenti",
  [msgIds.MSG_AUTHORIZATIONS_CHANGE_GAINED_AUTH_LABEL]: "Strutture che guadagnano l'accesso ai documenti",
  [msgIds.MSG_AUTHORIZATIONS_CHANGE_LOST_AUTH_INFO]:
    "Confermando l'operazione, le strutture elencate non potranno più accedere ai documenti associati a questo Dox.\nATTENZIONE!\nPotranno continuare ad accedervi solo se autorizzate a visualizzare altri Dox associati agli stessi documenti",
  [msgIds.MSG_AUTHORIZATIONS_CHANGE_GAINED_AUTH_INFO]:
    "I soggetti elencati sono già stati autorizzati ad accedere ai contenuti associati al Dox di destinazione o a un Dox di livello superiore.\nConfermando l'operazione, consentirai loro di cominciare ad accedere anche ai contenuti associati al Dox spostato.",
  [msgIds.MSG_AUTHORIZATION_RENEW_CONFIRM_TITLE]: 'Ripristino autorizzazione',
  [msgIds.MSG_AUTHORIZATION_RENEW_CONFIRM_BODY]:
    'Confermi di voler riattivare questa autorizzazione per altri {{days}} giorni?',
  [msgIds.MSG_AUTHORIZATION_RENEWAL_CONFIRM_TITLE]: 'Rinnovo autorizzazione',
  [msgIds.MSG_AUTHORIZATION_RENEWAL_CONFIRM_BODY]:
    'Questa autorizzazione è valida fino al {{date}}.\nConfermi di volerla rinnovare per altri {{days}} giorni?',

  // LoadMore component
  [msgIds.MSG_LOAD_MORE_NO_MORE_TO_LOAD]: '-',
  [msgIds.MSG_LOAD_MORE_LOADING_MORE]: 'Caricamento ...',

  // Account info details
  [msgIds.MSG_ACCOUNT_DETAILS_TITLE]: 'Account',

  // Notifications
  [msgIds.MSG_NOTIFICATIONS_TITLE]: 'Notifiche',
  [msgIds.MSG_NOTIFICATIONS_ARCHIVE_TITLE]: 'Archivio notifiche',
  [msgIds.MSG_NOTIFICATIONS_ARCHIVE_LINK]: 'Archivio notifiche',
  [msgIds.MSG_NOTIFICATIONS_SETTINGS_LINK]: 'Impostazioni',
  [msgIds.MSG_NOTIFICATIONS_ARCHIVE_ALL_BUTTON]: 'Archivia tutte',
  // NotificationsFilters keys
  [msgIds.MSG_NOTIFICATIONS_FILTER_FROM_KEY]: 'Dal',
  [msgIds.MSG_NOTIFICATIONS_FILTER_TO_KEY]: 'al',
  [msgIds.MSG_NOTIFICATIONS_FILTER_STRUCTURE_KEY]: 'Struttura',
  [msgIds.MSG_NOTIFICATIONS_FILTER_OPERATOR_KEY]: 'Operatore',
  [msgIds.MSG_NOTIFICATIONS_FILTER_ARGUMENT]: 'Argomento',
  // Notifications tooltip
  [msgIds.MSG_NOTIFICATION_TOOLTIP_SHOW_DOSSIER]: 'Visualizza fascicolo',
  [msgIds.MSG_NOTIFICATION_TOOLTIP_SHOW_DOCUMENT]: 'Visualizza documento',
  [msgIds.MSG_NOTIFICATION_TOOLTIP_SHOW_DATA_PROCESSING_SETTINGS]: 'Visualizza impostazioni del trattamento dati',
  [msgIds.MSG_NOTIFICATION_TOOLTIP_SHOW_CONTRACT]: 'Visualizza contratto',
  [msgIds.MSG_NOTIFICATION_TOOLTIP_SHOW_CONSENTS]: 'Visualizza consensi',
  [msgIds.MSG_NOTIFICATION_TOOLTIP_SHOW_DOX]: 'Visualizza dox',
  [msgIds.MSG_NOTIFICATION_TOOLTIP_SEND_INVITATION]: 'Invia invito',
  [msgIds.MSG_NOTIFICATION_TOOLTIP_ACCEPT_INVITATION]: 'Accetta invito',
  [msgIds.MSG_NOTIFICATION_TOOLTIP_SHOW_COLLABORATOR_CARD]: 'Mostra la scheda del collaboratore',
  // Notifications argument
  [msgIds.MSG_NOTIFICATION_ARGUMENT_LABEL]: 'Argomento delle notifiche',
  [msgIds.MSG_NOTIFICATION_ARGUMENT_NONE]: 'Nessuno',
  [msgIds.MSG_NOTIFICATION_ARGUMENT_MESSAGE]: 'Messaggio',
  [msgIds.MSG_NOTIFICATION_ARGUMENT_PROFILE_MANAGEMENT]: 'Gestione profilo',
  [msgIds.MSG_NOTIFICATION_ARGUMENT_ACCOUNT_MANAGEMENT]: 'Gestione account',
  [msgIds.MSG_NOTIFICATION_ARGUMENT_PDX_CONTRACTS]: 'Contratti PersonalDOX',
  [msgIds.MSG_NOTIFICATION_ARGUMENT_DOSSIER]: 'Fascicolo',
  [msgIds.MSG_NOTIFICATION_ARGUMENT_DOCUMENT]: 'Documento',
  [msgIds.MSG_NOTIFICATION_ARGUMENT_PRIVACY_SETTINGS]: 'Impostazioni privacy',
  [msgIds.MSG_NOTIFICATION_ARGUMENT_PRIVACY_POLICY]: 'Informativa privacy',
  [msgIds.MSG_NOTIFICATION_ARGUMENT_ARCHIVE_MANAGEMENT]: 'Gestione archivio',
  [msgIds.MSG_NOTIFICATION_ARGUMENT_INVITATION]: 'Invito',
  // Notifications body
  [msgIds.MSG_NOTIFICATION_BODY_UPCOMING_CUSTOMER_PROFILE_SUSPENSION]:
    "Tra {{daysCountDown}} giorni scadrà il termine per il rinnovo del servizio collegato al tuo profilo '{{profileTypeDsc}}'. Se non provvederai al pagamento, il piano del tuo abbonamento verrà cambiato automaticamente nel piano Base",
  [msgIds.MSG_NOTIFICATION_BODY_UPCOMING_OPERATOR_AUTO_PROFILE_SUSPENSION]:
    "Tra {{daysCountDown}} giorni scadrà il termine per il rinnovo del servizio collegato al tuo profilo '{{profileTypeDsc}}' per la gestione della struttura '{{identity}}'. Se non provvederai al pagamento, il piano del tuo abbonamento verrà cambiato automaticamente nel piano Base",
  [msgIds.MSG_NOTIFICATION_BODY_CUSTOMER_PROFILE_SUSPENSION]:
    "Il servizio collegato al profilo '{{profileTypeDsc}}' non è stato rinnovato. Il piano del tuo abbonamento è stato cambiato nel piano Base",
  [msgIds.MSG_NOTIFICATION_BODY_OPERATOR_AUTO_PROFILE_SUSPENSION]:
    "Il servizio collegato al tuo profilo '{{profileTypeDsc}}' per la gestione della struttura '{{identity}}' non è stato rinnovato. Il piano del tuo abbonamento è stato cambiato nel piano Base",
  [msgIds.MSG_NOTIFICATION_BODY_UPCOMING_CUSTOMER_PROFILE_DELETION]:
    "Abbiamo riscontrato che il tuo profilo '{{profileTypeDsc}}' non viene utilizzato da quasi 2 anni. Tra {{daysCountDown}} giorni verrà chiuso e tutti i documenti ad esso riferiti verranno rimossi dagli archivi di PersonalDOX. Per evitare la chiusura del profilo è sufficiente effettuare un login e selezionare il profilo",
  [msgIds.MSG_NOTIFICATION_BODY_UPCOMING_OPERATOR_AUTO_PROFILE_DELETION]:
    "Abbiamo riscontrato che il tuo profilo '{{profileTypeDsc}}' per la gestione della struttura '{{identity}}' non viene utilizzato da quasi 2 anni. Tra {{daysCountDown}} giorni verrà chiuso e tutti i documenti ad esso riferiti verranno rimossi dagli archivi di PersonalDOX. Per evitare la chiusura del profilo è sufficiente effettuare un login e selezionare il profilo",
  [msgIds.MSG_NOTIFICATION_BODY_CUSTOMER_PROFILE_DELETION_BY_SYSTEM]:
    "Il tuo profilo '{{profileTypeDsc}}' non viene utilizzato da più di 2 anni, lo abbiamo chiuso automaticamente e tutti i dati ad esso riferiti sono stati cancellati",
  [msgIds.MSG_NOTIFICATION_BODY_OPERATOR_AUTO_PROFILE_DELETION_BY_SYSTEM]:
    "Il tuo profilo '{{profileTypeDsc}}' per la gestione della struttura '{{identity}}' non viene utilizzato da più di 2 anni, lo abbiamo chiuso automaticamente e tutti i dati ad esso riferiti sono stati cancellati",
  [msgIds.MSG_NOTIFICATION_BODY_CUSTOMER_PROFILE_DELETION_BY_OWNER]:
    "Come da te richiesto abbiamo provveduto alla chiusura del tuo profilo '{{profileTypeDsc}}', tutti i dati ad esso riferiti sono stati cancellati",
  [msgIds.MSG_NOTIFICATION_BODY_OPERATOR_AUTO_PROFILE_DELETION_BY_OWNER]:
    "Come da te richiesto abbiamo provveduto alla chiusura del tuo profilo '{{profileTypeDsc}}' per la gestione della struttura '{{identity}}', tutti i dati ad esso riferiti sono stati cancellati",
  [msgIds.MSG_NOTIFICATION_BODY_COLLABORATOR_PROFILE_DELETION_BY_OWNER]:
    "Come da te richiesto abbiamo provveduto alla chiusura del tuo profilo '{{profileTypeDsc}}' della struttura '{{identity}}'",
  [msgIds.MSG_NOTIFICATION_BODY_COLLABORATOR_PROFILE_DELETION_BY_OPERATOR_ADMIN]:
    "Come da te richiesto abbiamo provveduto alla chiusura del profilo '{{profileTypeDsc}}' della struttura '{{identity}}'",
  [msgIds.MSG_NOTIFICATION_BODY_UPCOMING_USER_DELETION]:
    "Abbiamo riscontrato che non stai utilizzando il tuo account da quasi 2 anni. Tra {{daysCountDown}} giorni l'account e tutti i profili associati verranno chiusi, tutti i dati ad essi riferiti verranno rimossi dagli archivi di PersonalDOX. Per evitare la chiusura dell'account è sufficiente effettuare un login e selezionare i profili che intendi continuare ad utilizzare",
  [msgIds.MSG_NOTIFICATION_BODY_USER_DELETION_BY_SYSTEM]:
    'Il tuo account non viene utilizzato da più di 2 anni, lo abbiamo chiuso automaticamente assieme ai profili associati, tutti i dati ad essi riferiti sono stati rimossi dagli archivi di PersonalDOX',
  [msgIds.MSG_NOTIFICATION_BODY_USER_DELETION_BY_OWNER]:
    'Come da te richiesto abbiamo provveduto alla chiusura del tuo account, tutti i profili associati sono stati chiusi e tutti i dati ad essi riferiti sono stati rimossi dagli archivi di PersonalDOX',
  [msgIds.MSG_NOTIFICATION_BODY_PRIVACY_POLICY_UPDATED_BY_EXIDEA_WITH_BREAKING_CHANGE]:
    "Pubblicato una nuova informativa privacy per i profili '{{targetProfileType}}'. Per continuare ad utilizzare PersonalDOX è necessario che tu ci fornisca nuovamente il consenso per il trattamento dei dati. Premi sull'icona 'Contratto' per visionare il nuovo documento",
  [msgIds.MSG_NOTIFICATION_BODY_PRIVACY_POLICY_UPDATED_BY_EXIDEA_WITH_NO_BREAKING_CHANGE]:
    "Pubblicato una nuova informativa privacy per i profili '{{targetProfileType}}'. Il trattamento dei dati non cambia e i consensi forniti rimangono validi. Premi sull'icona 'Contratto' per visionare il nuovo documento",
  [msgIds.MSG_NOTIFICATION_BODY_TERMS_OF_USE_UPDATED_BY_EXIDEA_WITH_BREAKING_CHANGE]:
    "Aggiornato le condizioni d'uso per i profili '{{targetProfileType}}'. Per continuare ad utilizzare PersonalDOX è necessario che tu ne accetti le condizioni. Premi sull'icona 'Contratto' per visionare il nuovo documento",
  [msgIds.MSG_NOTIFICATION_BODY_TERMS_OF_USE_UPDATED_BY_EXIDEA_WITH_NO_BREAKING_CHANGE]:
    "Aggiornato le condizioni d'uso per i profili '{{targetProfileType}}'. Puoi continuare ad utilizzare PersonalDOX, i consensi forniti rimangono validi. Premi sull'icona 'Contratto' per visionare il nuovo documento",
  [msgIds.MSG_NOTIFICATION_BODY_DATA_PROCESSOR_UPDATED_BY_EXIDEA_WITH_BREAKING_CHANGE]:
    "Aggiornato l'accordo per la nomina di responsabile al trattamento dei dati. Per continuare ad utilizzare PersonalDOX è necessario che tu ne accetti le condizioni. Premi sull'icona 'Contratto' per visionare il nuovo documento",
  [msgIds.MSG_NOTIFICATION_BODY_DATA_PROCESSOR_UPDATED_BY_EXIDEA_WITH_NO_BREAKING_CHANGE]:
    "Aggiornato l'accordo per la nomina di responsabile al trattamento dei dati. Puoi continuare ad utilizzare PersonalDOX, i consensi forniti rimangono validi. Premi sull'icona 'Contratto' per visionare il nuovo documento",
  [msgIds.MSG_NOTIFICATION_BODY_DOX_DELIVERED]: 'Consegnato un nuovo dox',
  [msgIds.MSG_NOTIFICATION_BODY_DOX_RECEIVED]: 'Ti ha inviato un nuovo dox',
  [msgIds.MSG_NOTIFICATION_BODY_DOCUMENT_REVISION_CREATED_BY_CUSTOMER]:
    "Revisionato il documento '{{title}}' con data {{editedAt}} creato da '{{creatorIdentity}}'",
  [msgIds.MSG_NOTIFICATION_BODY_DOCUMENT_REVISION_CREATED_BY_STRUCTURE]:
    "Revisionato il documento '{{title}}' con data {{editedAt}} creato da '{{creatorIdentity}}'",
  [msgIds.MSG_NOTIFICATION_BODY_DOCUMENT_VIEWED]:
    "Visualizzato il documento '{{title}}' con data {{editedAt}} creato da '{{creatorIdentity}}'",
  [msgIds.MSG_NOTIFICATION_BODY_DOCUMENT_REVISION_DOWNLOADED]:
    "Effettuato il download del documento '{{title}}' con data {{editedAt}} creato da '{{creatorIdentity}}'",
  [msgIds.MSG_NOTIFICATION_BODY_DATA_PROCESSING_GRANTED]: 'Autorizzato il trattamento dei dati',
  [msgIds.MSG_NOTIFICATION_BODY_DATA_PROCESSING_UPDATED]:
    'Impostato la durata del trattamento dal {{startAt}} al {{endAt}}',
  [msgIds.MSG_NOTIFICATION_BODY_DATA_PROCESSING_DELETED]: 'Revocato il trattamento dei dati',
  [msgIds.MSG_NOTIFICATION_BODY_DATA_PROCESSING_SUSPENDED]: 'Sospeso il trattamento dei dati',
  [msgIds.MSG_NOTIFICATION_BODY_DATA_PROCESSING_RESTORED]: 'Riattivato il trattamento dei dati',
  [msgIds.MSG_NOTIFICATION_BODY_UPCOMING_DATA_PROCESSING_EXPIRATION_CUSTOMER]:
    'Tra {{daysCountDown}} giorni la struttura {{identity}} non sarà più autorizzata a trattare i tuoi dati',
  [msgIds.MSG_NOTIFICATION_BODY_UPCOMING_DATA_PROCESSING_EXPIRATION_OPERATOR]:
    "Tra {{daysCountDown}} giorni il trattamento dei dati dell'utente {{identity}} nato il {{birthdate}} non sarà più autorizzato",
  [msgIds.MSG_NOTIFICATION_BODY_DATA_PROCESSING_EXPIRED_CUSTOMER]:
    'La struttura {{identity}} non è più autorizzata a trattare i tuoi dati',
  [msgIds.MSG_NOTIFICATION_BODY_DATA_PROCESSING_EXPIRED_OPERATOR]:
    "Il trattamento dei dati dell'utente {{identity}} nato il {{birthdate}} non è più autorizzato",
  [msgIds.MSG_NOTIFICATION_BODY_PRIVACY_POLICY_UPDATED_BY_STRUCTURE_WITH_BREAKING_CHANGE1]:
    "Pubblicato una nuova informativa privacy, per autorizzare il trattamento dei dati è necessario che tu prenda visione del nuovo documento. Premi sull'icona 'Privacy' per visionare la nuova informativa",
  [msgIds.MSG_NOTIFICATION_BODY_PRIVACY_POLICY_UPDATED_BY_STRUCTURE_WITH_BREAKING_CHANGE2]:
    "Pubblicato una nuova informativa privacy, i consensi che hai fornito in precedenza sono stati revocati. Per riattivarli è necessario che tu fornisca dei nuovi consensi. Premi sull'icona 'Privacy' per visionare la nuova informativa",
  [msgIds.MSG_NOTIFICATION_BODY_PRIVACY_POLICY_UPDATED_BY_STRUCTURE_WITH_NO_BREAKING_CHANGE]:
    "Pubblicato una nuova informativa privacy, il trattamento dei dati non cambia e i consensi forniti rimangono validi. Premi sull'icona 'Privacy' per visionare la nuova informativa",
  [msgIds.MSG_NOTIFICATION_BODY_PRIVACY_POLICY_UPDATED_BY_STRUCTURE_WITH_NO_BREAKING_CHANGE_AND_NEW_SECTIONS_WITH_CONSENT]:
    "Pubblicato una nuova informativa privacy, il trattamento dei dati non cambia e i consensi forniti rimangono validi. NOTA: nuovi trattamenti sono in attesa del tuo consenso.\nPremi sull'icona 'Privacy' per visionare la nuova informativa",
  [msgIds.MSG_NOTIFICATION_BODY_PRIVACY_POLICY_CONSENT_UPDATED1]: 'Revocato consenso per una sezione',
  [msgIds.MSG_NOTIFICATION_BODY_PRIVACY_POLICY_CONSENT_UPDATED2]: "Preso visione dell'informativa",
  [msgIds.MSG_NOTIFICATION_BODY_PRIVACY_POLICY_CONSENT_UPDATED3]: 'Fornito consenso per una sezione',
  [msgIds.MSG_NOTIFICATION_BODY_PROFILE_ATTACHED]: 'Agganciato cliente segnaposto a cliente reale',
  [msgIds.MSG_NOTIFICATION_BODY_PROFILE_DETACHED]:
    "L'utente non dispone più di un profilo privato. Da ora potrai cercarlo nella tua anagrafica ma non potrai consegnargli dei fascicoli nè lui potrà condividere con te i suoi documenti attraverso PersonalDOX. Potrai comunque continuare ad accedere ai documenti che hai creato per lui. Ricorda che l'autorizzazione al trattamento dei dati non potrà più essere gestita automaticamente da PersonalDOX. Potrai farlo tu manualmente dalla scheda di '{{identity}}', ciò ti permetterà di regolare l'accesso alle informazioni personali che hai salvato. Qualora fosse necessario, dovrai raccogliere manualmente i consensi al trattamento, ad esempio utilizzando un tradizionale documento cartaceo.",
  [msgIds.MSG_NOTIFICATION_BODY_SUBSCRIPTION_TRIAL_UPCOMING_EXPIRATION]:
    'Il periodo di prova per la tua sottoscrizione {{subscriptionId}} scadrà in data {{trialEndAt}}',
  [msgIds.MSG_NOTIFICATION_BODY_UPCOMING_DOCUMENT_RETENTION_EXPIRATION]:
    'Tra {{daysCountDown}} giorni la conservazione del documento terminerà',
  [msgIds.MSG_NOTIFICATION_BODY_DOCUMENT_RETENTION_EXPIRED]:
    "La conservazione del documento '{{title}}' con data {{editedAt}} creato da '{{creatorIdentity}}' è terminata",
  [msgIds.MSG_NOTIFICATION_BODY_UPCOMING_DOX_RETENTION_EXPIRATION]:
    'Tra {{daysCountDown}} giorni la conservazione dei documenti associati al dox terminerà',
  [msgIds.MSG_NOTIFICATION_BODY_DOX_RETENTION_EXPIRED]: 'La conservazione dei documenti associati al dox è terminata',
  [msgIds.MSG_NOTIFICATION_BODY_INVITATION_PERMITTED]: "L'utente ti ha autorizzato a inviargli un invito",
  [msgIds.MSG_NOTIFICATION_BODY_INVITATION_RECEIVED_COLLABORATE]:
    'Sei stato invitato a collaborare con questa organizzazione',
  [msgIds.MSG_NOTIFICATION_BODY_INVITATION_ACCEPTED_COLLABORATE]:
    "L'utente ha accettato l'invito a collaborare con questa organizzazione",
  // Notifications show resources dialogs
  [msgIds.MSG_NOTIFICATION_SHOW_DOX_TITLE]: 'Mostra dox',
  [msgIds.MSG_NOTIFICATION_SHOW_DOX_NOT_AVAILABLE]: 'Il dox non è disponibile',
  [msgIds.MSG_NOTIFICATION_SHOW_DOX_WRONG_ASSISTED_CUSTUMER]:
    'Il dox si riferisce ad un utente diverso da quello attualmente selezionato',

  // Profile details editor
  [msgIds.MSG_PROFILE_EDITOR_NOT_AVAILABLE_ACCOUNT]: 'Account non disponibile',
  [msgIds.MSG_PROFILE_EDITOR_CONSUMER_TITLE]: 'Informazioni personali come utente privato',
  [msgIds.MSG_PROFILE_EDITOR_ABOUT_CONSUMER_TITLE]: "Informazioni personali dell'utente {{identity}}",
  [msgIds.MSG_PROFILE_EDITOR_BUSINESS_TITLE]: 'Informazioni personali come professionista',
  [msgIds.MSG_PROFILE_EDITOR_STRUCTURE_TITLE]: 'Informazioni struttura',
  [msgIds.MSG_PROFILE_EDITOR_COLLABORATOR_TITLE]: 'Informazioni personali come collaboratore',
  [msgIds.MSG_PROFILE_EDITOR_ABOUT_COLLABORATOR_TITLE]: 'Informazioni personali del collaboratore {{identity}}',
  [msgIds.MSG_PROFILE_EDITOR_PRESENTATION_TITLE]: 'Presentazione',
  [msgIds.MSG_PROFILE_EDITOR_PRESENTATION_SELECT_IMAGE_BUTTON]: "Seleziona l'immagine",
  [msgIds.MSG_PROFILE_EDITOR_PRESENTATION_REMOVE_IMAGE_BUTTON]: 'Rimuovi immagine',
  [msgIds.MSG_PROFILE_EDITOR_PRESENTATION_DEFAULT_PROFILE_LABEL]: 'Profilo predefinito',
  [msgIds.MSG_PROFILE_EDITOR_PRESENTATION_PROFILE_VISIBILITY_LABEL]: 'Visibiltà del profilo',
  [msgIds.MSG_PROFILE_EDITOR_PRESENTATION_PROFILE_VISIBILITY_NONE]: 'Nessuno',
  [msgIds.MSG_PROFILE_EDITOR_PRESENTATION_PROFILE_VISIBILITY_COLLABORATORS]: 'Solo ai collaboratori',
  [msgIds.MSG_PROFILE_EDITOR_PRESENTATION_PROFILE_VISIBILITY_ALL]: 'Tutti',
  [msgIds.MSG_PROFILE_EDITOR_PRESENTATION_ALIAS_LABEL]: 'Alias del profilo (per uso personale)',
  [msgIds.MSG_PROFILE_EDITOR_PRESENTATION_TITLE_LABEL]: 'Titolo',
  [msgIds.MSG_PROFILE_EDITOR_IDENTITY_TITLE]: 'Identità',
  [msgIds.MSG_PROFILE_EDITOR_IDENTITY_WARNING]:
    "Le seguenti informazioni possono essere modificate solamente dall'helpdesk",
  [msgIds.MSG_PROFILE_EDITOR_IDENTITY_BIRTH_COUNTRY]: 'Paese di nascita',
  [msgIds.MSG_PROFILE_EDITOR_IDENTITY_BIRTH_CITY]: 'Comune di nascita',
  [msgIds.MSG_PROFILE_EDITOR_IDENTITY_VAT]: 'Partita IVA',
  [msgIds.MSG_PROFILE_EDITOR_IDENTITY_CONFIRM_TITLE]: 'Modifica dati identificativi',
  [msgIds.MSG_PROFILE_EDITOR_IDENTITY_CONFIRM_BODY]:
    "I dati identificativi non possono essere modificati in autonomia. Verrà inviata una richiesta all'help desk di PersonalDOX.\n\nConfermi di voler procedere?",
  [msgIds.MSG_PROFILE_EDITOR_RESIDENCE_TITLE]: 'Residenza',
  [msgIds.MSG_PROFILE_EDITOR_RESIDENCE_STRUCTURE_TITLE]: 'Sede',
  [msgIds.MSG_PROFILE_EDITOR_CONTACTS_TITLE]: 'Contatti',
  [msgIds.MSG_PROFILE_EDITOR_CONTACTS_MOBILE_PHONE_1]: 'Cellulare 1',
  [msgIds.MSG_PROFILE_EDITOR_CONTACTS_MOBILE_PHONE_2]: 'Cellulare 2',
  [msgIds.MSG_PROFILE_EDITOR_CONTACTS_EMAIL_1]: 'Email 1',
  [msgIds.MSG_PROFILE_EDITOR_CONTACTS_EMAIL_2]: 'Email 2',
  [msgIds.MSG_PROFILE_EDITOR_CONTACTS_EMAIL_3]: 'Email 3',
  [msgIds.MSG_PROFILE_EDITOR_CONTACTS_EMAIL_4]: 'Email 4',
  [msgIds.MSG_PROFILE_EDITOR_CONTACTS_WEBSITE]: 'Sito web',
  [msgIds.MSG_PROFILE_EDITOR_BILLING_TITLE]: 'Fatturazione',
  [msgIds.MSG_PROFILE_EDITOR_BILLING_INFO]: 'Le seguenti informazioni non verranno rese pubbliche',
  [msgIds.MSG_PROFILE_EDITOR_BILLING_SDI]: 'Codice SDI',
  [msgIds.MSG_PROFILE_EDITOR_BILLING_PEC]: 'PEC',
  [msgIds.MSG_PROFILE_EDITOR_OPERATOR_CLOSE_PLACEHOLDER_ACCOUNT]:
    'ATTENZIONE! Questa operazione non può essere annullata.\n\nI dati collegati al profilo che stai chiudendo verranno rimossi dai nostri archivi.',
  [msgIds.MSG_PROFILE_EDITOR_OPERATOR_CLOSE_COLLABORATOR_PROFILE]:
    'ATTENZIONE!\nChiudendo questo profilo il collaboratore non potrà più accedere alle risorse di questa struttura.\n\nPer poter diventare nuovamente un collaboratore sarà necessario inviare un nuovo invito.',
  [msgIds.MSG_PROFILE_EDITOR_CLOSE_DIALOG_ADVERTISING]:
    "ATTENZIONE! Questa operazione non può essere annullata.\n\nIn accordo con quanto indicato nell'informativa privacy, i dati collegati al profilo che stai chiudendo verranno rimossi dai nostri archivi.\n\n",

  // PersonConfigurations
  [msgIds.MSG_ACCOUNT_CONFIGURATIONS_PAGE_TITLE]: 'Impostazioni',
  [msgIds.MSG_PASSWORD_EDITOR_TITLE]: 'Password',
  [msgIds.MSG_PASSWORD_EDITOR_INFO]:
    'La password deve avere almeno 8 caratteri, deve contenere almeno un numero, lettere maiuscole, lettere minuscole ed almeno un carattere speciale tra !%@-\n\nEsempio: d4Re!pO%',
  [msgIds.MSG_PASSWORD_EDITOR_PASSWORD]: 'Password',
  [msgIds.MSG_PASSWORD_EDITOR_PASSWORD_CONFIRMATION]: 'Conferma password',

  // TOTP
  [msgIds.MSG_TOTP_EDITOR_TITLE]: 'Autenticazione a due fattori (TOTP)',
  [msgIds.MSG_TOTP_EDITOR_ENABLE_LABEL]: 'Voglio utilizzare il codice TOTP per accedere al mio account',
  [msgIds.MSG_TOTP_EDITOR_INFO]:
    "Il TOTP garantisce una maggiore sicurezza per il tuo account.\nPer poter effetturare l'accesso dovrai inserire un codice provvisorio generato da un'apposita applicazione.",
  [msgIds.MSG_TOTP_EDITOR_GUIDE_LINK]: 'Leggi come funziona la generazione del codice TOTP',
  [msgIds.MSG_TOTP_EDITOR_CODE_INPUT_INFO]: "Inserisci il codice TOTP generato dall'app del tuo telefono",
  [msgIds.MSG_TOTP_EDITOR_CODE_INPUT_LABEL]: 'Codice TOTP',
  [msgIds.MSG_TOTP_EDITOR_CODE_TRY_BUTTON]: 'Prova TOTP',
  [msgIds.MSG_TOTP_EDITOR_QRCODE_INFO]:
    "Utilizza il QRCode o il codice che abbiamo generato per configurare l'app del tuo telefono.\nTi consentirà di generare i codici TOTP.",
  [msgIds.MSG_TOTP_EDITOR_QRCODE_WARNING]: 'ATTENZIONE! questo codice non verrà più visualizzato.',
  [msgIds.MSG_TOTP_EDITOR_QRCODE_CODE_LABEL]: 'Codice per la configurazione:',
  [msgIds.MSG_TOTP_EDITOR_DIALOG_DISABLE_TITLE]: "Conferma l'operazione con il TOTP",
  [msgIds.MSG_TOTP_EDITOR_DIALOG_DISABLE_INFO]:
    "Se disattivi l'authenticazione a due fattori l'accesso ai tuoi dati sarà meno sicuro.\nVuoi procedere ugualmente?",
  [msgIds.MSG_TOTP_EDITOR_VALID_CODE]: 'Codice TOTP valido',
  [msgIds.MSG_TOTP_EDITOR_SUCCESSFULLY_ENABLED]: 'Autenticazione a due fattori attivata',
  [msgIds.MSG_TOTP_EDITOR_SUCCESSFULLY_DISABLED]: 'Autenticazione a due fattori disattivata',

  // configurations authorizations
  [msgIds.MSG_CONFIGURATIONS_AUTH_EDITOR_TITLE]: 'Impostazioni autorizzazioni',
  [msgIds.MSG_CONFIGURATIONS_AUTH_EDITOR_INFO]:
    'Quando autorizzi una struttura a vedere un tuo dox o un tuo documento, tale autorizzazione durerà per il numero di giorni indicato nel campo sottostante. I professionisti della struttura autorizzata possono interrompere la condivisione prima dello scadere di questo tempo.',
  [msgIds.MSG_CONFIGURATIONS_AUTH_EDITOR_INPUT_LABEL]:
    'Durata predefinita in giorni delle condivisioni di dox e documenti',
  [msgIds.MSG_CONFIGURATIONS_AUTH_EDITOR_INPUT_ERROR]: 'Il valore deve essere compreso tra 1 e 135',

  // configurations notifications
  [msgIds.MSG_NOTIFICATIONS_SETTINGS_EDITOR_TITLE]: 'Notifiche da inviare alla email associata al profilo',
  [msgIds.MSG_NOTIFICATIONS_SETTINGS_EDITOR_TYPE_DOX_RECEIVED_LABEL]: 'Un dox è stato consegnato da una struttura',
  [msgIds.MSG_NOTIFICATIONS_SETTINGS_EDITOR_TYPE_DOCUMENT_REVISION_CREATED_LABEL]:
    'Un documento consegnato da una struttura è stato revisionato',
  [msgIds.MSG_NOTIFICATIONS_SETTINGS_EDITOR_TYPE_DOCUMENT_VIEWED_LABEL]:
    'Un documento consegnato da una struttura è stato visualizzato',
  [msgIds.MSG_NOTIFICATIONS_SETTINGS_EDITOR_TYPE_DOCUMENT_DOWNLOADED_LABEL]:
    'Un documento consegnato da una struttura è stato scaricato',
  [msgIds.MSG_NOTIFICATIONS_SETTINGS_EDITOR_TYPE_PRIVACY_POLICY_UPDATED_LABEL]:
    "L'informativa privacy di una struttura è stata modificata",

  // General settings
  [msgIds.MSG_GENERAL_SETTINGS_TITLE]: 'Impostazioni generali',
  [msgIds.MSG_GENERAL_SETTINGS_CHECKBOX_LABEL]:
    'Abilita il salvataggio automatico dei documenti editati in PersonalDox',
  [msgIds.MSG_GENERAL_SETTINGS_AUTOSAVE_TIME_INFO]:
    'Per attivare il salvataggio automatico impostare un valore maggiore di 0',
  [msgIds.MSG_GENERAL_SETTINGS_AUTOSAVE_TIME_INPUT_LABEL]: 'Salvataggio automatico (secondi)',

  // Documents Templates
  [msgIds.MSG_DOCUMENTS_TEMPLATES_TITLE]: 'Modelli di documenti interni',
  [msgIds.MSG_DOCUMENTS_TEMPLATES_LIST_LABEL]: 'Elenco dei modelli ({{count}})',
  [msgIds.MSG_DOCUMENTS_TEMPLATES_LIST_ADD_BUTTON]: '+ Nuovo',
  [msgIds.MSG_DOCUMENTS_TEMPLATES_TEMPLATE_NAME_LABEL]: 'Nome',
  [msgIds.MSG_DOCUMENTS_TEMPLATES_TEMPLATE_DESCRIPTION_LABEL]: 'Descrizione',
  [msgIds.MSG_DOCUMENTS_TEMPLATES_TEMPLATE_CONTENT_LABEL]: 'Contenuto',
  [msgIds.MSG_DOCUMENTS_TEMPLATES_TEMPLATE_NAME_DEFAULT]: 'Nuovo modello',
  [msgIds.MSG_DOCUMENTS_TEMPLATES_DELETE_DIALOG_CONFIRM_TITLE]: 'Cancellazione modello',
  [msgIds.MSG_DOCUMENTS_TEMPLATES_DELETE_DIALOG_CONFIRM_BODY]: 'Sei sicuro di voler cancellare questo modello?',

  // Document templates list dialog
  [msgIds.MSG_DOCUMENTS_TEMPLATES_DIALOG_TITLE]: 'Modelli di documenti',

  // Document templates
  [msgIds.MSG_DOCUMENTS_TEMPLATES_TEMPLATES_LIST_EMPTY]:
    "Non hai ancora creato alcun modello.\n\nNel menu laterale, vai in 'Impostazioni struttura' -> 'Gestione archivio'.\n\nNell'area 'Modelli di documenti interni' trovi lo strumento che ti permette di configurare modelli predefiniti da riutilizzare all'occorrenza.",

  // Document dialogs
  [msgIds.MSG_DOCUMENT_NOT_SAVED_CHANGED_DIALOG_BODY]: 'Documento: {{name}}\n\nSalvare le modifiche non salvate?',

  // Doxes Templates
  [msgIds.MSG_DOXES_TEMPLATES_TITLE]: 'Modelli di Dox organizzati',
  [msgIds.MSG_DOXES_TEMPLATES_LIST_LABEL]: 'Elenco dei modelli ({{count}})',
  [msgIds.MSG_DOXES_TEMPLATES_LIST_ADD_BUTTON]: '+ Nuovo',
  [msgIds.MSG_DOXES_TEMPLATES_TEMPLATE_NAME_LABEL]: 'Nome',
  [msgIds.MSG_DOXES_TEMPLATES_TEMPLATE_DESCRIPTION_LABEL]: 'Descrizione',
  [msgIds.MSG_DOXES_TEMPLATES_DOXES_LABEL]: 'Struttura dei DOX',
  [msgIds.MSG_DOXES_TEMPLATES_DOX_ADD_BUTTON]: '+ Aggiungi DOX',
  [msgIds.MSG_DOXES_TEMPLATES_DOX_NAME_DEFAULT]: 'Nuovo Dox',
  [msgIds.MSG_DOXES_TEMPLATES_TEMPLATE_NAME_DEFAULT]: 'Nuovo modello',
  [msgIds.MSG_DOXES_TEMPLATES_DELETE_MODEL_DIALOG_CONFIRM_TITLE]: 'Cancellazione modello',
  [msgIds.MSG_DOXES_TEMPLATES_DELETE_MODEL_DIALOG_CONFIRM_BODY]: 'Sei sicuro di voler cancellare questo modello?',
  [msgIds.MSG_DOXES_TEMPLATES_DELETE_ELEMENT_DIALOG_CONFIRM_TITLE]: 'Cancellazione elemento',
  [msgIds.MSG_DOXES_TEMPLATES_DELETE_ELEMENT_DIALOG_CONFIRM_BODY]: 'Sei sicuro di voler cancellare questo elemento?',

  // Doxes Templates
  [msgIds.MSG_DOX_TEMPLATE_EDITOR_DIALOG_TITLE]: 'Dox template',
  [msgIds.MSG_DOX_TEMPLATE_EDITOR_AUTO_RETENTION_DATE_LABEL]: 'Imposta automaticamente la data di fine conservazione',

  // Dox templates list dialog
  [msgIds.MSG_DOX_TEMPLATES_DIALOG_TITLE]: 'Modelli di Dox organizzati',

  // Organized doxes
  [msgIds.MSG_ORGANIZED_DOX_ERROR_DIALOG_TITLE]: 'Errore nel modello di dox organizzato',
  [msgIds.MSG_ORGANIZED_DOX_ERROR_DIALOG_BODY]:
    'Nel modello di Dox organizzato è utilizzato un trattamento non valido pertanto non è possibile procedere',
  [msgIds.MSG_ORGANIZED_DOX_TEMPLATES_LIST_EMPTY]:
    "Non hai ancora configurato alcun modello.\n\nNel menu laterale, vai in 'Impostazioni struttura' -> 'Gestione archivio'.\n\nNell'area 'Modelli di Dox organizzati' trovi lo strumento che ti permette di configurare gruppi di Dox con nomi e impostazioni predefinite.",

  // Users data form
  [msgIds.MSG_USERS_DATA_TITLE]: 'Gestione dei dati degli utenti',
  [msgIds.MSG_USERS_DATA_VISIBILITY_ALL_LABEL]: 'Può vedere tutti i dati trattati',
  [msgIds.MSG_USERS_DATA_VISIBILITY_BY_FINALITY_LABEL]: 'Può vedere i dati trattati per le seguenti finalità',

  // Users auth form
  [msgIds.MSG_USERS_AUTH_TITLE]: 'Gestione degli utenti',
  [msgIds.MSG_USERS_AUTH_VISIBILITY_ALL_LABEL]: 'Può vedere tutti gli utenti della struttura',
  [msgIds.MSG_USERS_AUTH_VISIBILITY_BY_USERS_LABEL]: 'Può vedere i seguenti utenti',

  // Privacy management
  [msgIds.MSG_PRIVACY_MANAGEMENT_TITLE]: 'Gestione impianto privacy',
  [msgIds.MSG_PRIVACY_MANAGEMENT_VIEW_LABEL]: "Può visualizzare l'impianto privacy",
  [msgIds.MSG_PRIVACY_MANAGEMENT_EDIT_LABEL]: "Può modificare l'impianto privacy",

  // Invitations management
  [msgIds.MSG_INVITATIONS_MANAGEMENT_TITLE]: 'Gestione inviti',
  [msgIds.MSG_INVITATIONS_MANAGEMENT_ENABLED_LABEL]: 'Può invitare altri utenti a collaborare con questa struttura',

  // Collaborators management
  [msgIds.MSG_COLLABORATORS_MANAGEMENT_TITLE]: 'Gestione dei collaboratori',
  [msgIds.MSG_COLLABORATORS_MANAGEMENT_VIEW_AUTH_LABEL]: 'Può visualizzare le autorizzazioni dei collaboratori',
  [msgIds.MSG_COLLABORATORS_MANAGEMENT_ADD_AUTH_LABEL]: 'Può dare le autorizzazioni ai collaboratori',
  [msgIds.MSG_COLLABORATORS_MANAGEMENT_REMOVE_AUTH_LABEL]: 'Può revocare le autorizzazioni dei collaboratori',
  [msgIds.MSG_COLLABORATORS_MANAGEMENT_EDIT_PROFILE_LABEL]: 'Può modificare il profilo dei collaboratori',

  // Communes
  [msgIds.MSG_COMMUNE_AUTOCOMPLETE_TIP]: 'Digita il nome del tuo comune',

  // History
  [msgIds.MSG_HISTORY_EVENT_SUBTYPE_DSC_DATA_PROCESSING]: 'al trattamento dei dati personali',
  [msgIds.MSG_HISTORY_EVENT_SUBTYPE_DSC_ALWAYS_DOWNLOAD]: 'al download di documenti e dox',
  [msgIds.MSG_HISTORY_EVENT_SUBTYPE_DSC_VIEW_DOX]: 'alla visualizzazione del dox',
  [msgIds.MSG_HISTORY_EVENT_SUBTYPE_DSC_VIEW_DOCUMENTS]: 'alla visualizzazione del documento',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_USER_CREATED]: 'iscrizione creata',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_USER_UPDATED]: 'iscrizione aggiornata',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_USER_ACTIVATED]: 'iscrizione attivata',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_USER_SUSPENDED]: 'iscrizione sospesa',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_USER_UPCOMING_DELETION]: 'iscrizione prossima alla cancellazione',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_USER_DELETED]: 'iscrizione cancellata',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_PROFILE_CREATED]: 'profilo creato',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_PROFILE_ATTACHED]: "profilo agganciato all'anagrafica aziendale",
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_PROFILE_DETACHED]: "profilo sganciato dall'anagrafica aziendale",
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_PROFILE_UPCOMING_SUSPENSION]: 'profilo prossimo alla sospensione',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_PROFILE_SUSPENDED]: 'profilo sospeso',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_PROFILE_UPCOMING_DELETION]: 'profilo prossimo alla cancellazione',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_PROFILE_DELETED]: 'profilo cancellato',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_INVITATION_CREATED]: 'inviato invito',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_INVITATION_REVOKED]: 'annullato invito',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_INVITATION_ACCEPTED]: 'accettato invito',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_PERMISSION_CREATED]: 'autorizzato {{subjectTypeDsc}}',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_PERMISSION_UPDATED]: 'modificato autorizzazione {{subjectTypeDsc}}',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_PERMISSION_SUSPENDED]: 'sospeso autorizzazione {{subjectTypeDsc}}',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_PERMISSION_RESTORED]: 'ripristinato autorizzazione {{subjectTypeDsc}}',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_PERMISSION_UPCOMING_EXPIRATION]:
    'autorizzazione prossima alla scadenza {{subjectTypeDsc}}',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_PERMISSION_EXPIRED]: 'autorizzazione scaduta {{subjectTypeDsc}}',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_PERMISSION_DELETED]: 'revocato autorizzazione {{subjectTypeDsc}}',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_PERMISSION_SENDED]: 'inviato mail con il link {{subjectTypeDsc}}',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_TREATMENT_CREATED]: 'Trattamento dei dati creato',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_TREATMENT_UPDATED]: 'Trattamento dei dati modificato',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_TREATMENT_DELETED]: 'Trattamento dei dati cancellato',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_TREATMENT_ENABLED]: 'Trattamento dei dati riattivato',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_TREATMENT_DISABLED]: 'Trattamento dei dati disattivato',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_CONTRACT_VERSION_CREATED]: 'versione contratto creata',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_CONTRACT_VERSION_UPDATED]: 'versione contratto aggiornata',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_CONTRACT_VERSION_PUBLISHED]: 'versione contratto pubblicata',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_CONTRACT_VERSION_DELETED]: 'versione contratto cancellata',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_DATA_PROCESSING_SECTION_VIEWED]: "preso visione dell'informativa privacy",
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_CONSENT_GRANTED]: 'fornito consenso',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_CONSENT_REVOKED]: 'revocato consenso',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_DOCUMENT_REVISION_CREATED]: 'creato revisione documento',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_DOCUMENT_REVISION_UPDATED]: 'modificato revisione documento',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_DOCUMENT_ARCHIVED]: 'archiviato documento',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_DOCUMENT_REVISION_DELETED]: 'cancellato revisione documento',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_DOCUMENT_REVISION_DOWNLOADED]: 'scaricato revisione documento',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_DOCUMENT_REVISION_VIEWED]: 'visualizzato revisione documento',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_DOCUMENT_CREATED]: 'creato documento',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_DOCUMENT_RENAMED]: 'rinominato documento',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_DOCUMENT_DELETED]: 'cancellato documento',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_DOCUMENT_DELIVERED]: 'consegnato documento',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_DOCUMENT_MARKED_AS_OBSOLETE]: 'marcato come obsoleto',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_DOCUMENT_MARKED_AS_IMPORTANT]: 'marcato come importante',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_RETENTION_DEADLINE_REMINDER]: 'promemoria scadenza conservazione',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_RETENTION_EXPIRED]: 'avviso termine conservazione',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_DOX_CREATED]: 'dox creato',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_DOX_UPDATED]: 'modificato dox',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_DOX_DELETED]: 'cancellato dox',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_DOX_DOWNLOADED]: 'scaricato dox',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_DOX_VIEWED]: 'visualizzato dox',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_DOX_DELIVERED]: 'consegnato dox',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_INVOICE_FINALIZED]: 'inviato fattura',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_ACTIVITY_CREATED]: '?? internal ??',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_NOTIFICATION_CREATED]: '?? internal ??',
  [msgIds.MSG_HISTORY_BODY_TREATMENT_OF_DATA]: 'trattamento dei dati',
  [msgIds.MSG_HISTORY_BODY_DOCUMENT_VISUALIZATION]: 'visualizzazione documento',
  [msgIds.MSG_HISTORY_BODY_DOX_VISUALIZATION]: 'visualizzazione dox',
  [msgIds.MSG_HISTORY_BODY_SEND_OF_INVITATION]: 'invio di un invito',
  [msgIds.MSG_HISTORY_BODY_PERMISSION_CREATED]:
    'Azione eseguita automaticamente a seguito della pubblicazione di una nuova informativa privacy che non richiede nuovi consensi',
  [msgIds.MSG_HISTORY_BODY_PERMISSION_DATA_PROCESSING]: 'trattamento dei dati',
  [msgIds.MSG_HISTORY_BODY_PERMISSION_VIEW_DOCUMENTS]: 'visualizzazione documento',
  [msgIds.MSG_HISTORY_BODY_PERMISSION_VIEW_DOX]: 'visualizzazione dox',
  [msgIds.MSG_HISTORY_BODY_PERMISSION_INVITE_PROFILES]: 'invio di un invito',
  [msgIds.MSG_HISTORY_BODY_PERMISSION_VIEW_PROFILE]: 'visualizza i dai del profilo',
  [msgIds.MSG_HISTORY_BODY_PERMISSION_ALLOWED_ACTIONS]: 'Azioni consentite:',
  [msgIds.MSG_HISTORY_BODY_PERMISSION_PIN_SECURITY]: 'Sicurezza tramite PIN:',
  [msgIds.MSG_HISTORY_BODY_RETENTION_UPCOMING_EXPIRATION]: 'conservazione in scadenza tra {{daysCountdown}} giorni',
  [msgIds.MSG_HISTORY_BODY_RETENTION_EXPIRED]: 'conservazione terminata',
  [msgIds.MSG_HISTORY_BODY_DOX_DELIVERED]: 'consegnato dox',
  [msgIds.MSG_HISTORY_BODY_DOX_RECEIVED]: 'ricevuto dox',
  [msgIds.MSG_HISTORY_BODY_DOX_NAME]: 'nome',
  [msgIds.MSG_HISTORY_BODY_DOCUMENT_NAME]: 'titolo',
  [msgIds.MSG_HISTORY_BODY_DOCUMENT_PREVIOUS_NAME]: 'titolo precedente',
  [msgIds.MSG_HISTORY_BODY_DOCUMENT_NOT_AVAILABLE_DRAFT]: 'non disponibile (documento in bozza)',
  [msgIds.MSG_HISTORY_BODY_TREATMENT_RETENTION_NOTICE_DAYS_BEFORE_EXPIRATION_YES]:
    "Invio notifica scadenza: SI', giorni di anticipo",
  [msgIds.MSG_HISTORY_BODY_TREATMENT_RETENTION_NOTICE_DAYS_BEFORE_EXPIRATION_NO]: 'Invio notifica scadenza: NO',
  [msgIds.MSG_HISTORY_BODY_TREATMENT_RETENTION_END_BEHAVIOR]: 'Comportamento al termine della conservazione',
  [msgIds.MSG_HISTORY_BODY_NEW_ACKNOWLEDGMENT_REQUIRED]: 'nuova presa visione richiesta',
  [msgIds.MSG_HISTORY_BODY_NEW_ACKNOWLEDGMENT_NOT_REQUIRED]: 'nuova presa visione non richiesta',
  [msgIds.MSG_HISTORY_BODY_NEW_CONSENTS_REQUIRED]: 'richiesti nuovi consensi',
  [msgIds.MSG_HISTORY_BODY_NEW_CONSENTS_NOT_REQUIRED]: 'consensi rinnovati implicitamente',
  [msgIds.MSG_HISTORY_BODY_CONTRACT_PUBLISHED_AT]: 'pubblicata il',
  [msgIds.MSG_HISTORY_BODY_CONSENT_RENEWED_AUTOMATICALLY]:
    'Azione eseguita automaticamente a seguito della pubblicazione di una nuova informativa privacy che non richiede nuovi consensi',
  [msgIds.MSG_HISTORY_BODY_CONSENT_RENEWED_FOR]: 'rinnovato consenso per',
  [msgIds.MSG_HISTORY_BODY_CONSENT_GRANTED_FOR]: 'fornito consenso per',
  [msgIds.MSG_HISTORY_BODY_CONSENT_REVOKED_FOR]: 'revocato consenso per',
  [msgIds.MSG_HISTORY_BODY_INVITATION_PURPOSE_COLLABORATE]: 'collaborare con la struttura',

  // Dialog
  [msgIds.MSG_HISTORY_DIALOG_DETAILS_TITLE]: 'Evento cronologia',
  [msgIds.MSG_HISTORY_SHOW_DOCUMENT_TITLE]: 'Mostra documento',
  [msgIds.MSG_HISTORY_SHOW_DOCUMENT_NOT_AVAILABLE]: 'Documento non disponibile',
  [msgIds.MSG_HISTORY_SHOW_DOX_TITLE]: 'Mostra dox',
  [msgIds.MSG_HISTORY_SHOW_DOX_NOT_AVAILABLE]: 'Il dox non è disponibile',
  [msgIds.MSG_HISTORY_SHOW_CONTRACT_TITLE]: 'Mostra contratto',
  [msgIds.MSG_HISTORY_SHOW_CONTRACT_NOT_AVAILABLE]: 'Il contratto non è disponibile',

  // Collaborator authorizations
  [msgIds.MSG_COLLABORATOR_AUTHORIZATION_DELETE_CONFIRM]: 'Sei sicuro di voler revocare questa autorizzazione?',

  // AccountCardPage
  [msgIds.MSG_ACCOUNT_CARD_PAGE_COMMAND_PRIVACY_POLICY]: 'Informativa privacy',
  [msgIds.MSG_ACCOUNT_CARD_PAGE_COMMAND_OPERATORS]: 'Operatori',
  [msgIds.MSG_ACCOUNT_CARD_PAGE_COMMAND_HISTORY]: 'Cronologia',
  [msgIds.MSG_ACCOUNT_CARD_PAGE_COMMAND_MODIFY]: 'Modifica',
  [msgIds.MSG_ACCOUNT_CARD_PAGE_COMMAND_SEND_INVITATION]: 'Invita',
  [msgIds.MSG_ACCOUNT_CARD_PAGE_COMMAND_REVOKE_INVITATION]: 'Revoca invito',
  [msgIds.MSG_ACCOUNT_CARD_PAGE_COMMAND_ACCEPT_INVITATION]: 'Accetta invito',
  [msgIds.MSG_ACCOUNT_CARD_PAGE_COMMAND_AUTHORIZE_INVITATION]: 'Autorizza invito',
  [msgIds.MSG_ACCOUNT_CARD_PAGE_COMMAND_LINKED_STRUCTURE]: 'Struttura',
  [msgIds.MSG_ACCOUNT_CARD_PAGE_COMMAND_AUTHORIZATIONS]: 'Autorizzazioni',
  [msgIds.MSG_ACCOUNT_CARD_PAGE_COMMAND_SHARED_DOCUMENTS]: 'Documenti condivisi',
  [msgIds.MSG_ACCOUNT_CARD_PAGE_COMMAND_ATTACH_TO_REAL_USER]: 'Aggancia a utente reale',
  [msgIds.MSG_ACCOUNT_CARD_PAGE_COMMAND_CLOSE_PROFILE]: 'Chiudi profilo',
  [msgIds.MSG_ACCOUNT_CARD_PAGE_COMMAND_ADD_OPERATOR_PROFILE]: 'Aggiungi profilo operatore',

  // AccountAuthorizationsPage
  [msgIds.MSG_ACCOUNT_AUTHORIZATIONS_PAGE_TITLE]: 'Autorizzazioni di ${{identity}}',

  // NotFoundPage
  [msgIds.MSG_NOT_FOUND_PAGE_404_ERROR]: '404 - Pagina non trovata',
  [msgIds.MSG_NOT_FOUND_PAGE_ALERT]: 'La pagina che stai cercando non esiste.',
  [msgIds.MSG_NOT_FOUND_PAGE_GO_TO_LOGIN_FOR_OPERATORS]: 'Vai alla pagina di login per i professionisti',
  [msgIds.MSG_NOT_FOUND_PAGE_GO_TO_LOGIN_FOR_CUSTOMERS]: 'Vai alla pagina di login per gli utenti privati',

  // Invitations
  [msgIds.MSG_INVITATION_SEND_TITLE]: 'Invito',
  [msgIds.MSG_INVITATION_SEND_CONFIRM]:
    "L'utente riceverà una notifica che lo invita a collaborare con la tua organizzazione.\nSe accetterà l'invito gli sarà assegnato un profilo dedicato ma non potrà accedere ad alcuna risorsa finchè non verrà autorizzato.\n\nVuoi procedere?",
  [msgIds.MSG_INVITATION_REVOKE_TITLE]: 'Revoca invito',
  [msgIds.MSG_INVITATION_REVOKE_CONFIRM]:
    'ATTENZIONE: se questo invito è già stato accettato la sua revoca non avrà alcun effetto.\n\nSei sicuro di voler revocare questo invito?',
  [msgIds.MSG_INVITATION_ACCEPTANCE_TITLE]: 'Accettazione invito',
  [msgIds.MSG_INVITATION_ACCEPTANCE_CONFIRM]:
    'Sei stato invitato a far parte di questa organizzazione. Se accetti ti verrà assegnato un nuovo profilo da professionista.\n\nATTENZIONE: finchè non verranno configurate le autorizzazioni non potrai accedere a nessuna risorsa di questa organizzazione.\n\nAccetti linvito?',
  [msgIds.MSG_INVITATION_AUTHORIZE_TITLE]: 'Autorizza invito',
  [msgIds.MSG_INVITATION_AUTHORIZE_CONFIRM]:
    "Con questo comando autorizzi la struttura a mandarti un invito per diventare un suo collaboratore interno.\nPer consentire alla struttura di identificarti le verranno resi disponibili il tuo nome e cognome.\nQuesta autorizzazione sarà valida per 3 giorni dopo di chè verrà disattivata e rimossa dagli archivi di PersonalDOX.\n\nATTENZIONE:\nse la struttura sta già facendo altri trattamenti sui tuoi dati, questa autorizzazione non avrà alcun effetto su tali trattamenti.\n\nAutorizzi la struttura a invitarti a far parte dell'organizzazione?",
  [msgIds.MSG_INVITATION_AUTHORIZATION_IMPOSSIBLE]: 'Hai già autorizzato questa struttura a invitarti fino al',
  [msgIds.MSG_INVITATION_AUTHORIZATION_EXECUTED]:
    "Quando questa struttura ti inviterà a far parte dell'organizzazione riceverai una notifica.\n\nPer accettare l'invito clicca nella notifica e dai conferma.\nVerrà creato per te un nuovo profilo professionista che ti permetterà di accedere alle risorse per le quali sei stato autorizzato.",
  [msgIds.MSG_INVITATION_SENDED_SUCCESSFULLY]: 'Invito inviato con successo',

  // ContractVersionViewer
  [msgIds.MSG_CONSENTS_UPDATED_SUCCESSFULLY]: 'Impostazioni e consensi aggiornati con successo',

  // EventHistoryPage
  [msgIds.MSG_EVENT_HISTORY_PAGE_TITLE]: 'Cronologia',

  // EventHistoryTable
  [msgIds.MSG_EVENT_HISTORY_TABLE_DATE_HEADER]: 'Data/ora',
  [msgIds.MSG_EVENT_HISTORY_TABLE_AUTHOR_HEADER]: 'Esecutore',
  [msgIds.MSG_EVENT_HISTORY_TABLE_ACTION_HEADER]: 'Azione',
  [msgIds.MSG_EVENT_HISTORY_TABLE_DESCRIPTION_HEADER]: 'Descrizione',

  // EventHistoryFiltersForm
  [msgIds.MSG_EVENT_HISTORY_FILTERS_FORM_TITLE]: 'Filtri',
  [msgIds.MSG_EVENT_HISTORY_FILTERS_FORM_SEARCH_STRUCTURE_PLACEHOLDER]: 'Cerca una struttura',
  [msgIds.MSG_EVENT_HISTORY_FILTERS_FORM_SEARCH_OPERATOR_PLACEHOLDER]: 'Cerca una operatore',

  // EventHistoryFilters keys
  [msgIds.MSG_EVENT_HISTORY_FILTER_FROM_KEY]: 'Dal',
  [msgIds.MSG_EVENT_HISTORY_FILTER_TO_KEY]: 'al',
  [msgIds.MSG_EVENT_HISTORY_FILTER_STRUCTURE_KEY]: 'Struttura',
  [msgIds.MSG_EVENT_HISTORY_FILTER_OPERATOR_KEY]: 'Operatore',
  [msgIds.MSG_EVENT_HISTORY_FILTER_TREATMENT]: 'Trattamento',

  // Desktop
  [msgIds.MSG_DESKTOP_PAGE_TITLE]: 'Scrivania',
  [msgIds.MSG_DESKTOP_CREATE_INTERNAL_DOCUMENT_FROM_TEMPLATE_TOOLTIP]:
    'Crea un nuovo documento interno da un modello predefinito',
  [msgIds.MSG_DESKTOP_CREATE_INTERNAL_DOCUMENT_TOOLTIP]: 'Crea un nuovo documento interno',
  [msgIds.MSG_DESKTOP_UPLOAD_DOCUMENT_TOOLTIP]: 'Carica un nuovo documento o trascina qui',
  [msgIds.MSG_DESKTOP_CLEAR_TOOLTIP]: 'Svuota la scrivania',
  [msgIds.MSG_DOCUMENTS_NOT_SAVED_DIALOG_TITLE]: 'Documenti non salvati',
  [msgIds.MSG_DOCUMENTS_NOT_SAVED_DIALOG_CONTENT]:
    'Attenzione, ci sono dei documenti non salvati, sicuro di voler proseguire?',

  // Profile selector Dialog
  [msgIds.MSG_PROFILE_SELECTOR_DIALOG_TITLE]: 'Seleziona un profilo',

  // Notice board
  [msgIds.MSG_NOTICE_BOARD_TITLE]: 'Bacheca',
  [msgIds.MSG_NOTICE_BOARD_ADD_ELEMENT]: 'Aggiungi elemento',
  [msgIds.MSG_NOTICE_BOARD_EDIT_ELEMENT]: 'Modifica nota',
  [msgIds.MSG_NOTICE_BOARD_DELETE_ELEMENT]: 'Cancella nota',
  [msgIds.MSG_NOTICE_BOARD_EDIT_ELEMENT_DIALOG_TITLE]: 'Modifica nota',
  [msgIds.MSG_NOTICE_BOARD_DELETE_ELEMENT_DIALOG_CONFIRM_TITLE]: 'Cancellazione elemento',
  [msgIds.MSG_NOTICE_BOARD_DELETE_ELEMENT_DIALOG_CONFIRM_BODY]: 'Sei sicuro di voler rimuovere questo elemento?',

  // Login
  [msgIds.MSG_LOGIN_FOR_OPERATORS]: 'Per il professionista',
  [msgIds.MSG_LOGIN_FOR_CUSTOMERS]: "Per l'utente privato",
  [msgIds.MSG_LOGIN_FOR_GUEST_OPERATORS]: 'Per il professionista visitatore',
  [msgIds.MSG_LOGIN_FOR_GUEST_CUSTOMERS]: "Per l'utente privato visitatore",
  [msgIds.MSG_LOGIN_FOR_GUEST_AUTHORIZATION_CHECK_IN_PROGRESS]: 'Verifica autorizzazione in corso...',
  [msgIds.MSG_LOGIN_BUTTON_TITLE]: 'Accedi',
  [msgIds.MSG_LOGIN_FORGOT_PASSWORD_LINK]: 'Non ricordo la mia password',
  [msgIds.MSG_LOGIN_REGISTER_LINK]: 'Registrami in PersonalDOX',
  [msgIds.MSG_LOGIN_CREATE_NEW_PROFILE_LINK]: 'Sono già registrato, voglio attivare un nuovo profilo',
  [msgIds.MSG_LOGIN_2FA_PROBLEM_QUESTION]: 'Ci sono dei problemi?',
  [msgIds.MSG_LOGIN_2FA_EMERGENCY_LINK]: 'Utilizza un codice OTP di emergenza',
  [msgIds.MSG_LOGIN_2FA_INFO]:
    "Apri l'app che hai scelto per la generazione del codice TOTP, copia e incolla il codice in questo campo",
  [msgIds.MSG_LOGIN_PASSWORD_RESET]: 'Aggiorna la password',
  [msgIds.MSG_LOGIN_FAILED_DIALOG_TITLE]: 'Accesso a PersonalDOX',
  [msgIds.MSG_LOGIN_FAILED_DIALOG_NO_AVAILABLE_BUSINESS_PROFILES]: 'Non disponi di alcun profilo per uso professionale',
  [msgIds.MSG_LOGIN_FAILED_DIALOG_NO_AVAILABLE_CONSUMER_PROFILES]: 'Non disponi di un profilo utente privato',
  [msgIds.MSG_LOGIN_AS_BUSINESS_USER]: 'Accedi come professionista',
  [msgIds.MSG_LOGIN_AS_CONSUMER_USER]: 'Accedi come utente privato',
  [msgIds.MSG_LOGIN_GUEST_2FA_INFO]:
    "Digita il PIN per l'accesso. Se non hai un codice valido chiedilo alla persona che ti ha autorizzato ad accedere all'area riservata",
  [msgIds.MSG_LOGIN_GUEST_PIN_LABEL]: 'Codice PIN',
  [msgIds.MSG_OTP_RECOVERY_INFO]:
    "Quando hai attivato l'autenticazione a due fattori con OTP, sono stati generati per te 10 codici di emergenza. Puoi utilizzarne uno per accedere al servizio. Ricordati che ciascun codice può essere utilizzato una sola volta.",
  [msgIds.MSG_PASSWORD_RECOVERY_INFO]: 'Inserisci la mail che hai utilizzato per registrarti in PersonalDox',
  [msgIds.MSG_PASSWORD_RECOVERY_SEND_EMAIL_BUTTON]: 'Invia email',
  [msgIds.MSG_PASSWORD_RECOVERY_LOGIN_LINK]: 'Vai alla pagina di login',
  [msgIds.MSG_CONTRACTS_UPDATE_WARNING_DIALOG_TITLE]: 'Modifiche ai contratti',
  [msgIds.MSG_CONTRACTS_UPDATE_WARNING_ACCESS]: 'Accedi',
  [msgIds.MSG_CONTRACTS_UPDATE_WARNING_ACCESS_AND_MUTE_MSG]: 'Accedi e non mostrare più questo avviso',
  [msgIds.MSG_CONTRACTS_UPDATE_WARNING_SHOW_CONTRACTS]: 'Mostra le nuove condizioni',
  [msgIds.MSG_PASSWORD_RECOVERY_MAIL_SUCCESSFULLY]:
    "Manderemo una mail all'indirizzo che hai indicato. Segui le indicazioni contenute al suo interno per generare una nuova password",
  [msgIds.MSG_NO_OTHER_AVAILABLE_ACCOUNTS]: 'Non ci sono altri profili attivi',

  // Registration
  [msgIds.MSG_PRIVATE_AREA_SELECTION_TITLE]: 'Selezione area privata',

  [msgIds.MSG_USER_BUSINESS]: 'Professionista',
  [msgIds.MSG_USER_PRIVATE]: 'Utente privato',
  [msgIds.MSG_REGISTER_BUTTON_TITLE]: 'Registrati',

  [msgIds.MSG_REGISTRATION_PAGE_TITLE_CONTRACT_REVISION]: 'Aggiornamento contratti per il profilo {{profileTypeDsc}}',
  [msgIds.MSG_REGISTRATION_PAGE_TITLE_PROFILE_CREATION]: "Creazione di un nuovo profilo '{{profileTypeDsc}}'",
  [msgIds.MSG_REGISTRATION_PAGE_TITLE_USER_REGISTRATION]: 'Creazione di un nuovo account',
  [msgIds.MSG_REGISTRATION_PAGE_TITLE_CHANGE_PLAN]: 'Impostazione dei dati di fatturazione',
  [msgIds.MSG_REGISTRATION_CONTRACT_PRIVACY_POLICY_TITLE]: 'Informativa privacy',
  [msgIds.MSG_REGISTRATION_CONTRACT_TERMS_POLICY_TITLE]: "Condizioni d'uso",
  [msgIds.MSG_REGISTRATION_CONTRACT_DATA_PROCESSOR]: 'Nomina a responsabile del trattamento dei dati',
  [msgIds.MSG_REGISTRATION_PERSON_IDENTITY_TITLE]: 'Anagrafica',
  [msgIds.MSG_REGISTRATION_BILLING_TITLE]: 'Dati per la fatturazione',
  [msgIds.MSG_REGISTRATION_COMPANY_IDENTITY_TITLE]: 'Dati aziendali',
  [msgIds.MSG_REGISTRATION_CREDENTIALS_TITLE]: "Credenziali per l'accesso",
  [msgIds.MSG_REGISTRATION_SUMMARY_TITLE]: 'Riepilogo',
  [msgIds.MSG_REGISTRATION_CONFIRM_DIALOG_TITLE]: 'Conclusione procedura',
  [msgIds.MSG_REGISTRATION_CONFIRM_DIALOG_BODY]: 'Confermi di voler procedere?',
  [msgIds.MSG_REGISTRATION_SUCCESS_MESSAGE_NEW_CONSENTS]:
    'Le tue preferenze sono state salvate\n\nTra qualche istante verrai inoltrato alla bacheca',
  [msgIds.MSG_REGISTRATION_SUCCESS_MESSAGE_NEW_PROFILE]:
    'Il tuo nuovo profilo è stato attivato\n\nTra qualche istante verrai inoltrato alla bacheca',
  [msgIds.MSG_REGISTRATION_SUCCESS_MESSAGE_NEW_ACCOUNT]:
    "L'operazione è andata a buon fine.\nTi abbiamo inviato una mail all'indirizzo che ci hai fornito.\nSegui le indicazioni contenute al suo interno per completare la procedura di registrazione.",
  [msgIds.MSG_REGISTRATION_SUCCESS_MESSAGE_BILLING_DATA_UPDATED]: 'I dati di fatturazione sono stati aggiornati',

  [msgIds.MSG_REGISTRATION_SUMMARY_CONTACT_EMAIL_SECTION_TITLE]: 'Email di contatto',
  [msgIds.MSG_REGISTRATION_SUMMARY_PERSONAL_IDENTITY_SECTION_TITLE]: 'Identità personale',
  [msgIds.MSG_REGISTRATION_SUMMARY_COMPANY_SECTION_TITLE]: 'Identità aziendale',
  [msgIds.MSG_REGISTRATION_SUMMARY_BILLING_TITLE]: 'Dati per la fatturazione',
  [msgIds.MSG_REGISTRATION_SUMMARY_CONFIRM_FOR_CONSENTS]: "Premi 'Conferma' per applicare i consensi",

  // user activation
  [msgIds.MSG_USER_ACTIVATION_TITLE]: 'Attivazione account',
  [msgIds.MSG_USER_ACTIVATION_IN_PROGRESS]: 'Attivazione in corso',
  [msgIds.MSG_USER_ACTIVATION_WAIT]: 'Attendere prego...',
  [msgIds.MSG_USER_ACTIVATION_INFO1]: 'Tra poco potrai accedere con le tue credenziali',
  [msgIds.MSG_USER_ACTIVATION_DONE]: 'Attivazione eseguita',
  [msgIds.MSG_USER_ACTIVATION_FAILED]: 'Attivazione fallita',

  [msgIds.MSG_NEXT_BUTTON_TITLE]: 'Avanti',
  [msgIds.MSG_PREVIOUS_BUTTON_TITLE]: 'Indietro',

  // Billing
  [msgIds.MSG_BILLING_PRODUCT_FEATURE_PRF_SHOWCASE_DSC]: "Scheda di presentazione all'interno del motore di ricerca",
  [msgIds.MSG_BILLING_PRODUCT_FEATURE_TREATMENTS_CONF_DSC]: 'Configurazione dei trattamenti',
  [msgIds.MSG_BILLING_PRODUCT_FEATURE_PRV_POLICY_EDITOR_DSC]:
    "Editor per la creazione e il download dell'informativa privacy",
  [msgIds.MSG_BILLING_PRODUCT_FEATURE_DATA_PROCESSING_MAX_DSC]: 'Numero massimo di trattamenti dei dati',
  [msgIds.MSG_BILLING_PRODUCT_FEATURE_PRV_PLANT_HIST_DSC]: "Cronologia dell'impianto privacy",
  [msgIds.MSG_BILLING_PRODUCT_FEATURE_PRV_POLICY_CONSENTS_DSC]:
    'Raccolta e storicizzazione della presa visione e dei consensi al trattamento',
  [msgIds.MSG_BILLING_PRODUCT_FEATURE_MIN_ANAGRAPHIC_DSC]:
    'Anagrafica utenti minimizzata in funzione dei trattamenti e della conservazione dei dati',
  [msgIds.MSG_BILLING_PRODUCT_FEATURE_SHARED_DOCS_READ_DSC]: 'Lettura dei documenti condivisi dagli utenti privati',
  [msgIds.MSG_BILLING_PRODUCT_FEATURE_SHARED_DOCS_DWN_DSC]: 'Download dei documenti condivisi dagli utenti privati',
  [msgIds.MSG_BILLING_PRODUCT_FEATURE_COMPANY_ARCHIVES_DSC]:
    'Un archivio aziendale per ciascun utente organizzabile tramite i dox',
  [msgIds.MSG_BILLING_PRODUCT_FEATURE_DATA_RETENTION_DSC]: 'Gestione della conservazione dei dati',
  [msgIds.MSG_BILLING_PRODUCT_FEATURE_DOX_AND_DOC_HIST_DSC]: 'Cronologia delle azioni svolte su dox e documenti',
  [msgIds.MSG_BILLING_PRODUCT_FEATURE_USERS_HIST_DSC]: 'Cronologia delle azioni svolte dagli utenti privati',
  [msgIds.MSG_BILLING_PRODUCT_FEATURE_INT_DOCS_EDITOR_DSC]: "Creazione di nuovi documenti con l'editor integrato",
  [msgIds.MSG_BILLING_PRODUCT_FEATURE_EXT_DOCS_EDITOR_DSC]: 'Caricamento di documenti digitalizzati',
  [msgIds.MSG_BILLING_PRODUCT_FEATURE_DOX_DELIVERY_DSC]: 'Consegna di dox e documenti agli utenti privati',
  [msgIds.MSG_BILLING_PRODUCT_FEATURE_SEARCH_ENGINE_DSC]:
    "Ricerca di operatori e strutture all'interno del motore di ricerca",
  [msgIds.MSG_BILLING_PRODUCT_FEATURE_DATA_PROCESSING_DSC]:
    'Attivazione e monitoraggio del trattamento dei dati con le strutture',
  [msgIds.MSG_BILLING_PRODUCT_FEATURE_ARCHIVE_DSC]: "Organizzazione dell'archivio personale tramite i dox",
  [msgIds.MSG_BILLING_PRODUCT_FEATURE_DOX_AND_DOCS_SHARING_DSC]: 'Condivisione dei documenti con operatori e strutture',
  [msgIds.MSG_BILLING_PRODUCT_FEATURE_DOX_AND_DOCS_RECEINVING_DSC]:
    'Ricezione di dox e documenti consegnati dalle strutture',
  [msgIds.MSG_BILLING_PRODUCT_FEATURE_OPERATOR_HIST_DSC]:
    'Cronologia delle azioni svolte dagli operatori e dalle strutture',

  [msgIds.MSG_BILLING_PRODUCT_FEATURE_UNLIMITED]: 'illimitato',
  [msgIds.MSG_BILLING_PRODUCTS_FOR_OPERATOR]: 'Prodotti per il professionista',
  [msgIds.MSG_BILLING_PRODUCTS_FOR_USER]: "Prodotti per l'utente privato",
  [msgIds.MSG_BILLING_PRODUCTS]: 'Prodotti',
  [msgIds.MSG_BILLING_TRIAL_FOR_DAYS]: "Prova il piano '{{name}}' gratis per {{trialDays}} giorni",
  [msgIds.MSG_BILLING_PLAN_ACTIVATE]: 'ATTIVA',
  [msgIds.MSG_BILLING_PLAN_VERIFY]: 'VERIFICA',
  [msgIds.MSG_BILLING_PRICE_FOR_USER]: 'per utente',
  [msgIds.MSG_BILLING_PRICE_VAT_EXCLUDED]: '* iva esclusa',
  [msgIds.MSG_BILLING_TRIAL_STARTED_SUCCESSFULLY]: 'Il periodo di prova è stato attivato',

  // Subscriptions
  [msgIds.MSG_SUBSCRIPTION_STATE_ACTIVE]: 'attiva',
  [msgIds.MSG_SUBSCRIPTION_STATE_NOT_ACTIVE]: 'non attiva',
  [msgIds.MSG_SUBSCRIPTION_STATE_TRIALING]: 'in prova',
  [msgIds.MSG_SUBSCRIPTION_USE_PLAN_NOT_ACTIVE]: 'Piano in uso\n({{name}} non attivo)',
  [msgIds.MSG_SUBSCRIPTION_USE_PLAN_IN_TRIAL]: 'Piano in uso\n(in prova)',
  [msgIds.MSG_SUBSCRIPTION_USE_PLAN_IN_USE]: 'Piano in uso',
  [msgIds.MSG_SUBSCRIPTION_ACTIVATION_TITLE]: 'Attivazione sottoscrizione',
  [msgIds.MSG_SUBSCRIPTION_ACTIVATION_BODY1]: 'Scegli una delle seguenti opzioni per proseguire nel modo corretto',
  [msgIds.MSG_SUBSCRIPTION_ACTIVATION_BODY2]:
    'Per attivare la sottoscrizione dobbiamo prima autenticarti. Scegli una delle seguenti opzioni per proseguire nel modo corretto',
  [msgIds.MSG_SUBSCRIPTION_ACTIVATION_ACTIVATE_NEW_PROFILE]: 'Voglio attivare un nuovo profilo',
  [msgIds.MSG_SUBSCRIPTION_ACTIVATION_CHANGE_PROFILE_PLAN]: 'Voglio cambiare il piano del mio profilo',
  [msgIds.MSG_SUBSCRIPTION_ACTIVATION_CREATE_NEW_ACCOUNT]: 'Registrami in PersonalDOX',
  [msgIds.MSG_SUBSCRIPTION_ACTIVATION_REGISTERED_ACTIVATE_NEW_PROFILE]:
    'Sono già registrato, voglio attivare un nuovo profilo',
  [msgIds.MSG_SUBSCRIPTION_ACTIVATION_REGISTERED_CHANGE_PROFILE_PLAN]:
    'Sono già registrato, voglio cambiare il piano del mio profilo',

  // Subscriptions
  [msgIds.MSG_SUBSCRIPTION_INFO_STATUS_TITLE]: 'Stato',
  [msgIds.MSG_SUBSCRIPTION_INFO_START_AT_TITLE]: 'Inizio periodo corrente',
  [msgIds.MSG_SUBSCRIPTION_INFO_END_AT_TITLE]: 'Fine periodo corrente',
  [msgIds.MSG_SUBSCRIPTION_INFO_AMOUNT_TITLE]: 'Importo',
  [msgIds.MSG_SUBSCRIPTION_INFO_DISCOUNT_TITLE]: 'Sconto',
  [msgIds.MSG_SUBSCRIPTION_INFO_STATUS_ACTIVE]: 'Attiva',
  [msgIds.MSG_SUBSCRIPTION_INFO_STATUS_PAST_DUE]: 'Incompleta',
  [msgIds.MSG_SUBSCRIPTION_INFO_STATUS_UNPAID]: 'Non pagata',
  [msgIds.MSG_SUBSCRIPTION_INFO_STATUS_CANCELED]: 'Cancellata',
  [msgIds.MSG_SUBSCRIPTION_INFO_STATUS_INCOMPLETE]: 'Incompleta',
  [msgIds.MSG_SUBSCRIPTION_INFO_STATUS_INCOMPLETE_EXPIRED]: 'Incompleta - scaduta',
  [msgIds.MSG_SUBSCRIPTION_INFO_STATUS_TRIALING]: 'In prova',
  [msgIds.MSG_SUBSCRIPTION_INFO_COMMAND_INVOICES]: 'Fatture',
  [msgIds.MSG_SUBSCRIPTION_INFO_COMMAND_PAYMENT_METHOD]: 'Modalità di pagamento',
  [msgIds.MSG_SUBSCRIPTION_INFO_COMMAND_CHANGE_PLAN]: 'Cambia piano',
  [msgIds.MSG_SUBSCRIPTION_CHANGE_ALREADY_IN_USE]: 'Il piano scelto corrisponde a quello attualmente in uso',
  [msgIds.MSG_SUBSCRIPTION_USER_ALREADY_REGISTERED]: 'Ho già un account per entrare in PersonalDOX',
  [msgIds.MSG_SUBSCRIPTION_USER_NOT_REGISTERED]: 'Non ho un account per entrare in PersonalDOX',

  // Check Legal Status
  [msgIds.MSG_CHECL_LEGAL_STATUS_DIALOG_INTRO_HEAVY_CHANGES]:
    'Sono state apportate modifiche sostanziali ai seguenti contratti:',
  [msgIds.MSG_CHECL_LEGAL_STATUS_DIALOG_INTRO_OPTIONAL_CONSENTS_REQUESTED]:
    'Sono state apportate modifiche sostanziali ai seguenti contratti per i quali è richiesto il rilascio di nuovi consensi:',
  [msgIds.MSG_CHECL_LEGAL_STATUS_DIALOG_INTRO_LIGHT_CHANGES]:
    'Sono state apportate modifiche **non** sostanziali ai seguenti contratti:',
  [msgIds.MSG_CHECL_LEGAL_STATUS_DIALOG_INTRO_PRIVACY_POLICY]: '\n1. **informativa privacy**',
  [msgIds.MSG_CHECL_LEGAL_STATUS_DIALOG_INTRO_TERMS_OF_USE]: "\n1. **condizioni d'uso**",
  [msgIds.MSG_CHECL_LEGAL_STATUS_DIALOG_INTRO_DATA_PROCESSOR]: '\n1. **nomina a responsabile del trattamento**',
  [msgIds.MSG_CHECL_LEGAL_STATUS_DIALOG_INTRO_REDIRECT_TO_CONTRACTS]:
    'Verrai inoltrato alla pagina nella quale potrai prendere visione delle nuove condizioni.',
  [msgIds.MSG_CHECL_LEGAL_STATUS_DIALOG_INTRO_CONSENTS_VALID]: 'I consensi forniti in precedenza sono ancora validi.',

  // Payment method
  [msgIds.MSG_PAYMENT_METHOD_PAGE_TITLE]: 'Modalità di pagamento',
  [msgIds.MSG_PAYMENT_METHOD_PAGE_INFO1]: 'Per la gestione dei pagamenti utilizziamo la piattaforma',
  [msgIds.MSG_PAYMENT_METHOD_PAGE_INFO2]:
    'Le informazioni sui tuoi metodi di pagamento non transitano nei nostri server nè vengono salvate nei nostri archivi.',
  [msgIds.MSG_PAYMENT_METHOD_CURRENT_PLAN]: 'Piano corrente',
  [msgIds.MSG_PAYMENT_METHOD_NEW_PLAN]: 'Nuovo piano',
  [msgIds.MSG_PAYMENT_METHOD_DISCOUNT_CODE]: 'Codice sconto',
  [msgIds.MSG_PAYMENT_METHOD_INSERT_DISCOUNT_CODE]: 'Inserire il codice',
  [msgIds.MSG_PAYMENT_METHOD_CHOOSE_PAYMENT_METHOD]: 'Seleziona una modalità di pagamento',
  [msgIds.MSG_PAYMENT_METHOD_YOUR_CARDS]: 'Le tue carte di credito e di debito',
  [msgIds.MSG_PAYMENT_METHOD_ADD_NEW_CARD]: 'Aggiungi una nuova carta',
  [msgIds.MSG_PAYMENT_METHOD_PAYMENT_INTERVAL]: 'Ricorrenza pagamento',
  [msgIds.MSG_PAYMENT_METHOD_SUBSCRIPTION_TO_PRODUCT]: 'Iscrizione al servizio',
  [msgIds.MSG_PAYMENT_METHOD_PAYMENT_INTERVAL]: 'Ricorrenza pagamento',
  [msgIds.MSG_PAYMENT_METHOD_AMOUNT]: 'Importo',
  [msgIds.MSG_PAYMENT_METHOD_CARD_REMOVE_DIALOG_TITLE]: 'Rimozione carta',
  [msgIds.MSG_PAYMENT_METHOD_CARD_REMOVE_DIALOG_BODY]: 'Sei sicuro di voler rimuovere questa carta?',

  // Invoices
  [msgIds.MSG_INVOICE_DATA]: 'Data',
  [msgIds.MSG_INVOICE_AMOUNT]: 'Importo',
  [msgIds.MSG_INVOICE_STATE]: 'Stato',
  [msgIds.MSG_INVOICE_STATUS_PENDING]: 'In attesa di pagamento',
  [msgIds.MSG_INVOICE_STATUS_UNCOLLECTABLE]: 'Inesigibile',
  [msgIds.MSG_INVOICE_STATUS_PAID]: 'Pagata',
  [msgIds.MSG_INVOICES_SDI_INFO]:
    'Se sei idoneo alla ricezione della fattura elettronica ti verrà inoltrata automaticamente tramite il sistema di interscambio (SDI)',

  // Contract editor
  [msgIds.MSG_CONTRACT_EDITOR_DIALOG_TITLE]: 'Modifica contratto',
}
