import { useTranslation } from 'react-i18next'
import msgIds from '../../locales/msgIds'
import { EditorFormContainer } from '../editorFormContainer/EditorFormContainer'
import { SwitchRow } from '../switchRow/SwitchRow'
import { useEffect, useState } from 'react'
import { ViewActions } from '../viewActions/ViewActions'
import { ViewActionsButton } from '../viewActions/ViewActionsButton'
import { UpdateProfileArgs } from '../../dal/DalAccount'
import * as dalAccount from '../../dal/DalAccount'
import { ProfilePreferences } from '../../models/ProfilePreferences'
import { Utils } from '../../shared/Utils'
import { INotificationsSettingsProps } from './NotificationsSettings.types'
import { PreferencesNotifications } from '../../models/PreferencesNotifications'

export type NotificationsSettingsData = {
  doxReceived: boolean
  documentRevisionCreated: boolean
  documentViewed: boolean
  documentDownloaded: boolean
  privacyPolicyUpdated: boolean
}

export function NotificationsSettings(props: INotificationsSettingsProps): JSX.Element {
  const { account, disabled, isEditMode } = props
  const { t } = useTranslation()
  const [args, setArgs] = useState<UpdateProfileArgs>({})
  const [areUnsavedChanges, setAreUnsavedChanges] = useState(false)
  const profileId = account.profile?.profileId

  function initFields() {
    const preferences = account.profile?.profilePreferences
    setArgs({
      preferences: {
        permissions: {
          defaultViewDurationDays: preferences?.permissions?.defaultViewDurationDays || 0,
        },
        notifications: {
          userPrivateMessage: preferences?.notifications?.userPrivateMessage,
          upcomingProfileSuspension: preferences?.notifications?.upcomingProfileSuspension,
          profileSuspension: preferences?.notifications?.profileSuspension,
          upcomingProfileDeletion: preferences?.notifications?.upcomingProfileDeletion,
          profileDeletionBySystem: preferences?.notifications?.profileDeletionBySystem,
          profileDeletionByOwner: preferences?.notifications?.profileDeletionByOwner,
          upcomingUserDeletion: preferences?.notifications?.upcomingUserDeletion,
          userDeletionBySystem: preferences?.notifications?.userDeletionBySystem,
          userDeletionByOwner: preferences?.notifications?.userDeletionByOwner,
          contractUpdated: preferences?.notifications?.contractUpdated,
          dossierDelivered: preferences?.notifications?.dossierDelivered,
          documentRevisionCreated: preferences?.notifications?.documentRevisionCreated,
          documentViewed: preferences?.notifications?.documentViewed,
          documentDownloaded: preferences?.notifications?.documentDownloaded,
          dataProcessingGranted: preferences?.notifications?.dataProcessingGranted,
          dataProcessingUpdated: preferences?.notifications?.dataProcessingUpdated,
          dataProcessingSuspended: preferences?.notifications?.dataProcessingSuspended,
          upcomingDataProcessingExpiration: preferences?.notifications?.upcomingDataProcessingExpiration,
          dataProcessingExpired: preferences?.notifications?.dataProcessingExpired,
          privacyPolicyUpdated: preferences?.notifications?.privacyPolicyUpdated,
          privacyPolicyConsentUpdated: preferences?.notifications?.privacyPolicyConsentUpdated,
          profileAttached: preferences?.notifications?.profileAttached,
          profileDeattached: preferences?.notifications?.profileDeattached,
          doxDelivered: preferences?.notifications?.doxDelivered,
          doxReceived: preferences?.notifications?.doxReceived,
          permissionViewUpdated: preferences?.notifications?.permissionViewUpdated,
        },
      },
    })
  }

  useEffect(() => {
    initFields()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account])

  useEffect(() => {
    const actNotifications = args.preferences?.notifications
    const orgNotifications = account.profile?.profilePreferences?.notifications
    const check =
      actNotifications?.userPrivateMessage !== orgNotifications?.userPrivateMessage ||
      actNotifications?.upcomingProfileSuspension !== orgNotifications?.upcomingProfileSuspension ||
      actNotifications?.profileSuspension !== orgNotifications?.profileSuspension ||
      actNotifications?.upcomingProfileDeletion !== orgNotifications?.upcomingProfileDeletion ||
      actNotifications?.profileDeletionBySystem !== orgNotifications?.profileDeletionBySystem ||
      actNotifications?.profileDeletionByOwner !== orgNotifications?.profileDeletionByOwner ||
      actNotifications?.upcomingUserDeletion !== orgNotifications?.upcomingUserDeletion ||
      actNotifications?.userDeletionBySystem !== orgNotifications?.userDeletionBySystem ||
      actNotifications?.userDeletionByOwner !== orgNotifications?.userDeletionByOwner ||
      actNotifications?.contractUpdated !== orgNotifications?.contractUpdated ||
      actNotifications?.dossierDelivered !== orgNotifications?.dossierDelivered ||
      actNotifications?.documentRevisionCreated !== orgNotifications?.documentRevisionCreated ||
      actNotifications?.documentViewed !== orgNotifications?.documentViewed ||
      actNotifications?.documentDownloaded !== orgNotifications?.documentDownloaded ||
      actNotifications?.dataProcessingGranted !== orgNotifications?.dataProcessingGranted ||
      actNotifications?.dataProcessingUpdated !== orgNotifications?.dataProcessingUpdated ||
      actNotifications?.dataProcessingSuspended !== orgNotifications?.dataProcessingSuspended ||
      actNotifications?.upcomingDataProcessingExpiration !== orgNotifications?.upcomingDataProcessingExpiration ||
      actNotifications?.dataProcessingExpired !== orgNotifications?.dataProcessingExpired ||
      actNotifications?.privacyPolicyUpdated !== orgNotifications?.privacyPolicyUpdated ||
      actNotifications?.privacyPolicyConsentUpdated !== orgNotifications?.privacyPolicyConsentUpdated ||
      actNotifications?.profileAttached !== orgNotifications?.profileAttached ||
      actNotifications?.profileDeattached !== orgNotifications?.profileDeattached ||
      actNotifications?.doxDelivered !== orgNotifications?.doxDelivered ||
      actNotifications?.doxReceived !== orgNotifications?.doxReceived ||
      actNotifications?.permissionViewUpdated !== orgNotifications?.permissionViewUpdated
    setAreUnsavedChanges(check)
  }, [args])

  async function onUpdateProfile() {
    if (!profileId) {
      return
    }

    try {
      const abortController = new AbortController()
      const updatedAccount = await dalAccount.updateProfile(abortController.signal, profileId, { ...args })
      account.profile = updatedAccount.profile
      setAreUnsavedChanges(false)
      props.onSave && props.onSave()
    } catch (error) {
      Utils.enqueueSnackbarError2(error, t)
    }
  }

  function onCancel() {
    initFields()
    props.onCancel && props.onCancel()
  }

  function onChange(key: keyof PreferencesNotifications, enabled: boolean) {
    const _args: UpdateProfileArgs = {
      ...args,
      preferences: {
        permissions: {
          ...args.preferences?.permissions,
        },
        notifications: {
          ...args.preferences?.notifications,
        },
      } as ProfilePreferences,
    }
    if (_args.preferences && _args.preferences.notifications) {
      _args.preferences.notifications[key] = enabled
      setArgs(_args)
    }
  }

  return (
    <EditorFormContainer spacing={1}>
      <SwitchRow
        divider
        disabled={disabled || !isEditMode}
        label={t(msgIds.MSG_NOTIFICATIONS_SETTINGS_EDITOR_TYPE_DOX_RECEIVED_LABEL)}
        checked={args.preferences?.notifications?.doxReceived || false}
        onChange={(_, checked) => onChange('doxReceived', checked)}
      />
      <SwitchRow
        divider
        disabled={disabled || !isEditMode}
        label={t(msgIds.MSG_NOTIFICATIONS_SETTINGS_EDITOR_TYPE_DOCUMENT_REVISION_CREATED_LABEL)}
        checked={args.preferences?.notifications?.documentRevisionCreated || false}
        onChange={(_, checked) => onChange('documentRevisionCreated', checked)}
      />
      <SwitchRow
        divider
        disabled={disabled || !isEditMode}
        label={t(msgIds.MSG_NOTIFICATIONS_SETTINGS_EDITOR_TYPE_DOCUMENT_VIEWED_LABEL)}
        checked={args.preferences?.notifications?.documentViewed || false}
        onChange={(_, checked) => onChange('documentViewed', checked)}
      />
      <SwitchRow
        divider
        disabled={disabled || !isEditMode}
        label={t(msgIds.MSG_NOTIFICATIONS_SETTINGS_EDITOR_TYPE_DOCUMENT_DOWNLOADED_LABEL)}
        checked={args.preferences?.notifications?.documentDownloaded || false}
        onChange={(_, checked) => onChange('documentDownloaded', checked)}
      />
      <SwitchRow
        disabled={disabled || !isEditMode}
        label={t(msgIds.MSG_NOTIFICATIONS_SETTINGS_EDITOR_TYPE_PRIVACY_POLICY_UPDATED_LABEL)}
        checked={args.preferences?.notifications?.privacyPolicyUpdated || false}
        onChange={(_, checked) => onChange('privacyPolicyUpdated', checked)}
      />
      {isEditMode && (
        <ViewActions justifyContent={'center'} sx={{ paddingTop: 6 }}>
          <ViewActionsButton autoFocus defaultAction onClick={onUpdateProfile} disabled={!areUnsavedChanges}>
            {t(msgIds.MSG_SAVE)}
          </ViewActionsButton>
          <ViewActionsButton onClick={onCancel}>{t(msgIds.MSG_CANCEL)}</ViewActionsButton>
        </ViewActions>
      )}
    </EditorFormContainer>
  )
}
