import React, { useContext, useReducer } from 'react'
import { TreatedField } from '../models/TreatedField'
import { Account } from '../models/Account'
import { Action } from '../shared/types/Action'
import {
  AccountType,
  ActionType,
  TreatedFieldName,
  isCollaborator,
  isConsumer,
  isOperator,
  isStructure,
} from '../shared/Constants'

export type FieldAcl = 'none' | 'visible' | 'editable'

export class TreatedFieldsAcl {
  registrationEmail: FieldAcl = 'none'

  lastname: FieldAcl = 'none'
  name: FieldAcl = 'none'
  gender: FieldAcl = 'none'
  birthdate: FieldAcl = 'none'
  birthplace: FieldAcl = 'none'
  fiscalCode: FieldAcl = 'none'
  vatNumber: FieldAcl = 'none'

  avatar: FieldAcl = 'none'
  alias: FieldAcl = 'none'
  profileDefault: FieldAcl = 'none'
  profileVisibility: FieldAcl = 'none'
  title: FieldAcl = 'none'

  billingSdiCode: FieldAcl = 'none'
  billingPec: FieldAcl = 'none'
  billingCountry: FieldAcl = 'none'
  billingCity: FieldAcl = 'none'
  billingProvince: FieldAcl = 'none'
  billingStreet: FieldAcl = 'none'
  billingStreetNumber: FieldAcl = 'none'
  billingZip: FieldAcl = 'none'

  phone: FieldAcl = 'none'
  mobilePhone: FieldAcl = 'none'
  email: FieldAcl = 'none'
  website: FieldAcl = 'none'

  residenceCountry: FieldAcl = 'none'
  residenceCity: FieldAcl = 'none'
  residenceProvince: FieldAcl = 'none'
  residenceStreet: FieldAcl = 'none'
  residenceStreetNumber: FieldAcl = 'none'
  residenceZip: FieldAcl = 'none'

  constructor() {}

  isVisible(fieldName: keyof TreatedFieldsAcl) {
    const status = this[fieldName]
    return status === 'visible' || status === 'editable'
  }
  isEditable(fieldName: keyof TreatedFieldsAcl) {
    const status = this[fieldName]
    return status === 'editable'
  }
}

export interface IContextState {
  neededFields: string[] // list of fields that UI want
  fieldsAcl: TreatedFieldsAcl
  setNeededFields: (neededFields: string[]) => void
  setFieldsAcl: (viewer?: Account, target?: Account) => void
}

const initialState: IContextState = {
  neededFields: [],
  fieldsAcl: new TreatedFieldsAcl(),
  setNeededFields: (neededFields: string[]) => {},
  setFieldsAcl: (viewer?: Account, target?: Account) => {},
}

type Actions =
  | Action<'SET_NEEDED_FIELDS', { neededFields: string[] }>
  | Action<'SET_FIELDS_ACL', { viewer?: Account; target?: Account }>

type ReducerFunc = (state: IContextState, action: Actions) => IContextState
function reducer(state: IContextState, action: Actions): IContextState {
  switch (action.type) {
    case 'SET_NEEDED_FIELDS': {
      return { ...state, neededFields: action.payload?.neededFields ?? [] }
    }
    case 'SET_FIELDS_ACL': {
      const acl = getAccountFieldsAcl(state.neededFields ?? [], action.payload?.viewer, action.payload?.target)
      return { ...state, fieldsAcl: acl }
    }
  }
}

function getAclValue(isVisible: boolean, isEditable: boolean, isNeeded: boolean): FieldAcl {
  if (isNeeded && isEditable && isVisible) {
    return 'editable'
  } else if (isNeeded && isVisible) {
    return 'visible'
  } else {
    return 'none'
  }
}

function isFieldTreated(fields: TreatedField[] | undefined, fieldName: string) {
  return !fields || !!fields.find((p) => p.fieldName === fieldName)
}

function isFieldNeeded(neededFields: string[], fieldName: string) {
  return neededFields.length === 0 || neededFields.includes(fieldName)
}

export function getAccountFieldsAcl(neededFields: string[], viewer?: Account, target?: Account) {
  const acl: TreatedFieldsAcl = new TreatedFieldsAcl()

  if (!viewer || !target) return acl

  const owned = viewer.profile?.profileId === target.profile?.profileId
  const linked = viewer.profile?.linkedProfileId === target.profile?.linkedProfileId
  const controlled = viewer.profile?.profileId === target.profile?.linkedProfileId
  const viewerOperator = isOperator(viewer.profile?.type)
  const viewerConsumer = isConsumer(viewer.profile?.type)
  const viewerCanUpdateCollaborator = viewer.canDo(ActionType.updateCollaboratorProfiles)
  const targetExternal = target?.user?.accountType === AccountType.externalUser
  const targetInternal = target?.user?.accountType === AccountType.internalUser
  const targetPlaceholder = target?.user?.accountType === AccountType.placeholderUser
  const targetConsumer = isConsumer(target.profile?.type)
  const targetOperator = isOperator(target.profile?.type)
  const targetStructure = isStructure(target.profile?.type)
  const targetCollaborator = isCollaborator(target.profile?.type)
  const targetOwnCollaborator =
    isCollaborator(target.profile?.type) && target.profile?.linkedProfileId === viewer.profile?.linkedProfileId

  // presentation
  acl.avatar = getAclValue(
    owned ||
      controlled ||
      linked ||
      (targetPlaceholder && isFieldTreated(target.treatedFields, TreatedFieldName.avatar)),
    owned ||
      controlled ||
      (targetCollaborator && viewer.canDo(ActionType.updateCollaboratorProfiles)) ||
      (targetPlaceholder && isFieldTreated(target.treatedFields, TreatedFieldName.avatar)),
    isFieldNeeded(neededFields, 'avatar')
  )
  acl.profileDefault = getAclValue(
    owned && viewerOperator,
    owned && viewerOperator,
    isFieldNeeded(neededFields, 'profileDefault')
  )
  acl.profileVisibility = getAclValue(
    (owned && targetOperator) || (targetCollaborator && viewer.canDo(ActionType.updateCollaboratorProfiles)),
    (owned && targetOperator) || (targetCollaborator && viewer.canDo(ActionType.updateCollaboratorProfiles)),
    isFieldNeeded(neededFields, 'profileVisibility')
  )
  acl.alias = getAclValue(owned, owned, isFieldNeeded(neededFields, 'alias'))
  acl.title = getAclValue(
    (targetOperator && owned) || (targetCollaborator && viewerCanUpdateCollaborator),
    (targetOperator && owned) || (targetCollaborator && viewerCanUpdateCollaborator),
    isFieldNeeded(neededFields, 'title')
  )

  // identity
  acl.registrationEmail = getAclValue(
    !targetStructure && targetExternal && owned,
    false,
    isFieldNeeded(neededFields, 'registrationEmail')
  )
  acl.lastname = getAclValue(
    !targetStructure &&
      (owned || linked || (targetPlaceholder && isFieldTreated(target.treatedFields, TreatedFieldName.lastname))),
    owned || controlled || (targetPlaceholder && isFieldTreated(target.treatedFields, TreatedFieldName.lastname)),
    isFieldNeeded(neededFields, 'lastname')
  )
  acl.name = getAclValue(
    owned || linked || controlled || (targetPlaceholder && isFieldTreated(target.treatedFields, TreatedFieldName.name)),
    owned || controlled || (targetPlaceholder && isFieldTreated(target.treatedFields, TreatedFieldName.name)),
    isFieldNeeded(neededFields, 'name')
  )
  acl.gender = getAclValue(
    !targetStructure &&
      (owned || linked || (targetPlaceholder && isFieldTreated(target.treatedFields, TreatedFieldName.gender))),
    owned || controlled || (targetPlaceholder && isFieldTreated(target.treatedFields, TreatedFieldName.gender)),
    isFieldNeeded(neededFields, 'gender')
  )
  acl.birthdate = getAclValue(
    !targetStructure &&
      (owned || linked || (targetPlaceholder && isFieldTreated(target.treatedFields, TreatedFieldName.birthdate))),
    owned || controlled || (targetPlaceholder && isFieldTreated(target.treatedFields, TreatedFieldName.birthdate)),
    isFieldNeeded(neededFields, 'birthdate')
  )
  acl.birthplace = getAclValue(
    !targetStructure &&
      (owned || linked || (targetPlaceholder && isFieldTreated(target.treatedFields, TreatedFieldName.birthplace))),
    owned || controlled || (targetPlaceholder && isFieldTreated(target.treatedFields, TreatedFieldName.birthplace)),
    isFieldNeeded(neededFields, 'birthplace')
  )
  acl.fiscalCode = getAclValue(
    owned ||
      linked ||
      controlled ||
      (targetPlaceholder && isFieldTreated(target.treatedFields, TreatedFieldName.fiscalCode)),
    owned || controlled || (targetPlaceholder && isFieldTreated(target.treatedFields, TreatedFieldName.fiscalCode)),
    isFieldNeeded(neededFields, 'fiscalCode')
  )
  acl.vatNumber = getAclValue(targetStructure, controlled, isFieldNeeded(neededFields, 'vatNumber'))

  // billing
  acl.billingSdiCode = getAclValue(
    targetStructure && controlled,
    targetStructure && controlled,
    isFieldNeeded(neededFields, 'billingSdiCode')
  )
  acl.billingPec = getAclValue(
    targetStructure && controlled,
    targetStructure && controlled,
    isFieldNeeded(neededFields, 'billingPec')
  )
  acl.billingCountry = getAclValue(
    targetStructure && controlled,
    targetStructure && controlled,
    isFieldNeeded(neededFields, 'billingCountry')
  )
  acl.billingCity = getAclValue(
    targetStructure && controlled,
    targetStructure && controlled,
    isFieldNeeded(neededFields, 'billingCity')
  )
  acl.billingProvince = getAclValue(
    targetStructure && controlled,
    targetStructure && controlled,
    isFieldNeeded(neededFields, 'billingProvince')
  )
  acl.billingStreet = getAclValue(
    targetStructure && controlled,
    targetStructure && controlled,
    isFieldNeeded(neededFields, 'billingStreet')
  )
  acl.billingStreetNumber = getAclValue(
    targetStructure && controlled,
    targetStructure && controlled,
    isFieldNeeded(neededFields, 'billingStreetNumber')
  )
  acl.billingZip = getAclValue(
    targetStructure && controlled,
    targetStructure && controlled,
    isFieldNeeded(neededFields, 'billingZip')
  )

  // residence
  acl.residenceCountry = getAclValue(
    owned ||
      controlled ||
      linked ||
      (targetPlaceholder && isFieldTreated(target.treatedFields, TreatedFieldName.residenceCountry)),
    owned || controlled,
    isFieldNeeded(neededFields, 'residenceCountry')
  )
  acl.residenceProvince = getAclValue(
    owned ||
      controlled ||
      linked ||
      (targetPlaceholder && isFieldTreated(target.treatedFields, TreatedFieldName.residenceProvince)),
    owned || controlled,
    isFieldNeeded(neededFields, 'residenceProvince')
  )
  acl.residenceCity = getAclValue(
    owned ||
      controlled ||
      linked ||
      (targetPlaceholder && isFieldTreated(target.treatedFields, TreatedFieldName.residenceCity)),
    owned || controlled,
    isFieldNeeded(neededFields, 'residenceCity')
  )
  acl.residenceStreet = getAclValue(
    owned ||
      controlled ||
      linked ||
      (targetPlaceholder && isFieldTreated(target.treatedFields, TreatedFieldName.residenceStreet)),
    owned || controlled,
    isFieldNeeded(neededFields, 'residenceStreet')
  )
  acl.residenceStreetNumber = getAclValue(
    owned ||
      controlled ||
      linked ||
      (targetPlaceholder && isFieldTreated(target.treatedFields, TreatedFieldName.residenceStreetNumber)),
    owned || controlled,
    isFieldNeeded(neededFields, 'residenceStreetNumber')
  )
  acl.residenceZip = getAclValue(
    owned ||
      controlled ||
      linked ||
      (targetPlaceholder && isFieldTreated(target.treatedFields, TreatedFieldName.residenceZip)),
    owned || controlled,
    isFieldNeeded(neededFields, 'residenceZip')
  )

  // contacts
  acl.phone = getAclValue(
    owned ||
      controlled ||
      (linked && (targetOperator || targetStructure)) ||
      targetOwnCollaborator ||
      ((targetConsumer || targetPlaceholder) && isFieldTreated(target.treatedFields, TreatedFieldName.phone)),
    owned ||
      controlled ||
      (linked && (targetOperator || targetStructure)) ||
      targetOwnCollaborator ||
      ((targetConsumer || targetPlaceholder) && isFieldTreated(target.treatedFields, TreatedFieldName.phone)),
    isFieldNeeded(neededFields, 'phone')
  )
  acl.mobilePhone = getAclValue(
    owned ||
      controlled ||
      (linked && (targetOperator || targetStructure)) ||
      targetOwnCollaborator ||
      ((targetConsumer || targetPlaceholder) && isFieldTreated(target.treatedFields, TreatedFieldName.mobilePhone)),
    owned ||
      controlled ||
      (linked && (targetOperator || targetStructure)) ||
      targetOwnCollaborator ||
      ((targetConsumer || targetPlaceholder) && isFieldTreated(target.treatedFields, TreatedFieldName.mobilePhone)),
    isFieldNeeded(neededFields, 'mobilePhone')
  )
  acl.email = getAclValue(
    owned ||
      controlled ||
      (linked && (targetOperator || targetStructure)) ||
      targetOwnCollaborator ||
      ((targetConsumer || targetPlaceholder) && isFieldTreated(target.treatedFields, TreatedFieldName.email)),
    owned ||
      controlled ||
      (linked && (targetOperator || targetStructure)) ||
      targetOwnCollaborator ||
      ((targetConsumer || targetPlaceholder) && isFieldTreated(target.treatedFields, TreatedFieldName.email)),
    isFieldNeeded(neededFields, 'email')
  )
  acl.website = getAclValue(targetStructure, controlled, isFieldNeeded(neededFields, 'website'))

  return acl
}

const injectActions = (dispatch: (arg: Actions) => void, state: IContextState) => {
  state.setNeededFields = (neededFields: string[]) => {
    dispatch({ type: 'SET_NEEDED_FIELDS', payload: { neededFields: neededFields } })
  }
  state.setFieldsAcl = (viewer?: Account, target?: Account) =>
    dispatch({ type: 'SET_FIELDS_ACL', payload: { viewer: viewer, target: target } })
}

interface IProps {
  neededFields?: string[]
  children?: React.ReactNode
}

const AccountEditorContext = React.createContext<IContextState>(initialState)
export const AccountEditorContextProvider: React.FC<IProps> = ({ neededFields, children }) => {
  const initialStateWithFields = {
    ...initialState,
    neededFields: neededFields ? neededFields : [],
  }
  const [state, dispatch] = useReducer<ReducerFunc>(reducer, initialStateWithFields)
  injectActions(dispatch, state)
  return <AccountEditorContext.Provider value={state}>{children}</AccountEditorContext.Provider>
}

export function useAccountEditorContext() {
  return useContext(AccountEditorContext)
}
