import { MouseEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router'
import _ from 'lodash'

import log from '../../shared/Logger'
import msgIds from '../../locales/msgIds'
import { Utils } from '../../shared/Utils'
import { ArchiveDoxes } from '../../shared/types/ArchiveDoxes'
import { Document } from '../../models/Document'
import { useAuthContext } from '../../contexts/AuthContext'
import {
  isOperator,
  isConsumer,
  ArchiveItemSourceType,
  ActionType,
  RetentionRules,
  isBusiness,
  isStructure,
} from '../../shared/Constants'
import * as dalDocument from '../../dal/DalDocument'
import * as dalDox from '../../dal/DalDox'
import * as dalPermission from '../../dal/DalPermission'
import * as dalTreatment from '../../dal/DalTreatment'
import { PaginatedResponse } from '../../shared/types/PaginatedResponse'
import DoxesMenu from '../../components/doxesMenu/DoxesMenu'
import DocumentsTable from '../../components/documentsTable/DocumentsTable'
import { Dox } from '../../models/Dox'
import { Box, IconButton, Paper, Stack, SwipeableDrawer, useMediaQuery, useTheme } from '@mui/material'
import DocumentsTableFiltersBar from '../../components/documentsTableFiltersBar/DocumentsTableFiltersBar'
import {
  AddDocExternalIco,
  AddDocInternalIco,
  AutorenewIco,
  DoxIco,
  DoxManageIco,
  SelectedIco,
  VerticalGrabberIco,
} from '../../components/icons'
import { ArchiveTypes } from '../../models/ArchiveTypes'
import CommandBar from '../../components/commandBar/CommandBar'
import { DocumentsQuery, SortQuery } from '../../models/DocumentsQuery'
import { Account } from '../../models/Account'
import DialogTemplate from '../../components/template/dialogTemplate/DialogTemplate'
import {
  createBlob,
  downloadDocumentRevision,
  downloadDoxContent,
  enqueueSnackbarDownload,
  useDocumentCacheContext,
} from '../../contexts/DocumentCacheContext'
import { useArchiveContext } from '../../contexts/ArchiveContext'
import DoxEditorDialog from '../../dialogs/doxEditorDialog/DoxEditorDialog'
import { IDoxEditorDialogData } from '../../dialogs/doxEditorDialog/DoxEditorDialog.types'
import DoxSelectorDialog from '../../dialogs/doxSelectorDialog/DoxSelectorDialog'
import { IDoxSelectorDialogData } from '../../dialogs/doxSelectorDialog/DoxSelectorDialog.types'
import SimpleDialog from '../../dialogs/simpleDialog/SimpleDialog'
import { ISimpleDialogData } from '../../dialogs/simpleDialog/SimpleDialog.types'
import AuthorizationsChangeDialog from '../../dialogs/authorizationsChangeDialog/AuthorizationsChangeDialog'
import { IAuthorizationsChangeDialogData } from '../../dialogs/authorizationsChangeDialog/AuthorizationsChangeDialog.types'
import AuthorizationsDialog from '../../dialogs/authorizationsDialog/AuthorizationsDialog'
import { IAuthorizationsDialogData } from '../../dialogs/authorizationsDialog/AuthorizationsDialog.types'
import { IDoxDetailsDialogData } from '../../dialogs/doxDetailsDialog/DoxDetailsDialog.types'
import { DoxDetailsDialog } from '../../dialogs/doxDetailsDialog/DoxDetailsDialog'
import { DoxSelectorMode } from '../../components/doxSelector/DoxSelector.types'
import { Authorization } from '../../models/Authorization'
import { OrganizedDoxTemplate } from '../../models/OrganizedDoxTemplate'
import { useDesktopContext } from '../../contexts/DesktopContext'
import { IOrganizedDoxTemplateSelectorDialogData } from '../../dialogs/organizedDoxTemplateSelectorDialog/OrganizedDoxTemplateSelectorDialog.types'
import OrganizedDoxTemplateSelectorDialog from '../../dialogs/organizedDoxTemplateSelectorDialog/OrganizedDoxTemplateSelectorDialog'
import { Treatment } from '../../models/Treatment'
import { DoxTemplate } from '../../models/DoxTemplate'
import { IInternalDocTemplateSelectorDialogData } from '../../dialogs/internalDocTemplateSelectorDialog/InternalDocTemplateSelectorDialog.types'
import InternalDocTemplateSelectorDialog from '../../dialogs/internalDocTemplateSelectorDialog/InternalDocTemplateSelectorDialog'
import AddDocInternalTemplateIco from '../../components/icons/AddDocInternalTemplateIco'
import { IArchivePageInit } from './ArchivePage.types'
import { DocumentRevision } from '../../models/DocumentRevision'
import { useDocumentEditorState } from '../../components/documentEditor/DocumentEditorHooks'
import DocumentEditor from '../../components/documentEditor/DocumentEditor'
import { ICommand } from '../../components/commandBar/CommandBar.types'
import { StyledDialog } from '../../dialogs/styledDialog/StyledDialog'
import { DoxShareStepper } from '../../components/doxShareStepper/DoxShareStepper'
import { IDoxShareStepperProps } from '../../components/doxShareStepper/DoxShareStepper.types'

const defaultSortQuery: SortQuery = {
  field: 'edited_at',
  order: 'desc',
}

const defaultPageSize = 30

const defaultDoxesMenuWidth = 350
const minDoxesMenuWidth = 200

export default function ArchivePage() {
  const location = useLocation()
  const state = location.state as IArchivePageInit

  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const authContext = useAuthContext()
  const archiveContext = useArchiveContext()
  const documentCacheContext = useDocumentCacheContext()
  const desktop = useDesktopContext()
  const [reloadDoxesTrigger, setReloadDoxesTrigger] = useState<number>(1)
  const [documentsPages, setDocumentsPages] = useState<PaginatedResponse<Document>[]>([])
  const [documentsPageIndex, setDocumentsPageIndex] = useState(0)
  const [nextDocumentsPageTriggered, setNextDocumentsPageTriggered] = useState(-1)
  const [reloadDocumentsTrigger, setReloadDocumentsTrigger] = useState<number>(1)
  const [doxSelection, setDoxSelection] = useState<Dox[]>([])
  const [docSelection, setDocSelection] = useState<Document[]>([])
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const [isDoxMenuVisible, setIsDoxMenuVisible] = useState(!isMobile)
  const [doxMenuPin, setDoxMenuPin] = useState(true)
  const [isDoxMenuCollpased, setIsDoxMenuCollapsed] = useState(false)
  const [documentInEditing, setDocumentInEditing] = useState<Document | null>(null)
  const [isDocumentEdited, setIsDocumentEdited] = useState(false)
  const [initialCommand, setInitialCommand] = useState<string>()
  const documentEditorState = useDocumentEditorState({
    document: documentInEditing,
    onChangeDocument: setDocumentInEditing,
    isEditing: isDocumentEdited,
    onChangeIsEditing: setIsDocumentEdited,
    initialCommand: initialCommand,
    onChangeInitialCommand: setInitialCommand,
  })
  const [showDocumentsTableCheckbox, setShowDocumentsTableCheckbox] = useState(!isMobile)
  // dialogs
  const [simpleDialogData, setSimpleDialogData] = useState<ISimpleDialogData>()
  const [simpleDialogOpen, setSimpleDialogOpen] = useState(false)
  const [doxDetailsDialogData, setDoxDetailsDialogData] = useState<IDoxDetailsDialogData>()
  const [doxDetailsDialogOpen, setDoxDetailsDialogOpen] = useState(false)
  const [doxEditorDialogData, setDoxEditorDialogData] = useState<IDoxEditorDialogData>()
  const [doxEditorDialogOpen, setDoxEditorDialogOpen] = useState(false)
  const [doxSelectorDialogData, setDoxSelectorDialogData] = useState<IDoxSelectorDialogData>()
  const [doxSelectorDialogOpen, setDoxSelectorDialogOpen] = useState(false)
  const [authorizationsDialogData, setAuthorizationsDialogData] = useState<IAuthorizationsDialogData>()
  const [authorizationsDialogOpen, setAuthorizationsDialogOpen] = useState(false)
  const [changeAuthorizationsDialogData, setChangeAuthorizationsDialogData] =
    useState<IAuthorizationsChangeDialogData>()
  const [authorizationsChangeDialogOpen, setAuthorizationsChangeDialogOpen] = useState(false)
  const [organizedDoxTemplateSelectorDialogData, setOrganizedDoxTemplateSelectorDialogData] =
    useState<IOrganizedDoxTemplateSelectorDialogData>()
  const [organizedDoxTemplateSelectorDialogOpen, setOrganizedDoxTemplateSelectorDialogOpen] = useState(false)
  const [internalDocTemplateSelectorDialogData, setInternalDocTemplateSelectorDialogData] =
    useState<IInternalDocTemplateSelectorDialogData>()
  const [internalDocTemplateSelectorDialogOpen, setInternalDocTemplateSelectorDialogOpen] = useState(false)
  // dialog doxShare
  const [doxShareDialogData, setDoxShareDialogData] = useState<IDoxShareStepperProps>()
  const [doxShareDialogOpen, setDoxShareDialogOpen] = useState(false)

  const documents = useMemo(() => {
    return documentsPages.reduce((docs, page) => {
      return docs.concat(page.rows)
    }, [] as Document[])
  }, [documentsPages])

  useEffect(() => {
    // reset
    setIsDoxMenuVisible(false)
    setDoxMenuPin(true)
    setShowDocumentsTableCheckbox(!isMobile)
  }, [isMobile])

  useEffect(() => {
    let archiveType = ArchiveTypes.none
    if (state) {
      if (isOperator(authContext.loggedProfileType)) {
        if (state.isPerspective) {
          archiveType = ArchiveTypes.structureArchiveForCustomer
        } else {
          archiveType = ArchiveTypes.structureArchiveSharedByCustomer
        }
      } else if (isConsumer(authContext.loggedProfileType)) {
        if (authContext.loggedAccount?.isGuest) {
          archiveType = ArchiveTypes.guestArchive
        } else {
          if (state.onlyReceived) {
            archiveType = ArchiveTypes.customerArchiveReceived
          } else {
            archiveType = ArchiveTypes.customerArchiveOwned
          }
        }
      }
    }
    if (archiveType !== archiveContext.archiveType) {
      archiveContext.setRWArchiveDoxes(new ArchiveDoxes())
      archiveContext.setROArchiveDoxes(new ArchiveDoxes())
      archiveContext.setArchiveType(archiveType)
    }
  }, [state, authContext.loggedProfileType])

  const doxSelectionIds = useMemo(() => {
    return doxSelection.map(({ id }) => id)
  }, [doxSelection])

  const commandBarCommands = useMemo(() => {
    const commands: ICommand[] = []
    const canAssociateToDox =
      archiveContext.archiveType === ArchiveTypes.customerArchiveOwned ||
      archiveContext.archiveType === ArchiveTypes.customerArchiveReceived ||
      archiveContext.archiveType === ArchiveTypes.structureArchiveSharedByCustomer ||
      archiveContext.archiveType === ArchiveTypes.structureArchiveForCustomer ||
      archiveContext.archiveType === ArchiveTypes.guestArchive
    const canCreateDocuments =
      (archiveContext.archiveType === ArchiveTypes.customerArchiveOwned ||
        archiveContext.archiveType === ArchiveTypes.structureArchiveForCustomer ||
        archiveContext.archiveType === ArchiveTypes.guestArchive) &&
      authContext.loggedAccount?.canDo(ActionType.createDocuments)

    if (canAssociateToDox && docSelection.length) {
      commands.push({
        commandText: t(msgIds.MSG_DOC_COMMAND_ASSOCIATE_TO_DOX),
        tooltipText: t(msgIds.MSG_DOC_COMMAND_ASSOCIATE_TO_DOX),
        icon: <DoxIco />,
        onClick: () => {
          associateToDox(docSelection)
        },
      })
    }

    if (canCreateDocuments) {
      if (
        authContext.linkedStructureAccount?.profile?.fePreferences?.archiveCfg?.internalDocumentTemplates &&
        authContext.linkedStructureAccount?.profile?.fePreferences?.archiveCfg?.internalDocumentTemplates?.length > 0
      ) {
        commands.push({
          commandText: t(msgIds.MSG_COMMAND_CREATE_DOCUMENT_FROM_TEMPLATE),
          tooltipText: t(msgIds.MSG_DESKTOP_CREATE_INTERNAL_DOCUMENT_FROM_TEMPLATE_TOOLTIP),
          icon: <AddDocInternalTemplateIco />,
          onClick: () => {
            createNewDocumentFromTemplate()
          },
        })
      }

      commands.push({
        commandText: t(msgIds.MSG_COMMAND_CREATE_DOCUMENT),
        tooltipText: t(msgIds.MSG_COMMAND_CREATE_DOCUMENT),
        icon: <AddDocInternalIco />,
        onClick: () => {
          createNewDocument(ArchiveItemSourceType.internalSource)
        },
      })
      commands.push({
        commandText: t(msgIds.MSG_COMMAND_UPLOAD_DOCUMENT),
        tooltipText: t(msgIds.MSG_COMMAND_UPLOAD_DOCUMENT),
        icon: <AddDocExternalIco />,
        onClick: () => {
          createNewDocument(ArchiveItemSourceType.externalSource)
        },
      })
    }

    return commands
  }, [docSelection.length, archiveContext.archiveType, doxSelectionIds, t])

  function createNewDocument(sourceType: ArchiveItemSourceType) {
    if (!authContext.loggedAccount?.canDo(ActionType.createDocuments)) return
    const names = desktop.desktopDocuments.map((doc) => doc.document.getName() || '')
    const name = Utils.computeDefaultDistinctName(t(msgIds.MSG_DOCUMENT_EDITOR_NEW_INTERNAL_DOCUMENT_NAME), names)
    const newDocument = Utils.createDocument(sourceType, authContext, t, name)
    if (doxSelectionIds.length === 1) {
      newDocument.doxIds.push(doxSelectionIds[0])
    }
    setIsDocumentEdited(true)
    setDocumentInEditing(newDocument)
  }

  function createNewDocumentFromTemplate() {
    if (!authContext.loggedAccount?.canDo(ActionType.createDocuments)) return
    const templates =
      authContext.linkedStructureAccount?.profile?.fePreferences?.archiveCfg?.internalDocumentTemplates || []
    if (templates.length > 0) {
      setInternalDocTemplateSelectorDialogOpen(true)
      setInternalDocTemplateSelectorDialogData({
        onResult: (result) => {
          setInternalDocTemplateSelectorDialogOpen(false)
          if (result.userChoice !== 'yes') return
          const names = desktop.desktopDocuments.map((doc) => doc.document.getName() || '')
          const name = Utils.computeDefaultDistinctName(t(msgIds.MSG_DOCUMENT_EDITOR_NEW_INTERNAL_DOCUMENT_NAME), names)
          const newDocument = Utils.createDocument(
            ArchiveItemSourceType.internalSource,
            authContext,
            t,
            name,
            result.template.content
          )
          if (doxSelectionIds.length === 1) {
            newDocument.doxIds.push(doxSelectionIds[0])
          }
          setIsDocumentEdited(true)
          setDocumentInEditing(newDocument)
        },
      })
    } else {
      setSimpleDialogOpen(true)
      setSimpleDialogData({
        title: t(msgIds.MSG_DOCUMENTS_TEMPLATES_DIALOG_TITLE),
        content: t(msgIds.MSG_DOCUMENTS_TEMPLATES_TEMPLATES_LIST_EMPTY),
        actionsStyle: 'Ok',
        onClose: (result) => {
          setSimpleDialogOpen(false)
        },
      })
    }
  }

  async function associateToDox(documents: Document[]) {
    try {
      const abortController = new AbortController()
      await Promise.all(
        documents.map(async (doc) => {
          const document = await dalDocument.getDocument(abortController.signal, doc.documentId)
          doc.doxIds = document.doxIds
        })
      )

      const doxIdsCollections = documents.map((p) => p.doxIds)
      const commonAndMissing = Utils.findCommonAndMissingElements(doxIdsCollections)

      const selectionMode =
        isBusiness(authContext.loggedProfileType) && !!documents.find((p) => !p.anonymousAt)
          ? DoxSelectorMode.selectSingleWithRetention
          : DoxSelectorMode.selectSingle

      const disabledDoxId =
        isOperator(authContext.loggedProfileType) &&
        (archiveContext.archiveType === ArchiveTypes.structureArchiveForCustomer ||
          archiveContext.archiveType === ArchiveTypes.structureArchiveSharedByCustomer) &&
        documents.find((p) => p.ownerProfileId === authContext.linkedStructureProfileId)
          ? undefined
          : archiveContext.rwArchiveDoxes.roots[0].id

      setDoxSelectorDialogOpen(true)
      setDoxSelectorDialogData({
        selectionMode: selectionMode,
        assignableDoxes: archiveContext.rwArchiveDoxes,
        disabledDoxId: disabledDoxId,
        selectedDoxIds: commonAndMissing.common,
        undeterminedDoxIds: commonAndMissing.missing,
        onResult: async (result1) => {
          setDoxSelectorDialogOpen(false)
          if (result1.userChoice === 'yes') {
            const allAssignedDoxIds = Utils.getArrayUnion(commonAndMissing.common, result1.addedDoxIds)
            const allOwnedDoxIds = archiveContext.rwArchiveDoxes.distinct.map((p) => p.id)
            const allAssignedOwnedDoxIds = Utils.getArrayIntersection(allAssignedDoxIds, allOwnedDoxIds)
            const remainingDoxIds = Utils.getArrayDifference(allAssignedOwnedDoxIds, result1.removedDoxIds)
            const isRetentionRequired =
              isBusiness(authContext.loggedProfileType) &&
              remainingDoxIds.length === 1 &&
              remainingDoxIds[0] === archiveContext.rwArchiveDoxes.roots[0].id &&
              !archiveContext.rwArchiveDoxes.roots[0].isRetentionEnabled &&
              !!documents.find((p) => !p.anonymousAt)
            if (isRetentionRequired) {
              setSimpleDialogOpen(true)
              setSimpleDialogData({
                title: t(msgIds.MSG_DOX_SELECTOR_DIALOG_TITLE),
                content:
                  documents.length > 1
                    ? t(msgIds.MSG_DOX_SELECTOR_DIALOG_REMOVING_DOCUMENTS_CONFIRM)
                    : t(msgIds.MSG_DOX_SELECTOR_DIALOG_REMOVING_DOCUMENT_CONFIRM),
                actionsStyle: 'yesNO',
                onClose: async (result2) => {
                  setSimpleDialogOpen(false)
                  if (result2.userChoice === 'yes') {
                    try {
                      await Utils.applyNewDoxSelections(
                        authContext.loggedProfileType!,
                        archiveContext.rwArchiveDoxes,
                        documents,
                        result1.addedDoxIds,
                        result1.removedDoxIds,
                        true,
                        true
                      )
                      documents.forEach((document) => {
                        documentCacheContext.setDocumentDox(document)
                      })

                      // 'remainingDoxIds' is empty because you have requested to remove the document from the archive
                      updateDocumentsInList(documents, [], true)
                      enqueueSnackbar(t(msgIds.MSG_DOX_SELECTOR_DIALOG_CHANGES_MADE_SUCCESSFULLY), {
                        variant: 'success',
                      })
                    } catch (err) {
                      Utils.enqueueSnackbarError2(err, t)
                    }
                  }
                },
              })
            } else {
              try {
                await Utils.applyNewDoxSelections(
                  authContext.loggedProfileType!,
                  archiveContext.rwArchiveDoxes,
                  documents,
                  result1.addedDoxIds,
                  result1.removedDoxIds,
                  true,
                  false
                )
                documents.forEach((document) => {
                  documentCacheContext.setDocumentDox(document)
                })
                updateDocumentsInList(documents, remainingDoxIds, true)
                enqueueSnackbar(t(msgIds.MSG_DOX_SELECTOR_DIALOG_CHANGES_MADE_SUCCESSFULLY), { variant: 'success' })
              } catch (err) {
                Utils.enqueueSnackbarError2(err, t)
              }
            }
          }
        },
      })
    } catch (err) {
      Utils.enqueueSnackbarError2(err, t)
    }
  }

  function updateDocumentsInList(
    _documents: Document[],
    assignedDoxIds: number[],
    considerDoxChanges?: boolean,
    remove?: boolean
  ) {
    if (
      archiveContext.archiveType === ArchiveTypes.structureArchiveSharedByCustomer ||
      archiveContext.archiveType === ArchiveTypes.customerArchiveReceived
    )
      return

    let removedDocumentIds: number[] = []
    if (remove) {
      removedDocumentIds = _documents.map((p) => p.documentId)
    } else if (considerDoxChanges) {
      const allOwnedDoxIds = archiveContext.rwArchiveDoxes.distinct.map((p) => p.id)
      const remainingDoxIds = Utils.getArrayIntersection(assignedDoxIds, allOwnedDoxIds)
      const assignedDoxIdsForDocuments =
        doxSelectionIds.length > 0
          ? Utils.getArrayIntersection(remainingDoxIds, doxSelectionIds) // in this case all documents are showed
          : remainingDoxIds
      removedDocumentIds = assignedDoxIdsForDocuments.length === 0 ? _documents.map((p) => p.documentId) : []
    }

    const updatedPaginatedResponses: PaginatedResponse<Document>[] = documentsPages.map((documentsPage) => {
      const updatedPage = new PaginatedResponse<Document>()
      updatedPage.page = documentsPage.page
      updatedPage.offset = documentsPage.offset
      updatedPage.limit = documentsPage.limit
      updatedPage.total = documentsPage.total
      updatedPage._hasMore = documentsPage._hasMore
      updatedPage.rows = updateDocumentsInPagedRows(documentsPage.rows, _documents, removedDocumentIds)
      return updatedPage
    })
    setDocumentsPages(updatedPaginatedResponses)
  }

  function updateDocumentsInPagedRows(rows: Document[], _documents: Document[], removedDocumentIds: number[]) {
    // Remove documents from both collections that have a document_id in removedDocumentIds
    const filteredRows = rows.filter((doc) => !removedDocumentIds.includes(doc.documentId))
    const filteredDocuments = _documents.filter((doc) => !removedDocumentIds.includes(doc.documentId))
    // Create a map of _documents for quick access to document_ids
    const documentMap = new Map<number, Document>()
    // Insert all documents from _documents into the map
    filteredDocuments.forEach((doc) => documentMap.set(doc.documentId, doc))
    // Replace documents in rows with those in _documents if the document_id matches
    const updatedRows = filteredRows.map((doc) => {
      // If the document_id is in _documents, replace the version
      return documentMap.has(doc.documentId) ? documentMap.get(doc.documentId)! : doc
    })
    // Add to the top of _documents documents that are not already in rows
    const additionalDocuments = filteredDocuments.filter(
      (doc) => !filteredRows.some((row) => row.documentId === doc.documentId)
    )
    // Combines the two collections, with the new _documents documents on top
    const finalDocuments = [...additionalDocuments, ...updatedRows]
    return finalDocuments
  }

  const assistedAccountIdentity = useMemo(() => {
    return authContext.assistedAccount?.getIdentityInverse()
  }, [authContext.assistedAccount])

  const pageTitle = useMemo(() => {
    if (archiveContext.archiveType === ArchiveTypes.guestArchive) {
      return t(msgIds.MSG_DOX_SHARED_BY_WITH, {
        owner: authContext.linkedStructureAccount?.getIdentityInverse(),
        guest: authContext.loggedAccount?.getIdentityInverse(),
      })
    } else if (archiveContext.archiveType === ArchiveTypes.customerArchiveOwned) {
      return t(msgIds.MSG_PERSONAL_ARCHIVE)
    } else if (archiveContext.archiveType === ArchiveTypes.customerArchiveReceived) {
      return t(msgIds.MSG_RECEIVED_DOX)
    } else if (archiveContext.archiveType === ArchiveTypes.structureArchiveSharedByCustomer) {
      return t(msgIds.MSG_ARCHIVE_PAGE_SHARED_ARCHIVE_TITLE, { identity: assistedAccountIdentity })
    } else if (archiveContext.archiveType === ArchiveTypes.structureArchiveForCustomer) {
      return t(msgIds.MSG_ARCHIVE_PAGE_ARCHIVE_FOR_CUSTOMER_TITLE, { identity: assistedAccountIdentity })
    } else {
      return '...'
    }
  }, [archiveContext.archiveType, t, assistedAccountIdentity])

  const enqueueSnackbarError = useCallback(
    (err: any) => {
      if (err.code === 'ERR_CANCELED') {
        console.log(`[ABORTED] ${err.config.method} ${err.config.url}`)
      } else {
        enqueueSnackbar(t(Utils.getErrorMessageId(err)), { variant: 'error' })
      }
    },
    [t, enqueueSnackbar]
  )

  function reloadDoxes() {
    setReloadDoxesTrigger((t) => t + 1)
  }

  useEffect(() => {
    const loadDoxes = async (abortSignal: AbortSignal) => {
      try {
        setDoxSelection([])
        const profileId = authContext.loggedAccount?.isGuest
          ? authContext.loggedProfileId
          : authContext.assistedAccountProfileId
        const { rwArchiveDoxes, roArchiveDoxes } = await Utils.loadAvailableAndReadonlyDoxes(
          abortSignal,
          archiveContext.archiveType,
          profileId,
          state.grantorProfileId,
          t
        )
        if (!!rwArchiveDoxes) {
          archiveContext.setRWArchiveDoxes(rwArchiveDoxes)
          const dox = rwArchiveDoxes.getDox(state.selectedDoxId || 0)
          if (dox) {
            setDoxSelection([dox])
          }
          if (
            archiveContext.archiveType === ArchiveTypes.customerArchiveReceived &&
            roArchiveDoxes.distinct.length > 0
          ) {
            setDoxSelection([roArchiveDoxes.distinct[0]])
          }
        }
        if (!!roArchiveDoxes) {
          archiveContext.setROArchiveDoxes(roArchiveDoxes)
        }
      } catch (err) {
        Utils.enqueueSnackbarError2(err, t)
        archiveContext.setRWArchiveDoxes(new ArchiveDoxes())
        archiveContext.setROArchiveDoxes(new ArchiveDoxes())
      }
    }

    const abortController = new AbortController()
    loadDoxes(abortController.signal)

    return () => {
      abortController.abort()
    }
  }, [archiveContext.archiveType, authContext.assistedAccountProfileId, t, enqueueSnackbarError, reloadDoxesTrigger])

  const [docsFilter, setDocsFilter] = useState<DocumentsQuery>({})
  const [sortQuery, setSortQuery] = useState<SortQuery>(defaultSortQuery)

  const docsQuery = useMemo<DocumentsQuery | null>(() => {
    if (archiveContext.archiveType === ArchiveTypes.none) {
      log.debug('No archiveType')
      return null
    }
    let targetProfileId: number
    if (
      archiveContext.archiveType === ArchiveTypes.structureArchiveForCustomer ||
      archiveContext.archiveType === ArchiveTypes.structureArchiveSharedByCustomer
    ) {
      // TODO if profile id doesn't exist? Think a better implementation
      if (!authContext.assistedAccountProfileId) {
        log.error('No assistedAccountProfileId', authContext.assistedAccountProfileId)
        return null
      }
      targetProfileId = authContext.assistedAccountProfileId || 0
    } else {
      // TODO if profile id doesn't exist? Think a better implementation
      if (!authContext.loggedProfileId) {
        log.error('No loggedProfileId')
        return null
      }
      targetProfileId = authContext.loggedProfileId
    }
    const query: DocumentsQuery = {
      ...docsFilter,
      sortBy: sortQuery || defaultSortQuery,
      targetProfileId,
      doxIds: doxSelectionIds,
    }
    if (
      archiveContext.archiveType === ArchiveTypes.structureArchiveForCustomer ||
      archiveContext.archiveType === ArchiveTypes.customerArchiveOwned
    ) {
      const doxRootId = archiveContext.rwArchiveDoxes.root?.id
      let queryDoxIds = [...doxSelectionIds]
      if (queryDoxIds.length === 0 && doxRootId) {
        queryDoxIds = [doxRootId]
      }
      query.doxIds = queryDoxIds
    } else if (archiveContext.archiveType === ArchiveTypes.structureArchiveSharedByCustomer) {
      let queryDoxIds = [...doxSelectionIds]
      query.doxIds = queryDoxIds
    } else if (archiveContext.archiveType === ArchiveTypes.customerArchiveReceived) {
      let queryDoxIds = [...doxSelectionIds]
      if (queryDoxIds.length === 0) {
        log.error('No root doxes')
        return null
      }
      query.doxIds = queryDoxIds
    } else if (archiveContext.archiveType === ArchiveTypes.guestArchive) {
      let queryDoxIds = [...doxSelectionIds]
      query.doxIds = queryDoxIds
    } else {
      return null
    }
    if (docsFilter.createdByMe && authContext.loggedAccount) {
      const authors = query.authors ? [...query.authors] : []
      authors.push(authContext.loggedAccount)
      query.authors = authors
    }
    return query
  }, [
    docsFilter,
    sortQuery,
    archiveContext.archiveType,
    authContext.assistedAccountProfileId,
    authContext.loggedProfileId,
    authContext.loggedAccount,
    archiveContext.rwArchiveDoxes.root?.id,
    doxSelectionIds,
  ])

  const docsQueryString = JSON.stringify(docsQuery) // little experimental hack

  useEffect(() => {
    setDocumentsPageIndex(0)
    // -1 disable the trigger
    setNextDocumentsPageTriggered(-1)
  }, [docsQueryString])

  useEffect(() => {
    const noMore = documents.length % defaultPageSize
    if (documents.length > 0 && noMore === 0 && nextDocumentsPageTriggered === documents.length - 1) {
      setDocumentsPageIndex((i) => i + 1)
    }
  }, [nextDocumentsPageTriggered, documents.length])

  const onShowTriggerRow = useCallback((index: number) => {
    setNextDocumentsPageTriggered((i) => {
      if (i === -1) {
        // do not trigger
        return i
      }
      return index
    })
  }, [])

  function reloadDocuments() {
    setDocumentsPageIndex(0)
    setReloadDocumentsTrigger((t) => t + 1)
  }

  useEffect(() => {
    if (!docsQuery) {
      setDocumentsPages([])
      return
    }

    const loadDocuments = async (abortSignal: AbortSignal) => {
      try {
        documentCacheContext?.cleanCache()
        const query: DocumentsQuery = { ...docsQuery, pageSize: defaultPageSize, pageIndex: documentsPageIndex }
        const page = await loadPaginatedDocuments(abortSignal, archiveContext.archiveType, query)
        if (!query.pageIndex || query.pageIndex === 0) {
          setDocumentsPages([page])
        } else {
          setDocumentsPages((pages) => [...pages, page])
        }
        setDocSelection([])
        // re-enable trigger
        setNextDocumentsPageTriggered(0)
      } catch (err) {
        Utils.enqueueSnackbarError2(err, t)
      }
    }

    const abortController = new AbortController()
    loadDocuments(abortController.signal)

    return () => {
      abortController.abort()
    }
  }, [docsQueryString, reloadDocumentsTrigger, archiveContext.archiveType, documentsPageIndex])

  const doxesMenu = (
    <DoxesMenu
      selectedDoxId={state.selectedDoxId}
      onRequestReload={reloadDoxes}
      selection={doxSelection}
      onChangeSelection={setDoxSelection}
      isPinButtonVisible={!isMobile}
      pin={doxMenuPin}
      onChangePin={setDoxMenuPin}
      onCollapsed={setIsDoxMenuCollapsed}
      onDoxCommand={onDoxCommand}
    />
  )

  const resizerWidth = 12
  const resizerPadding = 0
  const doxesMenuRef = useRef<HTMLDivElement | null>(null)
  const doxesMenuWidth = useRef<number>(defaultDoxesMenuWidth)
  const mouseDown = useRef(false)
  const [mouseDragging, setMouseDragging] = useState(false)
  function onMouseDownResizer() {
    mouseDown.current = true
    setMouseDragging(true)
  }
  function onMouseMoveResizer(event: MouseEvent<HTMLDivElement>) {
    if (event.buttons === 0) {
      mouseDown.current = false
      setMouseDragging(false)
      return
    }
    if (!mouseDown.current || !doxesMenuRef.current || isDoxMenuCollpased) {
      return
    }
    const resizerLeftX = doxesMenuRef.current.offsetLeft + doxesMenuRef.current.offsetWidth
    const resizerCenterX = resizerLeftX + (resizerWidth + resizerPadding * 2.0) / 2.0
    const d = event.screenX - resizerCenterX
    const w = doxesMenuRef.current.offsetWidth + d
    const minW = minDoxesMenuWidth
    const parent = doxesMenuRef.current.parentElement
    const maxW = parent ? parent.offsetWidth - 100 : 500
    const boundedW = Math.min(maxW, Math.max(minW, w))
    doxesMenuWidth.current = boundedW
    doxesMenuRef.current.style.width = boundedW + 'px'
  }
  function onMouseUpResizer() {
    mouseDown.current = false
    setMouseDragging(false)
  }
  useEffect(() => {
    if (!doxesMenuRef.current) {
      return
    }
    if (!isDoxMenuCollpased) {
      doxesMenuRef.current.style.width = doxesMenuWidth.current + 'px'
    } else {
      doxesMenuRef.current.style.width = ''
    }
  }, [isDoxMenuCollpased])

  function onCloseDocumentEditor(force?: boolean, remove?: boolean) {
    if (!documentEditorState) {
      return
    }
    const originalDoc = documentEditorState.documentEditorState.document
    if (force || !documentEditorState.isUnsaved) {
      updateDocumentsListAndCloseEditor(originalDoc, remove)
      return
    }
    const doc = documentEditorState.documentEditorState.draftDocument
    const revision = documentEditorState.documentEditorState.draftRevision
    if (!doc || !revision) {
      updateDocumentsListAndCloseEditor(originalDoc)
      return
    }
    const name = revision.name ?? ''
    setSimpleDialogOpen(true)
    setSimpleDialogData({
      title: t(msgIds.MSG_DOX_SELECTOR_DIALOG_TITLE),
      content: t(msgIds.MSG_DOCUMENT_NOT_SAVED_CHANGED_DIALOG_BODY, { name: name }),
      actionsStyle: 'YESnoabort',
      onClose: async (result) => {
        setSimpleDialogOpen(false)
        try {
          if (result.userChoice === 'yes') {
            const d = await saveDocument(doc, revision)
            updateDocumentsListAndCloseEditor(d)
            enqueueSnackbar(t(msgIds.MSG_DOCUMENT_SAVED_SUCCESSFULLY), { variant: 'success' })
          } else if (result.userChoice === 'no') {
            updateDocumentsListAndCloseEditor(originalDoc)
          }
        } catch (err) {
          Utils.enqueueSnackbarError2(err, t)
        }
      },
    })
  }

  async function saveDocument(document: Document, revision: DocumentRevision) {
    const abortController = new AbortController()
    let savedDocument: Document
    if (document.documentId === 0) {
      savedDocument =
        document.sourceType === ArchiveItemSourceType.internalSource
          ? await dalDocument.createInternalDocument(abortController.signal, document, revision)
          : await dalDocument.createExternalDocument(abortController.signal, document, revision, (progress) => {
              log.debug({ downloadDocumentRevision: 'download update progress', progress })
              //args.state.patchUpload(uploadId, { progress })
            })
    } else {
      savedDocument =
        document.sourceType === ArchiveItemSourceType.internalSource
          ? await dalDocument.updateInternalDocument(abortController.signal, document, revision)
          : await dalDocument.updateExternalDocument(abortController.signal, document, revision, (progress) => {
              log.debug({ downloadDocumentRevision: 'download update progress', progress })
              //args.state.patchUpload(uploadId, { progress })
            })
    }

    document.documentId = savedDocument.documentId
    document.createdAt = savedDocument.createdAt
    document.updatedAt = savedDocument.updatedAt
    const savedRevision =
      revision.revisionId === 0 ? savedDocument.revisions[0] : savedDocument.getRevision(revision?.revisionId)
    if (savedRevision) {
      revision.revisionId = savedRevision.revisionId
      revision.createdAt = savedRevision.createdAt
      revision.updatedAt = savedRevision.updatedAt
    }

    return savedDocument
  }

  function updateDocumentsListAndCloseEditor(document?: Document, remove?: boolean) {
    if (document && document?.documentId !== 0) {
      updateDocumentsInList([document], document.doxIds, true, remove)
    }
    setDocumentInEditing(null)
    setIsDocumentEdited(false)
  }

  function onEditedDocumentAssignedDoxChanged(doxIds: number[]) {
    if (documentInEditing) {
      documentInEditing.doxIds = doxIds
    }
  }

  function checkUserCanDo(actionType: ActionType, title: string) {
    if (!authContext.loggedAccount?.canDo(actionType)) {
      setSimpleDialogOpen(true)
      setSimpleDialogData({
        title: title,
        content: t(msgIds.MSG_ERR_ACTION_TYPE_NOT_ALLOWED),
        actionsStyle: 'Ok',
        onClose: (result) => {
          setSimpleDialogOpen(false)
        },
      })
      return false
    } else {
      return true
    }
  }

  const [waitingDownloads, setWaitingDownloads] = useState<string[]>([])

  useEffect(() => {
    waitingDownloads.forEach((w) => {
      const download = documentCacheContext.downloadMap[w]
      if (download?.data) {
        setWaitingDownloads((old) => {
          return old.filter((id) => id !== w)
        })
        const blob = createBlob(download.data, download.mimetype)
        Utils.downloadBlob(blob, download.name)
      }
    })
  }, [waitingDownloads, documentCacheContext.downloadMap])

  // ********************
  // document commands
  async function onDocumentCommand(document: Document, action: string) {
    switch (action) {
      case 'associateToDox':
        associateToDox([document])
        break
      case 'showAuthorizations':
        showDocumentAuthorizations(document)
        break
      case 'removeFromStructureArchive':
        removeFromStructureArchive(document)
        break
      case 'download':
        downloadDocument(document)
        break
      case 'markAsObsolete':
        markAsObsolete(document)
        break
      case 'showHistory':
        showDocumentHistory(document)
        break
      case 'modify':
        modifyDocument(document)
        break
      case 'delete':
        deleteDocument(document)
        break
      case 'archive':
        await archiveDocument(document)
        break
      case 'addToDesktop':
        desktop.addDocument(document)
        break
      case 'removeFromDesktop':
        desktop.removeDocument(document)
        break
      case 'toggleAnonymousState':
        await toggleAnonymousState(document)
        break
    }
  }

  function showDocumentAuthorizations(document: Document) {
    setAuthorizationsDialogOpen(true)
    setAuthorizationsDialogData({
      element: document,
      onClose: (result) => {
        setAuthorizationsDialogOpen(false)
      },
    })
  }

  function removeFromStructureArchive(document: Document) {
    if (!checkUserCanDo(ActionType.updateDocumentsRetentions, t(msgIds.MSG_DOC_COMMAND_REMOVE_FROM_STRUCTURE_ARCHIVE)))
      return

    setSimpleDialogOpen(true)
    setSimpleDialogData({
      title: t(msgIds.MSG_DOC_COMMAND_REMOVE_FROM_STRUCTURE_ARCHIVE),
      content: t(msgIds.MSG_DOX_COMMAND_REMOVE_FROM_STRUCTURE_ARCHIVE_CONFIRM),
      actionsStyle: 'yesNO',
      onClose: async (result) => {
        try {
          if (result.userChoice !== 'yes') return

          const doxToDeassociate = archiveContext.rwArchiveDoxes?.distinct
            .filter((p) => document.doxIds.some((o) => o === p.id))
            .map((i) => i.id)
          if (doxToDeassociate) {
            const abortController = new AbortController()
            const tasks: Promise<number[] | undefined>[] = []
            doxToDeassociate?.forEach((p) => {
              tasks.push(dalDox.removeDocumentsFromDox(abortController.signal, p, [document.documentId], true))
            })
            await Promise.all(tasks)
          }
          setSimpleDialogOpen(false)
        } catch (err) {
          Utils.enqueueSnackbarError2(err, t)
        }
      },
    })
  }

  function downloadDocument(document: Document) {
    const state = documentCacheContext
    state.addDocument(document)
    const revision = document.getLastArchivedRevision() || document.revisions[0]
    if (!revision) {
      Utils.enqueueSnackbarError2(new Error('no revision'), t)
      return
    }
    const download = downloadDocumentRevision({ state, revision })
    enqueueSnackbarDownload(download)
    setWaitingDownloads((waiting) => [...waiting, download.id])
  }

  function markAsObsolete(document: Document) {
    if (!checkUserCanDo(ActionType.updateDocuments, t(msgIds.MSG_DOC_COMMAND_MARK_AS_OBSOLETE))) return

    setSimpleDialogOpen(true)
    setSimpleDialogData({
      title: t(msgIds.MSG_DOC_COMMAND_MARK_AS_OBSOLETE),
      content: t(msgIds.MSG_DOCUMENT_MARK_AS_OBSOLETE_CONFIRM),
      actionsStyle: 'yesNO',
      onClose: async (result) => {
        try {
          setSimpleDialogOpen(false)
          if (result.userChoice !== 'yes') return

          setSimpleDialogOpen(false)
          const abortController = new AbortController()
          const updatedDocument = await dalDocument.markAsObsolete(abortController.signal, document.documentId)
          updatedDocument.revisions.forEach((updatedRevision) => {
            const revision = document.revisions.find((p) => p.revisionId === updatedRevision.revisionId)
            if (revision) {
              updatedRevision.content = revision.content
            }
          })
          updateDocumentsInList([updatedDocument], [], false)
          enqueueSnackbar(t(msgIds.MSG_DOCUMENT_MARKED_AS_OBSOLETE_SUCCESSFULLY), { variant: 'success' })
        } catch (err) {
          Utils.enqueueSnackbarError2(err, t)
        }
      },
    })
  }

  function showDocumentHistory(document: Document) {
    navigate('/history', {
      state: {
        historyType: 'document',
        documentId: document.documentId,
      },
    })
  }

  function modifyDocument(document: Document) {
    if (!checkUserCanDo(ActionType.updateDocuments, t(msgIds.MSG_DOC_COMMAND_MODIFY))) return

    if (document.draftRevisionId) {
      setInitialCommand('editDraft')
    } else {
      setInitialCommand('createDraft')
    }
    setDocumentInEditing(document)
    setIsDocumentEdited(true)
  }

  function deleteDocument(document: Document) {
    setSimpleDialogOpen(true)
    setSimpleDialogData({
      title: t(msgIds.MSG_DOCUMENT),
      content: `${(document.getLastArchivedRevision() ?? document.getDraftRevision())?.name}\n\n${t(
        msgIds.MSG_DOCUMENT_DELETE_CONFIRM
      )}`,
      actionsStyle: 'yesNO',
      onClose: async (result) => {
        try {
          setSimpleDialogOpen(false)
          if (result.userChoice !== 'yes') return

          const abortController = new AbortController()
          const documentId = await dalDocument.deleteDocument(abortController.signal, document.documentId)
          const deletedDocument = documents.find((p) => p.documentId === documentId)
          if (deletedDocument) {
            updateDocumentsInList([document], [], true)
          }
          enqueueSnackbar(t(msgIds.MSG_DOCUMENT_DELETED_SUCCESSFULLY), { variant: 'success' })
        } catch (err) {
          Utils.enqueueSnackbarError2(err, t)
        }
      },
    })
  }

  async function archiveDocument(document: Document) {
    if (!checkUserCanDo(ActionType.updateDocuments, t(msgIds.MSG_DOC_COMMAND_ARCHIVE))) return

    try {
      const abortController = new AbortController()
      const updatedDocument = await dalDocument.archiveDocument(abortController.signal, document.documentId)
      updatedDocument.revisions.forEach((updatedRevision) => {
        const revision = document.revisions.find((p) => p.revisionId === updatedRevision.revisionId)
        if (revision) {
          updatedRevision.content = revision.content
        }
      })
      updateDocumentsInList([updatedDocument], [], false)
      enqueueSnackbar(t(msgIds.MSG_DOCUMENT_ARCHIVED_SUCCESSFULLY), { variant: 'success' })
    } catch (err) {
      Utils.enqueueSnackbarError2(err, t)
    }
  }

  async function toggleAnonymousState(document: Document) {
    try {
      if (!checkUserCanDo(ActionType.updateDocuments, t(msgIds.MSG_DOC_COMMAND_ARCHIVE))) return

      const newAnonymousState = !document.anonymousAt
      const abortController = new AbortController()
      const updatedDocument = await dalDocument.changeIsAnonymousState(
        abortController.signal,
        document.documentId,
        newAnonymousState
      )
      updatedDocument.revisions.forEach((updatedRevision) => {
        const revision = document.revisions.find((p) => p.revisionId === updatedRevision.revisionId)
        if (revision) {
          updatedRevision.content = revision.content
        }
      })
      updateDocumentsInList([updatedDocument], [], false)
      enqueueSnackbar(
        newAnonymousState ? t(msgIds.MSG_DOCUMENT_NOW_IS_ANONYMOUS) : t(msgIds.MSG_DOCUMENT_NOW_IS_NOT_ANONYMOUS),
        { variant: 'success' }
      )
    } catch (err) {
      Utils.enqueueSnackbarError2(err, t)
    }
  }

  // ********************
  // dox commands
  function onDoxCommand(dox: Dox, action: string) {
    switch (action) {
      case 'showAuthorizations':
        showDoxAuthorizations(dox)
        break
      case 'createNewDox':
        createNewDox(dox)
        break
      case 'createNewOrganizedDox':
        createNewOrganizedDox(dox)
        break
      case 'modifyDox':
        modifyDox(dox)
        break
      case 'moveDox':
        moveDox(dox)
        break
      case 'deleteDox':
        deleteDox(dox)
        break
      case 'showDoxHistory':
        showDoxHistory(dox)
        break
      case 'showDoxDetails':
        showDoxDetails(dox)
        break
      case 'downloadDox':
        downloadDox(dox)
        break
      case 'terminateRetention':
        terminateRetention(dox)
        break
      case 'deliverDox':
        deliverDox(dox)
        break
      case 'shareDox':
        shareDox(dox)
        break
    }
  }

  function showDoxAuthorizations(dox: Dox) {
    setAuthorizationsDialogOpen(true)
    setAuthorizationsDialogData({
      element: dox,
      onClose: (result) => {
        setAuthorizationsDialogOpen(false)
      },
    })
  }

  function createNewDox(parentDox: Dox) {
    if (!checkUserCanDo(ActionType.createDox, t(msgIds.MSG_DOX_COMMAND_CREATE_NEW_DOX))) return

    if (isOperator(authContext.loggedProfileType)) {
      // TODO: use hint to inform user of the possibility to create predefined dox structure
      createNewChildDox(parentDox)
    } else {
      createNewChildDox(parentDox)
    }
  }

  function createNewChildDox(parentDox: Dox) {
    let targetProfileId = isConsumer(authContext.loggedProfileType)
      ? authContext.loggedProfileId!
      : authContext.assistedAccountProfileId!
    const newDox = archiveContext.rwArchiveDoxes.createNewChildDox(parentDox, targetProfileId, t)

    setDoxEditorDialogOpen(true)
    setDoxEditorDialogData({
      dox: newDox,
      mode: 'new',
      onResult: (_result) => {
        setDoxEditorDialogOpen(false)
      },
    })
  }

  function createNewOrganizedDox(parentDox: Dox) {
    if (!authContext.loggedAccount?.canDo(ActionType.createDox)) return
    const templates =
      authContext.linkedStructureAccount?.profile?.fePreferences?.archiveCfg?.organizedDoxTemplates || []
    if (templates.length > 0) {
      setOrganizedDoxTemplateSelectorDialogOpen(true)
      setOrganizedDoxTemplateSelectorDialogData({
        onResult: async (result) => {
          setOrganizedDoxTemplateSelectorDialogOpen(false)
          if (result.userChoice !== 'yes') return
          console.log(result.template)
          await onCreateNewOrganizedDoxFromTemplate(parentDox, result.template)
        },
      })
    } else {
      setSimpleDialogOpen(true)
      setSimpleDialogData({
        title: t(msgIds.MSG_DOX_TEMPLATES_DIALOG_TITLE),
        content: t(msgIds.MSG_ORGANIZED_DOX_TEMPLATES_LIST_EMPTY),
        actionsStyle: 'Ok',
        onClose: (result) => {
          setSimpleDialogOpen(false)
        },
      })
    }
  }

  async function onCreateNewOrganizedDoxFromTemplate(parentDox: Dox, template: OrganizedDoxTemplate) {
    if (!authContext.linkedStructureProfileId) return

    try {
      let isDoxTemplateValidated = true
      const abortController = new AbortController()
      const treatments = await dalTreatment.getTreatments(
        abortController.signal,
        authContext.linkedStructureProfileId,
        true,
        true
      )
      for (const doxTemplate of template.doxTemplates) {
        if (!validateDoxTemplateTreatments(doxTemplate, treatments)) {
          isDoxTemplateValidated = false
          setSimpleDialogOpen(true)
          setSimpleDialogData({
            title: t(msgIds.MSG_ORGANIZED_DOX_ERROR_DIALOG_TITLE),
            content: t(msgIds.MSG_ORGANIZED_DOX_ERROR_DIALOG_BODY),
            actionsStyle: 'Ok',
            onClose: (result) => {
              setSimpleDialogOpen(false)
            },
          })
        }
      }

      if (isDoxTemplateValidated) {
        for (const doxTemplate of template.doxTemplates) {
          try {
            await archiveContext.rwArchiveDoxes.createOrganizedDoxFromTemplate(parentDox, doxTemplate, treatments)
          } catch (err) {
            Utils.enqueueSnackbarError2(err, t)
          }
        }
      }
    } catch (err) {
      Utils.enqueueSnackbarError2(err, t)
    }
  }

  function validateDoxTemplateTreatments(doxTemplate: DoxTemplate, treatments: Treatment[]) {
    if (doxTemplate.treatmentId && treatments.map((p) => p.id).findIndex((p) => p === doxTemplate.treatmentId) === -1) {
      return false
    }
    for (const childDoxTemplate of doxTemplate.children) {
      if (!validateDoxTemplateTreatments(childDoxTemplate, treatments)) {
        return false
      }
    }
    return true
  }

  function modifyDox(dox: Dox) {
    if (!checkUserCanDo(ActionType.updateDox, t(msgIds.MSG_DOX_COMMAND_MODIFY_DOX))) return

    setDoxEditorDialogOpen(true)
    setDoxEditorDialogData({
      dox: dox,
      mode: 'edit',
      onResult: (_result) => {
        setDoxEditorDialogOpen(false)
      },
    })
  }

  async function moveDox(dox: Dox) {
    try {
      if (!checkUserCanDo(ActionType.updateDox, t(msgIds.MSG_DOX_COMMAND_MOVE_DOX))) return

      const selectionMode =
        isBusiness(authContext.loggedProfileType) && dox.isRetentionEnabled
          ? DoxSelectorMode.selectSingleWithRetentionExceptSelfAndChilds
          : DoxSelectorMode.selectSingleExceptSelfAndChilds

      // select new parent dox
      setDoxSelectorDialogOpen(true)
      setDoxSelectorDialogData({
        selectionMode: selectionMode,
        assignableDoxes: archiveContext.rwArchiveDoxes,
        disabledDoxId: dox.id,
        selectedDoxIds: [],
        undeterminedDoxIds: [],
        onResult: async (result) => {
          try {
            setDoxSelectorDialogOpen(false)
            if (result.userChoice !== 'yes') return
            if (result.addedDoxIds.length === 0) return
            const oldParentDoxId = dox.parentId
            const newParentDoxId = result.addedDoxIds[0]
            if (isConsumer(authContext.loggedProfileType)) {
              // check authorization changes berofe move
              // TODO: use busy indicator with isLoading and abort command
              let lostedUnique: Authorization[] = []
              let gainedUnique: Authorization[] = []
              const abortController = new AbortController()
              const allLosted = await dalPermission.getDoxAuthorizations(abortController.signal, oldParentDoxId, [])
              const losted = allLosted.filter(
                (p) => p.profileId !== authContext.loggedProfileId && isStructure(p.dstIdentity?.profile?.type)
              )
              const allGained = await dalPermission.getDoxAuthorizations(abortController.signal, newParentDoxId, [])
              const gained = allGained.filter(
                (p) => p.profileId !== authContext.loggedProfileId && isStructure(p.dstIdentity?.profile?.type)
              )
              const commonIds = Utils.getArrayIntersection(
                losted.map((p) => p.profileId),
                gained.map((p) => p.profileId)
              )
              lostedUnique = losted.filter((p) => !commonIds.includes(p.profileId))
              gainedUnique = gained.filter((p) => !commonIds.includes(p.profileId))

              if (lostedUnique.length > 0 || gainedUnique.length > 0) {
                setAuthorizationsChangeDialogOpen(true)
                setChangeAuthorizationsDialogData({
                  action: 'moveDox',
                  doxId: dox.id,
                  oldParentDoxId: dox.parentId,
                  newParentDoxId: newParentDoxId,
                  lostedAuthorizations: lostedUnique,
                  gainedAuthorizations: gainedUnique,
                  onClose: async (result: any) => {
                    setAuthorizationsChangeDialogOpen(false)
                    if (result.userChoice !== 'yes') return

                    await setNewDoxParent(dox, newParentDoxId)
                  },
                })
              } else {
                await setNewDoxParent(dox, newParentDoxId)
              }
            } else {
              await setNewDoxParent(dox, newParentDoxId)
            }
          } catch (err) {
            Utils.enqueueSnackbarError2(err, t)
          }
        },
      })
    } catch (err) {
      Utils.enqueueSnackbarError2(err, t)
    }
  }

  async function setNewDoxParent(dox: Dox, newParentDoxId: number) {
    try {
      const abortController = new AbortController()
      await dalDox.moveDox(abortController.signal, dox.id, dox.name, newParentDoxId)
      dox.parentId = newParentDoxId
      archiveContext.rwArchiveDoxes.moveDox(dox)
    } catch (err) {
      Utils.enqueueSnackbarError2(err, t)
    }
  }

  async function deleteDox(dox: Dox) {
    if (!checkUserCanDo(ActionType.deleteDox, t(msgIds.MSG_DOX_COMMAND_DELETE_DOX))) return

    try {
      if (
        isConsumer(authContext.loggedProfileType) &&
        archiveContext.archiveType === ArchiveTypes.customerArchiveReceived
      ) {
        setSimpleDialogOpen(true)
        setSimpleDialogData({
          title: t(msgIds.MSG_DOX_DELETE_CONFIRM_TITLE),
          content: t(msgIds.MSG_DOX_DELETE_CONFIRM_ALERT2),
          actionsStyle: 'yesNO',
          onClose: async (result) => {
            setSimpleDialogOpen(false)
            if (result.userChoice === 'yes') {
              await doDeleteDox(dox)
            }
          },
        })
      } else {
        let lostedAuthorizations: Authorization[] = []
        if (isConsumer(authContext.loggedProfileType) && archiveContext.archiveType !== ArchiveTypes.guestArchive) {
          // check authorization changes berofe move
          // TODO: use busy indicator with isLoading and abort command
          const abortController = new AbortController()
          const allLosted = await dalPermission.getDoxAuthorizations(abortController.signal, dox.id, [])
          lostedAuthorizations = allLosted.filter(
            (p) => p.profileId !== authContext.loggedProfileId && isStructure(p.dstIdentity?.profile?.type)
          )
        }

        setAuthorizationsChangeDialogOpen(true)
        setChangeAuthorizationsDialogData({
          action: 'deleteDox',
          lostedAuthorizations: lostedAuthorizations,
          gainedAuthorizations: [],
          doxIdToDelete: dox.id,
          onClose: async (result) => {
            setAuthorizationsChangeDialogOpen(false)
            if (result.userChoice !== 'yes') return
            await doDeleteDox(dox)
          },
        })
      }
    } catch (err) {
      Utils.enqueueSnackbarError2(err, t)
    }
  }

  function showDoxHistory(dox: Dox) {
    navigate('/history', {
      state: {
        historyType: 'dox',
        doxId: dox.id,
      },
    })
  }

  async function doDeleteDox(dox: Dox) {
    try {
      const abortController = new AbortController()
      await dalDox.deleteDox(abortController.signal, dox.id)
      archiveContext.rwArchiveDoxes.removeDox(dox)
      if (
        archiveContext.archiveType === ArchiveTypes.customerArchiveReceived &&
        archiveContext.rwArchiveDoxes.distinct[0]
      ) {
        setDoxSelection([archiveContext.rwArchiveDoxes.distinct[0]])
      } else if (archiveContext.rwArchiveDoxes.roots.length === 0) {
        setDoxSelection([])
      }
      enqueueSnackbar(t(msgIds.MSG_DOX_DELETED_SUCCESSFULLY), { variant: 'success' })
    } catch (err) {
      Utils.enqueueSnackbarError2(err, t)
    }
  }

  function showDoxDetails(dox: Dox) {
    setDoxDetailsDialogOpen(true)
    setDoxDetailsDialogData({
      doxId: dox.id,
      onClose: (result) => {
        setDoxDetailsDialogOpen(false)
      },
    })
  }

  function downloadDox(dox: Dox) {
    const state = documentCacheContext
    if (!dox) {
      Utils.enqueueSnackbarError2(new Error('no dox'), t)
      return
    }
    const name = dox.name
    const download = downloadDoxContent({ state, dox, name })
    enqueueSnackbarDownload(download)
    setWaitingDownloads((waiting) => [...waiting, download.id])
  }

  function terminateRetention(dox: Dox) {
    if (!checkUserCanDo(ActionType.deleteDoxRetention, t(msgIds.MSG_DOX_COMMAND_TERMINATE_RETENTION))) return

    if (dox.retentionRules === RetentionRules.centralized) {
      setSimpleDialogOpen(true)
      setSimpleDialogData({
        title: t(msgIds.MSG_DOX_COMMAND_TERMINATE_RETENTION),
        content: t(msgIds.MSG_DOX_COMMAND_TERMINATE_RETENTION_CONFIRM),
        actionsStyle: 'yesNO',
        onClose: async (result) => {
          try {
            setSimpleDialogOpen(false)
            if (result.userChoice !== 'yes') return

            const abortController = new AbortController()
            const res = await dalDox.terminateDoxRetention(abortController.signal, dox.id)
            enqueueSnackbar(t(msgIds.MSG_RETENTION_TERMINATED_SUCCESFULLY), { variant: 'success' })
            // If after the operation the Dox has no more documents assigned is automatically eliminated
            // from the backndend therefore now check if the dox still exists
            if (res.doxDeleted) {
              archiveContext.rwArchiveDoxes.removeDox(dox)
            } else {
              dox.treatmentId = undefined
            }

            reloadDoxes()
          } catch (err) {
            Utils.enqueueSnackbarError2(err, t)
          }
        },
      })
    } else {
      setSimpleDialogOpen(true)
      setSimpleDialogData({
        title: t(msgIds.MSG_DOX_COMMAND_TERMINATE_RETENTION),
        content: t(msgIds.MSG_SPECIFIC_RETENTION_TERMINATION_NOT_POSSIBLE_FROM_DOX),
        actionsStyle: 'Ok',
        onClose: (result) => {
          setSimpleDialogOpen(false)
        },
      })
    }
  }

  function deliverDox(dox: Dox) {
    if (!checkUserCanDo(ActionType.deliverDox, t(msgIds.MSG_DOX_COMMAND_DELIVER_DOX))) return

    setSimpleDialogOpen(true)
    setSimpleDialogData({
      title: t(msgIds.MSG_DOX_COMMAND_DELIVER_DOX),
      content: t(msgIds.MSG_DOX_COMMAND_DELIVER_DOX_CONFIRM),
      actionsStyle: 'yesNO',
      onClose: async (result) => {
        try {
          setSimpleDialogOpen(false)
          if (result.userChoice !== 'yes') return

          const abortController = new AbortController()
          await dalDox.deliverDox(abortController.signal, dox.id)
          enqueueSnackbar(t(msgIds.MSG_DOX_DELIVERED_SUCCESSFULLY), { variant: 'success' })
        } catch (err) {
          Utils.enqueueSnackbarError2(err, t)
        }
      },
    })
  }

  function shareDox(dox: Dox) {
    // TODO: add permission check to share dox
    // if (!checkUserCanDo(ActionType.createDox, t(msgIds.MSG_DOX_COMMAND_CREATE_NEW_DOX))) return

    setDoxShareDialogOpen(true)
    setDoxShareDialogData({
      dox: dox,
      onClickExit: () => {},
    })
  }

  return (
    <Stack
      component="div"
      onMouseLeave={onMouseUpResizer}
      onMouseMove={onMouseMoveResizer}
      onMouseUp={onMouseUpResizer}
      sx={{
        cursor: mouseDragging ? 'grabbing' : undefined,
        width: '100%',
        maxWidth: '100%',
        height: '100%',
        maxHeight: '100%',
        overflow: 'hidden',
      }}
    >
      {simpleDialogData && <SimpleDialog {...simpleDialogData} isOpen={simpleDialogOpen}></SimpleDialog>}
      {doxEditorDialogData && (
        <DoxEditorDialog
          {...doxEditorDialogData}
          isOpen={doxEditorDialogOpen}
          onClose={() => setDoxEditorDialogOpen(false)}
        />
      )}
      {doxShareDialogData && (
        <StyledDialog open={doxShareDialogOpen} onClose={() => setDoxShareDialogOpen(false)} minHeight={100}>
          <DoxShareStepper {...doxShareDialogData} onClickExit={() => setDoxShareDialogOpen(false)} />
        </StyledDialog>
      )}
      {doxDetailsDialogData && <DoxDetailsDialog {...doxDetailsDialogData} isOpen={doxDetailsDialogOpen} />}
      {doxSelectorDialogData && (
        <DoxSelectorDialog
          {...doxSelectorDialogData}
          isOpen={doxSelectorDialogOpen}
          onClose={() => setDoxSelectorDialogOpen(false)}
        />
      )}
      {authorizationsDialogData && (
        <AuthorizationsDialog {...authorizationsDialogData} isOpen={authorizationsDialogOpen} />
      )}
      {changeAuthorizationsDialogData && (
        <AuthorizationsChangeDialog {...changeAuthorizationsDialogData} isOpen={authorizationsChangeDialogOpen} />
      )}
      {organizedDoxTemplateSelectorDialogData && (
        <OrganizedDoxTemplateSelectorDialog
          {...organizedDoxTemplateSelectorDialogData}
          isOpen={organizedDoxTemplateSelectorDialogOpen}
          onClose={() => setOrganizedDoxTemplateSelectorDialogOpen(false)}
        />
      )}
      {internalDocTemplateSelectorDialogData && (
        <InternalDocTemplateSelectorDialog
          {...internalDocTemplateSelectorDialogData}
          isOpen={internalDocTemplateSelectorDialogOpen}
          onClose={() => setInternalDocTemplateSelectorDialogOpen(false)}
        />
      )}

      <DialogTemplate
        isOpen={!!documentInEditing}
        title={t(msgIds.MSG_DOCUMENT)}
        isFullscreenForMobile={true}
        maxWidth="xl"
        p={0}
        m={0}
        canAbort={true}
        onClose={() => onCloseDocumentEditor()}
        showActions={false}
        actions={[]}
        dialogContentProps={{
          sx: {
            display: 'flex',
            flexDirection: 'column',
            height: '700px',
          },
        }}
      >
        {documentEditorState && (
          <DocumentEditor
            {...documentEditorState.documentEditorState}
            onCloseDocumentEditor={onCloseDocumentEditor}
            onEditedDocumentAssignedDoxChanged={onEditedDocumentAssignedDoxChanged}
            isDesktop={false}
          />
        )}
      </DialogTemplate>

      <CommandBar title={pageTitle} commands={commandBarCommands} />
      <Stack
        flex={1}
        direction="row"
        justifyContent="stretch"
        alignItems="stretch"
        padding={isMobile ? 0 : 1}
        paddingBottom={0}
        maxWidth="100%"
        maxHeight="100%"
        overflow="hidden"
      >
        {isMobile && (
          <SwipeableDrawer
            variant="temporary"
            open={isDoxMenuVisible}
            onOpen={() => isMobile && setIsDoxMenuVisible(true)}
            onClose={() => isMobile && setIsDoxMenuVisible(false)}
            ModalProps={{
              keepMounted: true,
            }}
            PaperProps={{
              sx: {
                width: '90%',
                height: '100%',
                top: 0,
              },
            }}
          >
            {doxesMenu}
          </SwipeableDrawer>
        )}
        {!isMobile && (
          <Paper ref={doxesMenuRef} sx={{ paddingBottom: 1, flexGrow: 0, flexShrink: 1, maxHeight: '100%' }}>
            {doxesMenu}
          </Paper>
        )}

        {!isMobile && (
          <Box
            sx={{
              flexShrink: 0,
              width: resizerWidth + 'px',
              height: '100px',
              paddingX: resizerPadding + 'px',
              userSelect: 'none',
              cursor: mouseDragging ? 'grabbing' : 'grab',
              alignSelf: 'center',
              display: 'flex',
            }}
            onMouseDown={onMouseDownResizer}
            onMouseUp={onMouseUpResizer}
          >
            <VerticalGrabberIco sx={{ fontSize: 70, color: theme.palette.common.gray4, alignSelf: 'flex-end' }} />
          </Box>
        )}

        <Paper sx={{ paddingBottom: 1, flexGrow: 1, flexShrink: 1, maxHeight: '100%' }}>
          <Stack height="100%" maxHeight="100%" justifyContent="stretch">
            <Stack direction="row" padding={1}>
              {isMobile && (
                <IconButton color="inherit" size="small" onClick={() => setIsDoxMenuVisible(!isDoxMenuVisible)}>
                  <DoxManageIco />
                </IconButton>
              )}
              <IconButton sx={{ flexShrink: 0 }} size="small" color="inherit" onClick={reloadDocuments}>
                <AutorenewIco />
              </IconButton>
              {isMobile && (
                <IconButton color="inherit" size="small" onClick={() => setShowDocumentsTableCheckbox((flag) => !flag)}>
                  <SelectedIco />
                </IconButton>
              )}
              <DocumentsTableFiltersBar filter={docsFilter} onChangeFilter={setDocsFilter} />
            </Stack>
            <Box position="relative" display="block" flexGrow={1}>
              <Box position="absolute" left={0} right={0} top={0} bottom={0}>
                <DocumentsTable
                  showCheckbox={showDocumentsTableCheckbox}
                  documents={documents}
                  selection={docSelection}
                  onChangeSelection={setDocSelection}
                  sortQuery={sortQuery}
                  onChangeSortQuery={setSortQuery}
                  onClickDocument={setDocumentInEditing}
                  onShowTriggerRow={onShowTriggerRow}
                  onDocumentCommand={onDocumentCommand}
                />
              </Box>
            </Box>
          </Stack>
        </Paper>
      </Stack>
    </Stack>
  )
}

function mapAccountsIds(accounts?: Account[]): number[] {
  if (!accounts) {
    return []
  }
  const ids: number[] = []
  for (const account of accounts) {
    if (account.profile?.profileId) {
      ids.push(account.profile.profileId)
    }
  }
  return ids
}

function loadPaginatedDocuments(abortSignal: AbortSignal, archiveType: ArchiveTypes, query: DocumentsQuery) {
  log.debug({ pageIndex: query.pageIndex, pageSize: query.pageSize, loadPaginatedDocuments: query })
  if (!query.targetProfileId) {
    log.error({ loadPaginatedDocuments: 'no targetProfileId', targetProfileId: query.targetProfileId })
    return Promise.reject(new Error('Missing targetProfileId'))
  }
  const sort = query.sortBy ? `${query.sortBy.field}:${query.sortBy.order}` : 'edited_at:desc'
  return dalDocument.getDocuments(
    abortSignal,
    query.pageIndex || 0,
    query.pageSize || defaultPageSize,
    sort,
    query.targetProfileId,
    query.onlyDrafts || false,
    archiveType === ArchiveTypes.structureArchiveSharedByCustomer || archiveType === ArchiveTypes.guestArchive,
    archiveType === ArchiveTypes.customerArchiveReceived ? 0 : undefined,
    undefined,
    query.from?.toISOString(),
    query.to?.toISOString(),
    mapAccountsIds(query.owners),
    mapAccountsIds(query.authors),
    query.showObsolete,
    query.doxIds,
    undefined,
    undefined
  )
}
