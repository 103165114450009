import { useState } from 'react'
import { Stack } from '@mui/material'
import { EditIco } from '../icons'
import { useTranslation } from 'react-i18next'
import msgIds from '../../locales/msgIds'
import DataEditorHeader from '../dataEditorHeader/DataEditorHeader'
import { IAccountEditorPresentationFormProps } from './AccountEditorPresentationForm.types'
import { AccountEditorPresentation } from './AccountEditorPresentation'

export function AccountEditorPresentationForm(props: IAccountEditorPresentationFormProps): JSX.Element {
  const { t } = useTranslation()
  const [isEditMode, setIsEditMode] = useState(false)

  return (
    <Stack paddingBottom={3} spacing={0}>
      <DataEditorHeader
        title={t(msgIds.MSG_PROFILE_EDITOR_PRESENTATION_TITLE)}
        isEditMode={isEditMode}
        viewModeCommands={[
          {
            commandText: '',
            icon: <EditIco />,
            onClick: () => setIsEditMode(true),
            tooltipText: t(msgIds.MSG_MODIFY) ?? undefined,
            disabled: false,
          },
        ]}
        editModeCommands={[]}
      />
      <AccountEditorPresentation
        account={props.account}
        isEditMode={isEditMode}
        disabled={false}
        onSave={() => {
          setIsEditMode(false)
        }}
        onCancel={() => {
          setIsEditMode(false)
        }}
      />
    </Stack>
  )
}
