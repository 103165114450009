import { useEffect, useState } from 'react'
import { IContractInfoEditorProps } from './ContractInfoEditor.types'
import { Checkbox, Divider, FormControlLabel, IconButton, Stack, TextField, Typography } from '@mui/material'
import msgIds from '../../locales/msgIds'
import * as dalContractVersion from '../../dal/DalContractVersion'
import { Utils, ValType } from '../../shared/Utils'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import { ViewActions } from '../viewActions/ViewActions'
import { ViewActionsButton } from '../viewActions/ViewActionsButton'
import { EditorFormContainer } from '../editorFormContainer/EditorFormContainer'
import { UpdateContractVersionMetadataArgs } from '../../dal/DalContractVersion'
import { ISimpleDialogData } from '../../dialogs/simpleDialog/SimpleDialog.types'
import SimpleDialog from '../../dialogs/simpleDialog/SimpleDialog'
import { InfoIco } from '../icons'

export default function ContractInfoEditor(props: IContractInfoEditorProps) {
  const { disabled, isEditMode, contractVersion, isAuthorizedToEdit, comparation } = props
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState<any>({})
  const [args, setArgs] = useState<UpdateContractVersionMetadataArgs>({})
  const [areUnsavedChanges, setAreUnsavedChanges] = useState(false)

  // dialogs
  const [simpleDialogData, setSimpleDialogData] = useState<ISimpleDialogData>()
  const [simpleDialogOpen, setSimpleDialogOpen] = useState(false)

  function initFields() {
    setArgs({
      notes: contractVersion.notes,
      isNewAcknowledgmentRequired: contractVersion.isNewAcknowledgmentRequired,
      avoidNewAcknowledgmentExplanation: contractVersion.avoidNewAcknowledgmentExplanation,
      areNewConsentsRequired: contractVersion.areNewConsentsRequired,
      avoidNewConsentsExplanation: contractVersion.avoidNewConsentsExplanation,
    })
  }

  useEffect(() => {
    initFields()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractVersion])

  useEffect(() => {
    const check =
      args?.notes !== contractVersion?.notes ||
      args?.isNewAcknowledgmentRequired !== contractVersion?.isNewAcknowledgmentRequired ||
      args?.avoidNewAcknowledgmentExplanation !== contractVersion?.avoidNewAcknowledgmentExplanation ||
      args?.areNewConsentsRequired !== contractVersion?.areNewConsentsRequired ||
      args?.avoidNewConsentsExplanation !== contractVersion?.avoidNewConsentsExplanation
    setAreUnsavedChanges(check)
  }, [args])

  const showIsNewAcknowledgmentRequiredInfo = () => {
    setSimpleDialogOpen(true)
    setSimpleDialogData({
      title: t(msgIds.MSG_CONTRACT_NEW_ACK_REQUIRED),
      content: t(msgIds.MSG_CONTRACT_NEW_ACK_REQUIRED_INFO),
      actionsStyle: 'Ok',
      onClose: async (result) => {
        setSimpleDialogOpen(false)
      },
    })
  }
  const showAreNewConsentsRequiredInfo = () => {
    setSimpleDialogOpen(true)
    setSimpleDialogData({
      title: t(msgIds.MSG_CONTRACT_NEW_CONSENTS_REQUIRED),
      content: t(msgIds.MSG_CONTRACT_NEW_CONSENTS_REQUIRED_INFO),
      actionsStyle: 'Ok',
      onClose: async (result) => {
        setSimpleDialogOpen(false)
      },
    })
  }

  function validateData(val: UpdateContractVersionMetadataArgs) {
    const errors = {
      avoidNewAcknowledgmentExplanation:
        comparation?.dataProcessingAddedFields?.length && !args.isNewAcknowledgmentRequired
          ? Utils.validateText(val.avoidNewAcknowledgmentExplanation, [
              { type: ValType.notNull },
              { type: ValType.notEmpty },
            ])
          : undefined,
      avoidNewConsentsExplanation:
        comparation?.consentsAddedFields?.length && !args.areNewConsentsRequired
          ? Utils.validateText(val.avoidNewConsentsExplanation, [
              { type: ValType.higherOrEqualThan, value1: 0 },
              { type: ValType.lowerOrEqualThan, value1: 60 },
            ])
          : undefined,
    }
    if (Object.values(errors).find((e) => !!e)) {
      return errors
    } else {
      return null
    }
  }

  async function onSave() {
    const contractId = contractVersion?.contractId
    if (!contractId || !args) return

    const errors = validateData(args)
    setErrors(errors || {})
    if (errors) {
      enqueueSnackbar(t(msgIds.MSG_VAL_ERR_THERE_ARE_FORM_ERRORS), { variant: 'error' })
      return
    }

    try {
      setIsLoading(true)
      const abortController = new AbortController()
      const updatedContractVersion = await dalContractVersion.updateContractVersionMetadata(
        abortController.signal,
        contractId,
        args
      )
      contractVersion.notes = updatedContractVersion.notes
      contractVersion.isNewAcknowledgmentRequired = updatedContractVersion.isNewAcknowledgmentRequired
      contractVersion.avoidNewAcknowledgmentExplanation = updatedContractVersion.avoidNewAcknowledgmentExplanation
      contractVersion.areNewConsentsRequired = updatedContractVersion.areNewConsentsRequired
      contractVersion.avoidNewConsentsExplanation = updatedContractVersion.avoidNewConsentsExplanation
      setAreUnsavedChanges(false)
      props.onSave && props.onSave()
    } catch (err) {
      Utils.enqueueSnackbarError2(err, t)
    } finally {
      setIsLoading(false)
    }
  }

  function onCancel() {
    initFields()
    setErrors({})
    props.onCancel && props.onCancel()
  }

  const isDisabled = disabled || !(isEditMode && isAuthorizedToEdit)

  return (
    <EditorFormContainer pt={0}>
      {simpleDialogData && <SimpleDialog {...simpleDialogData} isOpen={simpleDialogOpen}></SimpleDialog>}

      <Stack spacing={1}>
        <Typography variant="body2">{t(msgIds.MSG_CONTRACT_ANNOTATIONS_VISIBLE)}</Typography>
        <TextField
          disabled={isDisabled}
          label={t(msgIds.MSG_CONTRACT_NOTES_VERSION)}
          variant="outlined"
          multiline
          rows={3}
          value={args.notes || ''}
          onChange={(event) => setArgs({ ...args, notes: event.target.value })}
        />
      </Stack>

      <Stack>
        <Divider />
        <Typography mt={2} variant="body2">
          {t(msgIds.MSG_CONTRACT_INTERNAL_ANNOTATIONS)}
        </Typography>
      </Stack>

      <Stack>
        <FormControlLabel
          control={
            <Checkbox
              disabled={isDisabled}
              onChange={(event) => setArgs({ ...args, isNewAcknowledgmentRequired: event.target.checked })}
              checked={args?.isNewAcknowledgmentRequired || false}
            />
          }
          label={
            <>
              {t(msgIds.MSG_CONTRACT_NEW_ACK_REQUIRED)}{' '}
              <IconButton
                onClick={showIsNewAcknowledgmentRequiredInfo}
                sx={{ padding: 0, transform: 'translateY(-1px)' }}
                size="small"
              >
                <InfoIco />
              </IconButton>
            </>
          }
        />
        {!args.isNewAcknowledgmentRequired && (
          <TextField
            error={!!errors.avoidNewAcknowledgmentExplanation}
            helperText={t(errors.avoidNewAcknowledgmentExplanation)}
            disabled={isDisabled}
            label={t(msgIds.MSG_CONTRACT_WHY_NO_NEW_ACK)}
            variant="outlined"
            multiline
            rows={3}
            value={args.avoidNewAcknowledgmentExplanation || ''}
            onChange={(event) => setArgs({ ...args, avoidNewAcknowledgmentExplanation: event.target.value })}
          />
        )}
      </Stack>

      <Stack>
        <FormControlLabel
          control={
            <Checkbox
              disabled={isDisabled}
              onChange={(event) => setArgs({ ...args, areNewConsentsRequired: event.target.checked })}
              checked={args?.areNewConsentsRequired || false}
            />
          }
          label={
            <>
              {t(msgIds.MSG_CONTRACT_NEW_CONSENTS_REQUIRED)}{' '}
              <IconButton
                onClick={showAreNewConsentsRequiredInfo}
                sx={{ padding: 0, transform: 'translateY(-1px)' }}
                size="small"
              >
                <InfoIco />
              </IconButton>
            </>
          }
        />
        {!args.areNewConsentsRequired && (
          <TextField
            error={!!errors.avoidNewConsentsExplanation}
            helperText={t(errors.avoidNewConsentsExplanation)}
            disabled={isDisabled}
            label={t(msgIds.MSG_CONTRACT_WHY_NO_NEW_CONSENTS)}
            variant="outlined"
            multiline
            rows={3}
            value={args.avoidNewConsentsExplanation || ''}
            onChange={(event) => setArgs({ ...args, avoidNewConsentsExplanation: event.target.value })}
          />
        )}
      </Stack>

      {isEditMode && (
        <ViewActions justifyContent={'center'} sx={{ paddingTop: 6 }}>
          <ViewActionsButton autoFocus defaultAction onClick={onSave} disabled={!areUnsavedChanges}>
            {t(msgIds.MSG_SAVE)}
          </ViewActionsButton>
          <ViewActionsButton onClick={onCancel}>{t(msgIds.MSG_CANCEL)}</ViewActionsButton>
        </ViewActions>
      )}
    </EditorFormContainer>
  )
}
