import { useEffect, useMemo, useState } from 'react'
import { ContractVersion } from '../../models/ContractVersion'
import { ContractType, ProfileType, RegistrationPurpose } from '../../shared/Constants'
import {
  UseContractVersionViewer,
  useContractVersionViewerHook,
} from '../contractVersionViewer/ContractVersionViewerHook'
import { Utils } from '../../shared/Utils'
import { useTranslation } from 'react-i18next'
import * as dalContract from '../../dal/DalContract'
import { useAuthContext } from '../../contexts/AuthContext'

export type UseRegistrationFormArgs<T> = {
  initValue: T
  validate: (data: T) => any
}

export type UseRegistrationForm<T> = {
  errors: any
  setErrors: (errors: any) => void
  data: T
  setData: (data: T) => void
  onChangeData: (data: T, key: keyof T) => void
  ready: boolean
  validate: (data: T) => any
}

export function useRegistrationForm<T>({ initValue, validate }: UseRegistrationFormArgs<T>): UseRegistrationForm<T> {
  const [data, setData] = useState<T>(initValue)
  const [errors, setErrors] = useState<any>({})
  const ready = useMemo(() => {
    const errors = validate(data)
    return !errors || Object.keys(errors).length === 0
  }, [data, validate])

  function onChangeData(data: T, key: keyof T) {
    setErrors((errors: any) => {
      if (!errors) {
        return errors
      }
      const copy = { ...errors }
      delete copy[key]
      return copy
    })
    setData(data)
  }

  return {
    errors,
    setErrors,
    data,
    setData,
    onChangeData,
    ready,
    validate,
  }
}

export type UseContractRegistrationFormArgs = {
  profileType: ProfileType
  contractType: ContractType
  grantorProfileId?: number
  purpose?: RegistrationPurpose
}

export type UseContractRegistrationForm = UseRegistrationForm<ContractVersion | undefined> & {
  contractVersionViewer: UseContractVersionViewer
}

export type SectionsEditability = 'all' | 'allExceptMandatory' | 'allExceptMandatoryAccepted' | 'allExceptNotRevocable'

export function useContractRegistrationForm(args: UseContractRegistrationFormArgs): UseContractRegistrationForm {
  const { profileType, contractType, grantorProfileId, purpose } = args
  const { t } = useTranslation()
  const authContext = useAuthContext()
  const [contract, setContract] = useState<ContractVersion | undefined>()
  const [consentsHistory, setConsentsHistory] = useState<ContractVersion[] | undefined>()
  const contractState = useContractVersionViewerHook({
    isEditable: true,
    sectionsEditability: computeSectionsEditability(),
    disablePadding: true,
    contractType: ContractType.privacyPolicy,
    profileType: profileType,
    profileId: 0,
    structureProfileId: 0,
    targetProfileType: profileType,
    consentsSnapshot: contract,
    consentsHistory: consentsHistory,
  })
  const contractOwnerProfileId =
    purpose === RegistrationPurpose.placeholderRegistration ? authContext.linkedStructureProfileId || 0 : 0

  function computeSectionsEditability(): SectionsEditability {
    if (purpose && purpose === RegistrationPurpose.contractRevision) return 'allExceptNotRevocable'
    return 'all'
  }

  useEffect(() => {
    const abortController = new AbortController()
    const loadContract = async () => {
      try {
        const contracts = await dalContract.getPublishedContracts(abortController.signal, {
          targetProfileType: profileType,
          ownerProfileId: contractOwnerProfileId,
          getSections: true,
          ignoreSectionRead: false,
          contractType: contractType,
        })
        const contract = contracts[0]
        if (contract) {
          if (grantorProfileId) {
            const consentsHistory = await dalContract.getContractConsents(abortController.signal, {
              grantorProfileId: grantorProfileId,
              contractId: contract.id,
              getSectionsText: true,
            })
            const sortedConsentsHistory = consentsHistory.sort(ContractVersion.compareConsentsDateTime)
            const lastConsentsSnapshot = sortedConsentsHistory[0]
            setContract(lastConsentsSnapshot)
            setConsentsHistory(consentsHistory)
          } else {
            const publishedVersion = ContractVersion.sortByPublishedAt(contract.versions)[0]
            setContract(publishedVersion)
          }
        } else {
          setContract(undefined)
        }
      } catch (error) {
        Utils.enqueueSnackbarError2(error, t)
      }
    }
    loadContract()
    return () => abortController.abort()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileType, contractType, contractOwnerProfileId])

  return {
    errors: null,
    setErrors: () => {},
    data: contract,
    setData: setContract,
    onChangeData: (data) => setContract(data),
    ready: contractState.canSave,
    validate: () => (contractState.canSave ? null : {}),
    contractVersionViewer: contractState,
  }
}
