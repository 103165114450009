import React, { useReducer } from 'react'
import { Action } from '../shared/types/Action'
import { ArchiveDoxes } from '../shared/types/ArchiveDoxes'
import { ArchiveTypes } from '../models/ArchiveTypes'

export interface IContextState {
  // context props
  archiveType: ArchiveTypes
  rwArchiveDoxes: ArchiveDoxes
  roArchiveDoxes: ArchiveDoxes

  // context actions
  setArchiveType: (archiveType: ArchiveTypes) => void
  setRWArchiveDoxes: (archiveDoxes: ArchiveDoxes) => void
  setROArchiveDoxes: (archiveDoxes: ArchiveDoxes) => void
}

const initialState: IContextState = {
  // context props
  archiveType: ArchiveTypes.none,
  rwArchiveDoxes: new ArchiveDoxes(),
  roArchiveDoxes: new ArchiveDoxes(),

  // context actions
  setArchiveType: (archiveType: ArchiveTypes) => {},
  setRWArchiveDoxes: (archiveDoxes: ArchiveDoxes) => {},
  setROArchiveDoxes: (archiveDoxes: ArchiveDoxes) => {},
}

type Actions =
  | Action<'SET_ARCHIVE_TYPE', ArchiveTypes>
  | Action<'SET_RW_ARCHIVE_DOXES', ArchiveDoxes>
  | Action<'SET_RO_ARCHIVE_DOXES', ArchiveDoxes>

type ReducerFunc = (state: IContextState, action: Actions) => IContextState
function reducer(state: IContextState, action: Actions): IContextState {
  switch (action.type) {
    case 'SET_ARCHIVE_TYPE': {
      return !!action.payload ? { ...state, archiveType: action.payload } : { ...state, archiveType: ArchiveTypes.none }
    }
    case 'SET_RW_ARCHIVE_DOXES': {
      return !!action.payload ? { ...state, rwArchiveDoxes: action.payload } : { ...state }
    }
    case 'SET_RO_ARCHIVE_DOXES': {
      return !!action.payload ? { ...state, roArchiveDoxes: action.payload } : { ...state }
    }
  }
}

const injectActions = (dispatch: (arg: Actions) => void, state: IContextState) => {
  state.setArchiveType = (archiveType: ArchiveTypes) => dispatch({ type: 'SET_ARCHIVE_TYPE', payload: archiveType })
  state.setRWArchiveDoxes = (archiveDoxes: ArchiveDoxes) =>
    dispatch({ type: 'SET_RW_ARCHIVE_DOXES', payload: archiveDoxes })
  state.setROArchiveDoxes = (archiveDoxes: ArchiveDoxes) =>
    dispatch({ type: 'SET_RO_ARCHIVE_DOXES', payload: archiveDoxes })
}

interface IProps {
  children?: React.ReactNode
}

const ArchiveContext = React.createContext<IContextState>(initialState)
export const ArchiveContextProvider: React.FC<IProps> = ({ children }) => {
  const [state, dispatch] = useReducer<ReducerFunc>(reducer, initialState)
  injectActions(dispatch, state)

  return <ArchiveContext.Provider value={state}>{children}</ArchiveContext.Provider>
}

export const useArchiveContext = (): IContextState => React.useContext(ArchiveContext)
