import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  StackProps,
  Typography,
  useTheme,
} from '@mui/material'
import msgIds from '../../locales/msgIds'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import * as dalAccount from '../../dal/DalAccount'
import * as dalPermission from '../../dal/DalPermission'
import { AutorenewIco, CloseIco, InfoIco, SettingsIco } from '../icons'
import { useAuthContext } from '../../contexts/AuthContext'
import { Utils, ValType } from '../../shared/Utils'
import { InfoTooltip } from '../infoTooltip/InfoTooltip'
import { ViewActions } from '../viewActions/ViewActions'
import { ViewActionsButton } from '../viewActions/ViewActionsButton'
import { enqueueSnackbar } from 'notistack'
import { ViewContent } from '../viewContent/ViewContent'
import { IDoxShareProps } from './DoxShare.types'
import { Permission } from '../../models/Permission'
import { ActionType } from '../../shared/Constants'
import FromToDatePicker from '../fromToDatePicker/FromToDatePicker'
import SimpleDialog from '../../dialogs/simpleDialog/SimpleDialog'
import { ISimpleDialogData } from '../../dialogs/simpleDialog/SimpleDialog.types'

export type UpdateDoxShareArgs = {
  fromDate?: Date
  toDate?: Date
  email: string
  usePin: boolean
  downloadContents?: boolean
  editDocuments?: boolean
  editDox?: boolean
}

const unsetArgs = {
  fromDate: undefined,
  toDate: undefined,
  email: '',
  usePin: false,
  downloadContents: false,
  editDocuments: false,
  editDox: false,
}

export function DoxShare(props: IDoxShareProps & StackProps): JSX.Element {
  const { dox, onSetProfileInformations } = props
  const { t } = useTranslation()
  const theme = useTheme()
  const authContext = useAuthContext()
  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState<any>({})
  const [initialArgs, setInitialArgs] = useState<UpdateDoxShareArgs>(unsetArgs)
  const [currentArgs, setCurrentArgs] = useState<UpdateDoxShareArgs>(unsetArgs)
  const [permission, setPermission] = useState<Permission>()
  const [areUnsavedChanges, setAreUnsavedChanges] = useState(false)
  const [viewContents, setViewContents] = useState(true)
  const [emailOptions, setEmailOptions] = useState<string[]>([])
  const [emailOptionsNeedsReload, setEmailOptionsNeedsReload] = useState(true)
  const [newPin, setNewPin] = useState<string>()

  // dialogs
  const [simpleDialogData, setSimpleDialogData] = useState<ISimpleDialogData>()
  const [simpleDialogOpen, setSimpleDialogOpen] = useState(false)

  function aquirePermission(permission?: Permission) {
    setPermission(permission)
    if (permission) {
      const _downloadContents =
        permission.dependentActions.includes(ActionType.downloadDocuments) ||
        permission.dependentActions.includes(ActionType.downloadDox)
      const _editDocuments =
        permission.dependentActions.includes(ActionType.createDocuments) ||
        permission.dependentActions.includes(ActionType.updateDocuments) ||
        permission.dependentActions.includes(ActionType.archiveDocuments) ||
        permission.dependentActions.includes(ActionType.deleteDocuments)
      const _editDox =
        permission.dependentActions.includes(ActionType.createDox) ||
        permission.dependentActions.includes(ActionType.updateDox) ||
        permission.dependentActions.includes(ActionType.deleteDox)
      const _args = {
        fromDate: permission?.startAt ?? (Utils.today(false) as Date),
        toDate: permission?.endAt ?? (Utils.daysFromToday(7, false) as Date),
        email: permission?.emailTo ?? '',
        usePin: permission?.hasPin ?? false,
        downloadContents: _downloadContents,
        editDocuments: _editDocuments,
        editDox: _editDox,
      }
      setCurrentArgs(_args)
      setInitialArgs(_args)
    } else {
      const newArgs = {
        ...unsetArgs,
        fromDate: Utils.today() as Date,
        toDate: Utils.daysFromToday(7) as Date,
      }
      setCurrentArgs(newArgs)
      setInitialArgs(newArgs)
    }
  }

  useEffect(() => {
    const loadPermission = async (
      abortSignal: AbortSignal,
      structureProfileId: number,
      guestProfileId: number,
      doxId: number
    ) => {
      try {
        setIsLoading(true)
        const paginatedPermissions = await dalPermission.getCustomerGuestPermissionsByAction(
          abortSignal,
          0,
          50,
          structureProfileId,
          guestProfileId,
          `${ActionType.viewDox}:${doxId}`
        )
        const _permission = paginatedPermissions.rows[0]
        aquirePermission(_permission)
      } catch (err) {
        Utils.enqueueSnackbarError2(err, t)
      } finally {
        setIsLoading(false)
      }
    }

    const abortController = new AbortController()

    if (dox.id && authContext.linkedStructureProfileId && authContext.assistedAccountProfileId) {
      loadPermission(
        abortController.signal,
        authContext.linkedStructureProfileId,
        authContext.assistedAccountProfileId,
        dox.id
      )
    }

    return () => {
      abortController.abort()
    }
  }, [dox.id, authContext.linkedStructureProfileId, authContext.assistedAccountProfileId, t])

  useEffect(() => {
    const loadEmailOptions = async (abortSignal: AbortSignal, profileId: number) => {
      try {
        setIsLoading(true)
        const account = await dalAccount.getAccountFromProfileId(abortSignal, profileId, false, false)
        if (account) {
          const ownedProfileDetails = account.profileDetailsOwnedByOthers
          if (ownedProfileDetails) {
            const _emailOptions: string[] = []
            if (ownedProfileDetails.email1) {
              _emailOptions.push(ownedProfileDetails.email1)
            }
            if (ownedProfileDetails.email2) {
              _emailOptions.push(ownedProfileDetails.email2)
            }
            if (ownedProfileDetails.email3) {
              _emailOptions.push(ownedProfileDetails.email3)
            }
            if (ownedProfileDetails.email4) {
              _emailOptions.push(ownedProfileDetails.email4)
            }
            setEmailOptions(_emailOptions)
            setEmailOptionsNeedsReload(false)
          }
        }
      } catch (err) {
        Utils.enqueueSnackbarError2(err, t)
      } finally {
        setIsLoading(false)
      }
    }

    const abortController = new AbortController()

    if (authContext.assistedAccountProfileId) {
      loadEmailOptions(abortController.signal, authContext.assistedAccountProfileId)
    }

    return () => {
      abortController.abort()
    }
  }, [authContext.assistedAccountProfileId, emailOptionsNeedsReload, t])

  useEffect(() => {
    if (currentArgs) {
      if (permission) {
        if (
          initialArgs.fromDate !== currentArgs.fromDate ||
          initialArgs.toDate !== currentArgs.toDate ||
          initialArgs.email !== currentArgs.email ||
          initialArgs.usePin !== currentArgs.usePin ||
          initialArgs.downloadContents !== currentArgs.downloadContents ||
          initialArgs.editDocuments !== currentArgs.editDocuments ||
          initialArgs.editDox !== currentArgs.editDox
        ) {
          setAreUnsavedChanges(true)
        } else {
          setAreUnsavedChanges(false)
        }
      } else {
        setAreUnsavedChanges(true)
      }
    }
  }, [currentArgs])

  function onEmailChanged(email: string | null) {
    setCurrentArgs({ ...currentArgs, email: email || '' })
    setErrors({ ...errors, email: undefined })
  }

  function onFromDateChanged(date: Date | undefined) {
    setCurrentArgs({ ...currentArgs, fromDate: date })
    setErrors({ ...errors, fromDate: undefined })
  }

  function onToDateChanged(date: Date | undefined) {
    setCurrentArgs({ ...currentArgs, toDate: date })
    setErrors({ ...errors, toDate: undefined })
  }

  function onChangeProfileEmails() {
    onSetProfileInformations()
    setEmailOptionsNeedsReload(true)
  }

  function validateInput(): any | null {
    const errors = {
      email: Utils.validateEmail(currentArgs.email || '', [{ type: ValType.notNull }, { type: ValType.notEmpty }]),
      fromToDate: Utils.validateDates(currentArgs.fromDate?.toString(), currentArgs.toDate?.toISOString(), 'minor'),
    }
    if (Object.values(errors).find((e) => !!e)) {
      setErrors(errors)
      return errors
    } else {
      setErrors(null)
      return null
    }
  }

  function onSetPinUse(enabled: boolean) {
    if (enabled) {
      setCurrentArgs({ ...currentArgs, usePin: true })
      return
    } else {
      if (!permission?.hasPin) {
        setCurrentArgs({ ...currentArgs, usePin: false })
        return
      }
    }

    if (!permission) return

    setSimpleDialogOpen(true)
    setSimpleDialogData({
      title: t(msgIds.MSG_DOX_SHARE_REMOVE_PIN_CONFIRM_TITLE),
      content: t(msgIds.MSG_DOX_SHARE_REMOVE_PIN_CONFIRM_BODY),
      actionsStyle: 'yesNO',
      onClose: async (result2) => {
        setSimpleDialogOpen(false)
        if (result2.userChoice === 'yes') {
          setNewPin(undefined)
          setCurrentArgs({ ...currentArgs, usePin: false })
        }
      },
    })
  }

  async function onGeneratePin() {
    if (!permission) return

    try {
      setIsLoading(true)
      const abortController = new AbortController()
      const cmd = await dalPermission.setPermissionActions(
        abortController.signal,
        permission.id,
        currentArgs.email,
        true
      )
      setNewPin(cmd.pinAction?.pin)
      enqueueSnackbar(t(msgIds.MSG_OPERATION_EXECUTED_SUCCESSFULLY), { variant: 'success' })
    } catch (err) {
      Utils.enqueueSnackbarError2(err, t)
    } finally {
      setIsLoading(false)
    }
  }

  async function onSendInvitation() {
    if (!authContext.assistedAccountProfileId) return

    const errors = validateInput()
    setErrors(errors || {})
    if (errors) {
      enqueueSnackbar(t(msgIds.MSG_VAL_ERR_THERE_ARE_FORM_ERRORS), { variant: 'error' })
      return
    }

    if (!currentArgs.fromDate) return
    if (!currentArgs.toDate) return
    if (!currentArgs.email) return

    try {
      const abortController = new AbortController()
      let _permission = permission
      let dependendActions = collectDependentActions()
      if (!_permission) {
        _permission = await dalPermission.createPermission(abortController.signal, {
          profileId: authContext.assistedAccountProfileId,
          action: ActionType.viewDox,
          targetId: dox.id,
          startAt: currentArgs.fromDate,
          endAt: currentArgs.toDate,
          dependendActions: dependendActions,
        })
        aquirePermission({ ..._permission, hasPin: currentArgs.usePin })
      } else {
        _permission = await dalPermission.updatePermission(
          abortController.signal,
          _permission.id,
          currentArgs.fromDate,
          currentArgs.toDate,
          dependendActions
        )
        aquirePermission({ ..._permission, hasPin: currentArgs.usePin })
      }
      if (
        _permission &&
        (initialArgs.email !== currentArgs.email ||
          initialArgs.usePin !== currentArgs.usePin ||
          initialArgs.downloadContents !== currentArgs.downloadContents ||
          initialArgs.editDocuments !== currentArgs.editDocuments ||
          initialArgs.editDox !== currentArgs.editDox)
      ) {
        const cmd = await dalPermission.setPermissionActions(
          abortController.signal,
          _permission.id,
          currentArgs.email,
          currentArgs.usePin
        )
        aquirePermission({
          ..._permission,
          emailTo: cmd.emailAction?.sent ? cmd.emailAction?.address : undefined,
          hasPin: cmd.pinAction?.generated ?? false,
        })
        setNewPin(cmd.pinAction?.pin)
        enqueueSnackbar(t(msgIds.MSG_DOX_SHARE_INVITATION_SENDED), { variant: 'success' })
      }
    } catch (error) {
      Utils.enqueueSnackbarError2(error, t)
    }
  }

  function collectDependentActions() {
    const dependendPermissions: string[] = []
    if (viewContents) {
    }
    if (currentArgs.downloadContents) {
      dependendPermissions.push(ActionType.downloadDocuments)
      dependendPermissions.push(ActionType.downloadDox)
    }
    if (currentArgs.editDocuments) {
      dependendPermissions.push(ActionType.createDocuments)
      dependendPermissions.push(ActionType.updateDocuments)
      dependendPermissions.push(ActionType.archiveDocuments)
      dependendPermissions.push(ActionType.deleteDocuments)
    }
    if (currentArgs.editDox) {
      dependendPermissions.push(ActionType.createDox)
      dependendPermissions.push(ActionType.updateDox)
      dependendPermissions.push(ActionType.deleteDox)
    }
    return dependendPermissions
  }

  async function onUnshare() {
    if (!permission) return

    setSimpleDialogOpen(true)
    setSimpleDialogData({
      title: t(msgIds.MSG_DOX_SHARE_UNSHARE_CONFIRM_TITLE),
      content: t(msgIds.MSG_DOX_SHARE_UNSHARE_CONFIRM_BODY),
      actionsStyle: 'yesNO',
      onClose: async (result2) => {
        setSimpleDialogOpen(false)
        if (result2.userChoice === 'yes') {
          try {
            setIsLoading(true)
            const abortController = new AbortController()
            await dalPermission.deletePermission(abortController.signal, permission.id)
            setPermission(undefined)
            setCurrentArgs(unsetArgs)
            setNewPin(undefined)
            enqueueSnackbar(t(msgIds.MSG_OPERATION_EXECUTED_SUCCESSFULLY), { variant: 'success' })
            props.onClickExit()
          } catch (err) {
            Utils.enqueueSnackbarError2(err, t)
          } finally {
            setIsLoading(false)
          }
        }
      },
    })
  }

  function getPinDsc() {
    if (newPin) {
      return newPin
    } else if (permission?.hasPin) {
      return '******'
    } else {
      t(msgIds.MSG_DOX_SHARE_PIN_DISABLED)
    }
  }

  const stackProps: any = { ...props }
  delete stackProps.element
  delete stackProps.onClickExit

  function getPermissionStatusDsc() {
    if (permission) {
      if (permission.isExpired || permission.isSuspended) {
        return t(msgIds.MSG_DOX_SHARE_PERMISSION_NOT_ACTIVE)
      } else {
        if (permission.startAt <= new Date()) {
          return t(msgIds.MSG_DOX_SHARE_PERMISSION_ACTIVE_FOR_DAYS, {
            daysDifference: Utils.daysDifference(new Date(), permission?.endAt),
          })
        } else {
          return t(msgIds.MSG_DOX_SHARE_PERMISSION_FUTURE_FOR_DAYS, {
            daysDifference: Utils.daysDifference(permission.startAt, permission?.endAt),
          })
        }
      }
    } else {
      return ''
    }
  }

  return (
    <Stack alignItems="stretch" justifyContent="space-between" gap={2} marginY={3} {...stackProps}>
      {simpleDialogData && <SimpleDialog {...simpleDialogData} isOpen={simpleDialogOpen}></SimpleDialog>}
      <ViewContent id="dox-editor-view-content" spacing={2} alignItems="stretch">
        <Typography
          color={
            permission?.isExpired || permission?.isSuspended ? theme.palette.error.main : theme.palette.common.gray0
          }
        >
          {getPermissionStatusDsc()}
        </Typography>
        <FromToDatePicker
          justifyContent="space-between"
          fromDate={currentArgs.fromDate}
          onChangeFromDate={onFromDateChanged}
          toDate={currentArgs.toDate}
          onChangeToDate={onToDateChanged}
        />

        <Stack direction={'row'} alignItems="center" gap={1}>
          <Box sx={{ flexGrow: 1 }}>
            <FormControl fullWidth sx={{ marginTop: 2 }}>
              <InputLabel id="field-mail-label">{t(msgIds.MSG_EMAIL)}</InputLabel>
              <Select
                error={!!errors.email}
                labelId="field-mail-label"
                label={t(msgIds.MSG_EMAIL)}
                value={currentArgs.email}
                onChange={(event) => {
                  onEmailChanged(event.target.value)
                }}
                endAdornment={
                  currentArgs.email && (
                    <InputAdornment position="end" sx={{ marginRight: 4 }}>
                      <IconButton onClick={() => onEmailChanged('')} edge="end">
                        <CloseIco />
                      </IconButton>
                    </InputAdornment>
                  )
                }
              >
                {emailOptions.map((field) => (
                  <MenuItem key={field} value={field}>
                    {field}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <IconButton sx={{ flexShrink: 0, marginTop: 2 }} onClick={onChangeProfileEmails}>
            <SettingsIco />
          </IconButton>
        </Stack>

        <Stack direction={'row'} alignItems={'center'}>
          <FormControlLabel
            control={<Checkbox checked={currentArgs.usePin} onChange={(event) => onSetPinUse(event.target.checked)} />}
            label={t(msgIds.MSG_DOX_SHARE_USE_PIN)}
          />
          <InfoTooltip title={t(msgIds.MSG_DOX_SHARE_PIN_INFO)} placement="top" arrow>
            <Box>
              <InfoIco />
            </Box>
          </InfoTooltip>
        </Stack>

        {currentArgs.usePin && (
          <Box
            border={1}
            borderColor={theme.palette.common.gray6}
            borderRadius={2}
            padding={2}
            display="flex"
            alignItems="center"
            justifyContent="center"
            height={30}
          >
            <Stack width={'100%'} direction={'row'} alignItems={'center'}>
              {newPin ? (
                <Typography variant="h6" sx={{ flexGrow: 1 }}>
                  {newPin}
                </Typography>
              ) : (
                <Typography variant="body1" sx={{ flexGrow: 1 }}>
                  {getPinDsc()}
                </Typography>
              )}
              <Stack direction={'row'}>
                {permission?.hasPin && (
                  <InfoTooltip title={t(msgIds.MSG_DOX_SHARE_GENERATE_PIN)} placement="top" arrow>
                    <IconButton sx={{ flexShrink: 0 }} onClick={onGeneratePin}>
                      <AutorenewIco />
                    </IconButton>
                  </InfoTooltip>
                )}{' '}
              </Stack>
            </Stack>
          </Box>
        )}

        <Stack sx={{ overflowY: 'auto' }}>
          <Typography variant="body1">
            <strong>{t(msgIds.MSG_DOX_SHARE_AUTHORIZATIONS_TITLE)}</strong>
          </Typography>
          <Stack direction={'row'} alignItems={'center'}>
            <FormControlLabel
              disabled={true}
              control={<Checkbox checked={viewContents} onChange={(event) => setViewContents(event.target.checked)} />}
              label={t(msgIds.MSG_DOX_SHARE_AUTHORIZATIONS_VIEW)}
            />
            <InfoTooltip title={t(msgIds.MSG_DOX_SHARE_AUTHORIZATIONS_VIEW_INFO)} placement="top" arrow>
              <Box>
                <InfoIco />
              </Box>
            </InfoTooltip>
          </Stack>
          <Stack direction={'row'} alignItems={'center'}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={currentArgs.downloadContents}
                  onChange={(event) => setCurrentArgs({ ...currentArgs, downloadContents: event.target.checked })}
                />
              }
              label={t(msgIds.MSG_DOX_SHARE_AUTHORIZATIONS_DOWNLOAD)}
            />
            <InfoTooltip title={t(msgIds.MSG_DOX_SHARE_AUTHORIZATIONS_DOWNLOAD_INFO)} placement="top" arrow>
              <Box>
                <InfoIco />
              </Box>
            </InfoTooltip>
          </Stack>
          <Stack direction={'row'} alignItems={'center'}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={currentArgs.editDocuments}
                  onChange={(event) => setCurrentArgs({ ...currentArgs, editDocuments: event.target.checked })}
                />
              }
              label={t(msgIds.MSG_DOX_SHARE_AUTHORIZATIONS_EDIT_DOCUMENTS)}
            />
            <InfoTooltip title={t(msgIds.MSG_DOX_SHARE_AUTHORIZATIONS_EDIT_DOCUMENTS_INFO)} placement="top" arrow>
              <Box>
                <InfoIco />
              </Box>
            </InfoTooltip>
          </Stack>
          <Stack direction={'row'} alignItems={'center'}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={currentArgs.editDox}
                  onChange={(event) => setCurrentArgs({ ...currentArgs, editDox: event.target.checked })}
                />
              }
              label={t(msgIds.MSG_DOX_SHARE_AUTHORIZATIONS_EDIT_DOX)}
            />
            <InfoTooltip title={t(msgIds.MSG_DOX_SHARE_AUTHORIZATIONS_EDIT_DOX_INFO)} placement="top" arrow>
              <Box>
                <InfoIco />
              </Box>
            </InfoTooltip>
          </Stack>
        </Stack>
      </ViewContent>
      <ViewActions>
        <ViewActionsButton autoFocus defaultAction onClick={onSendInvitation} disabled={!areUnsavedChanges}>
          {t(msgIds.MSG_SEND)}
        </ViewActionsButton>
        {/* <ViewActionsButton onClick={onCopyLink} disabled={!permission}>
          {t(msgIds.MSG_COPY_LINK)}
        </ViewActionsButton> */}
        <ViewActionsButton onClick={onUnshare} disabled={!permission}>
          {t(msgIds.MSG_UNSHARE)}
        </ViewActionsButton>
        <ViewActionsButton onClick={props.onClickExit}>{t(msgIds.MSG_CLOSE)}</ViewActionsButton>
      </ViewActions>
    </Stack>
  )
}
