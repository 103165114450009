import { useTranslation } from 'react-i18next'
import { Box, FormGroup, Stack, TextField, Button, Checkbox, FormControlLabel } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import msgIds from '../../locales/msgIds'
import { useAuthContext } from '../../contexts/AuthContext'
import { DocumentRevision } from '../../models/DocumentRevision'
import { Utils } from '../../shared/Utils'
import { ArchiveItemSourceType, isOperator } from '../../shared/Constants'
import { Document } from '../../models/Document'
import { useEffect, useState } from 'react'

export interface IDocumentInfoForm {
  style?: React.CSSProperties
  errors: any
  document: Document
  revision: DocumentRevision
  onChangeDocument: (document: Document) => void
  onChangeRevision: (revision: DocumentRevision) => void
}

export default function DocumentInfoForm(props: IDocumentInfoForm): JSX.Element {
  const { t } = useTranslation()
  const authContext = useAuthContext()
  const { style, errors, document, revision, onChangeDocument, onChangeRevision } = props

  const isDefaultName = revision.name.startsWith('Nuovo documento')
  const [isNameEqualToFilename, setIsNameEqualToFilename] = useState(false)

  useEffect(() => {
    if (document.sourceType === ArchiveItemSourceType.externalSource) {
      const name = Utils.removeFileExtension(revision.filename)
      if (isNameEqualToFilename && revision.name !== name) {
        onChangeRevision({ ...revision, name })
      }
    }
  }, [revision])

  useEffect(() => {
    setIsNameEqualToFilename(document.sourceType === ArchiveItemSourceType.externalSource && isDefaultName)
  }, [document])

  const handleChange = <K extends keyof DocumentRevision>(field: K, val: DocumentRevision[K]) => {
    onChangeRevision({ ...revision, [field]: val })
  }

  const handleChangeIsAnonymous = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newDoc = new Document()
    Object.assign(newDoc, document)
    newDoc.anonymousAt = event.target.checked ? (Utils.today(true) as string) : ''
    onChangeDocument(newDoc)
  }

  return (
    <Box padding={2} style={style}>
      {revision && (
        <FormGroup sx={{ gap: 4 }}>
          <TextField
            label={t(msgIds.MSG_DOCUMENT_EDITOR_REVISION_NAME_LABEL)}
            value={revision.name || ''}
            onChange={(e) => {
              setIsNameEqualToFilename(false)
              handleChange('name', e.target.value)
            }}
            error={!!errors.name}
            helperText={t(errors.name)}
          />
          <DatePicker
            label={t(msgIds.MSG_DOCUMENT_EDITOR_REVISION_EDITED_AT_LABEL)}
            defaultValue={dayjs(new Date(revision.editedAt))}
            onChange={(value) => handleChange('editedAt', (value?.isValid() && value?.toDate()?.toISOString()) || '')}
            slotProps={{
              textField: {
                error: !!errors.editedAt,
                helperText: t(errors.editedAt),
              },
            }}
          />
          {document.sourceType === ArchiveItemSourceType.externalSource && (
            <Stack spacing={1} direction={'row'} display={'flex'}>
              <TextField
                sx={{ flexGrow: 1 }}
                label={t(msgIds.MSG_DOCUMENT_EDITOR_REVISION_CREATOR_IDENTITY_LABEL)}
                value={revision.creatorIdentity || ''}
                onChange={(e) => handleChange('creatorIdentity', e.target.value)}
                error={!!errors.creatorIdentity}
                helperText={t(errors.creatorIdentity)}
              />
              <Button
                variant="contained"
                sx={{ textTransform: 'none', alignSelf: 'flex-start', height: '55px' }}
                onClick={() => {
                  handleChange('creatorIdentity', authContext.loggedAccount?.getIdentityInverse() ?? '')
                }}
              >
                {t(msgIds.MSG_MYSELF)}
              </Button>
            </Stack>
          )}
          {isOperator(authContext.loggedProfileType) && (
            <FormControlLabel
              control={<Checkbox onChange={handleChangeIsAnonymous} checked={!!document.anonymousAt} />}
              label="Non contiene dati personali"
            />
          )}
        </FormGroup>
      )}
    </Box>
  )
}
