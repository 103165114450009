import { Stack } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import DataEditorHeader from '../dataEditorHeader/DataEditorHeader'
import msgIds from '../../locales/msgIds'
import { EditIco } from '../icons'
import { GeneralSettings } from './GeneralSettings'
import { IGeneralSettingsFormProps } from './GeneralSettingsForm.types'

export function GeneralSettingsForm(props: IGeneralSettingsFormProps): JSX.Element {
  const { t } = useTranslation()
  const [isEditMode, setIsEditMode] = useState(false)

  return (
    <Stack paddingBottom={3} spacing={0}>
      <DataEditorHeader
        title={t(msgIds.MSG_GENERAL_SETTINGS_TITLE)}
        isEditMode={isEditMode}
        viewModeCommands={[
          {
            commandText: '',
            icon: <EditIco />,
            onClick: () => setIsEditMode(true),
            tooltipText: t(msgIds.MSG_MODIFY) ?? undefined,
            disabled: false,
          },
        ]}
        editModeCommands={[]}
      />
      <GeneralSettings
        account={props.account}
        isEditMode={isEditMode}
        onSave={() => setIsEditMode(false)}
        onCancel={() => setIsEditMode(false)}
      />
    </Stack>
  )
}
