import { useTranslation } from 'react-i18next'
import { ChangeEvent, useEffect, useRef, useState } from 'react'
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  useTheme,
} from '@mui/material'
import * as dalAccount from '../../dal/DalAccount'
import msgIds from '../../locales/msgIds'
import { Center } from '../center/Center'
import { ProfileVisibility, isConsumer, isOperator } from '../../shared/Constants'
import { IAccountEditorPresentationProps } from './AccountEditorPresentation.types'
import { useLayout } from '../../hooks/Layout'
import { ViewActions } from '../viewActions/ViewActions'
import { ViewActionsButton } from '../viewActions/ViewActionsButton'
import { Utils } from '../../shared/Utils'
import { useAuthContext } from '../../contexts/AuthContext'
import { useSnackbar } from 'notistack'
import { useAccountEditorContext } from '../../contexts/AccountEditorContext'
import { EditorFormContainer } from '../editorFormContainer/EditorFormContainer'

const avatarImageSize = 100
const spacing = 4

export type IPresentationArgs = {
  defaultBusinessProfile?: number | null
  defaultConsumerProfile?: number | null
  visibility?: number
  alias?: string
  avatarImage?: string | null
  title?: string
}

export function AccountEditorPresentation(props: IAccountEditorPresentationProps): JSX.Element {
  const { account, disabled, isEditMode } = props
  const theme = useTheme()
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const accountEditorContext = useAccountEditorContext()
  const [isLoading, setIsLoading] = useState(false)
  const authContext = useAuthContext()
  const { isMobile } = useLayout()
  const inputFileRef = useRef<HTMLInputElement>(null)
  const [avatarFile, setAvatarFile] = useState<File | undefined>()
  const [args, setArgs] = useState<IPresentationArgs>({})
  const [areUnsavedChanges, setAreUnsavedChanges] = useState(false)

  const consumer = account?.profile ? isConsumer(account.profile.type) : false
  const operator = account?.profile ? isOperator(account.profile.type) : false

  function initFields() {
    setArgs({
      defaultConsumerProfile: account?.user?.defaultConsumerProfile,
      defaultBusinessProfile: account?.user?.defaultBusinessProfile,
      visibility: account?.profile?.visibility,
      alias: account?.profile?.alias,
      avatarImage: account?.profile?.avatarImage,
      title: account?.profile?.title,
    })
  }

  useEffect(() => {
    initFields()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account])

  useEffect(() => {
    const check =
      args?.defaultConsumerProfile !== account?.user?.defaultConsumerProfile ||
      args?.defaultBusinessProfile !== account?.user?.defaultBusinessProfile ||
      args?.visibility !== account?.profile?.visibility ||
      args?.alias !== account?.profile?.alias ||
      args?.avatarImage !== account?.profile?.avatarImage ||
      args?.title !== account?.profile?.title
    setAreUnsavedChanges(check)
  }, [args])

  useEffect(() => {
    if (!args || !avatarFile) {
      return
    }
    if (avatarFile === null) {
      setArgs({ ...args, avatarImage: null })
      return
    }

    const reader = new FileReader()
    reader.onload = function (event: ProgressEvent<FileReader>) {
      if (typeof event.target?.result === 'string') {
        setArgs({ ...args, avatarImage: event.target?.result as string })
      }
    }
    reader.readAsDataURL(avatarFile)

    return () => reader.abort()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [avatarFile])

  function onChangeAvatarFile(event: ChangeEvent<HTMLInputElement>) {
    if (!event.currentTarget.files || event.currentTarget.files.length === 0) {
      setAvatarFile(undefined)
      return
    }
    const file = event.currentTarget.files.item(0) || undefined
    setAvatarFile(file)
    event.target.value = '' // due to resolve this issue: https://github.com/ngokevin/react-file-reader-input/issues/11
  }

  const defaultProfile: boolean =
    account?.profile?.profileId === (consumer ? args.defaultConsumerProfile : args.defaultBusinessProfile)

  function onChangeDefaultProfile(isDefault: boolean) {
    if (consumer) {
      const profileId = isDefault ? account?.profile?.profileId : null
      setArgs({ ...args, defaultConsumerProfile: profileId })
    } else if (operator) {
      const profileId = isDefault ? account?.profile?.profileId : null
      setArgs({ ...args, defaultBusinessProfile: profileId })
    }
  }

  async function onUpdateAccount() {
    const profileId = account?.profile?.profileId
    const userId = account?.user?.userId
    if (!profileId || !userId) return

    try {
      setIsLoading(true)
      const abortController = new AbortController()
      const updatedAccount = await dalAccount.updateProfile(abortController.signal, profileId, { ...args })
      updatedAccount.linkedAccount = account.linkedAccount
      if (
        (isOperator(account.profile?.type) && args.defaultBusinessProfile !== account.user?.defaultBusinessProfile) ||
        (isConsumer(account.profile?.type) && args.defaultConsumerProfile !== account.user?.defaultConsumerProfile)
      ) {
        const updatedUser = await dalAccount.updateUser(abortController.signal, userId, { ...args })
        updatedAccount.user = updatedUser.user
      }

      if (updatedAccount.profile?.profileId === authContext.loggedProfileId) {
        authContext.setLoggedAccount(updatedAccount)
      }
      setAreUnsavedChanges(false)
      props.onSave && props.onSave()
    } catch (err) {
      enqueueSnackbar(t(Utils.getErrorMessageId(err)), { variant: 'error' })
    } finally {
      setIsLoading(false)
    }
  }

  function onCancel() {
    initFields()
    props.onCancel && props.onCancel()
  }

  return (
    <EditorFormContainer>
      <Center spacing={spacing}>
        {accountEditorContext.fieldsAcl.isVisible('avatar') && (
          <Avatar
            sx={{ width: avatarImageSize, height: avatarImageSize, border: `1px solid ${theme.palette.common.gray7}` }}
            alt={account?.getIdentityInverse()}
            src={args?.avatarImage || undefined}
            onClick={(e: React.MouseEvent<HTMLHeadingElement>) => {
              e.stopPropagation()
            }}
          >
            {account?.getInitials()}
          </Avatar>
        )}
        {isEditMode && (
          <Stack direction="row" spacing={spacing}>
            <Box id="avatar-file-input-label" component="label">
              <input
                ref={inputFileRef}
                style={{ display: 'none' }}
                type="file"
                accept=".jpg,.jpeg,.png,image/*"
                required
                onChange={onChangeAvatarFile}
              />
              <Button onClick={() => inputFileRef.current?.click()} variant="contained" color="primary">
                {t(msgIds.MSG_PROFILE_EDITOR_PRESENTATION_SELECT_IMAGE_BUTTON)}
              </Button>
            </Box>
            <Button onClick={() => setArgs({ ...args, avatarImage: null })} variant="contained" color="primary">
              {t(msgIds.MSG_PROFILE_EDITOR_PRESENTATION_REMOVE_IMAGE_BUTTON)}
            </Button>
          </Stack>
        )}
      </Center>

      {accountEditorContext.fieldsAcl.isVisible('profileDefault') && (
        <FormControlLabel
          disabled={disabled || !isEditMode}
          control={
            <Checkbox checked={defaultProfile} onChange={(event) => onChangeDefaultProfile(event.target.checked)} />
          }
          label={t(msgIds.MSG_PROFILE_EDITOR_PRESENTATION_DEFAULT_PROFILE_LABEL)}
        />
      )}

      {accountEditorContext.fieldsAcl.isVisible('profileVisibility') && (
        <FormControl fullWidth>
          <InputLabel id="profile-visibility-label">
            {t(msgIds.MSG_PROFILE_EDITOR_PRESENTATION_PROFILE_VISIBILITY_LABEL)}
          </InputLabel>
          <Select
            disabled={disabled || !isEditMode}
            labelId="profile-visibility-label"
            id="profile-visibility-select"
            value={'' + args?.visibility || ''}
            label={t(msgIds.MSG_PROFILE_EDITOR_PRESENTATION_PROFILE_VISIBILITY_LABEL)}
            onChange={(event) => setArgs({ ...args, visibility: parseInt(event.target.value as string) })}
          >
            <MenuItem value={ProfileVisibility.none}>
              {t(msgIds.MSG_PROFILE_EDITOR_PRESENTATION_PROFILE_VISIBILITY_NONE)}
            </MenuItem>
            <MenuItem value={ProfileVisibility.collaborators}>
              {t(msgIds.MSG_PROFILE_EDITOR_PRESENTATION_PROFILE_VISIBILITY_COLLABORATORS)}
            </MenuItem>
            <MenuItem value={ProfileVisibility.all}>
              {t(msgIds.MSG_PROFILE_EDITOR_PRESENTATION_PROFILE_VISIBILITY_ALL)}
            </MenuItem>
          </Select>
        </FormControl>
      )}

      {accountEditorContext.fieldsAcl.isVisible('alias') && (
        <TextField
          disabled={disabled || !isEditMode}
          label={t(msgIds.MSG_PROFILE_EDITOR_PRESENTATION_ALIAS_LABEL)}
          variant="outlined"
          value={args?.alias || ''}
          onChange={(event) => setArgs({ ...args, alias: event.target.value })}
        />
      )}

      {accountEditorContext.fieldsAcl.isVisible('title') && (
        <FormControl fullWidth>
          <InputLabel id="profile-title-label">{t(msgIds.MSG_PROFILE_EDITOR_PRESENTATION_TITLE_LABEL)}</InputLabel>
          <Select
            disabled={disabled || !isEditMode}
            labelId="profile-title-label"
            id="profile-title-select"
            value={args?.title || ''}
            label={t(msgIds.MSG_PROFILE_EDITOR_PRESENTATION_TITLE_LABEL)}
            onChange={(event) => setArgs({ ...args, title: event.target.value })}
          >
            <MenuItem value={'Arch'}>{t(msgIds.MSG_PROFILE_TITLE_ABBREVIATION_ARCH)}</MenuItem>
            <MenuItem value={'Avv'}>{t(msgIds.MSG_PROFILE_TITLE_ABBREVIATION_AVV)}</MenuItem>
            <MenuItem value={'Dr'}>{t(msgIds.MSG_PROFILE_TITLE_ABBREVIATION_DR)}</MenuItem>
            <MenuItem value={'Ing'}>{t(msgIds.MSG_PROFILE_TITLE_ABBREVIATION_ING)}</MenuItem>
            <MenuItem value={'Prof'}>{t(msgIds.MSG_PROFILE_TITLE_ABBREVIATION_PROF)}</MenuItem>
          </Select>
        </FormControl>
      )}

      {props.isEditMode && (
        <ViewActions justifyContent={'center'} sx={{ paddingTop: 6 }}>
          <ViewActionsButton autoFocus defaultAction onClick={() => onUpdateAccount()} disabled={!areUnsavedChanges}>
            {t(msgIds.MSG_SAVE)}
          </ViewActionsButton>
          <ViewActionsButton onClick={onCancel}>{t(msgIds.MSG_CANCEL)}</ViewActionsButton>
        </ViewActions>
      )}
    </EditorFormContainer>
  )
}
