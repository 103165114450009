import { useTranslation } from 'react-i18next'
import * as dalAuth from '../../dal/DalAuth'
import { EditorFormContainer } from '../editorFormContainer/EditorFormContainer'
import { Button, Link, Stack, Switch, Typography, useTheme } from '@mui/material'
import msgIds from '../../locales/msgIds'
import { Center } from '../center/Center'
import { useState } from 'react'
import { PasswordInput } from '../passwordInput/PasswordInput'
import { useAuthContext } from '../../contexts/AuthContext'
import { Utils } from '../../shared/Utils'
import { TotpSecret } from '../../models/TotpSecret'
import { ITotpEditorProps } from './TotpEditor.types'
import { ViewActions } from '../viewActions/ViewActions'
import { ViewActionsButton } from '../viewActions/ViewActionsButton'
import { useSnackbar } from 'notistack'
import { ITotpCheckDialogData } from '../../dialogs/totpCheckDialog/TotpCheckDialog.types'
import TotpCheckDialog from '../../dialogs/totpCheckDialog/TotpCheckDialog'

export function TotpEditor(props: ITotpEditorProps): JSX.Element {
  const { isEditMode } = props
  const { t } = useTranslation()
  const theme = useTheme()
  const { enqueueSnackbar } = useSnackbar()
  const authContext = useAuthContext()
  const [isLoading, setIsLoading] = useState(false)
  const [code, setCode] = useState<string>('')
  const [codeError, setCodeError] = useState<string | undefined>()
  const [totpEnabled, setTotpEnabled] = useState<boolean>(authContext.loggedAccount?.user?.has2fa || false)
  const [totpSecret, setTotpSecret] = useState<TotpSecret | undefined>()

  // dialogs
  const [totpCheckDialogData, setTotpCheckDialogData] = useState<ITotpCheckDialogData>()
  const [totpCheckDialogOpen, setTotpCheckDialogOpen] = useState(false)

  function reset() {
    setCode('')
  }

  async function onEnableTotp() {
    try {
      setIsLoading(true)
      const abortController = new AbortController()
      const totpSecret = await dalAuth.set2faState(abortController.signal, true)
      setTotpSecret(totpSecret)
      setTotpEnabled(true)
      enqueueSnackbar(t(msgIds.MSG_TOTP_EDITOR_SUCCESSFULLY_ENABLED), { variant: 'success' })
    } catch (error) {
      Utils.enqueueSnackbarError2(error, t)
    } finally {
      setIsLoading(false)
    }
  }

  function onDisableTotp() {
    setTotpCheckDialogOpen(true)
    setTotpCheckDialogData({
      text: t(msgIds.MSG_TOTP_EDITOR_DIALOG_DISABLE_INFO),
      onClose: async (result) => {
        setTotpCheckDialogOpen(false)
        if (result.userChoice !== 'yes') return
        try {
          setIsLoading(true)
          const abortController = new AbortController()
          await dalAuth.set2faState(abortController.signal, false)
          setTotpSecret(undefined)
          setTotpEnabled(false)
          enqueueSnackbar(t(msgIds.MSG_TOTP_EDITOR_SUCCESSFULLY_DISABLED), { variant: 'success' })
        } catch (error) {
          Utils.enqueueSnackbarError2(error, t)
        } finally {
          setIsLoading(false)
        }
      },
    })
  }

  async function onTryCode() {
    try {
      setIsLoading(true)
      const abortController = new AbortController()
      await dalAuth.checkTotp(abortController.signal, code)
      reset()
      enqueueSnackbar(t(msgIds.MSG_TOTP_EDITOR_VALID_CODE), { variant: 'success' })
    } catch (error) {
      Utils.enqueueSnackbarError2(error, t)
    } finally {
      setIsLoading(false)
    }
  }

  function onCancel() {
    reset()
    props.onCancel && props.onCancel()
  }

  return (
    <EditorFormContainer>
      {totpCheckDialogData && <TotpCheckDialog {...totpCheckDialogData} isOpen={totpCheckDialogOpen} />}
      <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
        <Typography>{t(msgIds.MSG_TOTP_EDITOR_ENABLE_LABEL)}</Typography>
        <Switch
          checked={totpEnabled}
          disabled={!isEditMode || isLoading}
          onChange={(_, checked) => (checked ? onEnableTotp() : onDisableTotp())}
        />
      </Stack>
      <Typography color={theme.palette.text.secondary}>{t(msgIds.MSG_TOTP_EDITOR_INFO)}</Typography>
      {isEditMode && (
        <Stack spacing={4}>
          <Center>
            {/* TODO creare il link al contenuto che spiega*/}
            <Link href="#">{t(msgIds.MSG_TOTP_EDITOR_GUIDE_LINK)}</Link>
          </Center>
          {totpEnabled && totpSecret && (
            <Stack>
              <Typography>{t(msgIds.MSG_TOTP_EDITOR_QRCODE_INFO)}</Typography>
              <Typography>{t(msgIds.MSG_TOTP_EDITOR_QRCODE_WARNING)}</Typography>
              <Stack direction="row" flexWrap="wrap" alignItems="center">
                <img src={totpSecret.qrcode} alt="QRCode" />
                <Stack>
                  <Typography>{t(msgIds.MSG_TOTP_EDITOR_QRCODE_CODE_LABEL)}</Typography>
                  <Typography fontWeight="600">{totpSecret.secret}</Typography>
                </Stack>
              </Stack>
            </Stack>
          )}
          <Stack spacing={1} alignSelf="stretch">
            <Typography color={theme.palette.text.secondary}>{t(msgIds.MSG_TOTP_EDITOR_CODE_INPUT_INFO)}</Typography>
            <Stack alignSelf="stretch" direction="row" spacing={2}>
              <PasswordInput
                error={!!codeError}
                sx={{ flexGrow: 1 }}
                id="totp-input"
                label={t(msgIds.MSG_TOTP_EDITOR_CODE_INPUT_LABEL)}
                value={code}
                onChange={(event) => setCode(event.target.value)}
              />
              <Button onClick={onTryCode} variant="contained" sx={{ textTransform: 'none' }}>
                {t(msgIds.MSG_TOTP_EDITOR_CODE_TRY_BUTTON)}
              </Button>
            </Stack>
          </Stack>
          <ViewActions justifyContent={'center'} sx={{ paddingTop: 6 }}>
            <ViewActionsButton onClick={onCancel}>{t(msgIds.MSG_CLOSE)}</ViewActionsButton>
          </ViewActions>
        </Stack>
      )}
    </EditorFormContainer>
  )
}
