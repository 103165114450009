import { useEffect, useState } from 'react'
import { ITreatmentDataRetentionEditorProps } from './TreatmentDataRetentionEditor.types'
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import msgIds from '../../locales/msgIds'
import {
  RetentionDuration,
  RetentionDurationTranslationMapPlural,
  RetentionEndBehavior,
  RetentionEndBehaviorTranslationMap,
} from '../../shared/Constants'
import * as dalTreatment from '../../dal/DalTreatment'
import { Utils, ValType } from '../../shared/Utils'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import { UpdateTreatmentArgs } from '../../dal/DalTreatment'
import { ViewActions } from '../viewActions/ViewActions'
import { ViewActionsButton } from '../viewActions/ViewActionsButton'
import { EditorFormContainer } from '../editorFormContainer/EditorFormContainer'

export default function TreatmentDataRetentionEditor(props: ITreatmentDataRetentionEditorProps) {
  const { treatment, disabled, isEditMode } = props
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState<any>({})
  const [args, setArgs] = useState<UpdateTreatmentArgs>({})
  const [areUnsavedChanges, setAreUnsavedChanges] = useState(false)
  const [isRetentionEnabled, setIsRetentionEnabled] = useState(false)
  const [isRetentionNotifyEnabled, setIsRetentionNotifyEnabled] = useState(false)

  const retentionDurationBases = Utils.enumToArray(RetentionDuration, t, RetentionDurationTranslationMapPlural).filter(
    (p) => p.value !== '0'
  )
  const retentionEndBehaviors = Utils.enumToArray(RetentionEndBehavior, t, RetentionEndBehaviorTranslationMap).filter(
    (p) => p.value !== '0'
  )

  function initFields() {
    setArgs({
      retentionDurationBase: treatment.retentionDurationBase,
      retentionDurationCount: treatment.retentionDurationCount,
      retentionNoticeDaysBeforeExpiration: treatment.retentionNoticeDaysBeforeExpiration,
      retentionEndBehavior: treatment.retentionEndBehavior,
    })
    const _isRetentionEnabled =
      treatment.retentionDurationBase !== RetentionDuration.none && treatment.retentionDurationCount > 0
    setIsRetentionEnabled(_isRetentionEnabled)
    const _isRetentionNotifyEnabled =
      !!treatment.retentionNoticeDaysBeforeExpiration && treatment.retentionNoticeDaysBeforeExpiration > 0
    setIsRetentionNotifyEnabled(_isRetentionNotifyEnabled)
  }

  useEffect(() => {
    initFields()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treatment])

  useEffect(() => {
    const check =
      args?.retentionDurationBase !== treatment?.retentionDurationBase ||
      args?.retentionDurationCount !== treatment?.retentionDurationCount ||
      args?.retentionNoticeDaysBeforeExpiration !== treatment?.retentionNoticeDaysBeforeExpiration ||
      args?.retentionEndBehavior !== treatment?.retentionEndBehavior
    setAreUnsavedChanges(check)
  }, [args])

  function validateData(val: UpdateTreatmentArgs) {
    const errors = {
      retentionDurationCount:
        isRetentionEnabled && val.retentionDurationBase !== RetentionDuration.none
          ? Utils.validateNumber(val.retentionDurationCount, [{ type: ValType.higherOrEqualThan, value1: 1 }])
          : undefined,
      retentionNoticeDaysBeforeExpiration: val.retentionNoticeDaysBeforeExpiration
        ? Utils.validateNumber(val.retentionNoticeDaysBeforeExpiration, [
            { type: ValType.higherOrEqualThan, value1: 0 },
            { type: ValType.lowerOrEqualThan, value1: 60 },
          ])
        : undefined,
    }
    if (Object.values(errors).find((e) => !!e)) {
      return errors
    } else {
      return null
    }
  }

  async function onSave() {
    const treatmentId = treatment?.id
    if (!treatmentId || !args) return

    const errors = validateData(args)
    setErrors(errors || {})
    if (errors) {
      enqueueSnackbar(t(msgIds.MSG_VAL_ERR_THERE_ARE_FORM_ERRORS), { variant: 'error' })
      return
    }

    try {
      setIsLoading(true)
      const abortController = new AbortController()
      const updatedTreatment = await dalTreatment.updateTreatment(abortController.signal, treatmentId, args)
      treatment.retentionDurationBase = updatedTreatment.retentionDurationBase
      treatment.retentionDurationCount = updatedTreatment.retentionDurationCount
      treatment.retentionNoticeDaysBeforeExpiration = updatedTreatment.retentionNoticeDaysBeforeExpiration
      treatment.retentionEndBehavior = updatedTreatment.retentionEndBehavior
      setAreUnsavedChanges(false)
      props.onSave && props.onSave()
    } catch (err) {
      Utils.enqueueSnackbarError2(err, t)
    } finally {
      setIsLoading(false)
    }
  }

  function onCancel() {
    initFields()
    setErrors({})
    props.onCancel && props.onCancel()
  }

  return (
    <EditorFormContainer>
      <Stack>
        <FormControlLabel
          disabled={disabled || !isEditMode}
          control={
            <Checkbox
              checked={isRetentionEnabled}
              onChange={(event) => {
                setIsRetentionEnabled(event.target.checked)
                if (event.target.checked) {
                  setArgs({ ...args, retentionDurationBase: RetentionDuration.year, retentionDurationCount: 1 })
                } else {
                  setArgs({
                    ...args,
                    retentionDurationBase: RetentionDuration.none,
                    retentionDurationCount: 0,
                    retentionNoticeDaysBeforeExpiration: undefined,
                    retentionEndBehavior: RetentionEndBehavior.none,
                  })
                }
              }}
            />
          }
          label={t(msgIds.MSG_TREATMENT_DATA_MAINTAINED)}
        />

        <Stack direction={'row'} spacing={2}>
          <FormControl fullWidth>
            <InputLabel id="customer-visibility-rules-label">{t(msgIds.MSG_TREATMENT_STORAGE_PERIOD)}</InputLabel>
            <Select
              disabled={disabled || !isEditMode}
              labelId="customer-visibility-rules-label"
              id="customer-visibility-rules-select"
              value={'' + args?.retentionDurationBase?.toString() || 0}
              label={t(msgIds.MSG_TREATMENT_STORAGE_PERIOD)}
              onChange={(event) => setArgs({ ...args, retentionDurationBase: parseInt(event.target.value as string) })}
            >
              {retentionDurationBases.map((item) => (
                <MenuItem
                  key={item.value}
                  value={item.value}
                  selected={item.value === args?.customerVisibilityRules?.toString()}
                >
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            error={!!errors.retentionDurationCount}
            helperText={t(errors.retentionDurationCount)}
            disabled={disabled || !isEditMode}
            label={t(msgIds.MSG_QUANTITY)}
            variant="outlined"
            value={args.retentionDurationCount || 0}
            onChange={(event) => setArgs({ ...args, retentionDurationCount: parseInt(event.target.value) })}
          />
        </Stack>
      </Stack>

      <Stack>
        <Typography variant="body2">{t(msgIds.MSG_TREATMENT_SETTINGS_AFFECT_DOX)}</Typography>

        <FormControlLabel
          disabled={disabled || !isEditMode}
          control={
            <Checkbox
              checked={!!args?.retentionNoticeDaysBeforeExpiration}
              onChange={(event) => {
                if (event.target.checked) {
                  setArgs({ ...args, retentionNoticeDaysBeforeExpiration: 30 })
                } else {
                  setArgs({ ...args, retentionNoticeDaysBeforeExpiration: 0 })
                }
              }}
            />
          }
          label={t(msgIds.MSG_TREATMENT_NOTIFY_END_OF_CONSERVATION)}
        />

        {!!args?.retentionNoticeDaysBeforeExpiration && (
          <TextField
            error={!!errors.retentionNoticeDaysBeforeExpiration}
            helperText={t(errors.retentionNoticeDaysBeforeExpiration)}
            disabled={disabled || !isEditMode}
            label={t(msgIds.MSG_DAYS_IN_ADVANCE)}
            variant="outlined"
            value={args.retentionNoticeDaysBeforeExpiration || 0}
            onChange={(event) =>
              setArgs({ ...args, retentionNoticeDaysBeforeExpiration: parseInt(event.target.value) })
            }
          />
        )}

        <FormControl sx={{ mt: 4 }}>
          <InputLabel id="retention-end-behavior-label">
            {t(msgIds.MSG_TREATMENT_ACTION_AT_CONSERVATION_END)}
          </InputLabel>
          <Select
            disabled={disabled || !isEditMode}
            labelId="retention-end-behavior-label"
            id="retention-end-behavior-select"
            value={'' + args?.retentionEndBehavior?.toString() || ''}
            label={t(msgIds.MSG_TREATMENT_ACTION_AT_CONSERVATION_END)}
            onChange={(event) => setArgs({ ...args, retentionEndBehavior: parseInt(event.target.value as string) })}
          >
            {retentionEndBehaviors.map((item) => (
              <MenuItem
                key={item.value}
                value={item.value}
                selected={item.value === args?.retentionEndBehavior?.toString()}
              >
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>

      {isEditMode && (
        <ViewActions justifyContent={'center'} sx={{ paddingTop: 6 }}>
          <ViewActionsButton autoFocus defaultAction onClick={onSave} disabled={!areUnsavedChanges}>
            {t(msgIds.MSG_SAVE)}
          </ViewActionsButton>
          <ViewActionsButton onClick={onCancel}>{t(msgIds.MSG_CANCEL)}</ViewActionsButton>
        </ViewActions>
      )}
    </EditorFormContainer>
  )
}
