import { PDX_API_URL, ProfileType } from '../shared/Constants'
import log from '../shared/Logger'
import { axiosInstance } from '../contexts/AuthContext'
import { IAccountJson, Account } from '../models/Account'
import { ITotpSecretJson, TotpSecret } from '../models/TotpSecret'

// ********************
// GET

// ********************
// POST

export function doLogin(
  abortSignal: AbortSignal,
  email: string,
  password: string,
  isBusiness: boolean
): Promise<Account> {
  const funcName = 'doLogin'
  let url = `${PDX_API_URL}/v1/auth`

  const parms = {
    email: email,
    password: password,
    is_business: isBusiness,
  }
  return axiosInstance
    .post(url, parms, {
      headers: {
        'Accept-Version': '1.1.x',
      },
      signal: abortSignal,
    })
    .then((resp) => {
      const reply = Account.deserialize(resp.data.account as IAccountJson)
      return reply
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

export function checkTotp(abortSignal: AbortSignal, totp: string): Promise<Account> {
  const funcName = 'checkTotp'
  let url = `${PDX_API_URL}/v1/auth/2fa`

  const parms = {
    token: totp,
  }
  return axiosInstance
    .post(url, parms, {
      headers: {
        'Accept-Version': '1.1.x',
      },
      signal: abortSignal,
    })
    .then((resp) => {
      const reply = Account.deserialize(resp.data.account as IAccountJson)
      return reply
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

export function authenticateGuest(
  abortSignal: AbortSignal,
  jwt: string
): Promise<{ account: Account; permissionRes: string }> {
  const funcName = 'authenticateGuest'
  let url = `${PDX_API_URL}/v1/auth`

  const parms = {}
  return axiosInstance
    .post(url, parms, {
      headers: {
        authorization: `Bearer ${jwt}`,
        'Accept-Version': '1.1.x',
      },
      signal: abortSignal,
    })
    .then((resp) => {
      const account = Account.deserialize(resp.data.account as IAccountJson)
      const permissionRes: string = resp.data.permission_res
      return {
        account,
        permissionRes,
      }
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

export function checkGuestPin(
  abortSignal: AbortSignal,
  pin: string
): Promise<{ account: Account; permissionRes: string }> {
  const funcName = 'checkGuestPin'
  let url = `${PDX_API_URL}/v1/auth/2fa`

  const parms = {
    token: pin,
  }
  return axiosInstance
    .post(url, parms, {
      headers: {
        'Accept-Version': '1.1.x',
      },
      signal: abortSignal,
    })
    .then((resp) => {
      const account = Account.deserialize(resp.data.account as IAccountJson)
      const permissionRes: string = resp.data.permission_res
      return {
        account,
        permissionRes,
      }
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

export function sendPasswordResetMail(abortSignal: AbortSignal, email: string) {
  const funcName = 'sendPasswordResetMail'
  let url = `${PDX_API_URL}/v1/auth/recovery`

  const parms = {
    email: email,
  }
  return axiosInstance
    .post(url, parms, {
      headers: {
        'Accept-Version': '1.0.x',
      },
      signal: abortSignal,
    })
    .then((resp) => {
      const reply = resp.data.email
      return reply
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

export function resetPassword(abortSignal: AbortSignal, password: string, jwt: string) {
  const funcName = 'resetPassword'
  let url = `${PDX_API_URL}/v1/auth/activate`

  const parms = {
    password: password,
  }
  return axiosInstance
    .post(url, parms, {
      headers: {
        authorization: `Bearer ${jwt}`,
        'Accept-Version': '1.0.x',
      },
      signal: abortSignal,
    })
    .then((resp) => {
      const email: string = resp.data.email
      return {
        email,
      }
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

export function activateUser(abortSignal: AbortSignal, jwt: string) {
  const funcName = 'activateUser'
  let url = `${PDX_API_URL}/v1/auth/activate`

  const parms = {}
  return axiosInstance
    .post(url, parms, {
      headers: {
        authorization: `Bearer ${jwt}`,
        'Accept-Version': '1.0.x',
      },
      signal: abortSignal,
    })
    .then((resp) => {
      const email: string = resp.data.email
      const profileType: ProfileType = resp.data.profile_type
      return {
        email,
        profileType,
      }
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

export function changeActiveProfile(abortSignal: AbortSignal, profileId: number) {
  const funcName = 'changeActiveProfile'
  let url = `${PDX_API_URL}/v1/auth`

  const parms = {
    active_profile_id: profileId,
  }
  return axiosInstance
    .post(url, parms, {
      headers: {
        'Accept-Version': '1.1.x',
      },
      signal: abortSignal,
    })
    .then((resp) => {
      const reply = Account.deserialize(resp.data.account as IAccountJson)
      return reply
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

// ********************
// PATCH

export function changeUserPassword(abortSignal: AbortSignal, userId: number, password: string) {
  const funcName = 'changeUserPassword'
  const url = `${PDX_API_URL}/v1/auth/${userId}`

  const parms = {
    password: password,
  }
  return axiosInstance
    .patch(url, parms, {
      headers: {
        'Accept-Version': '1.0.x',
      },
      signal: abortSignal,
    })
    .then((resp) => {
      const reply = Account.deserialize(resp.data as IAccountJson)
      return reply
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

export function set2faState(abortSignal: AbortSignal, has2fa: boolean) {
  const funcName = 'set2faState'
  const url = `${PDX_API_URL}/v1/auth/2fa`

  const parms = {
    enable: has2fa,
  }
  return axiosInstance
    .patch(url, parms, {
      headers: {
        'Accept-Version': '1.1.x',
      },
      signal: abortSignal,
    })
    .then((resp) => {
      const reply = TotpSecret.deserialize(resp.data as ITotpSecretJson)
      return reply
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

// ********************
// DELETE
