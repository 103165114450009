import { useTranslation } from 'react-i18next'
import msgIds from '../../locales/msgIds'
import { IconButton, InputAdornment, TextField, Tooltip, useTheme } from '@mui/material'
import { IAccountEditorContactsProps } from './AccountEditorContacts.types'
import { useEffect, useState } from 'react'
import { Utils } from '../../shared/Utils'
import { useLayout } from '../../hooks/Layout'
import { UpdateProfileArgs } from '../../dal/DalAccount'
import * as dalAccount from '../../dal/DalAccount'
import { ViewActions } from '../viewActions/ViewActions'
import { ViewActionsButton } from '../viewActions/ViewActionsButton'
import { EditorFormContainer } from '../editorFormContainer/EditorFormContainer'
import { useSnackbar } from 'notistack'
import { useAccountEditorContext } from '../../contexts/AccountEditorContext'
import { AsteriskIco } from '../icons'
import { AccountType, TreatedFieldName } from '../../shared/Constants'

export function AccountEditorContacts(props: IAccountEditorContactsProps): JSX.Element {
  const { account, detailsOwnerProfileId, disabled, isEditMode } = props
  const { t } = useTranslation()
  const theme = useTheme()
  const { enqueueSnackbar } = useSnackbar()
  const { isMobile } = useLayout()
  const accountEditorContext = useAccountEditorContext()
  const [errors, setErrors] = useState<any>({})
  const [args, setArgs] = useState<UpdateProfileArgs>({})
  const [areUnsavedChanges, setAreUnsavedChanges] = useState(false)
  const profileId = account.profile?.profileId

  function initFields() {
    const profileDetails =
      account.user?.accountType === AccountType.placeholderUser
        ? account.profileDetailsOwnedByOthers
        : account.profileDetailsOwnedBySelf
    setArgs({
      phone: profileDetails?.phone,
      mobilePhone1: profileDetails?.mobilePhone1,
      mobilePhone2: profileDetails?.mobilePhone2,
      email1: profileDetails?.email1,
      email2: profileDetails?.email2,
      email3: profileDetails?.email3,
      email4: profileDetails?.email4,
      website: profileDetails?.website,
    })
  }

  useEffect(() => {
    initFields()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account])

  useEffect(() => {
    const profileDetails =
      account.user?.accountType === AccountType.placeholderUser
        ? account.profileDetailsOwnedByOthers
        : account.profileDetailsOwnedBySelf
    const check =
      args?.phone !== profileDetails?.phone ||
      args?.mobilePhone1 !== profileDetails?.mobilePhone1 ||
      args?.mobilePhone2 !== profileDetails?.mobilePhone2 ||
      args?.email1 !== profileDetails?.email1 ||
      args?.email2 !== profileDetails?.email2 ||
      args?.email3 !== profileDetails?.email3 ||
      args?.email4 !== profileDetails?.email4 ||
      args?.website !== profileDetails?.website
    setAreUnsavedChanges(check)
  }, [args])

  function validateData(val: UpdateProfileArgs) {
    const errors = {
      phone:
        accountEditorContext.fieldsAcl.isVisible('phone') && val.phone
          ? Utils.validatePhoneNumber(val.phone || '', [])
          : undefined,
      mobilePhone1:
        accountEditorContext.fieldsAcl.isVisible('mobilePhone') && val.mobilePhone1
          ? Utils.validatePhoneNumber(val.mobilePhone1 || '', [])
          : undefined,
      mobilePhone2:
        accountEditorContext.fieldsAcl.isVisible('mobilePhone') && val.mobilePhone2
          ? Utils.validatePhoneNumber(val.mobilePhone2 || '', [])
          : undefined,
      email1:
        accountEditorContext.fieldsAcl.isVisible('email') && val.email1
          ? Utils.validateEmail(val.email1 || '', [])
          : undefined,
      email2:
        accountEditorContext.fieldsAcl.isVisible('email') && val.email2
          ? Utils.validateEmail(val.email2 || '', [])
          : undefined,
      email3:
        accountEditorContext.fieldsAcl.isVisible('email') && val.email3
          ? Utils.validateEmail(val.email3 || '', [])
          : undefined,
      email4:
        accountEditorContext.fieldsAcl.isVisible('email') && val.email4
          ? Utils.validateEmail(val.email4 || '', [])
          : undefined,
      website:
        accountEditorContext.fieldsAcl.isVisible('website') && val.website
          ? Utils.validateWebsiteUrl(val.website || '', [])
          : undefined,
    }
    if (Object.values(errors).find((e) => !!e)) {
      return errors
    } else {
      return null
    }
  }

  async function onUpdateProfile() {
    if (!profileId) {
      return
    }

    const errors = validateData(args)
    setErrors(errors || {})
    if (errors) {
      enqueueSnackbar(t(msgIds.MSG_VAL_ERR_THERE_ARE_FORM_ERRORS), { variant: 'error' })
      return
    }

    try {
      const abortController = new AbortController()
      const updatedAccount = await dalAccount.updateProfile(abortController.signal, profileId, { ...args })
      account.profileDetails = updatedAccount.profileDetails
      setAreUnsavedChanges(false)
      props.onSave && props.onSave()
    } catch (error) {
      Utils.enqueueSnackbarError2(error, t)
    }
  }

  function onCancel() {
    initFields()
    setErrors({})
    props.onCancel && props.onCancel()
  }

  function getAdornment(fieldName: string) {
    if (props.mandatoryFieldsNames?.includes(fieldName)) {
      return (
        <InputAdornment position="start">
          <Tooltip title={t(msgIds.MSG_MANDATORY)}>
            <AsteriskIco color="error" />
          </Tooltip>
        </InputAdornment>
      )
    } else if (props.optionalFieldsNames?.includes(fieldName)) {
      return (
        <InputAdornment position="start">
          <Tooltip title={t(msgIds.MSG_OPTIONAL2)}>
            <AsteriskIco color="warning" />
          </Tooltip>
        </InputAdornment>
      )
    }
  }

  return (
    <EditorFormContainer isInDialog={props.isInDialog}>
      {accountEditorContext.fieldsAcl.isVisible('phone') && (
        <TextField
          error={!!errors.phone}
          helperText={t(errors.phone)}
          disabled={!accountEditorContext.fieldsAcl.isEditable('phone') || disabled || !isEditMode}
          label={t(msgIds.MSG_TREATED_FIELDS_PHONE)}
          variant="outlined"
          value={args?.phone || ''}
          onChange={(event) => setArgs({ ...args, phone: event.target.value })}
          InputProps={{
            endAdornment: getAdornment(TreatedFieldName.phone),
          }}
        />
      )}
      {accountEditorContext.fieldsAcl.isVisible('mobilePhone') && (
        <TextField
          error={!!errors.mobilePhone1}
          helperText={t(errors.mobilePhone1)}
          disabled={!accountEditorContext.fieldsAcl.isEditable('mobilePhone') || disabled || !isEditMode}
          label={t(msgIds.MSG_PROFILE_EDITOR_CONTACTS_MOBILE_PHONE_1)}
          variant="outlined"
          value={args?.mobilePhone1 || ''}
          onChange={(event) => setArgs({ ...args, mobilePhone1: event.target.value })}
          InputProps={{
            endAdornment: getAdornment(TreatedFieldName.mobilePhone),
          }}
        />
      )}
      {accountEditorContext.fieldsAcl.isVisible('mobilePhone') && (
        <TextField
          error={!!errors.mobilePhone2}
          helperText={t(errors.mobilePhone2)}
          disabled={!accountEditorContext.fieldsAcl.isEditable('mobilePhone') || disabled || !isEditMode}
          label={t(msgIds.MSG_PROFILE_EDITOR_CONTACTS_MOBILE_PHONE_2)}
          variant="outlined"
          value={args?.mobilePhone2 || ''}
          onChange={(event) => setArgs({ ...args, mobilePhone2: event.target.value })}
          InputProps={{
            endAdornment: getAdornment(TreatedFieldName.mobilePhone),
          }}
        />
      )}
      {accountEditorContext.fieldsAcl.isVisible('email') && (
        <TextField
          error={!!errors.email1}
          helperText={t(errors.email1)}
          disabled={!accountEditorContext.fieldsAcl.isEditable('email') || disabled || !isEditMode}
          label={t(msgIds.MSG_PROFILE_EDITOR_CONTACTS_EMAIL_1)}
          variant="outlined"
          value={args?.email1 || ''}
          onChange={(event) => setArgs({ ...args, email1: event.target.value })}
          InputProps={{
            endAdornment: getAdornment(TreatedFieldName.email),
          }}
        />
      )}
      {accountEditorContext.fieldsAcl.isVisible('email') && (
        <TextField
          error={!!errors.email2}
          helperText={t(errors.email2)}
          disabled={!accountEditorContext.fieldsAcl.isEditable('email') || disabled || !isEditMode}
          label={t(msgIds.MSG_PROFILE_EDITOR_CONTACTS_EMAIL_2)}
          variant="outlined"
          value={args?.email2 || ''}
          onChange={(event) => setArgs({ ...args, email2: event.target.value })}
          InputProps={{
            endAdornment: getAdornment(TreatedFieldName.email),
          }}
        />
      )}
      {accountEditorContext.fieldsAcl.isVisible('email') && (
        <TextField
          error={!!errors.email3}
          helperText={t(errors.email3)}
          disabled={!accountEditorContext.fieldsAcl.isEditable('email') || disabled || !isEditMode}
          label={t(msgIds.MSG_PROFILE_EDITOR_CONTACTS_EMAIL_3)}
          variant="outlined"
          value={args?.email3 || ''}
          onChange={(event) => setArgs({ ...args, email3: event.target.value })}
          InputProps={{
            endAdornment: getAdornment(TreatedFieldName.email),
          }}
        />
      )}
      {accountEditorContext.fieldsAcl.isVisible('email') && (
        <TextField
          error={!!errors.email4}
          helperText={t(errors.email4)}
          disabled={!accountEditorContext.fieldsAcl.isEditable('email') || disabled || !isEditMode}
          label={t(msgIds.MSG_PROFILE_EDITOR_CONTACTS_EMAIL_4)}
          variant="outlined"
          value={args?.email4 || ''}
          onChange={(event) => setArgs({ ...args, email4: event.target.value })}
          InputProps={{
            endAdornment: getAdornment(TreatedFieldName.email),
          }}
        />
      )}
      {accountEditorContext.fieldsAcl.isVisible('website') && (
        <TextField
          error={!!errors.website}
          helperText={t(errors.website)}
          disabled={!accountEditorContext.fieldsAcl.isEditable('website') || disabled || !isEditMode}
          label={t(msgIds.MSG_PROFILE_EDITOR_CONTACTS_WEBSITE)}
          variant="outlined"
          value={args?.website || ''}
          onChange={(event) => setArgs({ ...args, website: event.target.value })}
        />
      )}
      {isEditMode && (
        <ViewActions justifyContent={'center'} sx={{ paddingTop: 6 }}>
          <ViewActionsButton autoFocus defaultAction onClick={onUpdateProfile} disabled={!areUnsavedChanges}>
            {t(msgIds.MSG_SAVE)}
          </ViewActionsButton>
          <ViewActionsButton onClick={onCancel}>{t(msgIds.MSG_CANCEL)}</ViewActionsButton>
        </ViewActions>
      )}
    </EditorFormContainer>
  )
}
