import { Alert } from '@mui/material'
import { EditorFormContainer } from '../editorFormContainer/EditorFormContainer'
import { useTranslation } from 'react-i18next'
import msgIds from '../../locales/msgIds'
import { useState } from 'react'
import { ViewActions } from '../viewActions/ViewActions'
import { ViewActionsButton } from '../viewActions/ViewActionsButton'
import { Utils } from '../../shared/Utils'
import { IPasswordEditorProps } from './PasswordEditor.types'
import * as dalAuth from '../../dal/DalAuth'
import { useAuthContext } from '../../contexts/AuthContext'
import { PasswordEditorFields } from './PasswordEditorFields'

export function PasswordEditor(props: IPasswordEditorProps): JSX.Element {
  const { isEditMode } = props
  const { t } = useTranslation()
  const { loggedUserId } = useAuthContext()
  const [password, setPassword] = useState<string>('')
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>('')
  const [errors, setErrors] = useState<any>({})
  const [isLoading, setIsLoading] = useState(false)

  function validate() {
    return Utils.validatePasswordConfirmation(password, passwordConfirmation)
  }

  function reset() {
    setPassword('')
    setPasswordConfirmation('')
    setErrors({})
  }

  async function onSave() {
    if (!loggedUserId) {
      return
    }
    const errors = validate()
    setErrors(errors || {})
    if (errors) {
      return
    }
    setIsLoading(true)
    const abortController = new AbortController()
    try {
      await dalAuth.changeUserPassword(abortController.signal, loggedUserId, password)
      reset()
      props.onSave && props.onSave()
    } catch (error) {
      Utils.enqueueSnackbarError2(error, t)
    } finally {
      setIsLoading(false)
    }
  }

  function onCancel() {
    reset()
    props.onCancel && props.onCancel()
  }

  return (
    <EditorFormContainer>
      <Alert severity="info" sx={{ whiteSpace: 'pre-line' }}>
        {t(msgIds.MSG_PASSWORD_EDITOR_INFO)}
      </Alert>
      {isEditMode && (
        <>
          <PasswordEditorFields
            errors={errors}
            password={password}
            passwordConfirmation={passwordConfirmation}
            onChangePassword={setPassword}
            onChangePasswordConfirmation={setPasswordConfirmation}
          />
          <ViewActions justifyContent={'center'} sx={{ paddingTop: 6 }}>
            <ViewActionsButton autoFocus defaultAction onClick={onSave} disabled={isLoading}>
              {t(msgIds.MSG_SAVE)}
            </ViewActionsButton>
            <ViewActionsButton onClick={onCancel}>{t(msgIds.MSG_CANCEL)}</ViewActionsButton>
          </ViewActions>
        </>
      )}
    </EditorFormContainer>
  )
}
