import { TextField, Typography } from '@mui/material'
import { EditorFormContainer } from '../editorFormContainer/EditorFormContainer'
import msgIds from '../../locales/msgIds'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { ViewActions } from '../viewActions/ViewActions'
import { ViewActionsButton } from '../viewActions/ViewActionsButton'
import { parseInt } from 'lodash'
import { UpdateProfileArgs } from '../../dal/DalAccount'
import * as dalAccount from '../../dal/DalAccount'
import { Utils, ValType } from '../../shared/Utils'
import { IConfigurationsAuthorizationsProps } from './ConfigurationsAuthorizations.types'
import { useSnackbar } from 'notistack'

export function ConfigurationsAuthorizations(props: IConfigurationsAuthorizationsProps): JSX.Element {
  const { account, disabled, isEditMode } = props
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const [args, setArgs] = useState<UpdateProfileArgs>({})
  const [areUnsavedChanges, setAreUnsavedChanges] = useState(false)
  const [errors, setErrors] = useState<any>({})
  const profileId = account.profile?.profileId

  function initFields() {
    const preferences = account.profile?.profilePreferences
    setArgs({
      preferences: {
        permissions: {
          defaultViewDurationDays: preferences?.permissions?.defaultViewDurationDays || 0,
        },
        notifications: {
          userPrivateMessage: preferences?.notifications?.userPrivateMessage || false,
          upcomingProfileSuspension: preferences?.notifications?.upcomingProfileSuspension || false,
          profileSuspension: preferences?.notifications?.profileSuspension || false,
          upcomingProfileDeletion: preferences?.notifications?.upcomingProfileDeletion || false,
          profileDeletionBySystem: preferences?.notifications?.profileDeletionBySystem || false,
          profileDeletionByOwner: preferences?.notifications?.profileDeletionByOwner || false,
          upcomingUserDeletion: preferences?.notifications?.upcomingUserDeletion || false,
          userDeletionBySystem: preferences?.notifications?.userDeletionBySystem || false,
          userDeletionByOwner: preferences?.notifications?.userDeletionByOwner || false,
          contractUpdated: preferences?.notifications?.contractUpdated || false,
          dossierDelivered: preferences?.notifications?.dossierDelivered || false,
          documentRevisionCreated: preferences?.notifications?.documentRevisionCreated || false,
          documentViewed: preferences?.notifications?.documentViewed || false,
          documentDownloaded: preferences?.notifications?.documentDownloaded || false,
          dataProcessingGranted: preferences?.notifications?.dataProcessingGranted || false,
          dataProcessingUpdated: preferences?.notifications?.dataProcessingUpdated || false,
          dataProcessingSuspended: preferences?.notifications?.dataProcessingSuspended || false,
          upcomingDataProcessingExpiration: preferences?.notifications?.upcomingDataProcessingExpiration || false,
          dataProcessingExpired: preferences?.notifications?.dataProcessingExpired || false,
          privacyPolicyUpdated: preferences?.notifications?.privacyPolicyUpdated || false,
          privacyPolicyConsentUpdated: preferences?.notifications?.privacyPolicyConsentUpdated || false,
          profileAttached: preferences?.notifications?.profileAttached || false,
          profileDeattached: preferences?.notifications?.profileDeattached || false,
          doxDelivered: preferences?.notifications?.doxDelivered || false,
          doxReceived: preferences?.notifications?.doxReceived || false,
          permissionViewUpdated: preferences?.notifications?.permissionViewUpdated || false,
        },
      },
    })
  }

  useEffect(() => {
    initFields()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account])

  useEffect(() => {
    const check =
      args?.preferences?.permissions?.defaultViewDurationDays !==
      account.profile?.profilePreferences?.permissions?.defaultViewDurationDays
    setAreUnsavedChanges(check)
  }, [args])

  function validateData(val: UpdateProfileArgs) {
    const errors = {
      defaultViewDurationDays: Utils.validateNumber(val.preferences?.permissions?.defaultViewDurationDays, [
        { type: ValType.notEmpty },
        { type: ValType.higherOrEqualThan, value1: 1 },
        { type: ValType.lowerOrEqualThan, value1: 355 },
      ]),
    }
    if (Object.values(errors).find((e) => !!e)) {
      return errors
    } else {
      return null
    }
  }

  async function onUpdateProfile() {
    if (!profileId) {
      return
    }

    const errors = validateData(args)
    setErrors(errors || {})
    if (errors) {
      enqueueSnackbar(t(msgIds.MSG_VAL_ERR_THERE_ARE_FORM_ERRORS), { variant: 'error' })
      return
    }

    try {
      const abortController = new AbortController()
      const updatedAccount = await dalAccount.updateProfile(abortController.signal, profileId, { ...args })
      account.profile = updatedAccount.profile
      setAreUnsavedChanges(false)
      props.onSave && props.onSave()
    } catch (error) {
      Utils.enqueueSnackbarError2(error, t)
    }
  }

  function onCancel() {
    initFields()
    setErrors({})
    props.onCancel && props.onCancel()
  }

  return (
    <EditorFormContainer>
      <Typography>{t(msgIds.MSG_CONFIGURATIONS_AUTH_EDITOR_INFO)}</Typography>
      <TextField
        error={!!errors.defaultViewDurationDays}
        helperText={t(errors.defaultViewDurationDays)}
        disabled={disabled || !isEditMode}
        label={t(msgIds.MSG_CONFIGURATIONS_AUTH_EDITOR_INPUT_LABEL)}
        variant="outlined"
        value={args?.preferences?.permissions?.defaultViewDurationDays || '1'}
        onChange={(event) => {
          const _args: UpdateProfileArgs = {
            ...args,
            preferences: {
              ...args.preferences,
              permissions: {
                ...args.preferences?.permissions,
                defaultViewDurationDays: parseInt(event.target.value),
              },
            },
          }
          setArgs(_args)
        }}
      />
      {isEditMode && (
        <ViewActions justifyContent={'center'} sx={{ paddingTop: 6 }}>
          <ViewActionsButton autoFocus defaultAction onClick={onUpdateProfile} disabled={!areUnsavedChanges}>
            {t(msgIds.MSG_SAVE)}
          </ViewActionsButton>
          <ViewActionsButton onClick={onCancel}>{t(msgIds.MSG_CANCEL)}</ViewActionsButton>
        </ViewActions>
      )}
    </EditorFormContainer>
  )
}
