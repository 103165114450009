import { Dox } from '../../models/Dox'
import { ProfileType, RetentionRules } from '../Constants'
import * as dalDox from '../../dal/DalDox'
import msgIds from '../../locales/msgIds'
import { Utils } from '../Utils'
import { DoxTemplate } from '../../models/DoxTemplate'
import { Treatment } from '../../models/Treatment'

export class ArchiveDoxes {
  static parentRouteSeparator: string = ' \u2022 '

  static containerName_CustomerDoxes: string = 'customerDoxes'
  static containerName_CustomerSharedDoxes: string = 'customerSharedDoxes'
  static containerName_CustomerReceivedDoxes: string = 'customerReceivedDoxes'
  static containerName_StructureDoxes: string = 'structureDoxes'

  root?: Dox
  roots: Dox[] = []
  plain: string[] = []
  distinct: Dox[] = []
  doxIds: number[] = []
  ownerProfileType: ProfileType = ProfileType.none

  constructor() {}

  public initializeWithRoots(roots: Dox[], ownerProfileType: ProfileType) {
    this.ownerProfileType = ownerProfileType
    this.root = roots.find((p) => p.isRoot)
    this.roots = [...roots]
    this.initialize(ownerProfileType)
  }

  public initializeWithRoot(root: Dox, ownerProfileType: ProfileType) {
    this.ownerProfileType = ownerProfileType
    this.root = root
    this.roots = [root]
    this.initialize(ownerProfileType)
  }

  initialize(ownerProfileType: ProfileType) {
    this.populateDistinct()
    this.rebuildHierarchy()
    this.populatePlain()
    this.distinct.forEach((p) => {
      p.ownerProfileType = ownerProfileType
      this.initRoutes(p)
    })
  }

  public deinitialize() {
    this.root = undefined
    this.roots = []
    this.plain = []
    this.distinct = []
  }

  populateDistinct() {
    const _distinct: Dox[] = []
    const collection: Dox[] = []
    this.roots.forEach((root) => {
      collection.push(root)
      this.populateChildrenDistinct(collection, root)
    })
    collection
      .sort((a, b) => a.name.localeCompare(b.name))
      .forEach((dox) => {
        _distinct.push(dox)
      })
    this.distinct = _distinct
  }
  populateChildrenDistinct(collection: Dox[], parent: Dox) {
    parent.children.forEach((child) => {
      collection.push(child)
      this.populateChildrenDistinct(collection, child)
    })
  }

  rebuildHierarchy() {
    for (let i = 0; i < this.roots.length; i++) {
      const root = this.roots[i]
      for (let u = 0; u < root.children.length; u++) {
        const dox = root.children[u]
        if (dox.parentId !== root.id) {
          const parent = this.getDox(dox.parentId)
          if (parent) {
            this.addToChildren(parent.children, dox)
            root.children.splice(u, 1)
            u--
          }
          // console.log(root.children)
        }
      }
    }
  }

  populatePlain() {
    this.plain = []
    this.roots.forEach((root) => {
      const plainName: string = root.name
      this.plain.push(plainName)
      this.populateChildrenPlain(this.plain, plainName, root)
    })
  }
  populateChildrenPlain(collection: string[], parentPlain: string, parent: Dox) {
    parent.children
      .sort((a, b) => a.name.localeCompare(b.name))
      .forEach((child) => {
        const plainName = `${parentPlain}${ArchiveDoxes.parentRouteSeparator}${child.name}`
        collection.push(plainName)
        this.populateChildrenPlain(collection, plainName, child)
      })
  }

  initRoutes(dox: Dox) {
    const routeDoxes: Dox[] = []
    let doxTmp: Dox | undefined = dox
    while (doxTmp && doxTmp?.isRoot === false) {
      doxTmp = this.getDox(doxTmp!.parentId)
      if (doxTmp) {
        if (doxTmp.parentId !== 0) {
          routeDoxes.splice(0, 0, doxTmp)
        }
      }
    }

    if (!!this.root) {
      dox.archiveName = this.root.name
      if (dox.isRoot) {
        dox.name = this.root.name
      }
    }

    dox.parentsRoute = this.getRoute(routeDoxes)

    if (dox.isRoot) {
      dox.parentsRouteWithRoot = ''
    } else {
      if (!dox.parentsRoute) {
        dox.parentsRouteWithRoot = dox.archiveName
      } else if (!dox.archiveName) {
        dox.parentsRouteWithRoot = dox.parentsRoute
      } else {
        dox.parentsRouteWithRoot = `${dox.archiveName}${ArchiveDoxes.parentRouteSeparator}${dox.parentsRoute}`
      }
    }
    // console.log(dox.name + ' ---- ' + dox.parentsRouteWithRoot)

    if (dox.isRoot) {
      dox.completeRoute = dox.name
    } else {
      if (!dox.archiveName) {
        if (!dox.parentsRoute) {
          dox.completeRoute = dox.name
        } else {
          dox.completeRoute = `${dox.parentsRoute}${ArchiveDoxes.parentRouteSeparator}${dox.name}`
        }
      } else {
        if (!dox.parentsRoute) {
          dox.completeRoute = `${dox.archiveName}${ArchiveDoxes.parentRouteSeparator}${dox.name}`
        } else {
          dox.completeRoute = `${dox.archiveName}${ArchiveDoxes.parentRouteSeparator}${dox.parentsRoute}${ArchiveDoxes.parentRouteSeparator}${dox.name}`
        }
      }
    }
  }

  public getDox(doxId: number): Dox | undefined {
    return this.distinct.find((p) => p.id === doxId)
  }

  addToChildren(children: Dox[], dox: Dox) {
    children.push(dox)
    children.sort((a, b) => a.name.localeCompare(b.name))
  }

  private getRoute(dox: Dox[]): string {
    const joinedString = dox.map((p) => p.name).join(ArchiveDoxes.parentRouteSeparator)
    return joinedString
  }

  public getCopy(): ArchiveDoxes {
    var doxes = new ArchiveDoxes()
    if (!!this.root) {
      var rootCopy = this.root.getCopy(true)
      doxes.initializeWithRoot(rootCopy, this.ownerProfileType)
    } else {
      if (this.roots) {
        var rootsCopy: Dox[] = []
        this.roots.forEach((root) => {
          rootsCopy.push(root.getCopy(true))
        })
        doxes.initializeWithRoots(rootsCopy, this.ownerProfileType)
      }
    }
    return doxes
  }

  public getDoxesMatchingWithIds(ids: number[], includeRoot: boolean): Dox[] {
    if (includeRoot) {
      return this.distinct.filter((p) => !!ids.find((o) => o === p.id))
    } else {
      return this.distinct.filter((p) => !p.isRoot && !!ids.find((o) => o === p.id))
    }
  }

  /**
   * Returns the dox corresponding to the leaf of the passed 'route'.
   * The route must be formatted as: dox1 * dox2 * doxn
   *
   * @param route
   * @param includeRoot the route also includes the name of the root dox. To be used only if the object has been initialized with the doxes of an entire diary
   */
  public getDoxIdFromRoute(route: string, includeRoot: boolean): number {
    const dox = this.getDoxFromRoute(route, includeRoot)
    return !!dox ? dox.id : 0
  }
  /**
   * Returns the dox corresponding to the leaf of the passed 'route'.
   * The route must be formatted as: dox1 * dox2 * doxn
   *
   * @param route
   * @param includeRoot the route also includes the name of the root dox. To be used only if the object has been initialized with the doxes of an entire diary
   */
  public getDoxFromRoute(route: string, includeRoot: boolean) {
    var dox: Dox | undefined
    if (route) {
      const doxNames: string[] = route.split(ArchiveDoxes.parentRouteSeparator).filter(Boolean)
      if (doxNames.length > 0) {
        if (includeRoot) {
          if (this.root) {
            if (doxNames.length === 1 && doxNames[0] === this.root.name) {
              // if the route consists only of the diary dox
              dox = this.root
            } else {
              dox = this.getDoxFromRouteParts(this.root, doxNames)
            }
          } else {
            throw new Error(
              'You cannot use "includeRoot" if the object has not been initialized with a diary structure'
            )
          }
        } else {
          for (let i = 0; i < this.roots.length; i++) {
            dox = this.getDoxFromRouteParts(this.roots[i], doxNames)
            if (dox) {
              break
            }
          }
        }
      }
    }
    return dox
  }

  getDoxFromRouteParts(parent: Dox, routeParts: string[]): Dox | undefined {
    const routePart: string = routeParts[0]
    if (parent.name === routePart) {
      if (routePart.length === 1) {
        // I matched all parts of the route
        return parent
      }
      parent.children.forEach((child) => {
        const remainingParts: string[] = [...routeParts]
        remainingParts.splice(0, 1)
        const dox = this.getDoxFromRouteParts(child, remainingParts)
        if (dox) {
          return dox
        }
      })
      return undefined
    } else {
      return undefined
    }
  }

  public getDoxIdsFromLeafToRoot(doxId: number, includeLeaf: boolean = false) {
    const ids: number[] = includeLeaf ? [doxId] : []
    let dox = this.getDox(doxId)
    let parentId = dox?.parentId
    while (parentId) {
      ids.push(parentId)
      dox = this.getDox(parentId)
      parentId = dox?.parentId
    }
    return ids
  }

  public addDox(dox: Dox) {
    const parent = this.getDox(dox.parentId)
    if (parent) {
      dox.ownerProfileType = this.ownerProfileType
      this.addToChildren(parent.children, dox)
      this.populatePlain()
      this.populateDistinct()
      this.initRoutes(dox)
    }
  }

  public removeDox(dox: Dox) {
    const parent = this.getDox(dox.parentId)
    if (parent) {
      const index = parent.children.findIndex((p) => p.id === dox.id)
      if (index !== -1) {
        parent.children.splice(index, 1)
      }
    } else {
      const index = this.roots.findIndex((p) => p.id === dox.id)
      if (index !== -1) {
        this.roots.splice(index, 1)
      }
    }
    this.populatePlain()
    this.populateDistinct()
  }

  public moveDox(dox: Dox) {
    const oldParent = this.distinct.find((p) => p.children.findIndex((o) => o.id === dox.id) !== -1)
    if (oldParent) {
      const index = oldParent.children.findIndex((p) => p.id === dox.id)
      if (index !== -1) {
        oldParent.children.splice(index, 1)
      }
    } else {
      const index = this.roots.findIndex((p) => p.id === dox.id)
      if (index !== -1) {
        this.roots.splice(index, 1)
      }
    }

    const newParent = this.getDox(dox.parentId)
    if (newParent) {
      const _dox = this.getDox(dox.id) ?? dox // GetDox is used to make sure you retrieve the dox instance present in the tree
      this.addToChildren(newParent.children, _dox)
    }

    this.populatePlain()
    this.populateDistinct()
  }

  public getHierarchyFromPlainFromPlainList(list: Dox[], rootId: number) {
    const subtree: Dox[] = []
    list.forEach((dox) => {
      if (dox.parentId === rootId) {
        this.addToChildren(subtree, dox)
      } else {
        const first = list.find((p) => p.id === dox.parentId)
        if (first) {
          this.addToChildren(first.children, dox)
        }
      }
    })
  }

  public createNewChildDox(parentDox: Dox, targetProfileId: number, t: any) {
    const newDox = new Dox()
    const brotherNames = this.getDox(parentDox.id)!.children.map((p) => p.name)
    newDox.name = Utils.incrementProgressive(brotherNames, t(msgIds.MSG_DOX_EDITOR_NEW_DOX_NAME))
    newDox.parentId = parentDox.id
    newDox.children = []
    if (parentDox.treatmentId) {
      newDox.treatmentId = parentDox.treatmentId
      newDox.retentionInheritedFrom = parentDox.retentionInheritedFrom ? parentDox.retentionInheritedFrom : parentDox.id
    }
    this.initRoutes(newDox)
    newDox.targetProfileId = targetProfileId
    return newDox
  }

  public async createOrganizedDoxFromTemplate(parentDox: Dox, doxTemplate: DoxTemplate, treatments: Treatment[]) {
    let newDox = new Dox()
    newDox.name = doxTemplate.name
    const names = parentDox.children.map((p) => p.name)
    if (names.some((p) => p === newDox.name)) {
      newDox.name = Utils.getUniqueStr(names, newDox.name)
    }
    newDox.parentId = parentDox.id
    newDox.targetProfileId = parentDox.targetProfileId
    newDox.treatmentId = doxTemplate.treatmentId
    newDox.retentionRules = doxTemplate.retentionRules
    if (doxTemplate.retentionRules === RetentionRules.centralized) {
      newDox.retentionStartAt = Utils.today() as Date
      if (doxTemplate.automaticallySetEndRetentionDate) {
        const treatment = treatments.find((p) => p.id === newDox.treatmentId)
        newDox.retentionEndAt = treatment?.computeRetentionEndFrom(newDox.retentionStartAt) as Date
      } else {
        newDox.retentionEndAt = undefined
      }
    }

    const abortController = new AbortController()
    newDox = await dalDox.createDox(
      abortController.signal,
      newDox.name,
      newDox.notes,
      newDox.targetProfileId,
      newDox.parentId,
      newDox.treatmentId,
      newDox.retentionRules,
      newDox.retentionStartAt,
      newDox.retentionEndAt
    )
    this.addDox(newDox)

    for (const childDoxTemplate of doxTemplate.children) {
      await this.createOrganizedDoxFromTemplate(newDox, childDoxTemplate, treatments)
    }
  }

  // ********************
  // DAL helpers

  async getDoxesForGuestSharedByStructure(
    abortSignal: AbortSignal,
    guestProfileId: number,
    grantorProfileId: number,
    t: any,
    msgIds: any
  ) {
    this.deinitialize()
    const paginatedDoxRoots = await dalDox.getDoxesForGuestSharedByStructure(
      abortSignal,
      guestProfileId,
      grantorProfileId
    )
    const roots = paginatedDoxRoots.rows
    roots.forEach((root) => {
      if (root.isRoot) {
        root.name = t(msgIds.MSG_CUSTOMER_ARCHIVE_ROOT_NAME)
      }
    })
    this.initializeWithRoots(roots, ProfileType.customer)
  }

  async getDoxesForCustomerOwnedByStructure(
    abortSignal: AbortSignal,
    assistedAccountProfileId: number,
    t: any,
    msgIds: any
  ) {
    this.deinitialize()
    const paginatedDoxRoots = await dalDox.getDoxesForCustomerOwnedByStructure(abortSignal, assistedAccountProfileId)
    const root = paginatedDoxRoots.rows[0]
    root.name = t(msgIds.MSG_COMPANY_ARCHIVE_ROOT_NAME)
    this.initializeWithRoot(root, ProfileType.structure)
  }

  async getDoxesOfCustomerSharedWithStructure(
    abortSignal: AbortSignal,
    assistedAccountProfileId: number,
    t: any,
    msgIds: any
  ) {
    this.deinitialize()
    const paginatedDoxRoots = await dalDox.getDoxesOfCustomerSharedWithStructure(abortSignal, assistedAccountProfileId)
    const roots = paginatedDoxRoots.rows
    roots.forEach((root) => {
      if (root.isRoot) {
        root.name = t(msgIds.MSG_CUSTOMER_ARCHIVE_ROOT_NAME)
      }
    })
    this.initializeWithRoots(roots, ProfileType.customer)
  }

  async getDoxesOwned(abortSignal: AbortSignal, t: any, msgIds: any) {
    this.deinitialize()
    const paginatedDoxRoots = await dalDox.getDoxesOwned(abortSignal, false)
    const root = paginatedDoxRoots.rows[0]
    root.name = t(msgIds.MSG_PERSONAL_ARCHIVE_ROOT_NAME)
    this.initializeWithRoot(root, ProfileType.customer)
  }

  async getDoxesReceived(abortSignal: AbortSignal, t: any, msgIds: any) {
    this.deinitialize()
    const paginatedDoxRoots = await dalDox.getDoxesOwned(abortSignal, true)
    const roots = paginatedDoxRoots.rows
    roots.forEach((root) => {
      if (root.isRoot) {
        root.name = t(msgIds.MSG_CUSTOMER_ARCHIVE_ROOT_NAME)
      }
    })
    this.initializeWithRoots(roots, ProfileType.customer)
  }
}
