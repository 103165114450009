import React, { useReducer } from 'react'
import { isPersonalDoxMimetype } from '../shared/Constants'
import { Action } from '../shared/types/Action'
import { Document } from '../models/Document'
import * as dalDocument from '../dal/DalDocument'
import * as dalDox from '../dal/DalDox'
import { DocumentRevision } from '../models/DocumentRevision'
import log from '../shared/Logger'
import { closeSnackbar, enqueueSnackbar } from 'notistack'
import { DownloaderSnackbarAction } from '../components/documentDownloader/DownloaderSnackbarAction'
import { Dox } from '../models/Dox'

export interface IDownload {
  id: string
  name: string
  mimetype: string
  progress: number
  abortController?: AbortController
  data?: BlobPart[]
  error?: any
  documentId: number
  revisionId: number
  doxId?: number
}

export interface DocumentMap {
  [documentId: number]: Document
}

export interface DocumentActionPayload {
  document: Document
}

export interface RevisionActionPayload {
  documentId: number
  revisionId: number
  content: BlobPart
}

export interface SetDownloadActionPayload {
  downloadId: string
  download: IDownload
}

export interface RemoveDownloadActionPayload {
  downloadId: string
}

export interface IContextState {
  // context props
  documentMap: DocumentMap
  downloadMap: { [downloadId: string]: IDownload }

  // context actions
  cleanCache: () => void
  addDocument: (document: Document) => void
  setDocumentDox: (document: Document) => void
  setRevisionContent: (documentId: number, revisionId: number, content: BlobPart) => void
  patchDownload: (downloadId: string, download: any) => void
  removeDownload: (doenloadId: string) => void
}

const initialState: IContextState = {
  // context props
  documentMap: {},
  downloadMap: {},

  // context actions
  cleanCache: () => {},
  addDocument: (document: Document) => {},
  setDocumentDox: (document: Document) => {},
  setRevisionContent: (documentId: number, revisionId: number, content: BlobPart) => {},
  patchDownload: (downloadId: string, download: any) => {},
  removeDownload: (doenloadId: string) => {},
}

type Actions =
  | Action<'CLEAN_CACHE', undefined>
  | Action<'ADD_DOCUMENT', DocumentActionPayload>
  | Action<'SET_DOCUMENT_DOX', DocumentActionPayload>
  | Action<'SET_REVISION_CONTENT', RevisionActionPayload>
  | Action<'PATCH_DOWNLOAD', SetDownloadActionPayload>
  | Action<'REMOVE_DOWNLOAD', RemoveDownloadActionPayload>

type ReducerFunc = (state: IContextState, action: Actions) => IContextState
function reducer(state: IContextState, action: Actions): IContextState {
  switch (action.type) {
    case 'CLEAN_CACHE': {
      return {
        ...state,
        documentMap: {},
      }
    }
    case 'ADD_DOCUMENT': {
      if (!!action.payload) {
        state.documentMap[action.payload.document.documentId] = action.payload.document
      }
      return { ...state }
    }
    case 'SET_DOCUMENT_DOX': {
      if (!!action.payload && state.documentMap[action.payload.document.documentId]) {
        state.documentMap[action.payload.document.documentId].doxIds = action.payload.document.doxIds
      }
      return { ...state }
    }
    case 'SET_REVISION_CONTENT': {
      if (!!action.payload) {
        const document = state.documentMap[action.payload.documentId]
        const revision = document.revisions.find((p) => action.payload && p.revisionId === action.payload.revisionId)
        if (revision) {
          revision.content = action.payload.content
        }
      }
      return { ...state }
    }
    case 'PATCH_DOWNLOAD': {
      if (!action.payload) {
        return { ...state }
      }
      const { downloadId, download } = action.payload
      const prevDownload = state.downloadMap[downloadId]
      return {
        ...state,
        downloadMap: { ...state.downloadMap, [downloadId]: { ...prevDownload, ...download } },
      }
    }
    case 'REMOVE_DOWNLOAD': {
      if (!action.payload) {
        return { ...state }
      }
      const { downloadId } = action.payload
      delete state.downloadMap[downloadId]
      return {
        ...state,
        downloadMap: { ...state.downloadMap },
      }
    }
  }
}

export type GetCachedDocumentRevisionContentArgs = {
  state: IContextState
  revision: DocumentRevision
}

export type DownloadDocumentRevisionArgs = {
  state: IContextState
  revision: DocumentRevision
  abortController?: AbortController
  callback?: (data: BlobPart[]) => void
}

export function downloadDocumentRevision(args: DownloadDocumentRevisionArgs): IDownload {
  const { state, revision } = args
  const { documentId, revisionId } = revision
  const downloadId = `doc/rev/${revisionId}`
  const name = revision.filename || revision.name || downloadId
  const mimetype = revision.mimetype
  let download = state.downloadMap[downloadId]
  if (download) {
    log.debug({ downloadDocumentRevision: 'just a downlod in progress' })
    return download
  }
  const abortController = args.abortController || new AbortController()
  download = {
    id: downloadId,
    name,
    mimetype,
    progress: 0,
    abortController,
    documentId,
    revisionId,
  }
  state.patchDownload(downloadId, download)

  let loadDocument
  const cachedDoc = state.documentMap[revision.documentId]
  if (cachedDoc) {
    loadDocument = Promise.resolve(cachedDoc)
  } else {
    loadDocument = dalDocument.getDocument(abortController.signal, revision.documentId).then((doc) => {
      state.addDocument(doc)
      return doc
    })
  }
  loadDocument
    .then((doc) => {
      const revision = doc.getRevision(revisionId)
      const name = revision?.filename || revision?.name || downloadId
      const mimetype = revision?.mimetype
      console.log({ name, mimetype })
      state.patchDownload(downloadId, { name, mimetype })
      if (revision?.content) {
        const data = [revision.content]
        state.patchDownload(downloadId, { data })
        return data
      }
      return dalDocument
        .getDocumentRevisionContent(abortController.signal, documentId, revisionId, false, (progress) => {
          log.debug({ downloadDocumentRevision: 'download update progress', progress })
          state.patchDownload(downloadId, { progress })
        })
        .then((arrayBuffer) => {
          log.debug({ downloadDocumentRevision: 'download completed' })
          const blob = createBlob([arrayBuffer], mimetype)
          state.setRevisionContent(documentId, revisionId, blob)
          const data = [arrayBuffer]
          state.patchDownload(downloadId, { data })
          return data
        })
    })
    .catch((err) => {
      log.debug({ downloadDocumentRevision: 'download error' })
      state.removeDownload(downloadId)
      closeSnackbar(downloadId)
    })
  return download
}

export type DownloadDoxArgs = {
  state: IContextState
  dox: Dox
  abortController?: AbortController
  name?: string
  callback?: (data: BlobPart[]) => void
}

export function downloadDoxContent(args: DownloadDoxArgs): IDownload {
  const { state, dox } = args
  const mimetype = 'zip'
  const downloadId = `dox/${dox.id}`
  let download = state.downloadMap[downloadId]
  if (download) {
    log.debug({ downloadDocumentRevision: 'just a downlod in progress' })
    return download
  }
  const abortController = args.abortController || new AbortController()
  download = {
    id: downloadId,
    name: dox?.name || downloadId,
    mimetype: 'application/zip',
    progress: 0,
    abortController,
    documentId: 0,
    revisionId: 0,
    doxId: dox.id,
  }
  state.patchDownload(downloadId, download)
  dalDox
    .getDoxContent(abortController.signal, dox.id, false, (progress) => {
      log.debug({ downloadDox: 'download update progress', progress })
      state.patchDownload(downloadId, { progress })
    })
    .then((arrayBuffer) => {
      log.debug({ downloadDox: 'download completed' })
      const blob = createBlob([arrayBuffer], mimetype)
      const data = [arrayBuffer]
      state.patchDownload(downloadId, { data })
      return data
    })
    .catch((err) => {
      log.debug({ downloadDocumentRevision: 'download error' })
      state.removeDownload(downloadId)
      closeSnackbar(downloadId)
    })
  return download
}

export function enqueueSnackbarDownload(download: IDownload) {
  const snackbarId = download.id
  enqueueSnackbar(download.name, {
    key: snackbarId,
    anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
    persist: true,
    action: (
      <DownloaderSnackbarAction
        downloadId={download.id}
        onClose={() => {
          download.abortController?.abort()
          closeSnackbar(snackbarId)
        }}
      />
    ),
  })
}

const injectActions = (dispatch: (arg: Actions) => void, state: IContextState) => {
  state.cleanCache = () => dispatch({ type: 'CLEAN_CACHE' })
  state.addDocument = (document: Document) => dispatch({ type: 'ADD_DOCUMENT', payload: { document: document } })
  state.setDocumentDox = (document: Document) => dispatch({ type: 'SET_DOCUMENT_DOX', payload: { document: document } })
  state.setRevisionContent = (documentId: number, revisionId: number, content: BlobPart) =>
    dispatch({
      type: 'SET_REVISION_CONTENT',
      payload: { documentId: documentId, revisionId: revisionId, content: content },
    })
  state.patchDownload = (downloadId: string, download: any) => {
    dispatch({
      type: 'PATCH_DOWNLOAD',
      payload: { downloadId, download },
    })
  }
  state.removeDownload = (downloadId: string) => {
    dispatch({
      type: 'REMOVE_DOWNLOAD',
      payload: { downloadId },
    })
  }
}

interface IProps {
  children?: React.ReactNode
}

const DocumentCacheContext = React.createContext<IContextState>(initialState)
export const DocumentCacheContextProvider: React.FC<IProps> = ({ children }) => {
  const [state, dispatch] = useReducer<ReducerFunc>(reducer, initialState)
  injectActions(dispatch, state)

  return <DocumentCacheContext.Provider value={state}>{children}</DocumentCacheContext.Provider>
}

export const useDocumentCacheContext = (): IContextState => React.useContext(DocumentCacheContext)

export function createBlob(data: BlobPart[] | undefined, mimetype: string | undefined): Blob {
  return new Blob(data, {
    type: isPersonalDoxMimetype(mimetype) ? 'application/pdf' : mimetype,
  })
}
