export interface IDocumentRevisionJson {
  revision_id: number
  document_id: number
  name: string
  description: string
  edited_at: string
  creator_identity: string
  archived_at: string
  filename: string
  size: number
  mimetype: string
  checksum: string
  created_at: string
  updated_at: string
  content: BlobPart
}

export class DocumentRevision {
  revisionId: number = 0
  documentId: number = 0
  name: string = ''
  description: string = ''
  editedAt: string = ''
  creatorIdentity: string = ''
  archivedAt: string = ''
  filename: string = ''
  size: number = 0
  mimetype: string = ''
  checksum: string = ''
  createdAt: string = ''
  updatedAt: string = ''
  content: BlobPart = ''

  constructor() {}

  public static serialize(obj: DocumentRevision): IDocumentRevisionJson {
    return {
      revision_id: obj.revisionId,
      document_id: obj.documentId,
      name: obj.name,
      description: obj.description,
      edited_at: obj.editedAt,
      creator_identity: obj.creatorIdentity,
      archived_at: obj.archivedAt,
      filename: obj.filename,
      size: obj.size,
      mimetype: obj.mimetype,
      checksum: obj.checksum,
      created_at: obj.createdAt,
      updated_at: obj.updatedAt,
      content: obj.content,
    }
  }

  public static deserialize(json: IDocumentRevisionJson): DocumentRevision {
    const res = new DocumentRevision()
    res.revisionId = json.revision_id
    res.documentId = json.document_id
    res.name = json.name
    res.description = json.description
    res.editedAt = json.edited_at
    res.creatorIdentity = json.creator_identity
    res.archivedAt = json.archived_at
    res.filename = json.filename
    res.size = json.size
    res.mimetype = json.mimetype
    res.checksum = json.checksum
    res.createdAt = json.created_at
    res.updatedAt = json.updated_at
    res.content = json.content
    return res
  }

  public static getContentLength(revision: DocumentRevision) {
    if (!revision.content) {
      return 0
    }
    if (typeof revision.content === 'string') {
      return revision.content.length
    }
    if (revision.content instanceof ArrayBuffer || ArrayBuffer.isView(revision.content)) {
      const buffer = revision.content as ArrayBuffer
      return buffer.byteLength
    }
    if (Object.hasOwn(revision.content, 'size')) {
      const buffer = revision.content as Blob
      return buffer.size
    }
    if (Object.hasOwn(revision.content, 'length')) {
      const buffer = revision.content as any
      return buffer.length
    }
    return 0
  }

  public static serializeArray(objs: DocumentRevision[]): IDocumentRevisionJson[] {
    const jsons = objs.map((p) => {
      return DocumentRevision.serialize(p)!
    })
    return jsons
  }

  public static deserializeArray(json: IDocumentRevisionJson[]): DocumentRevision[] {
    const res = json.map((p) => {
      return DocumentRevision.deserialize(p)!
    })
    return res
  }
}
