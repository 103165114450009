import msgIds from './msgIds'

export default {
  [msgIds.MSG_PERSONALDOX]: 'PersonalDOX',
  [msgIds.MSG_OK]: 'Ok',
  [msgIds.MSG_YES]: 'Yes',
  [msgIds.MSG_NO]: 'No',
  [msgIds.MSG_ABORT]: 'Abort',
  [msgIds.MSG_CONFIRM]: 'Confirm',
  [msgIds.MSG_CONTINUE]: 'Continue',
  [msgIds.MSG_DO_YOU_CONFIRM]: 'Confirm?',
  [msgIds.MSG_ADD]: 'Add',
  [msgIds.MSG_DATE]: '{{val, datetime}}',
  [msgIds.MSG_DRAFT]: 'Draft',
  [msgIds.MSG_DOX]: 'Dox',
  [msgIds.MSG_DOCUMENT]: 'Document',
  [msgIds.MSG_AUTHORIZATIONS]: 'Authorizations',
  [msgIds.MSG_DIMENSION]: 'Dimension',
  [msgIds.MSG_MYSELF]: 'Myself',
  [msgIds.MSG_FINALITY]: 'Finality',
  [msgIds.MSG_RETENTION]: 'Retention',
  [msgIds.MSG_DAY]: 'day',
  [msgIds.MSG_DAYS]: 'days',
  [msgIds.MSG_WEEK]: 'week',
  [msgIds.MSG_WEEKS]: 'weeks',
  [msgIds.MSG_MONTH]: 'month',
  [msgIds.MSG_MONTHS]: 'months',
  [msgIds.MSG_YEAR]: 'year',
  [msgIds.MSG_YEARS]: 'years',
  [msgIds.MSG_LOADING_IN_PROGRESS]: 'Loading in progress...',
  [msgIds.MSG_NECESSARY]: 'Necessary',
  [msgIds.MSG_OPTIONAL]: 'Optional',
  [msgIds.MSG_BORN_ON]: 'Nato il',
  [msgIds.MSG_NEW_TREATMENT_FIELD]: 'New field',
  [msgIds.MSG_MOVE_UP]: 'Move up',
  [msgIds.MSG_MOVE_DOWN]: 'Move down',
  [msgIds.MSG_DELETE]: 'Delete',
  [msgIds.MSG_MODIFY]: 'Modify',
  [msgIds.MSG_FROM]: 'From',
  [msgIds.MSG_TO]: 'To',
  [msgIds.MSG_DISABLED]: 'Disabled',
  [msgIds.MSG_PROFILE_INFORMATIONS]: 'Profile informations',
  [msgIds.MSG_MANDATORY]: 'Mandatory',
  [msgIds.MSG_OPTIONAL2]: 'Optional',
  [msgIds.MSG_OPERATION_EXECUTED_SUCCESSFULLY]: 'Operation executed successfully',
  [msgIds.MSG_LANGUAGE]: 'Language',
  [msgIds.MSG_LANGUAGE_ITALIAN]: 'Italian',
  [msgIds.MSG_LANGUAGE_ENGLISH]: 'English',
  [msgIds.MSG_FUNCTION_NOT_AVAILABLE]: 'This function is not available. Contact your administrator',
  [msgIds.MSG_SUBSCRIPTION]: 'Subscription',
  [msgIds.MSG_LOGIN]: 'Login',
  [msgIds.MSG_LOGOUT]: 'Logout',
  [msgIds.MSG_PRODUCTS]: 'Products',
  [msgIds.MSG_INVOICES]: 'Invoices',
  [msgIds.MSG_VAT]: 'VAT',
  [msgIds.MSG_FIELD]: 'Field',
  [msgIds.MSG_PRIVATE]: 'Private',
  [msgIds.MSG_SHARED]: 'Shared',
  [msgIds.MSG_CONTRACT]: 'Contract',

  // leftbar
  [msgIds.MSG_ARCHIVE_MANAGEMENT]: 'Archive management',
  [msgIds.MSG_DESKTOP]: 'Desktop',
  [msgIds.MSG_PERSONAL_ARCHIVE]: 'Personal archive',
  [msgIds.MSG_DOX_SHARED_BY_WITH]: 'Dox shared by {{owner}} with {{guest}}',
  [msgIds.MSG_RECEIVED_DOX]: 'Received dox',
  [msgIds.MSG_SETTINGS]: 'Settings',
  [msgIds.MSG_PERSONAL_SETTINGS]: 'Personal settings',
  [msgIds.MSG_CONFIGURATIONS]: 'Configurations',
  [msgIds.MSG_PERSONAL_INFORMATIONS]: 'Informations',
  [msgIds.MSG_PRESENTATION]: 'Presentation',
  [msgIds.MSG_STRUCTURE_SETTINGS]: 'Structure settings',
  [msgIds.MSG_STRUCTURE_INFORMATIONS]: 'Informations',
  [msgIds.MSG_OPERATIVE_PRIVACY_MODEL]: 'Operative Privacy Model',
  [msgIds.MSG_TREATMENT_ACTIVITIES]: 'Treatment activities',
  [msgIds.MSG_PRIVACY_POLICIES]: 'Privacy policies',
  [msgIds.MSG_CHANGE_HISTORY]: 'Event history',
  [msgIds.MSG_USER_MANAGEMENT]: 'Gestione utente',
  [msgIds.MSG_SHARED_ARCHIVE]: 'Archivio condiviso',
  [msgIds.MSG_STRUCTURE_ARCHIVE]: 'Archivio aziendale',
  // users and profiles
  [msgIds.MSG_PRIVATE_USER]: 'Private user',
  [msgIds.MSG_ORGANIZATION]: 'Organization',
  [msgIds.MSG_OPERATOR]: 'Operator',
  [msgIds.MSG_COLLABORATOR]: 'Collaborator',
  [msgIds.MSG_ADMIN_LO]: 'admin',
  [msgIds.MSG_INTERNAL_LO]: 'internal',
  [msgIds.MSG_EXTERNAL_LO]: 'external',
  [msgIds.MSG_STRUCTURE]: 'Structure',
  [msgIds.MSG_USER]: 'User',
  [msgIds.MSG_USER_DETAILS]: 'User details',
  // topbar
  [msgIds.MSG_USER_MENU]: 'User menu',
  [msgIds.MSG_STRUCTURES]: 'Strutture',
  [msgIds.MSG_OPERATORS]: 'Operatori',
  [msgIds.MSG_COLLABORATORS]: 'Collaboratori',
  [msgIds.MSG_USERS]: 'Utenti',
  [msgIds.MSG_NOTICE_BOARD]: 'Notice board',
  [msgIds.MSG_NOTICE_GUIDE]: 'Guide',
  [msgIds.MSG_NOTIFICATIONS]: 'Notifications',
  // search dialog
  [msgIds.MSG_SEARCH]: 'Search...',
  [msgIds.MSG_SEARCH_FOR_STRUCTURES]: 'Search for structures',
  [msgIds.MSG_SEARCH_FOR_OPERATORS]: 'Search for operators',
  [msgIds.MSG_SEARCH_FOR_COLLABORATORS]: 'Search for collaborators',
  [msgIds.MSG_SEARCH_FOR_CUSTOMERS]: 'Search for customers',
  [msgIds.MSG_LOAD_MORE_ELEMENTS]: 'Load more items...',
  [msgIds.MSG_CREATE_NEW_PLACEHOLDER_USER]: 'Create new user',
  [msgIds.MSG_CREATE_NEW_PLACEHOLDER_USER_TITLE]: 'Creating a new user',
  [msgIds.MSG_CREATE_NEW_PLACEHOLDER_USER_BODY]:
    "To add a new user you must first publish a privacy policy.\nGo to the 'Privacy Organizational Model' area, configure the treatments you want to do on the user's data, draft and publish the privacy policy.",
  // word confirm dialog
  [msgIds.MSG_WORD_CONFIRM_DIALOG_TITLE]: 'Confirm',
  [msgIds.MSG_WORD_CONFIRM_DIALOG_BODY]: 'To confirm, write the word ${word} and press Ok',
  [msgIds.MSG_WORD_CONFIRM_FIELD_LABEL]: 'Word for confirmation',
  [msgIds.MSG_WORD_CONFIRM_CLOSE]: 'CLOSE',
  [msgIds.MSG_WORD_CONFIRM_INVALID]: 'The entered word is invalid',
  // fields
  [msgIds.MSG_LASTNAME]: 'Lastname',
  [msgIds.MSG_NAME]: 'Name',
  [msgIds.MSG_COMPANY_NAME]: 'Company name',
  [msgIds.MSG_FULLNAME]: 'Lastname Name',
  [msgIds.MSG_ADDRESS]: 'Address',
  [msgIds.MSG_PHONE]: 'Phone',
  [msgIds.MSG_MOBILE1]: 'Mobile 1',
  [msgIds.MSG_MOBILE2]: 'Mobile 2',
  [msgIds.MSG_EMAIL]: 'E-Mail',
  [msgIds.MSG_EMAIL1]: 'Mail 1',
  [msgIds.MSG_EMAIL2]: 'Mail 2',
  [msgIds.MSG_EMAIL3]: 'Mail 3',
  [msgIds.MSG_EMAIL4]: 'Mail 4',
  [msgIds.MSG_PEC]: 'PEC',
  [msgIds.MSG_WEBSITE]: 'Website',
  [msgIds.MSG_OTP]: 'OTP',
  //
  [msgIds.MSG_DETAILS]: 'Dettagli',
  [msgIds.MSG_DETAILS_OWNED_BY_STRUCTURE]: 'Information entered by your structure',
  [msgIds.MSG_DETAILS_OWNED_BY_CUSTOMER]: 'Information entered by the user',
  [msgIds.MSG_CLEAR]: 'Clear',
  [msgIds.MSG_CLOSE]: 'Close',
  [msgIds.MSG_SAVE]: 'Save',
  [msgIds.MSG_CANCEL]: 'Cancel',
  [msgIds.MSG_REQUEST_MODIFICATION]: 'Request modification',
  [msgIds.MSG_DAYS_IN_ADVANCE]: 'Days in advance',
  [msgIds.MSG_QUANTITY]: 'Quantity',
  [msgIds.MSG_SEND]: 'Send',
  [msgIds.MSG_COPY_LINK]: 'Copy link',
  [msgIds.MSG_UNSHARE]: 'Unshare',

  // ProfileType
  [msgIds.MSG_PROFILE_TYPE_NONE]: 'None',
  [msgIds.MSG_PROFILE_TYPE_CUSTOMER]: 'Private user',
  [msgIds.MSG_PROFILE_TYPE_OPERATOR_ADMIN]: 'Structur administrator',
  [msgIds.MSG_PROFILE_TYPE_OPERATOR_EXT]: 'External collaborator',
  [msgIds.MSG_PROFILE_TYPE_OPERATOR_INT]: 'Internal collaborator',
  [msgIds.MSG_PROFILE_TYPE_STRUCTURE]: 'Structure',
  [msgIds.MSG_PROFILE_TYPE_TUTOR]: 'Tutor',

  // ActionType
  [msgIds.MSG_ACTION_TYPE_VIEW_DOCUMENTS]: 'View documents',
  [msgIds.MSG_ACTION_TYPE_DOWNLOAD_DOCUMENTS]: 'Download documents',
  [msgIds.MSG_ACTION_TYPE_CREATE_DOCUMENTS]: 'Create documents',
  [msgIds.MSG_ACTION_TYPE_UPDATE_DOCUMENTS]: 'Update documents',
  [msgIds.MSG_ACTION_TYPE_ARCHIVE_DOCUMENTS]: 'Archive documents',
  [msgIds.MSG_ACTION_TYPE_DELETE_DOCUMENTS]: 'Delete documents (if possible)',
  [msgIds.MSG_ACTION_TYPE_VIEW_DOX]: 'View dox',
  [msgIds.MSG_ACTION_TYPE_DOWNLOAD_DOX]: 'Download dox',
  [msgIds.MSG_ACTION_TYPE_CREATE_DOX]: 'Create dox',
  [msgIds.MSG_ACTION_TYPE_UPDATE_DOX]: 'Update/Move dox',
  [msgIds.MSG_ACTION_TYPE_DELETE_DOX]: 'Delete dox',

  // abbreviations
  MSG_PROFILE_TITLE_ABBREVIATION_ARCH: 'Arch.',
  MSG_PROFILE_TITLE_ABBREVIATION_AVV: 'Avv.',
  MSG_PROFILE_TITLE_ABBREVIATION_DR: 'Dr.',
  MSG_PROFILE_TITLE_ABBREVIATION_ING: 'Ing.',
  MSG_PROFILE_TITLE_ABBREVIATION_PROF: 'Prof.',

  // rest errors
  [msgIds.MSG_ERR_FORBIDDEN]: 'Operation not permitted',
  [msgIds.MSG_AN_ERROR_HAS_OCCURRED]: 'An error has occurred',
  [msgIds.MSG_ERR_INVALID_EMAIL_OR_PWD_DSC]: 'Incorrect login credentials',
  [msgIds.MSG_ERR_TREATMENT_CODE_UNIQUE_VIOLATION_DSC]: 'Internal code already used',
  [msgIds.MSG_ERR_TREATMENT_CUSTOM_FIELD_NOT_UNIQUE_NAME_DSC]: 'The name of this field is already in use',
  [msgIds.MSG_ERR_MISSING_DOX_WITH_RETENTION_DSC]:
    'The document must be associated with at least one dox configured for data retention',
  [msgIds.MSG_ERR_DUPLICATED_PERMISSION_DSC]: 'This permission has already been created',
  [msgIds.MSG_ERR_TREATMENT_IN_USE_DSC]: 'The operation is not permitted because the treatment is in use',
  [msgIds.MSG_ERR_INVALID_TOKEN_DSC]: 'Invalid TOTP code',
  [msgIds.MSG_ERR_INVALID_PIN_DSC]: 'Invalid PIN code',
  [msgIds.MSG_ERR_FEATURE_SET_LIMITED_DSC]: 'Contact the structure for more information',
  [msgIds.MSG_ERR_INVITATION_NOT_ACCEPTABLE_DSC]: 'The invitation is no longer available',
  [msgIds.MSG_ERR_INVITATION_NOT_REVOKABLE_DSC]: 'The invitation has already been accepted',
  [msgIds.MSG_ERR_MISSING_EXPLANATION_TO_AVOID_NEW_ACKNOWLEDGMENT_DSC]:
    'You have been requested to access additional information. It is necessary to note the reason why a new acknowledgment of the privacy policy is not required.',
  [msgIds.MSG_ERR_MISSING_EXPLANATION_TO_AVOID_NEW_CONSENTS_DSC]:
    'You have been requested to access additional information. You must note the reason why you are not requesting new consent.',
  [msgIds.MSG_ERR_MISSING_COMPANY_NAME_DSC]: 'Company name is missing',
  // billing errors
  [msgIds.MSG_ERR_INVALID_COUPON_DSC]: 'Invalid discount code',
  [msgIds.MSG_ERR_INVALID_PROMOTION_CODE_DSC]: 'Invalid discount code',
  [msgIds.MSG_ERR_STRIPE_INVALID_CUSTOMER_DSC]: 'Customer code not founded',
  [msgIds.MSG_ERR_EMAIL_ALREADY_USED_DSC]: 'Email already used',
  [msgIds.MSG_ERR_INVALID_FISCAL_CODE_DSC]: 'Invalid tax code',
  [msgIds.MSG_ERR_DUPLICATED_FISCAL_CODE_DSC]: 'Tax code already used',
  [msgIds.MSG_ERR_DUPLICATED_VAT_NUMBER_DSC]: 'VAT number already used',
  [msgIds.MSG_ERR_MISSING_MANDATORY_AGREEMENT_DSC]: 'An error occurred during the registration process',
  [msgIds.MSG_ERR_MISSING_PROFILE_TYPE_DSC]: 'An error occurred during the registration process',
  // payment method card errors
  [msgIds.MSG_ERR_INSUFFICIENT_FUNDS_DSC]: 'Card declined: insufficient funds',
  [msgIds.MSG_ERR_LOST_CARD_DSC]: 'Card rejected: lost',
  [msgIds.MSG_ERR_STOLEN_CARD_DSC]: 'Card rejected: stolen',
  [msgIds.MSG_ERR_CARD_DECLINED_DSC]: 'Card rejected',
  [msgIds.MSG_ERR_EXPIRED_CARD_DSC]: 'Card expired',
  [msgIds.MSG_ERR_INCORRECT_CVC_CODE_DSC]: 'Incorrect CVC code',
  [msgIds.MSG_ERR_PROCESSING_ERROR_DSC]: 'Processing error',
  [msgIds.MSG_ERR_INCORRECT_CARD_NUMBER_DSC]: 'Incorrect card number',
  [msgIds.MSG_ERR_REQUIRED_PAYMENT_METHOD_DSC]:
    'Registration not completed: the payment was not successful, check that the card used is suitable',
  [msgIds.MSG_ERR_CANNOT_CONFIR_PAYMENT_METHOD_DSC]:
    'We are unable to authenticate your payment method. Please choose a different payment method and try again',
  [msgIds.MSG_ERR_TRIAL_ALREADY_USED_DSC]: 'The trial period has already been activated',
  // validation errors
  [msgIds.MSG_VAL_ERR_THERE_ARE_FORM_ERRORS]: 'There are some errors filling in the form',
  [msgIds.MSG_VAL_ERR_SOME_MANDATORY_CONSENTS_ARE_MISSING]: 'Some mandatory consents are missing',
  [msgIds.MSG_VAL_ERR_REQUIRED_FIELD]: 'Required field',
  [msgIds.MSG_VAL_ERR_WRONG_MAIL_FORMAT]: 'Wrong email format',
  [msgIds.MSG_VAL_ERR_WRONG_PHONE_FORMAT]: 'Wrong phone number format',
  [msgIds.MSG_VAL_ERR_WRONG_WEBSITE_FORMAT]: 'Wrong url format',
  [msgIds.MSG_VAL_ERR_WRONG_FISCAL_CODE_FORMAT]: 'Wrong fiscal code format',
  [msgIds.MSG_VAL_ERR_WRONG_VAT_NUMBER_FORMAT]: 'Wrong vat number format',
  [msgIds.MSG_VAL_ERR_WRONG_SDI_CODE_FORMAT]: 'Wrong SDI code format',
  [msgIds.MSG_VAL_ERR_TOO_SHORT]: 'The minimum length of characters is ',
  [msgIds.MSG_VAL_ERR_LENGTH_MUST_BE]: 'The length must be of characters ',
  [msgIds.MSG_VAL_ERR_MAX_LENGTH_MUST_BE]: 'The maximum length is of characters ',
  [msgIds.MSG_VAL_ERR_MIN_LENGTH_MUST_BE]: 'The minimum length is of characters ',
  [msgIds.MSG_VAL_ERR_MAX_MUST_BE]: 'The minimum value is ',
  [msgIds.MSG_VAL_ERR_MIN_MUST_BE]: 'The maximum value is ',
  [msgIds.MSG_VAL_ERR_MUST_CONTAIN_UPPERCASE]: 'Must contain at least one uppercase character',
  [msgIds.MSG_VAL_ERR_MUST_CONTAIN_NUMBER]: 'Must contain at least one number',
  [msgIds.MSG_VAL_ERR_CANNOT_CONTAIN_SPACES]: 'Cannot contain spaces',
  [msgIds.MSG_VAL_ERR_WRONG_PASSWORD_FORMAT]: 'Password format not allowed',
  [msgIds.MSG_VAL_ERR_INVALID_DATE]: 'Invalid date',
  [msgIds.MSG_VAL_ERR_DUPLICATED_NAME]: 'Duplicated name',
  [msgIds.MSG_VAL_ERR_PASSOWRD_NOT_CONFIRMED]: 'Password not confirmed',
  [msgIds.MSG_VAL_ERR_WRONG_DATE_RANGE]: 'Incorrect date range',
  // action type errors
  [msgIds.MSG_ERR_ACTION_TYPE_NOT_ALLOWED]: 'You are not authorized to perform this operation',
  [msgIds.MSG_ERR_SUBSCRIPTION_NOT_FOUNDED_PLAN]: 'An error occurred while selecting the plan',
  // generic errors
  [msgIds.MSG_ERR_LOADING_PAGE_ERROR]: 'La pagina non può essere caricata correttamente',

  // Treatments
  [msgIds.MSG_NEW_TREATMENT]: 'New treatment',
  [msgIds.MSG_NEW_TREATMENT_TOOLTIP_TEXT]: 'Create a new treatment',
  [msgIds.MSG_TREATMENT_ACTIVITIES_CODE]: 'Code',
  [msgIds.MSG_TREATMENT_FINALITY]: 'Finality',
  [msgIds.MSG_TREATMENT_LEGAL_BASE]: 'Legal base',
  [msgIds.MSG_TREATMENT_RETENTION]: 'Retention',
  [msgIds.MSG_TREATMENT_NOT_ACTIVE]: 'Inactive',
  [msgIds.MSG_TREATMENT_ACTIVITY_DETAILS]: 'Treatment activity details',
  [msgIds.MSG_SHOW_ONLY_ACTIVE_TREATMENTS]: 'View active treatments only',
  [msgIds.MSG_TREATMENT_DISABLE]: 'Turn off treatment',
  [msgIds.MSG_TREATMENT_ENABLE]: 'Turn on treatment',
  [msgIds.MSG_TREATMENT_DELETE]: 'Delete treatment',
  [msgIds.MSG_TREATMENT_BASIC_FEATURES]: 'Basic features',
  [msgIds.MSG_TREATMENT_INTRO]: 'In this section the characteristics of data processing are configured.',
  [msgIds.MSG_TREATMENT_ATTENTION_WARNING]:
    'These settings will affect the behavior of PersonalDOX regarding the management of the company archive.',
  [msgIds.MSG_TREATMENT_PRIVACY_EXPERT_RECOMMENDATION]:
    'If you have any doubts about how to configure this section, ask for the technical support of a privacy expert.',
  [msgIds.MSG_TREATMENT_INTERNAL_CODE]: 'Internal code',
  [msgIds.MSG_TREATMENT_SHORT_DESCRIPTION]: 'Short description',
  [msgIds.MSG_TREATMENT_PURPOSE]: 'Treatment purpose',
  [msgIds.MSG_TREATMENT_LEGAL_BASES_TITLE]: 'Legal basis',
  [msgIds.MSG_TREATMENT_ATTENTION]: 'Attention!',
  [msgIds.MSG_TREATMENT_LEGAL_BASE_DESCRIPTION]:
    'The legal basis for processing describes why you are authorized to process user data',
  [msgIds.MSG_TREATMENT_DATA_RETENTION]: 'Data retention',
  [msgIds.MSG_TREATMENT_DATA_MAINTAINED]: 'The data processed for this purpose must be retained',
  [msgIds.MSG_TREATMENT_STORAGE_PERIOD]: 'Storage period',
  [msgIds.MSG_TREATMENT_SETTINGS_AFFECT_DOX]:
    'The following settings will be applied to the doxes in which you activate retention for these processing purposes',
  [msgIds.MSG_TREATMENT_NOTIFY_END_OF_CONSERVATION]: 'Send a notification before the end of the retention',
  [msgIds.MSG_TREATMENT_ACTION_AT_CONSERVATION_END]: 'Action at the end of the retention',
  [msgIds.MSG_TREATMENT_MANDATORY_FIELDS]: 'Mandatory fields',
  [msgIds.MSG_TREATMENT_OPTIONAL_FIELDS]: 'Optional fields',
  [msgIds.MSG_TREATMENT_CONFIRM_DELETE_TITLE]: 'Treatment cancellation',
  [msgIds.MSG_TREATMENT_CONFIRM_DELETE_TEXT]: 'Are you sure you want to delete this treatment?',
  [msgIds.MSG_TREATMENT_CONFIRM_DISABLE_TITLE]: 'Treatment deactivation',
  [msgIds.MSG_TREATMENT_CONFIRM_DISABLE_TEXT]:
    'Are you sure you want to disable this treatment?\nThis operation can be performed only if the treatment is not used by PersonalDOX for data retention',
  [msgIds.MSG_TREATMENT_CONFIRM_ENABLE_TITLE]: 'Treatment activation',
  [msgIds.MSG_TREATMENT_CONFIRM_ENABLE_TEXT]: 'Are you sure you want to activate this treatment again?',
  [msgIds.MSG_TREATMENT_NO_SELECTABLE_ITEMS]: 'There are no selectable treatment activities',
  [msgIds.MSG_TREATMENT_DELETE_CUSTOM_FIELD_TITLE]: 'Treated fields',
  [msgIds.MSG_TREATMENT_DELETE_CUSTOM_FIELD_TEXT]: 'Are you sure to remove this field?',

  [msgIds.MSG_TREATMENT_FIELD_NAME]: 'Name',
  [msgIds.MSG_TREATMENT_FIELD_DESCRIPTION]: 'Description',
  [msgIds.MSG_TREATMENT_FIELD_MANDATORY]: 'Needed',

  [msgIds.MSG_LEGAL_BASE_EXPLICIT_CONSENT]: 'Explicit consent',
  [msgIds.MSG_LEGAL_BASE_CONTRACTUAL_OBLIGATION]: 'Contractual obligation',
  [msgIds.MSG_LEGAL_BASE_LEGAL_OBLIGATION]: 'Legal obligation',
  [msgIds.MSG_LEGAL_BASE_VITAL_INTEREST]: 'Vital interest',
  [msgIds.MSG_LEGAL_BASE_PUBLIC_INTEREST]: 'Public interest',
  [msgIds.MSG_LEGAL_BASE_LEGITIMATE_INTEREST]: 'Legitimate interest',

  [msgIds.MSG_RETENTION_END_BEHAVIOR_NONE]: 'None',
  [msgIds.MSG_RETENTION_END_BEHAVIOR_TERMINATE]: 'Terminate',
  [msgIds.MSG_RETENTION_END_BEHAVIOR_NOTIFY]: 'Notify',
  [msgIds.MSG_RETENTION_END_BEHAVIOR_TERMINATE_AND_NOTIFY]: 'Terminate and notify',

  [msgIds.MSG_CUSTOMER_VISIBILITY_RULES]: 'Period for accessibility to this information',
  [msgIds.MSG_CUSTOMER_VISIBILITY_RULES_NONE]: 'None',
  [msgIds.MSG_CUSTOMER_VISIBILITY_RULES_DOCUMENTS_RETENTION]:
    'As long as at least one document is retented for this purpose',
  [msgIds.MSG_CUSTOMER_VISIBILITY_RULES_COMPLETE_DURATION]:
    'For the entire period indicated in the "Data Retention" section',
  [msgIds.MSG_CUSTOMER_VISIBILITY_RULES_INFO]:
    'This setting is used to define until when the user will be visible in your registry.\n• As long as at least one document is kept for this purpose:\nwhen you no longer keep any user\'s document for this purpose, if there are no other reasons for which you are entitled to access your data, you will no longer see it in your registry.\n• For the entire period indicated in the "Data retention" section:\nyou will be able to access the data of the user for the period of time you set in the "Data retention" section, even if you don\'t keep any documents referring to him in your archive. It is useful, for example, for processing aimed at communications for marketing purposes.',

  [msgIds.MSG_RETENTION_DURATION_NONE]: '',
  [msgIds.MSG_RETENTION_DURATION_DAY]: 'Day',
  [msgIds.MSG_RETENTION_DURATION_WEEK]: 'Week',
  [msgIds.MSG_RETENTION_DURATION_MONTH]: 'Month',
  [msgIds.MSG_RETENTION_DURATION_YEAR]: 'Year',

  [msgIds.MSG_RETENTION_DURATION_DAYS]: 'Days',
  [msgIds.MSG_RETENTION_DURATION_WEEKS]: 'Weeks',
  [msgIds.MSG_RETENTION_DURATION_MONTHS]: 'Months',
  [msgIds.MSG_RETENTION_DURATION_YEARS]: 'Years',

  [msgIds.MSG_CONTRACT_ACCEPTANCE_STATUS_NONE]: 'None',
  [msgIds.MSG_CONTRACT_ACCEPTANCE_STATUS_TO_ACCEPT]: 'To accept',
  [msgIds.MSG_CONTRACT_ACCEPTANCE_STATUS_IMPLICITY_ACCEPTED_AND_CONFIRMED]: 'Implicitly accepted and confirmed',
  [msgIds.MSG_CONTRACT_ACCEPTANCE_STATUS_IMPLICITY_ACCEPTED_BUT_NOT_CONFIRMED]: 'Implicitly accepted but not confirmed',
  [msgIds.MSG_CONTRACT_ACCEPTANCE_STATUS_ACCEPTED]: 'Accepted',

  [msgIds.MSG_CONTRACT_PRIVACY_POLICY_NOT_AVAILABLE]: 'Privacy policy not available',
  [msgIds.MSG_CONTRACT_TERMS_OF_USE_NOT_AVAILABLE]: 'Contract not available',
  [msgIds.MSG_CONTRACT_DATA_PROCESSOR_NOT_AVAILABLE]: 'Data processor nomination not available',
  [msgIds.MSG_CONTRACT_NOT_AVAILABLE]: 'Document not available',
  [msgIds.MSG_CONTRACT_PRIVACY_POLICY_FOR_CONSUMER_TITLE]: 'Privacy policy for customers',
  [msgIds.MSG_CONTRACT_TERMS_OF_USE_FOR_CONSUMER_TITLE]: 'Terms of use for customers',
  [msgIds.MSG_CONTRACT_PRIVACY_POLICY_FOR_OPERATOR_TITLE]: 'Privacy policy for operators',
  [msgIds.MSG_CONTRACT_TERMS_OF_USE_FOR_OPERATOR_TITLE]: 'Term of use for operators',
  [msgIds.MSG_CONTRACT_DATA_PROCESSOR_FOR_OPERATOR_TITLE]: 'Data processor nomination',

  [msgIds.MSG_TREATED_FIELDS_GROUP_IDENTIFICATION]: 'Identification data',
  [msgIds.MSG_TREATED_FIELDS_GROUP_RECOGNITION]: 'Recognition data',
  [msgIds.MSG_TREATED_FIELDS_GROUP_RESIDENCE]: 'Residence data',
  [msgIds.MSG_TREATED_FIELDS_GROUP_CONTACT]: 'Contact data',

  [msgIds.MSG_TREATED_FIELDS_LASTNAME]: 'Lastname',
  [msgIds.MSG_TREATED_FIELDS_NAME]: 'Name',
  [msgIds.MSG_TREATED_FIELDS_GENDER]: 'Gender',
  [msgIds.MSG_TREATED_FIELDS_BIRTH_DATE]: 'Birth date',
  [msgIds.MSG_TREATED_FIELDS_BIRTH_PLACE]: 'Birth place',
  [msgIds.MSG_TREATED_FIELDS_FISCAL_CODE]: 'Fiscal code',
  [msgIds.MSG_TREATED_FIELDS_AVATAR]: 'Avatar',
  [msgIds.MSG_TREATED_FIELDS_COUNTRY]: 'Country',
  [msgIds.MSG_TREATED_FIELDS_CITY]: 'City',
  [msgIds.MSG_TREATED_FIELDS_PROVINCE]: 'Province',
  [msgIds.MSG_TREATED_FIELDS_STREET]: 'Street',
  [msgIds.MSG_TREATED_FIELDS_STREET_NUMBER]: 'Street number',
  [msgIds.MSG_TREATED_FIELDS_ZIP]: 'Zip',
  [msgIds.MSG_TREATED_FIELDS_PHONE]: 'Phone',
  [msgIds.MSG_TREATED_FIELDS_MOBILE_PHONE]: 'Mobile phone',
  [msgIds.MSG_TREATED_FIELDS_EMAIL]: 'Email',

  [msgIds.MSG_TREATED_FIELDS_TEXT1]: 'Treated data',
  [msgIds.MSG_TREATED_FIELDS_TEXT2]: 'Other treated data',

  [msgIds.MSG_TREATED_CUSTOM_FIELD_TITLE]: 'Type of information processed',

  [msgIds.MSG_FIELDNAME_AVATAR]: 'Avatar',
  [msgIds.MSG_FIELDNAME_CITY]: 'City',
  [msgIds.MSG_FIELDNAME_COUNTRY]: 'Country',
  [msgIds.MSG_FIELDNAME_PROVINCE]: 'Province',
  [msgIds.MSG_FIELDNAME_STREET]: 'Street',
  [msgIds.MSG_FIELDNAME_STREET_NUMBER]: 'Street number',
  [msgIds.MSG_FIELDNAME_ZIP]: 'Zip code',
  [msgIds.MSG_FIELDNAME_EMAIL1]: 'Email 1',
  [msgIds.MSG_FIELDNAME_EMAIL2]: 'Email 2',
  [msgIds.MSG_FIELDNAME_EMAIL3]: 'Email 3',
  [msgIds.MSG_FIELDNAME_EMAIL4]: 'Email 4',
  [msgIds.MSG_FIELDNAME_MOBILE_PHONE1]: 'Mobile phone 1',
  [msgIds.MSG_FIELDNAME_MOBILE_PHONE2]: 'Mobile phone 2',
  [msgIds.MSG_FIELDNAME_PHONE]: 'Phone',

  [msgIds.MSG_ACCOUNT_ATTACH_DIALOG_TITLE]: 'Fields to keep',

  [msgIds.MSG_CONTRACT_TYPE_NONE]: 'None',
  [msgIds.MSG_CONTRACT_TYPE_PRIVACY_INFO]: 'Privacy policy',
  [msgIds.MSG_CONTRACT_TYPE_TERMS_OF_USE]: 'Terms of use',
  [msgIds.MSG_CONTRACT_TYPE_DATA_PROCESSOR]: 'Data processor nomination',

  [msgIds.MSG_CONTRACT_VERSION_STATE_NONE]: 'None',
  [msgIds.MSG_CONTRACT_VERSION_STATE_PUBLISHED]: 'Published',
  [msgIds.MSG_CONTRACT_VERSION_STATE_OBSOLETE]: 'Obsolete',
  [msgIds.MSG_CONTRACT_VERSION_STATE_DRAFT]: 'Draft',

  [msgIds.MSG_CONTRACT_VERSION_VIEWER_PAGE_DOWNLOAD_COMMAND]: 'Download',
  [msgIds.MSG_CONTRACT_VERSION_VIEWER_PAGE_SHOW_OBSOLETE_COMMAND]: 'Consens history',
  [msgIds.MSG_CONTRACT_VERSION_VIEWER_PAGE_SUSPEND_DATA_PROCESSING]: 'Disable data sharing',
  [msgIds.MSG_CONTRACT_VERSION_VIEWER_PAGE_ACTIVATE_DATA_PROCESSING]: 'Enable data sharing',
  [msgIds.MSG_CONTRACT_VERSION_VIEWER_CONSENT_DATE]: 'Consents date',
  [msgIds.MSG_CONTRACT_VERSION_VIEWER_CHANGES]: 'Changes',
  [msgIds.MSG_CONTRACT_VERSION_VIEWER_NEW_ACKNOWLEDGMENT_REQUIRED]:
    'This structure is asking you to review the privacy policy again. To do this, you need to check the box provided.',
  [msgIds.MSG_CONTRACT_VERSION_LIST_DIALOG_TITLE]: 'Select a version',
  [msgIds.MSG_CONTRACT_VERSION_ADD_MISSING_INFO_BUTTON]: 'Add missing informations',

  [msgIds.MSG_CONSENT_CHANGE]: 'Acknowledgment and consent',
  [msgIds.MSG_CONSENT_CHANGE_REVOKE_ALL_PRIVACY_POLICY_CONSENTS_CONFIRM]:
    'Some sections with mandatory acceptance do not have your consent. If you confirm, the data processing, in all the forms provided for by this document, cannot be carried out.',
  [msgIds.MSG_CONSENT_CHANGE_REVOKE_ALL_CONTRACT_CONSENTS_CONFIRM]:
    'Some sections with mandatory acceptance do not have your consent. If you confirm, this agreement cannot be finalized.',
  [msgIds.MSG_CONSENT_CHANGE_ALL_MANDATORY_CONSENTS_NEEDED]:
    'Consent must be provided for all sections requiring mandatory acceptance.',

  [msgIds.MSG_CONTRACT_NEW]: 'New contract',
  [msgIds.MSG_CONTRACT_TITLE]: 'Contracts',
  [msgIds.MSG_CONTRACT_ID]: 'ID',
  [msgIds.MSG_CONTRACT_NAME]: 'Name',
  [msgIds.MSG_CONTRACT_TYPE]: 'Type',
  [msgIds.MSG_CONTRACT_VERSION]: 'Version',
  [msgIds.MSG_CONTRACT_DATE]: 'Date',
  [msgIds.MSG_CONTRACT_DOWNLOAD]: 'Download',

  [msgIds.MSG_CONTRACT_NEW_VERSION]: 'New version',
  [msgIds.MSG_CONTRACT_PRIVACY_POLICY]: 'Privacy policy',
  [msgIds.MSG_CONTRACT_TERMS_OF_USE]: 'Terms of use',
  [msgIds.MSG_CONTRACT_DATA_PROCESSOR]: 'Data processor nomination',
  [msgIds.MSG_CONTRACT_STATE]: 'State',

  [msgIds.MSG_CONTRACT_ADD_SECTION]: 'Add section',
  [msgIds.MSG_CONTRACT_PUBLISH]: 'Publish',
  [msgIds.MSG_CONTRACT_DELETE_VERSION]: 'Delete version',
  [msgIds.MSG_CONTRACT_DELETE_SECTION]: 'Delete section',
  [msgIds.MSG_CONTRACT_EDIT_VERSION_TITLE]: 'Version editing',
  [msgIds.MSG_CONTRACT_SECTION_TITLE]: 'Section',
  [msgIds.MSG_CONTRACT_INFO_TITLE]: 'Informations',
  [msgIds.MSG_CONTRACT_MAIN_PRIVACY_POLICY_INFO]: 'Main section of privacy policy',
  [msgIds.MSG_CONTRACT_MAIN_PRIVACY_POLICY_TEXT]:
    'This section is mandatory and must contain the information indicated in the art. 13 and 14 of the GDPR.\nIts acknowledgment by the user is essential to activate the data processing and allow PersonalDOX to control access to the information.',
  [msgIds.MSG_CONTRACT_TREATMENTS_LIST]: 'Treatments list',
  [msgIds.MSG_CONTRACT_ADD_DATA_TREATMENT]: 'Add treatment',

  [msgIds.MSG_CONTRACT_ANNOTATIONS_VISIBLE]: 'The following annotations are visible to the recipients of this document',
  [msgIds.MSG_CONTRACT_NOTES_VERSION]: 'Notes on this release (optional)',
  [msgIds.MSG_CONTRACT_INTERNAL_ANNOTATIONS]: 'The following annotations are for internal use',
  [msgIds.MSG_CONTRACT_NEW_ACK_REQUIRED]: 'Request a new acknowledgment',
  [msgIds.MSG_CONTRACT_NEW_ACK_REQUIRED_INFO]:
    'With this option activated, the user will have to read the information again. As long as he has not done so, PersonalDOX will continue to make available to you the information he had already authorized, with the following exceptions:\n• if you have reduced the number of fields you wish to process, these fields will no longer be visible to you immediately ;\n• if you have increased the number of fields you wish to process, they will not be visible to you until the user has read the new information.',
  [msgIds.MSG_CONTRACT_WHY_NO_NEW_ACK]: 'Explain why a new acknowledgment is not necessary',
  [msgIds.MSG_CONTRACT_NEW_CONSENTS_REQUIRED]: 'Request new consents',
  [msgIds.MSG_CONTRACT_NEW_CONSENTS_REQUIRED_INFO]:
    'With this option activated, previously released consents will no longer be valid and your users will have to manually issue new ones.\nWithout this option, the consents provided for the obsolete version will automatically be transferred to the new version.\nNOTE: the consents will be transferred from the obsolete version to the new following the 6-character identification code that identifies each section. For this reason, if necessary, in the new version you can change the order of the sections.\nWARNING: PersonalDOX does not carry out any checks on the content of the sections, therefore make sure that the object of each being is preserved as the versions progress published. This way, the consent given by users will continue to reflect the permissions they intend to grant you.',
  [msgIds.MSG_CONTRACT_WHY_NO_NEW_CONSENTS]: 'Explain why new consents are not needed',

  [msgIds.MSG_CONSENT_ACCEPTANCE_MODE_MANDATORY_EXPLANATION_PRIVACY_POLICY]:
    'Acknowledgment is necessary to authorize the processing of data',
  [msgIds.MSG_CONSENT_ACCEPTANCE_MODE_MANDATOTY_EXPLANATION_OTHER]: 'Acceptance is mandatory',
  [msgIds.MSG_CONSENT_I_HAVE_READ]: 'I have read',
  [msgIds.MSG_CONSENT_AGREE]: 'I agree',
  [msgIds.MSG_CONSENT_NOT_AGREE]: "I don't agree",
  [msgIds.MSG_CONSENT_ACCEPT]: 'I accept',
  [msgIds.MSG_CONSENT_NOT_ACCEPT]: 'I do not accept',
  [msgIds.MSG_CONSENT_MISSING_INFORMATIONS_TITLE]: 'Some information requested in the information is missing',
  [msgIds.MSG_CONSENT_PUSH_TO_ADD_MISSING_INFORMATIONS]:
    "To insert them, press the 'Fill in missing information' button",

  [msgIds.MSG_CONTRACT_DELETE_VERSION_CONFIRM]: 'Are you sure you want to delete this version draft?',
  [msgIds.MSG_CONTRACT_DELETE_SECTION_CONFIRM]: 'Are you sure you want to delete the section?',
  [msgIds.MSG_CONTRACT_DATA_PROCESSING_SECTION_ABSENT]:
    "The first section of the information is not associated with the 'Data processing' action.\nTo resolve this, create a new section and associate with it the processing activities that do not require explicit consent",
  [msgIds.MSG_CONTRACT_PUBLICATION]: 'Publication',
  [msgIds.MSG_ARE_YOU_SURE_TO_PUBLISH_VERSION]: 'Are you sure you want to publish this version?',
  [msgIds.MSG_ARE_YOU_SURE_TO_PUBLISH_PRIVACY_POLICY_VERSION_WITH_NEW_CONSENTS_REQUIRED]:
    'ATTENTIONFor each user, data processing will be suspended until they provide new consent.\nAre you sure you want to publish this version?',
  [msgIds.MSG_ARE_YOU_SURE_TO_PUBLISH_PRIVACY_POLICY_VERSION_WITHOUT_NEW_CONSENTS_REQUIRED]:
    'ATTENTION\nAll data processing will remain active.\nThe consents provided by your users will be reported in the new version of the information.\nAre you sure you want to publish this version?',
  [msgIds.MSG_ARE_YOU_SURE_TO_PUBLISH_GENERIC_VERSION_WITH_NEW_CONSENTS_REQUIRED]:
    'Are you sure you want to publish this version?',
  [msgIds.MSG_ARE_YOU_SURE_TO_PUBLISH_GENERIC_VERSION_WITHOUT_NEW_CONSENTS_REQUIRED]:
    'ATTENTION\nThe consents provided by your users will be reflected in the new version of the contract.\nAre you sure you want to publish this version?',
  // Archive
  [msgIds.MSG_PERSONAL_ARCHIVE_ROOT_NAME]: 'PERSONAL ARCHIVE',
  [msgIds.MSG_COMPANY_ARCHIVE_ROOT_NAME]: 'COMPANY ARCHIVE',
  [msgIds.MSG_CUSTOMER_ARCHIVE_ROOT_NAME]: 'CUSTOMER ARCHIVE',
  // Doxes menu
  [msgIds.MSG_DOXES_MENU_RELOAD_BUTTON]: 'Reload doxes',
  [msgIds.MSG_DOXES_MENU_PIN_BUTTON]: 'Collapse doxes',
  [msgIds.MSG_DOXES_MENU_SHOW_BUTTON]: 'Show doxes',
  [msgIds.MSG_DOXES_SELECTION_MODE_LABEL]: 'Selection mode for dox',
  [msgIds.MSG_DOXES_SELECTION_MODE_SINGLE]: 'Single selection',
  [msgIds.MSG_DOXES_SELECTION_MODE_MULTIPLE]: 'Multiple selection',
  [msgIds.MSG_DOXES_SELECTION_MODE_HIERARCHICAL]: 'Multiple selection with nested',
  // Dox commands
  [msgIds.MSG_DOX_COMMAND_SHOW_AUTHORIZATIONS]: 'Authorizations',
  [msgIds.MSG_DOX_COMMAND_CREATE_NEW_DOX]: 'New',
  [msgIds.MSG_DOX_COMMAND_CREATE_NEW_ORGANIZED_DOX]: 'New organized Dox',
  [msgIds.MSG_DOX_COMMAND_MODIFY_DOX]: 'Edit',
  [msgIds.MSG_DOX_COMMAND_MOVE_DOX]: 'Move',
  [msgIds.MSG_DOX_COMMAND_DELETE_DOX]: 'Delete',
  [msgIds.MSG_DOX_COMMAND_SHOW_DOX_CHRONOLOGY]: 'Chronology',
  [msgIds.MSG_DOX_COMMAND_SHOW_DOX_DETAILS]: 'Details',
  [msgIds.MSG_DOX_COMMAND_DOWNLOAD_DOX]: 'Download',
  [msgIds.MSG_DOX_COMMAND_TERMINATE_RETENTION]: 'Stop retention',
  [msgIds.MSG_DOX_COMMAND_DELIVER_DOX]: 'Deliver',
  [msgIds.MSG_DOX_COMMAND_SHARE_DOX]: 'Sharing',
  // DocumentsTableFiltersBar
  [msgIds.MSG_DOCS_FILTERS_BAR_BUTTON]: 'Filters',
  [msgIds.MSG_DOCS_FILTER_EDITED_FROM_KEY]: 'From',
  [msgIds.MSG_DOCS_FILTER_EDITED_TO_KEY]: 'To',
  [msgIds.MSG_DOCS_FILTER_STRUCTURE_OWNER_KEY]: 'Structure owner',
  [msgIds.MSG_DOCS_FILTER_OPERATOR_AUTHOR_KEY]: 'Operator author',
  [msgIds.MSG_DOCS_FILTER_CREATED_BY_ME_KEY]: 'Mine',
  [msgIds.MSG_DOCS_FILTER_ONLY_DRAFTS_KEY]: 'Only drafts',
  [msgIds.MSG_DOCS_FILTER_SHOW_OBSOLETE_KEY]: 'Show obsolete',
  [msgIds.MSG_DOCS_FILTERS_BAR_PLACEHOLDER]: 'No filters',
  // DocumentsFiltersDialog
  [msgIds.MSG_DOCS_FILTER_DIALOG_TITLE]: 'Filters',
  // Document table
  [msgIds.MSG_DOCS_TABLE_HEADER_NAME]: 'Title',
  [msgIds.MSG_DOCS_TABLE_HEADER_AUTHOR]: 'Author',
  [msgIds.MSG_DOCS_TABLE_HEADER_DATE]: 'Date',
  // Archive Page - command bar
  [msgIds.MSG_ARCHIVE_PAGE_SHARED_ARCHIVE_TITLE]: 'Shared archive from {{identity}}',
  [msgIds.MSG_ARCHIVE_PAGE_ARCHIVE_FOR_CUSTOMER_TITLE]: 'Company archive {{identity}}',
  [msgIds.MSG_COMMAND_CREATE_DOCUMENT_FROM_TEMPLATE]: 'Write document from a template',
  [msgIds.MSG_COMMAND_CREATE_DOCUMENT]: 'New document',
  [msgIds.MSG_COMMAND_UPLOAD_DOCUMENT]: 'Upload document',
  // Document commands
  [msgIds.MSG_DOC_COMMAND_ASSOCIATE_TO_DOX]: 'Associate to Dox',
  [msgIds.MSG_DOC_COMMAND_SHOW_AUTHORIZATIONS]: 'Authorizations',
  [msgIds.MSG_DOC_COMMAND_REMOVE_FROM_STRUCTURE_ARCHIVE]: 'Remove from company archive',
  [msgIds.MSG_DOC_COMMAND_DOWNLOAD]: 'Download',
  [msgIds.MSG_DOC_COMMAND_MARK_AS_OBSOLETE]: 'Mark as obsolete',
  [msgIds.MSG_DOC_COMMAND_SHOW_CHRONOLOGY]: 'Chronology',
  [msgIds.MSG_DOC_COMMAND_MODIFY]: 'Create/Edit draft',
  [msgIds.MSG_DOC_COMMAND_DELETE]: 'Delete',
  [msgIds.MSG_DOC_COMMAND_ARCHIVE]: 'Archive',
  [msgIds.MSG_DOC_COMMAND_ADD_DOCUMENT_TO_DESKTOP]: 'Add to desktop',
  [msgIds.MSG_DOC_COMMAND_SET_ANONYMOUS_STATE]: "Hasn't personal data",
  [msgIds.MSG_DOC_COMMAND_UNSET_ANONYMOUS_STATE]: 'Has personal data',

  // Document command confirms
  [msgIds.MSG_DOX_COMMAND_REMOVE_FROM_STRUCTURE_ARCHIVE_CONFIRM]:
    'The selected document will be permanently removed from the company archive.\nConfirm?',
  [msgIds.MSG_DOCUMENT_DELETE_CONFIRM]: 'Are you sure you want to delete this document?',
  [msgIds.MSG_DOCUMENT_MARK_AS_OBSOLETE_CONFIRM]:
    'Are you sure you want to make this document obsolete?\nThe operation will be irreversible',
  [msgIds.MSG_DOX_COMMAND_TERMINATE_RETENTION_CONFIRM]:
    'The documents associated with this dox and nested dox will be processed as follows:\n\n• Those stored for other purposes will be ignored;\n• Those marked as anonymous will be ignored;\n• Those created by your organization but not delivered will be deleted from the PersonalDOX archives (irreversible operation);\n• Those owned by other users will be removed from your company archive.\n\nNOTE: If, at the end of this operation, the dox has no more associated documents, it will be deleted.\n\nDo you confirm that you want to end the retention of this dox?',
  [msgIds.MSG_DOX_COMMAND_DELIVER_DOX_CONFIRM]:
    'Are you sure you want to deliver this dox?\n\nNOTE: Any draft documents not yet archived will be automatically archived.',

  // Document commands results
  [msgIds.MSG_DOCUMENT_DELETED_SUCCESSFULLY]: 'Document deleted successfully',
  [msgIds.MSG_DOCUMENT_ARCHIVED_SUCCESSFULLY]: 'Document archived successfully',
  [msgIds.MSG_DOCUMENT_SAVED_SUCCESSFULLY]: 'Document saved successfully',
  [msgIds.MSG_DOCUMENT_NOW_IS_ANONYMOUS]: 'The document is marked as "does not contain personal data"',
  [msgIds.MSG_DOCUMENT_NOW_IS_NOT_ANONYMOUS]: 'The document is marked as "containing personal data"',
  [msgIds.MSG_DOCUMENT_MARKED_AS_OBSOLETE_SUCCESSFULLY]: 'The document has been marked obsolete',
  [msgIds.MSG_RETENTION_TERMINATED_SUCCESFULLY]: 'Conservation interruption carried out successfully',
  [msgIds.MSG_SPECIFIC_RETENTION_TERMINATION_NOT_POSSIBLE_FROM_DOX]:
    'The retention applied to the documents associated with this dox and nested dox is specific to each document. For this reason, you cannot stop the retention directly from the dox, but you can individually stop it for each document through the "Retention" tab in the preview.',
  [msgIds.MSG_DOX_DELIVERED_SUCCESSFULLY]: 'Dox delivered successfully',
  [msgIds.MSG_DOX_CREATED_SUCCESSFULLY]: 'Dox created successfully',
  [msgIds.MSG_DOX_UPDATED_SUCCESSFULLY]: 'Dox modified successfully',
  [msgIds.MSG_DOX_DELETED_SUCCESSFULLY]: 'Dox deleted successfully',

  // Document sources
  [msgIds.MSG_DOCUMENT_SOURCE_PDX_EDITOR]: 'PersonalDOX editor',
  [msgIds.MSG_DOCUMENT_SOURCE_LOADED_FILE]: 'Loaded file',
  [msgIds.MSG_DOCUMENT_ORIGINAL_FILE_NAME]: 'Original file name',

  // DocumentEditor commands
  [msgIds.MSG_DOCUMENT_EDITOR_COMMAND_INFO]: 'Info',
  [msgIds.MSG_DOCUMENT_EDITOR_COMMAND_DOX]: 'Dox',
  [msgIds.MSG_DOCUMENT_EDITOR_COMMAND_CREATE_DRAFT]: 'Create draft',
  [msgIds.MSG_DOCUMENT_EDITOR_COMMAND_EDIT_DRAFT]: 'Edit draft',
  [msgIds.MSG_DOCUMENT_EDITOR_COMMAND_CLOSE_DRAFT]: 'Close draft',
  [msgIds.MSG_DOCUMENT_EDITOR_COMMAND_CONTENT]: 'Content',
  [msgIds.MSG_DOCUMENT_EDITOR_COMMAND_REVISIONS]: 'Revisions',
  [msgIds.MSG_DOCUMENT_EDITOR_COMMAND_RETENTION]: 'Retention',
  [msgIds.MSG_DOCUMENT_EDITOR_COMMAND_UNDO]: 'Undo',
  [msgIds.MSG_DOCUMENT_EDITOR_COMMAND_REDO]: 'Redo',
  [msgIds.MSG_DOCUMENT_EDITOR_COMMAND_SAVE]: 'Save',
  [msgIds.MSG_DOCUMENT_EDITOR_COMMAND_ARCHIVE]: 'Archive',

  // DocumentEditor revision labels
  [msgIds.MSG_DOCUMENT_EDITOR_REVISION_NAME_LABEL]: 'Title',
  [msgIds.MSG_DOCUMENT_EDITOR_REVISION_EDITED_AT_LABEL]: 'Date',
  [msgIds.MSG_DOCUMENT_EDITOR_REVISION_CREATOR_IDENTITY_LABEL]: 'Author',
  [msgIds.MSG_DOCUMENT_EDITOR_REVISION_MIMETYPE_LABEL]: 'Document type',
  [msgIds.MSG_DOCUMENT_EDITOR_REVISION_ARCHIVED_AT_LABEL]: 'Archive date',

  // DocumentEditor revision values
  [msgIds.MSG_DOCUMENT_EDITOR_REVISION_MIMETYPE_UNKNOWN]: 'Sconosciuto',
  [msgIds.MSG_DOCUMENT_EDITOR_REVISION_MIMETYPE_PERSONALDOX]: 'Editor di PersonalDOX',

  // DocumentEditor dox
  [msgIds.MSG_DOCUMENT_EDITOR_DOX_OF]: 'Dox of ',
  [msgIds.MSG_DOCUMENT_EDITOR_NO_DOX]: 'No associated dox',

  // DocumentEditor content
  [msgIds.MSG_DOCUMENT_EDITOR_NO_REVISION_SELECTED]: 'No revision selected',
  [msgIds.MSG_DOCUMENT_EDITOR_DOWNLOAD_ERROR]: 'Download error',
  [msgIds.MSG_DOCUMENT_EDITOR_NOT_AVAILABLE_VIEWER]: 'There is no viewer available for this type of document',
  [msgIds.MSG_DOCUMENT_EDITOR_INFO]: 'Document info',
  [msgIds.MSG_DOCUMENT_EDITOR_NEW_INTERNAL_DOCUMENT_NAME]: 'New document',
  [msgIds.MSG_DOCUMENT_EDITOR_TRANSMISSION_IN_PROGRESS]: 'Transmission in progress...',
  [msgIds.MSG_DOCUMENT_EDITOR_DRAFT_PREPARATION_IN_PROGRESS]: 'Draft preparation in progress...',

  // DocumentEditor retention
  [msgIds.MSG_DOCUMENT_EDITOR_RETENTION_FINALITY_LABEL]: 'Finality',
  [msgIds.MSG_DOCUMENT_EDITOR_RETENTION_START_AT_LABEL]: 'Start',
  [msgIds.MSG_DOCUMENT_EDITOR_RETENTION_END_AT_LABEL]: 'End',
  [msgIds.MSG_DOCUMENT_EDITOR_RETENTION_INTERVAL_DIALOG_TITLE]: 'Document retention',

  // DocumentUpload
  [msgIds.MSG_DOCUMENT_UPLOAD_INPUT_FILE_LABEL]: 'Select or Drag&Drop file to upload',
  [msgIds.MSG_DOCUMENT_UPLOAD_FILE_NAME_LABEL]: 'Name',
  [msgIds.MSG_DOCUMENT_UPLOAD_FILE_SIZE_LABEL]: 'Size',
  [msgIds.MSG_DOCUMENT_CHANGES_WILL_BE_LOST]: 'Some changes were not saved and will be lost.',

  // Document Dialogs
  [msgIds.MSG_DOCUMENT_RETENTION_DIALOG_TITLE]: 'Documento retention',
  [msgIds.MSG_DOCUMENT_RETENTION_DIALOG_TERMINATE_DETACHED_BUT_VISIBLE_BODY]:
    'This document is stored for different purposes therefore it will be detached from the dox but will continue to be visible.\n\nDo you confirm that you want to end this storage?',
  [msgIds.MSG_DOCUMENT_RETENTION_DIALOG_TERMINATE_VISIBLE_TO_OWNER_BODY]:
    'This document is not the property of your organization and will therefore be removed from your company archive. It will still remain visible to its owner.\n\nAre you sure you want to end this retention?',
  [msgIds.MSG_DOCUMENT_RETENTION_DIALOG_TERMINATE_VISIBLE_TO_TARGET_BY_DELIVER_BODY]:
    'This document will be removed from your company archive but will remain visible to the user to whom you delivered it.\n\nDo you confirm that you want to end this retention?',
  [msgIds.MSG_DOCUMENT_RETENTION_DIALOG_TERMINATE_DELETED_BODY]:
    'This document is the property of your facility and has never been delivered therefore it will be deleted from the PersonalDOX archives (non-reversible operation).\n\nDo you confirm that you want to end this storage?',
  [msgIds.MSG_DOCUMENT_SHOW_TITLE]: 'Show document',
  [msgIds.MSG_DOCUMENT_NOT_AVAILABLE]: 'Document not available',
  [msgIds.MSG_DOCUMENT_WRONG_ASSISTED_CUSTUMER]: 'The document refers to a user other than the one currently selected',

  // Dox Show Dialogs
  [msgIds.MSG_DOX_SHOW_TITLE]: 'Show dox',
  [msgIds.MSG_DOX_NOT_AVAILABLE]: 'Dox not available',
  [msgIds.MSG_DOX_WRONG_ASSISTED_CUSTUMER]: 'The dox refers to a user other than the one currently selected',

  // DoxDetails
  [msgIds.MSG_DOX_DETAILS_CONTENT_LABEL]: 'Content',
  [msgIds.MSG_DOX_DETAILS_DELIVER_SECTION_LABEL]: 'Delivering',
  [msgIds.MSG_DOX_DETAILS_RETENTION_SECTION_LABEL]: 'Data retention',
  [msgIds.MSG_DOX_DETAILS_PATH_LABEL]: 'Path',
  [msgIds.MSG_DOX_DETAILS_NAME_LABEL]: 'Name',
  [msgIds.MSG_DOX_DETAILS_NOTES_LABEL]: 'Notes',
  [msgIds.MSG_DOX_DETAILS_CONTENT_START_AT_LABEL]: 'First document',
  [msgIds.MSG_DOX_DETAILS_CONTENT_END_AT_LABEL]: 'Last document',
  [msgIds.MSG_DOX_DETAILS_CONTENT_DOX_COUNT_LABEL]: 'Doxes count',
  [msgIds.MSG_DOX_DETAILS_CONTENT_DOCUMENT_COUNT_LABEL]: 'Documents count',
  [msgIds.MSG_DOX_DETAILS_CONTENT_DRAFT_DOCUMENT_COUNT_LABEL]: 'Draft documents count',
  [msgIds.MSG_DOX_DETAILS_DELIVERED_AT_LABEL]: 'Delivered at',
  [msgIds.MSG_DOX_DETAILS_FINALITY_LABEL]: 'Finality',
  [msgIds.MSG_DOX_DETAILS_RETENTION_RULES_LABEL]: 'Retention rules',
  [msgIds.MSG_DOX_DETAILS_RETENTION_START_AT_LABEL]: 'Start at',
  [msgIds.MSG_DOX_DETAILS_RETENTION_END_AT_LABEL]: 'End at',
  [msgIds.MSG_DOX_DETAILS_RETENTION_NOTICE_AT_LABEL]: 'Notice before retention end',
  [msgIds.MSG_DOX_DETAILS_RETENTION_END_TERMINATE_LABEL]: 'Automatically stop retention',

  // DoxDetailsDialog
  [msgIds.MSG_DOX_DETAILS_DIALOG_TITLE]: 'Dox details',

  // DoxSelectorDialog
  [msgIds.MSG_DOX_SELECTOR_DIALOG_TITLE]: 'Dox selection',
  [msgIds.MSG_DOX_SELECTOR_DIALOG_REMOVING_DOCUMENTS_CONFIRM]:
    'The selected documents will be permanently removed from the company archive.\nConfirm?',
  [msgIds.MSG_DOX_SELECTOR_DIALOG_REMOVING_DOCUMENT_CONFIRM]:
    'The selected document will be permanently removed from the company archive.\nConfirm?',
  [msgIds.MSG_DOX_SELECTOR_DIALOG_CHANGES_MADE_SUCCESSFULLY]: 'Changes made successfully',

  // DoxEditorDialog
  [msgIds.MSG_DOX_EDITOR_DIALOG_NEW_DOX_TITLE]: 'New dox',
  [msgIds.MSG_DOX_EDITOR_DIALOG_MODIFY_DOX_TITLE]: 'Modify dox',

  // DoxEditor
  [msgIds.MSG_DOX_EDITOR_NEW_DOX_NAME]: 'New dox',
  [msgIds.MSG_DOX_EDITOR_PARENT_LABEL]: 'Parent',
  [msgIds.MSG_DOX_EDITOR_NAME_LABEL]: 'Name',
  [msgIds.MSG_DOX_EDITOR_NOTES_LABEL]: 'Notes',
  [msgIds.MSG_DOX_EDITOR_RETENTION_PLACEHOLDER]: 'Data retention',
  [msgIds.MSG_DOX_EDITOR_RETENTION_TITLE]: 'Documents retention mode',
  [msgIds.MSG_DOX_EDITOR_RETENTION_DESCRIPTION]:
    'This setting apply on all documents assigned to the dox and contained doxes',
  [msgIds.MSG_DOX_EDITOR_RETENTION_CENTRALIZED_LABEL]: 'Same to everyone',
  [msgIds.MSG_DOX_EDITOR_RETENTION_SPECIFIC_LABEL]: 'Specific to each one',
  [msgIds.MSG_DOX_EDITOR_RETENTION_END_AT_INFO]:
    'The end date of data retention may not be specified only in cases where it is not possible to apply a criterion for its determination.\nFor example, if data is preserved due to a court dispute, the end date of retention will not be determinable until the proceedings are concluded.',

  // DoxShare
  [msgIds.MSG_DOX_SHARE_DIALOG_TITLE]: 'Dox sharing',
  [msgIds.MSG_DOX_SHARE_EMAIL]: 'Email',
  [msgIds.MSG_DOX_SHARE_PIN]: 'PIN',
  [msgIds.MSG_DOX_SHARE_PIN_DISABLED]: 'Double authentication not active',
  [msgIds.MSG_DOX_SHARE_GENERATE_PIN]: 'Generate a new PIN',
  [msgIds.MSG_DOX_SHARE_REMOVE_PIN]: 'Remove PIN code protection',
  [msgIds.MSG_DOX_SHARE_REMOVE_PIN_CONFIRM_TITLE]: 'Security with PIN',
  [msgIds.MSG_DOX_SHARE_REMOVE_PIN_CONFIRM_BODY]: 'Are you sure you want to remove PIN protection?',
  [msgIds.MSG_DOX_SHARE_USE_PIN]: 'Use a PIN code to protect sharing',
  [msgIds.MSG_DOX_SHARE_PIN_INFO]:
    'The PIN code allows you to share this Dox with greater security.\nSend generated PIN through a secure channel to the person you are sharing this Dox with',
  [msgIds.MSG_DOX_SHARE_UNSHARE_CONFIRM_TITLE]: 'Dox Sharing',
  [msgIds.MSG_DOX_SHARE_UNSHARE_CONFIRM_BODY]: 'Do you confirm that you want to unshare this Dox?',
  [msgIds.MSG_DOX_SHARE_INVITATION_SENDED]: 'Invitation sent successfully',
  [msgIds.MSG_DOX_SHARE_PERMISSION_NOT_ACTIVE]: 'Authorization not active',
  [msgIds.MSG_DOX_SHARE_PERMISSION_ACTIVE_FOR_DAYS]: 'Active authorization valid for days: {{daysDifference}}',
  [msgIds.MSG_DOX_SHARE_PERMISSION_FUTURE_FOR_DAYS]: 'Future authorization valid for days: {{daysDifference}}',
  [msgIds.MSG_DOX_SHARE_AUTHORIZATIONS_TITLE]: 'Allowed actions',
  [msgIds.MSG_DOX_SHARE_AUTHORIZATIONS_VIEW]: 'View content',
  [msgIds.MSG_DOX_SHARE_AUTHORIZATIONS_VIEW_INFO]: 'View all documents and all Dox inside the Dox you have shared',
  [msgIds.MSG_DOX_SHARE_AUTHORIZATIONS_DOWNLOAD]: 'Download the contents',
  [msgIds.MSG_DOX_SHARE_AUTHORIZATIONS_DOWNLOAD_INFO]:
    'Download all documents individually or the entire Dox you shared at once',
  [msgIds.MSG_DOX_SHARE_AUTHORIZATIONS_EDIT_DOCUMENTS]: 'Create new documents',
  [msgIds.MSG_DOX_SHARE_AUTHORIZATIONS_EDIT_DOCUMENTS_INFO]:
    'Create new documents and later, if possible, modify or delete them',
  [msgIds.MSG_DOX_SHARE_AUTHORIZATIONS_EDIT_DOX]: 'Create new Dox',
  [msgIds.MSG_DOX_SHARE_AUTHORIZATIONS_EDIT_DOX_INFO]:
    'Create new Dox inside the Dox you shared and then edit or delete them. Can organize documents by associating them with available dox',

  // DoxDeleteConfirm
  [msgIds.MSG_DOX_DELETE_CONFIRM_TITLE]: 'Dox deletion',
  [msgIds.MSG_DOX_DELETE_CONFIRM_WORD_LABEL]: 'Delete confirmation',
  [msgIds.MSG_DOX_DELETE_CONFIRM_WORD]: 'DELETE',
  [msgIds.MSG_DOX_DELETE_CONFIRM_ALERT]:
    'Several documents are associated with this Dox, by deleting it they will all be associated with the higher level Dox.\nTo be sure of your decision we ask you to type the word DELETE in the field below.',
  [msgIds.MSG_DOX_DELETE_CONFIRM_ALERT2]:
    'For better use of the contents we recommend that you associate the documents received with the doxes in your archive. The documents that you do not organize in your dox will still be visible to you by selecting the PERSONAL ARCHIVE.',

  // AuthorizationsChangeDialog
  [msgIds.MSG_DOCUMENTS_VISIBILITY_CHANGES]: 'Changes to the visibity of documents',

  // RetentionRules
  [msgIds.MSG_RETENTION_RULE_NONE]: 'None',
  [msgIds.MSG_RETENTION_RULE_CENTRALIZED]: 'Same for all documents',
  [msgIds.MSG_RETENTION_RULE_SPECIFIC]: 'Specific for document',

  // Authorizations
  [msgIds.MSG_AUTHORIZATIONS_VISUALIZATION_ALLOWED]: 'Allowed',
  [msgIds.MSG_AUTHORIZATIONS_VISUALIZATION_EXPIRED]: 'Expired',
  [msgIds.MSG_AUTHORIZATIONS_VISUALIZATION_REVOKED]: 'Revoked',
  [msgIds.MSG_AUTHORIZATIONS_VISUALIZATION_SUSPENDED]: 'Suspended',
  [msgIds.MSG_AUTHORIZATIONS_VISUALIZATION_UNKNOWN]: 'Unknown',

  [msgIds.MSG_AUTHORIZATIONS_ADD_STRUCT_LABEL]: 'Add structure',
  [msgIds.MSG_AUTHORIZATIONS_ADD_STRUCT_BUTTON]: 'Add',

  [msgIds.MSG_AUTHORIZATION_ADDED_MESSAGE]: 'Structure added successfully',

  // AuthorizationsDialog
  [msgIds.MSG_AUTHORIZATION_DELETE_DIALOG_TITLE]: 'Delete authorization',
  [msgIds.MSG_AUTHORIZATION_DELETE_DIALOG_CONTENT]: 'Are you sure to delete authorization?',
  [msgIds.MSG_AUTHORIZATION_DELETED_MESSAGE]: 'Authorization deleted',

  // AuthorizationDetails dialog
  [msgIds.MSG_AUTHORIZATION_DETAILS_DIALOG_TITLE]: 'Authorization origin',
  [msgIds.MSG_AUTHORIZATION_DETAILS_DIALOG_MESSAGE]:
    "The authorization can't be deleted because inherits from authorizations given by the following doxes",

  // AuthorizationsChangeDialog
  [msgIds.MSG_AUTHORIZATIONS_CHANGE_LOST_AUTH_LABEL]: 'Structures that loose access to the document',
  [msgIds.MSG_AUTHORIZATIONS_CHANGE_GAINED_AUTH_LABEL]: 'Structures that gain access to the document',
  [msgIds.MSG_AUTHORIZATIONS_CHANGE_LOST_AUTH_INFO]:
    'By confirming the operation, the listed structures will no longer be able to access the documents associated with this Dox.\nWARNING!\nThey will only be able to continue to access it if you are authorized to view other Doxes associated with the same documents.',
  [msgIds.MSG_AUTHORIZATIONS_CHANGE_GAINED_AUTH_INFO]:
    'The subjects listed have already been authorized to access the contents associated with the destination Dox or a higher level Dox.\nBy confirming the operation, you will allow them to also begin accessing the contents associated with the moved Dox.',
  [msgIds.MSG_AUTHORIZATION_RENEW_CONFIRM_TITLE]: 'Authorization Restore',
  [msgIds.MSG_AUTHORIZATION_RENEW_CONFIRM_BODY]:
    'Do you confirm that you want to reactivate this permission for another {{days}} days?',
  [msgIds.MSG_AUTHORIZATION_RENEWAL_CONFIRM_TITLE]: 'Authorization renewal',
  [msgIds.MSG_AUTHORIZATION_RENEWAL_CONFIRM_BODY]:
    'This authorization is valid until {{date}}.\nDo you wish to renew it for another {{days}} days?',

  // LoadMore component
  [msgIds.MSG_LOAD_MORE_NO_MORE_TO_LOAD]: '-',
  [msgIds.MSG_LOAD_MORE_LOADING_MORE]: 'Loading ...',

  // Account info details
  [msgIds.MSG_ACCOUNT_DETAILS_TITLE]: 'Account',

  // Notifications
  [msgIds.MSG_NOTIFICATIONS_TITLE]: 'Notifications',
  [msgIds.MSG_NOTIFICATIONS_ARCHIVE_TITLE]: 'Notification archive',
  [msgIds.MSG_NOTIFICATIONS_ARCHIVE_LINK]: 'Notification archive',
  [msgIds.MSG_NOTIFICATIONS_SETTINGS_LINK]: 'Settings',
  [msgIds.MSG_NOTIFICATIONS_ARCHIVE_ALL_BUTTON]: 'Archive all',
  // NotificationsFilters keys
  [msgIds.MSG_NOTIFICATIONS_FILTER_FROM_KEY]: 'From',
  [msgIds.MSG_NOTIFICATIONS_FILTER_TO_KEY]: 'to',
  [msgIds.MSG_NOTIFICATIONS_FILTER_STRUCTURE_KEY]: 'Structure',
  [msgIds.MSG_NOTIFICATIONS_FILTER_OPERATOR_KEY]: 'Operator',
  [msgIds.MSG_NOTIFICATIONS_FILTER_ARGUMENT]: 'Topic',
  // Notifications tooltip
  [msgIds.MSG_NOTIFICATION_TOOLTIP_SHOW_DOSSIER]: 'Show dossier',
  [msgIds.MSG_NOTIFICATION_TOOLTIP_SHOW_DOCUMENT]: 'Show document',
  [msgIds.MSG_NOTIFICATION_TOOLTIP_SHOW_DATA_PROCESSING_SETTINGS]: 'Show data processing settings',
  [msgIds.MSG_NOTIFICATION_TOOLTIP_SHOW_CONTRACT]: 'Show contract',
  [msgIds.MSG_NOTIFICATION_TOOLTIP_SHOW_CONSENTS]: 'Show consents',
  [msgIds.MSG_NOTIFICATION_TOOLTIP_SHOW_DOX]: 'Show dox',
  [msgIds.MSG_NOTIFICATION_TOOLTIP_SEND_INVITATION]: 'Send invitation',
  [msgIds.MSG_NOTIFICATION_TOOLTIP_ACCEPT_INVITATION]: 'Accept invitation',
  [msgIds.MSG_NOTIFICATION_TOOLTIP_SHOW_COLLABORATOR_CARD]: 'Show collaborator card',
  // Notifications argument
  [msgIds.MSG_NOTIFICATION_ARGUMENT_LABEL]: 'Notifications topic',
  [msgIds.MSG_NOTIFICATION_ARGUMENT_NONE]: 'None',
  [msgIds.MSG_NOTIFICATION_ARGUMENT_MESSAGE]: 'Message',
  [msgIds.MSG_NOTIFICATION_ARGUMENT_PROFILE_MANAGEMENT]: 'Profile management',
  [msgIds.MSG_NOTIFICATION_ARGUMENT_ACCOUNT_MANAGEMENT]: 'Account management',
  [msgIds.MSG_NOTIFICATION_ARGUMENT_PDX_CONTRACTS]: 'PersonalDOX contracts',
  [msgIds.MSG_NOTIFICATION_ARGUMENT_DOSSIER]: 'Dossier',
  [msgIds.MSG_NOTIFICATION_ARGUMENT_DOCUMENT]: 'Document',
  [msgIds.MSG_NOTIFICATION_ARGUMENT_PRIVACY_SETTINGS]: 'Privacy settings',
  [msgIds.MSG_NOTIFICATION_ARGUMENT_PRIVACY_POLICY]: 'Privacy policy',
  [msgIds.MSG_NOTIFICATION_ARGUMENT_ARCHIVE_MANAGEMENT]: 'Archive management',
  [msgIds.MSG_NOTIFICATION_ARGUMENT_INVITATION]: 'Invitation',
  // Notifications body
  [msgIds.MSG_NOTIFICATION_BODY_UPCOMING_CUSTOMER_PROFILE_SUSPENSION]:
    "The deadline for renewing the service linked to your '{{profileTypeDsc}}' profile will expire in {{daysCountDown}} days. If you fail to pay, your subscription plan will automatically be changed to the Basic plan",
  [msgIds.MSG_NOTIFICATION_BODY_UPCOMING_OPERATOR_AUTO_PROFILE_SUSPENSION]:
    "The deadline for renewing the service linked to your profile '{{profileTypeDsc}}' for the management of the structure '{{identity}}' will expire in {{daysCountDown}} days. If you fail to pay, your subscription plan will automatically be changed to the Basic plan",
  [msgIds.MSG_NOTIFICATION_BODY_CUSTOMER_PROFILE_SUSPENSION]:
    "The service linked to profile '{{profileTypeDsc}}' has not been renewed. Your subscription plan has been changed to the Basic plan",
  [msgIds.MSG_NOTIFICATION_BODY_OPERATOR_AUTO_PROFILE_SUSPENSION]:
    "The service connected to your profile '{{profileTypeDsc}}' for managing the structure '{{identity}}' has not been renewed. Your subscription plan has been changed to the Basic plan",
  [msgIds.MSG_NOTIFICATION_BODY_UPCOMING_CUSTOMER_PROFILE_DELETION]:
    "We have found that your profile '{{profileTypeDsc}}' has not been used for almost 2 years. In {{daysCountDown}} days it will be closed and all documents relating to it will be removed from the PersonalDOX archives. To avoid closing your profile, simply log in and select your profile",
  [msgIds.MSG_NOTIFICATION_BODY_UPCOMING_OPERATOR_AUTO_PROFILE_DELETION]:
    "We have found that your profile '{{profileTypeDsc}}' for managing the structure '{{identity}}' has not been used for almost 2 years. In {{daysCountDown}} days it will be closed and all documents relating to it will be removed from the PersonalDOX archives. To avoid closing your profile, simply log in and select your profile",
  [msgIds.MSG_NOTIFICATION_BODY_CUSTOMER_PROFILE_DELETION_BY_SYSTEM]:
    "Your profile '{{profileTypeDsc}}' has not been used for more than 2 years, we have closed it automatically and all data relating to it have been deleted",
  [msgIds.MSG_NOTIFICATION_BODY_OPERATOR_AUTO_PROFILE_DELETION_BY_SYSTEM]:
    "Your profile '{{profileTypeDsc}}' for the management of the structure '{{identity}}' has not been used for more than 2 years, we have closed it automatically and all the data relating to it have been deleted",
  [msgIds.MSG_NOTIFICATION_BODY_CUSTOMER_PROFILE_DELETION_BY_OWNER]:
    "As you requested, we have closed your profile '{{profileTypeDsc}}', all data relating to it have been deleted",
  [msgIds.MSG_NOTIFICATION_BODY_OPERATOR_AUTO_PROFILE_DELETION_BY_OWNER]:
    "As you requested, we have closed your profile '{{profileTypeDsc}}' for the management of the '{{identity}}' structure, all data relating to it have been deleted",
  [msgIds.MSG_NOTIFICATION_BODY_COLLABORATOR_PROFILE_DELETION_BY_OWNER]:
    "As you requested, we have closed your profile '{{profileTypeDsc}}' of the structure '{{identity}}'",
  [msgIds.MSG_NOTIFICATION_BODY_COLLABORATOR_PROFILE_DELETION_BY_OPERATOR_ADMIN]:
    "As you requested, we have closed the profile '{{profileTypeDsc}}' of the structure '{{identity}}'",
  [msgIds.MSG_NOTIFICATION_BODY_UPCOMING_USER_DELETION]:
    'We have detected that you have not used your account for almost 2 years. In {{daysCountDown}} days the account and all associated profiles will be closed, all data relating to them will be removed from the PersonalDOX archives. To avoid closing your account, simply log in and select the profiles you intend to continue using',
  [msgIds.MSG_NOTIFICATION_BODY_USER_DELETION_BY_SYSTEM]:
    'Your account has not been used for more than 2 years, we have automatically closed it together with the associated profiles, all data referring to them have been removed from the PersonalDOX archives',
  [msgIds.MSG_NOTIFICATION_BODY_USER_DELETION_BY_OWNER]:
    'As you requested, we have closed your account, all associated profiles have been closed and all data referring to them have been removed from the PersonalDOX archives',
  [msgIds.MSG_NOTIFICATION_BODY_PRIVACY_POLICY_UPDATED_BY_EXIDEA_WITH_BREAKING_CHANGE]:
    "A new privacy policy has been published for '{{targetProfileType}}' profiles. To continue using PersonalDOX you need to give us your consent to process your data again. Press the 'Contract' icon to view the new document",
  [msgIds.MSG_NOTIFICATION_BODY_PRIVACY_POLICY_UPDATED_BY_EXIDEA_WITH_NO_BREAKING_CHANGE]:
    "A new privacy policy has been published for '{{targetProfileType}}' profiles. The data processing does not change and the consents provided remain valid. Press the 'Contract' icon to view the new document",
  [msgIds.MSG_NOTIFICATION_BODY_TERMS_OF_USE_UPDATED_BY_EXIDEA_WITH_BREAKING_CHANGE]:
    "Updated the terms of use for '{{targetProfileType}}' profiles. To continue using PersonalDOX you must accept the conditions. Press the 'Contract' icon to view the new document",
  [msgIds.MSG_NOTIFICATION_BODY_TERMS_OF_USE_UPDATED_BY_EXIDEA_WITH_NO_BREAKING_CHANGE]:
    "Updated the terms of use for '{{targetProfileType}}' profiles. You can continue to use PersonalDOX, the consents provided remain valid. Press the 'Contract' icon to view the new document",
  [msgIds.MSG_NOTIFICATION_BODY_DATA_PROCESSOR_UPDATED_BY_EXIDEA_WITH_BREAKING_CHANGE]:
    "The agreement for the appointment of data processing manager has been updated. To continue using PersonalDOX you must accept the conditions. Press the 'Contract' icon to view the new document",
  [msgIds.MSG_NOTIFICATION_BODY_DATA_PROCESSOR_UPDATED_BY_EXIDEA_WITH_NO_BREAKING_CHANGE]:
    "The agreement for the appointment of data processing manager has been updated. You can continue to use PersonalDOX, the consents provided remain valid. Press the 'Contract' icon to view the new document",
  [msgIds.MSG_NOTIFICATION_BODY_DOX_DELIVERED]: 'A new dox delivered',
  [msgIds.MSG_NOTIFICATION_BODY_DOX_RECEIVED]: 'He sent you a new dox',
  [msgIds.MSG_NOTIFICATION_BODY_DOCUMENT_REVISION_CREATED_BY_CUSTOMER]:
    "Revised the document '{{title}}' with date {{editedAt}} created by '{{creatorIdentity}}'",
  [msgIds.MSG_NOTIFICATION_BODY_DOCUMENT_REVISION_CREATED_BY_STRUCTURE]:
    "Revised the document '{{title}}' with date {{editedAt}} created by '{{creatorIdentity}}'",
  [msgIds.MSG_NOTIFICATION_BODY_DOCUMENT_VIEWED]:
    "Viewing document '{{title}}' with date {{editedAt}} created by '{{creatorIdentity}}'",
  [msgIds.MSG_NOTIFICATION_BODY_DOCUMENT_REVISION_DOWNLOADED]:
    "Effettuato il download del documento '{{title}}' con data {{editedAt}} creato da '{{creatorIdentity}}'",
  [msgIds.MSG_NOTIFICATION_BODY_DATA_PROCESSING_GRANTED]: 'Data processing authorized',
  [msgIds.MSG_NOTIFICATION_BODY_DATA_PROCESSING_UPDATED]: 'Set the treatment duration from {{startAt}} to {{endAt}}',
  [msgIds.MSG_NOTIFICATION_BODY_DATA_PROCESSING_DELETED]: 'Data processing revoked',
  [msgIds.MSG_NOTIFICATION_BODY_DATA_PROCESSING_SUSPENDED]: 'Data processing suspended',
  [msgIds.MSG_NOTIFICATION_BODY_DATA_PROCESSING_RESTORED]: 'Data processing reactivated',
  [msgIds.MSG_NOTIFICATION_BODY_UPCOMING_DATA_PROCESSING_EXPIRATION_CUSTOMER]:
    'In {{daysCountDown}} days the {{identity}} facility will no longer be authorized to process your data',
  [msgIds.MSG_NOTIFICATION_BODY_UPCOMING_DATA_PROCESSING_EXPIRATION_OPERATOR]:
    'In {{daysCountDown}} days the processing of data of the user {{identity}} born on {{birthdate}} will no longer be authorized',
  [msgIds.MSG_NOTIFICATION_BODY_DATA_PROCESSING_EXPIRED_CUSTOMER]:
    'The {{identity}} facility is no longer authorized to process your data',
  [msgIds.MSG_NOTIFICATION_BODY_DATA_PROCESSING_EXPIRED_OPERATOR]:
    'The processing of data of the user {{identity}} born on {{birthdate}} is no longer authorized',
  [msgIds.MSG_NOTIFICATION_BODY_PRIVACY_POLICY_UPDATED_BY_STRUCTURE_WITH_BREAKING_CHANGE1]:
    "A new privacy policy has been published, to authorize the processing of data you must read the new document. Press the 'Privacy' icon to view the new information",
  [msgIds.MSG_NOTIFICATION_BODY_PRIVACY_POLICY_UPDATED_BY_STRUCTURE_WITH_BREAKING_CHANGE2]:
    "A new privacy policy has been published, the consents you previously provided have been revoked. To reactivate them you need to provide new consents. Press the 'Privacy' icon to view the new information",
  [msgIds.MSG_NOTIFICATION_BODY_PRIVACY_POLICY_UPDATED_BY_STRUCTURE_WITH_NO_BREAKING_CHANGE]:
    "A new privacy policy has been published, data processing does not change and the consents provided remain valid. Press the 'Privacy' icon to view the new information",
  [msgIds.MSG_NOTIFICATION_BODY_PRIVACY_POLICY_UPDATED_BY_STRUCTURE_WITH_NO_BREAKING_CHANGE_AND_NEW_SECTIONS_WITH_CONSENT]:
    "A new privacy policy has been published, data processing does not change and the consents provided remain valid. NOTE: the new treatments are awaiting your consent.\nClick on the 'Privacy' icon to view the new policy",
  [msgIds.MSG_NOTIFICATION_BODY_PRIVACY_POLICY_CONSENT_UPDATED1]: 'Consent revoked for a section',
  [msgIds.MSG_NOTIFICATION_BODY_PRIVACY_POLICY_CONSENT_UPDATED2]: 'Having read the information',
  [msgIds.MSG_NOTIFICATION_BODY_PRIVACY_POLICY_CONSENT_UPDATED3]: 'Provided consent for a section',
  [msgIds.MSG_NOTIFICATION_BODY_PROFILE_ATTACHED]: 'Attached placeholder customer to real customer',
  [msgIds.MSG_NOTIFICATION_BODY_PROFILE_DETACHED]:
    "The user no longer has a private profile. From now on you will be able to search for him in your registry but you will not be able to give him files nor will he be able to share his documents with you through PersonalDOX. You will still be able to access the documents you created for him. Remember that the authorization to process data can no longer be managed automatically by PersonalDOX. You can do this manually from the '{{identity}}' tab, which will allow you to regulate access to the personal information you have saved. If necessary, you will have to manually collect consent for processing, for example using a traditional paper document.",
  [msgIds.MSG_NOTIFICATION_BODY_SUBSCRIPTION_TRIAL_UPCOMING_EXPIRATION]:
    'The trial period for your {{subscriptionId}} subscription will expire on {{trialEndAt}}',
  [msgIds.MSG_NOTIFICATION_BODY_UPCOMING_DOCUMENT_RETENTION_EXPIRATION]:
    'Document retention will end in {{daysCountDown}} days',
  [msgIds.MSG_NOTIFICATION_BODY_DOCUMENT_RETENTION_EXPIRED]:
    "Document '{{title}}' with date {{editedAt}} created by '{{creatorIdentity}}' has ended retention",
  [msgIds.MSG_NOTIFICATION_BODY_UPCOMING_DOX_RETENTION_EXPIRATION]:
    'In {{daysCountDown}} days the retention of documents associated with the dox will end',
  [msgIds.MSG_NOTIFICATION_BODY_DOX_RETENTION_EXPIRED]:
    'The storage of the documents associated with the dox has ended',
  [msgIds.MSG_NOTIFICATION_BODY_INVITATION_PERMITTED]: 'The user has authorized you to send him an invitation',
  [msgIds.MSG_NOTIFICATION_BODY_INVITATION_RECEIVED_COLLABORATE]:
    'You have been invited to collaborate with this organization',
  [msgIds.MSG_NOTIFICATION_BODY_INVITATION_ACCEPTED_COLLABORATE]:
    'You have accepted the invitation to collaborate with this organization',
  // Notifications show resources dialogs
  [msgIds.MSG_NOTIFICATION_SHOW_DOX_TITLE]: 'Show dox',
  [msgIds.MSG_NOTIFICATION_SHOW_DOX_NOT_AVAILABLE]: 'Dox not available',
  [msgIds.MSG_NOTIFICATION_SHOW_DOX_WRONG_ASSISTED_CUSTUMER]:
    'The dox refers to a user other than the one currently selected',

  // Profile details editor
  [msgIds.MSG_PROFILE_EDITOR_NOT_AVAILABLE_ACCOUNT]: 'Not available account',
  [msgIds.MSG_PROFILE_EDITOR_CONSUMER_TITLE]: 'Personal informations as private user',
  [msgIds.MSG_PROFILE_EDITOR_ABOUT_CONSUMER_TITLE]: 'Personal informations about user {{identity}}',
  [msgIds.MSG_PROFILE_EDITOR_BUSINESS_TITLE]: 'Personal informations as business',
  [msgIds.MSG_PROFILE_EDITOR_STRUCTURE_TITLE]: 'Structure informations',
  [msgIds.MSG_PROFILE_EDITOR_COLLABORATOR_TITLE]: 'Personal informations as collaborator',
  [msgIds.MSG_PROFILE_EDITOR_ABOUT_COLLABORATOR_TITLE]: 'Personal informations about collaborator {{identity}}',
  [msgIds.MSG_PROFILE_EDITOR_PRESENTATION_TITLE]: 'Presentation',
  [msgIds.MSG_PROFILE_EDITOR_PRESENTATION_SELECT_IMAGE_BUTTON]: 'Select a image',
  [msgIds.MSG_PROFILE_EDITOR_PRESENTATION_REMOVE_IMAGE_BUTTON]: 'Remove image',
  [msgIds.MSG_PROFILE_EDITOR_PRESENTATION_DEFAULT_PROFILE_LABEL]: 'Default profile',
  [msgIds.MSG_PROFILE_EDITOR_PRESENTATION_PROFILE_VISIBILITY_LABEL]: 'Profile visibility',
  [msgIds.MSG_PROFILE_EDITOR_PRESENTATION_PROFILE_VISIBILITY_NONE]: 'None',
  [msgIds.MSG_PROFILE_EDITOR_PRESENTATION_PROFILE_VISIBILITY_COLLABORATORS]: 'Collaborators only',
  [msgIds.MSG_PROFILE_EDITOR_PRESENTATION_PROFILE_VISIBILITY_ALL]: 'All',
  [msgIds.MSG_PROFILE_EDITOR_PRESENTATION_ALIAS_LABEL]: 'Profile alias (for personal use)',
  [msgIds.MSG_PROFILE_EDITOR_PRESENTATION_TITLE_LABEL]: 'Title',
  [msgIds.MSG_PROFILE_EDITOR_IDENTITY_TITLE]: 'Identity',
  [msgIds.MSG_PROFILE_EDITOR_IDENTITY_WARNING]: 'The following information can only be changed by the helpdesk',
  [msgIds.MSG_PROFILE_EDITOR_IDENTITY_BIRTH_COUNTRY]: 'Birth country',
  [msgIds.MSG_PROFILE_EDITOR_IDENTITY_BIRTH_CITY]: 'Birth city',
  [msgIds.MSG_PROFILE_EDITOR_IDENTITY_VAT]: 'VAT number',
  [msgIds.MSG_PROFILE_EDITOR_IDENTITY_CONFIRM_TITLE]: 'Edit identification data',
  [msgIds.MSG_PROFILE_EDITOR_IDENTITY_CONFIRM_BODY]:
    'The identification data cannot be modified independently. A request will be sent to the PersonalDOX help desk.\n\nAre you sure you want to proceed?',
  [msgIds.MSG_PROFILE_EDITOR_RESIDENCE_TITLE]: 'Residence',
  [msgIds.MSG_PROFILE_EDITOR_RESIDENCE_STRUCTURE_TITLE]: 'Office',
  [msgIds.MSG_PROFILE_EDITOR_CONTACTS_TITLE]: 'Contacts',
  [msgIds.MSG_PROFILE_EDITOR_CONTACTS_MOBILE_PHONE_1]: 'Mobile phone 1',
  [msgIds.MSG_PROFILE_EDITOR_CONTACTS_MOBILE_PHONE_2]: 'Mobile phone 2',
  [msgIds.MSG_PROFILE_EDITOR_CONTACTS_EMAIL_1]: 'Email 1',
  [msgIds.MSG_PROFILE_EDITOR_CONTACTS_EMAIL_2]: 'Email 2',
  [msgIds.MSG_PROFILE_EDITOR_CONTACTS_EMAIL_3]: 'Email 3',
  [msgIds.MSG_PROFILE_EDITOR_CONTACTS_EMAIL_4]: 'Email 4',
  [msgIds.MSG_PROFILE_EDITOR_CONTACTS_WEBSITE]: 'Website',
  [msgIds.MSG_PROFILE_EDITOR_BILLING_TITLE]: 'Billing',
  [msgIds.MSG_PROFILE_EDITOR_BILLING_INFO]: 'The following information will not be made public',
  [msgIds.MSG_PROFILE_EDITOR_BILLING_SDI]: 'SDI code',
  [msgIds.MSG_PROFILE_EDITOR_BILLING_PEC]: 'PEC',
  [msgIds.MSG_PROFILE_EDITOR_OPERATOR_CLOSE_PLACEHOLDER_ACCOUNT]:
    'This operation cannot be undone.\n\nThe data linked to the profile you are closing will be removed from our records.',
  [msgIds.MSG_PROFILE_EDITOR_OPERATOR_CLOSE_COLLABORATOR_PROFILE]:
    'ATTENTION!\nBy closing this profile the collaborator will no longer be able to access the resources of this structure.\n\nTo become a collaborator again it will be necessary to send a new invitation.',
  [msgIds.MSG_PROFILE_EDITOR_CLOSE_DIALOG_ADVERTISING]:
    "ATTENZIONE! Questa operazione non può essere annullata.\n\nIn accordo con quanto indicato nell'informativa privacy, i dati collegati al profilo che stai chiudendo verranno rimossi dai nostri archivi.\n\n",

  // PersonConfigurations
  [msgIds.MSG_ACCOUNT_CONFIGURATIONS_PAGE_TITLE]: 'Configurations',
  [msgIds.MSG_PASSWORD_EDITOR_TITLE]: 'Password',
  [msgIds.MSG_PASSWORD_EDITOR_INFO]:
    'Password must have at least 8 characters, must contain at least one number, uppercase letters, lowercase letters and at least one special character from !%@-\n\nFor example: d4Re!pO%',
  [msgIds.MSG_PASSWORD_EDITOR_PASSWORD]: 'Password',
  [msgIds.MSG_PASSWORD_EDITOR_PASSWORD_CONFIRMATION]: 'Password confirmation',

  // TOTP
  [msgIds.MSG_TOTP_EDITOR_TITLE]: 'Two-factor authentication (TOTP)',
  [msgIds.MSG_TOTP_EDITOR_ENABLE_LABEL]: 'I want to use the TOTP code to access my account',
  [msgIds.MSG_TOTP_EDITOR_INFO]:
    'TOTP guarantees more security for your account.\nTo log in you will need to enter a temporary code generated by a specific application.',
  [msgIds.MSG_TOTP_EDITOR_GUIDE_LINK]: 'Read how TOTP code generation works',
  [msgIds.MSG_TOTP_EDITOR_CODE_INPUT_INFO]: 'Enter the TOTP code generated by your phone app',
  [msgIds.MSG_TOTP_EDITOR_CODE_INPUT_LABEL]: 'TOTP Code',
  [msgIds.MSG_TOTP_EDITOR_CODE_TRY_BUTTON]: 'Try TOTP',
  [msgIds.MSG_TOTP_EDITOR_QRCODE_INFO]:
    'Please use the QRCode or code we generated to set up your phone app.\nIt will allow you to generate TOTP codes.',
  [msgIds.MSG_TOTP_EDITOR_QRCODE_WARNING]: 'WARNING! this code will no longer be displayed.',
  [msgIds.MSG_TOTP_EDITOR_QRCODE_CODE_LABEL]: 'Code for configuration:',
  [msgIds.MSG_TOTP_EDITOR_DIALOG_DISABLE_TITLE]: 'Confirm using TOTP',
  [msgIds.MSG_TOTP_EDITOR_DIALOG_DISABLE_INFO]:
    'If you disable two-factor authentication, access to your data will be less secure.\nDo you want to proceed anyway?',
  [msgIds.MSG_TOTP_EDITOR_VALID_CODE]: 'Valid TOTP code',
  [msgIds.MSG_TOTP_EDITOR_SUCCESSFULLY_ENABLED]: 'Two factor authentication enabled',
  [msgIds.MSG_TOTP_EDITOR_SUCCESSFULLY_DISABLED]: 'Two factor authentication disabled',

  // configurations authorizations
  [msgIds.MSG_CONFIGURATIONS_AUTH_EDITOR_TITLE]: 'Permissions settings',
  [msgIds.MSG_CONFIGURATIONS_AUTH_EDITOR_INFO]:
    'When you authorize a structure to see your dox or document, this authorization will last for the number of days indicated in the field below. The professionals of the authorized structure can stop sharing before this time expires.',
  [msgIds.MSG_CONFIGURATIONS_AUTH_EDITOR_INPUT_LABEL]: 'Default duration in days of dox and document shares',
  [msgIds.MSG_CONFIGURATIONS_AUTH_EDITOR_INPUT_ERROR]: 'Value must be between 1 and 135',

  // configurations notifications
  [msgIds.MSG_NOTIFICATIONS_SETTINGS_EDITOR_TITLE]: 'Notifications to be sent to the email associated with the profile',
  [msgIds.MSG_NOTIFICATIONS_SETTINGS_EDITOR_TYPE_DOX_RECEIVED_LABEL]: 'A dox was delivered by a structure',
  [msgIds.MSG_NOTIFICATIONS_SETTINGS_EDITOR_TYPE_DOCUMENT_REVISION_CREATED_LABEL]:
    'A document delivered by a structure has been revisionated',
  [msgIds.MSG_NOTIFICATIONS_SETTINGS_EDITOR_TYPE_DOCUMENT_VIEWED_LABEL]:
    'A document delivered by a structure has been viewed',
  [msgIds.MSG_NOTIFICATIONS_SETTINGS_EDITOR_TYPE_DOCUMENT_DOWNLOADED_LABEL]:
    'A document delivered by a structure has been downloaded',
  [msgIds.MSG_NOTIFICATIONS_SETTINGS_EDITOR_TYPE_PRIVACY_POLICY_UPDATED_LABEL]:
    "A property's privacy policy has changed",

  // General settings
  [msgIds.MSG_GENERAL_SETTINGS_TITLE]: 'General settings',
  [msgIds.MSG_GENERAL_SETTINGS_CHECKBOX_LABEL]: 'Enable automatic saving of documents edited in PersonalDox',
  [msgIds.MSG_GENERAL_SETTINGS_AUTOSAVE_TIME_INFO]: 'To activate automatic saving, set a value greater than 0',
  [msgIds.MSG_GENERAL_SETTINGS_AUTOSAVE_TIME_INPUT_LABEL]: 'Autosave time (seconds)',

  // Documents Templates
  [msgIds.MSG_DOCUMENTS_TEMPLATES_TITLE]: 'Private documents templates',
  [msgIds.MSG_DOCUMENTS_TEMPLATES_LIST_LABEL]: 'List of templates ({{count}})',
  [msgIds.MSG_DOCUMENTS_TEMPLATES_LIST_ADD_BUTTON]: '+ New',
  [msgIds.MSG_DOCUMENTS_TEMPLATES_TEMPLATE_NAME_LABEL]: 'Name',
  [msgIds.MSG_DOCUMENTS_TEMPLATES_TEMPLATE_DESCRIPTION_LABEL]: 'Description',
  [msgIds.MSG_DOCUMENTS_TEMPLATES_TEMPLATE_CONTENT_LABEL]: 'Content',
  [msgIds.MSG_DOCUMENTS_TEMPLATES_TEMPLATE_NAME_DEFAULT]: 'New template',
  [msgIds.MSG_DOCUMENTS_TEMPLATES_DELETE_DIALOG_CONFIRM_TITLE]: 'Template deletion',
  [msgIds.MSG_DOCUMENTS_TEMPLATES_DELETE_DIALOG_CONFIRM_BODY]: 'Are you sure to delete the template?',

  // Document templates list dialog
  [msgIds.MSG_DOCUMENTS_TEMPLATES_DIALOG_TITLE]: 'Document templates',

  // Document templates
  [msgIds.MSG_DOCUMENTS_TEMPLATES_TEMPLATES_LIST_EMPTY]:
    "You have not created any templates yet.\n\nIn the side menu, go to 'Structure Settings' -> 'Archive Management'.\n\nIn the 'Internal Document Templates' area you will find the tool that allows you to configure predefined templates to reuse if necessary.",

  // Document dialogs
  [msgIds.MSG_DOCUMENT_NOT_SAVED_CHANGED_DIALOG_BODY]: 'Document: {{name}}\n\nSave unsaved changes?',

  // Documents Templates
  [msgIds.MSG_DOXES_TEMPLATES_LIST_LABEL]: 'List of templates ({{count}})',
  [msgIds.MSG_DOXES_TEMPLATES_TITLE]: 'Organized Dox templates',
  [msgIds.MSG_DOXES_TEMPLATES_LIST_ADD_BUTTON]: '+ New',
  [msgIds.MSG_DOXES_TEMPLATES_TEMPLATE_NAME_LABEL]: 'Name',
  [msgIds.MSG_DOXES_TEMPLATES_TEMPLATE_DESCRIPTION_LABEL]: 'Description',
  [msgIds.MSG_DOXES_TEMPLATES_DOXES_LABEL]: 'Struttura dei DOX',
  [msgIds.MSG_DOXES_TEMPLATES_DOX_ADD_BUTTON]: '+ Aggiungi DOX',
  [msgIds.MSG_DOXES_TEMPLATES_DOX_NAME_DEFAULT]: 'Nuovo Dox',
  [msgIds.MSG_DOXES_TEMPLATES_TEMPLATE_NAME_DEFAULT]: 'New template',
  [msgIds.MSG_DOXES_TEMPLATES_DELETE_MODEL_DIALOG_CONFIRM_TITLE]: 'Template deletion',
  [msgIds.MSG_DOXES_TEMPLATES_DELETE_MODEL_DIALOG_CONFIRM_BODY]: 'Are you sure to delete the template?',
  [msgIds.MSG_DOXES_TEMPLATES_DELETE_ELEMENT_DIALOG_CONFIRM_TITLE]: 'Element deletion',
  [msgIds.MSG_DOXES_TEMPLATES_DELETE_ELEMENT_DIALOG_CONFIRM_BODY]: 'Are you sure to delete the element?',

  // Doxes Templates
  [msgIds.MSG_DOX_TEMPLATE_EDITOR_DIALOG_TITLE]: 'Dox template',
  [msgIds.MSG_DOX_TEMPLATE_EDITOR_AUTO_RETENTION_DATE_LABEL]: 'Automatically set retention end date',

  // Dox templates list dialog
  [msgIds.MSG_DOX_TEMPLATES_DIALOG_TITLE]: 'Organized Dox templates',

  // Organized doxes
  [msgIds.MSG_ORGANIZED_DOX_ERROR_DIALOG_TITLE]: 'Error in the organized dox template',
  [msgIds.MSG_ORGANIZED_DOX_ERROR_DIALOG_BODY]:
    'In the organized Dox model an invalid treatment is used therefore it is not possible to proceed',
  [msgIds.MSG_ORGANIZED_DOX_TEMPLATES_LIST_EMPTY]:
    "You have not configured any templates yet.\n\nIn the side menu, go to 'Structure Settings' -> 'Archive Management'.\n\nIn the 'Organized Dox Templates' area you will find the tool that allows you to configure Dox groups with default names and settings.",

  // Users data form
  [msgIds.MSG_USERS_DATA_TITLE]: 'Users data',
  [msgIds.MSG_USERS_DATA_VISIBILITY_ALL_LABEL]: 'Can see all data',
  [msgIds.MSG_USERS_DATA_VISIBILITY_BY_FINALITY_LABEL]: 'Can see data for the following finalities',

  // Users auth form
  [msgIds.MSG_USERS_AUTH_TITLE]: 'Users management',
  [msgIds.MSG_USERS_AUTH_VISIBILITY_ALL_LABEL]: 'Can see all structure users',
  [msgIds.MSG_USERS_AUTH_VISIBILITY_BY_USERS_LABEL]: 'Can see the following users',

  // Privacy management
  [msgIds.MSG_PRIVACY_MANAGEMENT_TITLE]: 'Privacy policy management',
  [msgIds.MSG_PRIVACY_MANAGEMENT_VIEW_LABEL]: 'Can see privacy policy',
  [msgIds.MSG_PRIVACY_MANAGEMENT_EDIT_LABEL]: 'Can edit privacy policy',

  // Invitations management
  [msgIds.MSG_INVITATIONS_MANAGEMENT_TITLE]: 'Invitations management',
  [msgIds.MSG_INVITATIONS_MANAGEMENT_ENABLED_LABEL]: 'Can invite other users to collaborate with the structure',

  // Collaborators management
  [msgIds.MSG_COLLABORATORS_MANAGEMENT_TITLE]: 'Collaborators management',
  [msgIds.MSG_COLLABORATORS_MANAGEMENT_VIEW_AUTH_LABEL]: 'Can see collaborators authorizations',
  [msgIds.MSG_COLLABORATORS_MANAGEMENT_ADD_AUTH_LABEL]: 'Can authorize collaborators',
  [msgIds.MSG_COLLABORATORS_MANAGEMENT_REMOVE_AUTH_LABEL]: 'Can revoce collaborators authorizations',
  [msgIds.MSG_COLLABORATORS_MANAGEMENT_EDIT_PROFILE_LABEL]: 'Can edit collaborators profiles',

  // Communes
  [msgIds.MSG_COMMUNE_AUTOCOMPLETE_TIP]: 'Type your city name',

  // History
  [msgIds.MSG_HISTORY_EVENT_SUBTYPE_DSC_DATA_PROCESSING]: 'to the processing of personal data',
  [msgIds.MSG_HISTORY_EVENT_SUBTYPE_DSC_ALWAYS_DOWNLOAD]: 'to download documents and doxes',
  [msgIds.MSG_HISTORY_EVENT_SUBTYPE_DSC_VIEW_DOX]: 'when viewing the dox',
  [msgIds.MSG_HISTORY_EVENT_SUBTYPE_DSC_VIEW_DOCUMENTS]: 'when viewing the document',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_USER_CREATED]: 'registration cancelled',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_USER_UPDATED]: 'updated registration',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_USER_ACTIVATED]: 'registration activated',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_USER_SUSPENDED]: 'registration suspended',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_USER_UPCOMING_DELETION]: 'registration close to cancellation',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_USER_DELETED]: 'registration cancelled',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_PROFILE_CREATED]: 'profile created',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_PROFILE_ATTACHED]: 'profile linked to company data',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_PROFILE_DETACHED]: 'profile detached from the company registry',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_PROFILE_UPCOMING_SUSPENSION]: 'profile close to suspension',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_PROFILE_SUSPENDED]: 'suspended profile',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_PROFILE_UPCOMING_DELETION]: 'profile close to deletion',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_PROFILE_DELETED]: 'profile deleted',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_INVITATION_CREATED]: 'invitation sent',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_INVITATION_REVOKED]: 'invitation canceled',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_INVITATION_ACCEPTED]: 'invitation accepted',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_PERMISSION_CREATED]: 'authorized {{subjectTypeDsc}}',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_PERMISSION_UPDATED]: 'authorization modified {{subjectTypeDsc}}',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_PERMISSION_SUSPENDED]: 'authorization suspended {{subjectTypeDsc}}',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_PERMISSION_RESTORED]: 'authorization reactivated {{subjectTypeDsc}}',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_PERMISSION_UPCOMING_EXPIRATION]:
    'authorization close to expire {{subjectTypeDsc}}',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_PERMISSION_EXPIRED]: 'authorization expired {{subjectTypeDsc}}',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_PERMISSION_DELETED]: 'authorization revoked {{subjectTypeDsc}}',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_PERMISSION_SENDED]: 'sended mail with link {{subjectTypeDsc}}',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_TREATMENT_CREATED]: 'Treatment created',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_TREATMENT_UPDATED]: 'Treatment modified',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_TREATMENT_DELETED]: 'Treatment deleted',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_TREATMENT_ENABLED]: 'Treatment reactivated',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_TREATMENT_DISABLED]: 'Treatment suspended',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_CONTRACT_VERSION_CREATED]: 'contract version created',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_CONTRACT_VERSION_UPDATED]: 'contract versione updated',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_CONTRACT_VERSION_PUBLISHED]: 'contract versione published',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_CONTRACT_VERSION_DELETED]: 'contract versione deleted',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_DATA_PROCESSING_SECTION_VIEWED]: 'having read the privacy information',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_CONSENT_GRANTED]: 'consent granted',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_CONSENT_REVOKED]: 'consent revoked',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_DOCUMENT_REVISION_CREATED]: 'document revision created',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_DOCUMENT_REVISION_UPDATED]: 'document revision modified',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_DOCUMENT_ARCHIVED]: 'document archived',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_DOCUMENT_REVISION_DELETED]: 'document revision canceled',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_DOCUMENT_REVISION_DOWNLOADED]: 'document revision downloaded',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_DOCUMENT_REVISION_VIEWED]: 'document revision viewed',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_DOCUMENT_CREATED]: 'document created',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_DOCUMENT_RENAMED]: 'document renamed',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_DOCUMENT_DELETED]: 'document deleted',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_DOCUMENT_DELIVERED]: 'document delivered',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_DOCUMENT_MARKED_AS_OBSOLETE]: 'marked as obsolete',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_DOCUMENT_MARKED_AS_IMPORTANT]: 'marked as important',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_RETENTION_DEADLINE_REMINDER]: 'storage expiry reminder',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_RETENTION_EXPIRED]: 'storage deadline notice',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_DOX_CREATED]: 'dox created',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_DOX_UPDATED]: 'dox modified',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_DOX_DELETED]: 'dox canceled',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_DOX_DOWNLOADED]: 'dox downloaded',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_DOX_VIEWED]: 'dox viewed',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_DOX_DELIVERED]: 'dox delivered',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_INVOICE_FINALIZED]: 'invoice sended',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_ACTIVITY_CREATED]: '?? internal ??',
  [msgIds.MSG_HISTORY_EVENT_TYPE_DSC_NOTIFICATION_CREATED]: '?? internal ??',
  [msgIds.MSG_HISTORY_BODY_TREATMENT_OF_DATA]: 'data processing',
  [msgIds.MSG_HISTORY_BODY_DOCUMENT_VISUALIZATION]: 'document view',
  [msgIds.MSG_HISTORY_BODY_DOX_VISUALIZATION]: 'dox display',
  [msgIds.MSG_HISTORY_BODY_SEND_OF_INVITATION]: 'sending an invitation',
  [msgIds.MSG_HISTORY_BODY_PERMISSION_CREATED]:
    'Action performed automatically following the publication of a new privacy policy which does not require new consent',
  [msgIds.MSG_HISTORY_BODY_PERMISSION_DATA_PROCESSING]: 'data processing',
  [msgIds.MSG_HISTORY_BODY_PERMISSION_VIEW_DOCUMENTS]: 'view documents',
  [msgIds.MSG_HISTORY_BODY_PERMISSION_VIEW_DOX]: 'view dox',
  [msgIds.MSG_HISTORY_BODY_PERMISSION_INVITE_PROFILES]: 'send an invitation',
  [msgIds.MSG_HISTORY_BODY_PERMISSION_VIEW_PROFILE]: 'view profile data',
  [msgIds.MSG_HISTORY_BODY_PERMISSION_ALLOWED_ACTIONS]: 'Allowed actions:',
  [msgIds.MSG_HISTORY_BODY_PERMISSION_PIN_SECURITY]: 'PIN Security:',
  [msgIds.MSG_HISTORY_BODY_RETENTION_UPCOMING_EXPIRATION]: 'retention expiring in {{daysCountdown}} days',
  [msgIds.MSG_HISTORY_BODY_RETENTION_EXPIRED]: 'conservation ended',
  [msgIds.MSG_HISTORY_BODY_DOX_DELIVERED]: 'dox delivered',
  [msgIds.MSG_HISTORY_BODY_DOX_RECEIVED]: 'dox received',
  [msgIds.MSG_HISTORY_BODY_DOX_NAME]: 'name',
  [msgIds.MSG_HISTORY_BODY_DOCUMENT_NAME]: 'title',
  [msgIds.MSG_HISTORY_BODY_DOCUMENT_PREVIOUS_NAME]: 'previous title',
  [msgIds.MSG_HISTORY_BODY_DOCUMENT_NOT_AVAILABLE_DRAFT]: 'not available (draft document)',
  [msgIds.MSG_HISTORY_BODY_TREATMENT_RETENTION_NOTICE_DAYS_BEFORE_EXPIRATION_YES]:
    'Sending deadline notification: YES, days in advance',
  [msgIds.MSG_HISTORY_BODY_TREATMENT_RETENTION_NOTICE_DAYS_BEFORE_EXPIRATION_NO]: 'Sending deadline notification: NO',
  [msgIds.MSG_HISTORY_BODY_TREATMENT_RETENTION_END_BEHAVIOR]: 'Behavior at the end of conservation',
  [msgIds.MSG_HISTORY_BODY_NEW_ACKNOWLEDGMENT_REQUIRED]: 'new acknowledgment required',
  [msgIds.MSG_HISTORY_BODY_NEW_ACKNOWLEDGMENT_NOT_REQUIRED]: 'new acknowledgment not required',
  [msgIds.MSG_HISTORY_BODY_NEW_CONSENTS_REQUIRED]: 'new consents required',
  [msgIds.MSG_HISTORY_BODY_NEW_CONSENTS_NOT_REQUIRED]: 'consents implicitly renewed',
  [msgIds.MSG_HISTORY_BODY_CONTRACT_PUBLISHED_AT]: 'published at',
  [msgIds.MSG_HISTORY_BODY_CONSENT_RENEWED_AUTOMATICALLY]:
    'Action performed automatically following the publication of a new privacy policy which does not require new consent',
  [msgIds.MSG_HISTORY_BODY_CONSENT_RENEWED_FOR]: 'renewer consent for',
  [msgIds.MSG_HISTORY_BODY_CONSENT_GRANTED_FOR]: 'granted consent for',
  [msgIds.MSG_HISTORY_BODY_CONSENT_REVOKED_FOR]: 'revoked consent for',
  [msgIds.MSG_HISTORY_BODY_INVITATION_PURPOSE_COLLABORATE]: 'collabote with structure',

  // Dialog
  [msgIds.MSG_HISTORY_DIALOG_DETAILS_TITLE]: 'Chronology event',
  [msgIds.MSG_HISTORY_SHOW_DOCUMENT_TITLE]: 'Show document',
  [msgIds.MSG_HISTORY_SHOW_DOCUMENT_NOT_AVAILABLE]: 'Document not available',
  [msgIds.MSG_HISTORY_SHOW_DOX_TITLE]: 'Show dox',
  [msgIds.MSG_HISTORY_SHOW_DOX_NOT_AVAILABLE]: 'Dox not available',
  [msgIds.MSG_HISTORY_SHOW_CONTRACT_TITLE]: 'Show contract',
  [msgIds.MSG_HISTORY_SHOW_CONTRACT_NOT_AVAILABLE]: 'Contract not available',

  // Collaborator authorizations
  [msgIds.MSG_COLLABORATOR_AUTHORIZATION_DELETE_CONFIRM]: 'Are you sure you want to revoke this permission?',

  // AccountCardPage
  [msgIds.MSG_ACCOUNT_CARD_PAGE_COMMAND_PRIVACY_POLICY]: 'Privacy policy',
  [msgIds.MSG_ACCOUNT_CARD_PAGE_COMMAND_OPERATORS]: 'Operators',
  [msgIds.MSG_ACCOUNT_CARD_PAGE_COMMAND_HISTORY]: 'History',
  [msgIds.MSG_ACCOUNT_CARD_PAGE_COMMAND_MODIFY]: 'Modify',
  [msgIds.MSG_ACCOUNT_CARD_PAGE_COMMAND_SEND_INVITATION]: 'Send invitation',
  [msgIds.MSG_ACCOUNT_CARD_PAGE_COMMAND_REVOKE_INVITATION]: 'Revoke invitation',
  [msgIds.MSG_ACCOUNT_CARD_PAGE_COMMAND_ACCEPT_INVITATION]: 'Accept invitation',
  [msgIds.MSG_ACCOUNT_CARD_PAGE_COMMAND_LINKED_STRUCTURE]: 'Structure',
  [msgIds.MSG_ACCOUNT_CARD_PAGE_COMMAND_AUTHORIZATIONS]: 'Authorizations',
  [msgIds.MSG_ACCOUNT_CARD_PAGE_COMMAND_SHARED_DOCUMENTS]: 'Shared documents',
  [msgIds.MSG_ACCOUNT_CARD_PAGE_COMMAND_ATTACH_TO_REAL_USER]: 'Attach to real user',
  [msgIds.MSG_ACCOUNT_CARD_PAGE_COMMAND_CLOSE_PROFILE]: 'Close profile',
  [msgIds.MSG_ACCOUNT_CARD_PAGE_COMMAND_ADD_OPERATOR_PROFILE]: 'Add operator profile',

  // AccountAuthorizationsPage
  [msgIds.MSG_ACCOUNT_AUTHORIZATIONS_PAGE_TITLE]: 'Authorizations of ${{identity}}',

  // NotFoundPage
  [msgIds.MSG_NOT_FOUND_PAGE_404_ERROR]: '404 - Page not found',
  [msgIds.MSG_NOT_FOUND_PAGE_ALERT]: 'The page you are looking for does not exist.',
  [msgIds.MSG_NOT_FOUND_PAGE_GO_TO_LOGIN_FOR_OPERATORS]: 'Go to the login page for operators',
  [msgIds.MSG_NOT_FOUND_PAGE_GO_TO_LOGIN_FOR_CUSTOMERS]: 'Go to the login page for private users',

  // Invitations
  [msgIds.MSG_INVITATION_SEND_TITLE]: 'Invitation',
  [msgIds.MSG_INVITATION_SEND_CONFIRM]:
    'The user will receive a notification inviting him to collaborate with your organization.\nIf he accepts the invitation, he will be assigned a dedicated profile but will not be able to access any resources until authorized.\n\nDo you want to proceed?',
  [msgIds.MSG_INVITATION_REVOKE_TITLE]: 'Revoke invitation',
  [msgIds.MSG_INVITATION_REVOKE_CONFIRM]:
    'ATTENTION: if this invitation has already been accepted, its revocation will have no effect.\n\nAre you sure you want to revoke this invitation?',
  [msgIds.MSG_INVITATION_ACCEPTANCE_TITLE]: 'Acceptance of invitation',
  [msgIds.MSG_INVITATION_ACCEPTANCE_CONFIRM]:
    'You have been invited to join this organization. If you accept, you will be assigned a new professional profile.\n\nATTENTION: until the permissions are configured you will not be able to access any resources of this organization.\n\nDo you accept the invitation?',
  [msgIds.MSG_INVITATION_AUTHORIZE_TITLE]: 'Authorize invitation',
  [msgIds.MSG_INVITATION_AUTHORIZE_CONFIRM]:
    'With this command you authorize the facility to send you an invitation to become one of its internal collaborators.\nTo allow the facility to identify you, your name and surname will be made available to it.\nThis authorization will be valid for 3 days after which it will be deactivated and removed from PersonalDOX archives.\n\nATTENTION:\nIf the organization is already carrying out other processing on your data, this authorization will have no effect on such processing.\n\nDo you authorize the organization to invite you to join the organization?',
  [msgIds.MSG_INVITATION_AUTHORIZATION_IMPOSSIBLE]: 'You have already authorized this property to invite you until',
  [msgIds.MSG_INVITATION_AUTHORIZATION_EXECUTED]:
    'When this structure invites you to join the organization you will receive a notification.\n\nTo accept the invitation click on the notification and confirm.\nA new professional profile will be created for you which will allow you to access the resources for which you have been authorized.',
  [msgIds.MSG_INVITATION_SENDED_SUCCESSFULLY]: 'Invitation sended successfully',

  // ContractVersionViewer
  [msgIds.MSG_CONSENTS_UPDATED_SUCCESSFULLY]: 'Settings and consents updated successfully',

  // EventHistoryPage
  [msgIds.MSG_EVENT_HISTORY_PAGE_TITLE]: 'History',

  // EventHistoryTable
  [msgIds.MSG_EVENT_HISTORY_TABLE_DATE_HEADER]: 'Date/time',
  [msgIds.MSG_EVENT_HISTORY_TABLE_AUTHOR_HEADER]: 'Executor',
  [msgIds.MSG_EVENT_HISTORY_TABLE_ACTION_HEADER]: 'Action',
  [msgIds.MSG_EVENT_HISTORY_TABLE_DESCRIPTION_HEADER]: 'Description',

  // EventHistoryFiltersForm
  [msgIds.MSG_EVENT_HISTORY_FILTERS_FORM_TITLE]: 'Filters',
  [msgIds.MSG_EVENT_HISTORY_FILTERS_FORM_SEARCH_STRUCTURE_PLACEHOLDER]: 'Search a structure',
  [msgIds.MSG_EVENT_HISTORY_FILTERS_FORM_SEARCH_OPERATOR_PLACEHOLDER]: 'Search a operator',

  // EventHistoryFilters keys
  [msgIds.MSG_EVENT_HISTORY_FILTER_FROM_KEY]: 'From',
  [msgIds.MSG_EVENT_HISTORY_FILTER_TO_KEY]: 'to',
  [msgIds.MSG_EVENT_HISTORY_FILTER_STRUCTURE_KEY]: 'Structure',
  [msgIds.MSG_EVENT_HISTORY_FILTER_OPERATOR_KEY]: 'Operator',
  [msgIds.MSG_EVENT_HISTORY_FILTER_TREATMENT]: 'Treatment',

  // Desktop
  [msgIds.MSG_DESKTOP_PAGE_TITLE]: 'Desktop',
  [msgIds.MSG_DESKTOP_CREATE_INTERNAL_DOCUMENT_FROM_TEMPLATE_TOOLTIP]:
    'Create a new internal document from a predefined template',
  [msgIds.MSG_DESKTOP_CREATE_INTERNAL_DOCUMENT_TOOLTIP]: 'Create a new internal document',
  [msgIds.MSG_DESKTOP_UPLOAD_DOCUMENT_TOOLTIP]: 'Upload a new document o drag&drop here',
  [msgIds.MSG_DESKTOP_CLEAR_TOOLTIP]: 'Clear desktop',
  [msgIds.MSG_DOCUMENTS_NOT_SAVED_DIALOG_TITLE]: 'Documents not saved',
  [msgIds.MSG_DOCUMENTS_NOT_SAVED_DIALOG_CONTENT]:
    'Warning, there are some documents to be saved, are you sure to continue?',

  // Profile selector Dialog
  [msgIds.MSG_PROFILE_SELECTOR_DIALOG_TITLE]: 'Select a profile',

  // Notice board
  [msgIds.MSG_NOTICE_BOARD_TITLE]: 'Notice board',
  [msgIds.MSG_NOTICE_BOARD_ADD_ELEMENT]: 'Add element',
  [msgIds.MSG_NOTICE_BOARD_EDIT_ELEMENT]: 'Edit note',
  [msgIds.MSG_NOTICE_BOARD_DELETE_ELEMENT]: 'Delete note',
  [msgIds.MSG_NOTICE_BOARD_EDIT_ELEMENT_DIALOG_TITLE]: 'Edit note',
  [msgIds.MSG_NOTICE_BOARD_DELETE_ELEMENT_DIALOG_CONFIRM_TITLE]: 'Note deletion',
  [msgIds.MSG_NOTICE_BOARD_DELETE_ELEMENT_DIALOG_CONFIRM_BODY]: 'Are you sure to delete the note?',

  // Login
  [msgIds.MSG_LOGIN_FOR_OPERATORS]: 'For the professional',
  [msgIds.MSG_LOGIN_FOR_CUSTOMERS]: 'For the private user',
  [msgIds.MSG_LOGIN_FOR_GUEST_OPERATORS]: 'For the guest professional user',
  [msgIds.MSG_LOGIN_FOR_GUEST_CUSTOMERS]: 'For the guest private user',
  [msgIds.MSG_LOGIN_FOR_GUEST_AUTHORIZATION_CHECK_IN_PROGRESS]: 'Checking authorization...',
  [msgIds.MSG_LOGIN_BUTTON_TITLE]: 'Accedi',
  [msgIds.MSG_LOGIN_FORGOT_PASSWORD_LINK]: 'Forgot password',
  [msgIds.MSG_LOGIN_REGISTER_LINK]: 'SignUp PersonalDOX',
  [msgIds.MSG_LOGIN_CREATE_NEW_PROFILE_LINK]: "I' signed up, I want to create a new profile",
  [msgIds.MSG_LOGIN_2FA_PROBLEM_QUESTION]: 'Are there any problems?',
  [msgIds.MSG_LOGIN_2FA_EMERGENCY_LINK]: 'Use the emergency OTP code',
  [msgIds.MSG_LOGIN_2FA_INFO]:
    'Open the app you choose for the TOTP code generation, copy and paste the code in this field',
  [msgIds.MSG_LOGIN_PASSWORD_RESET]: 'Update your password',
  [msgIds.MSG_LOGIN_FAILED_DIALOG_TITLE]: 'Access to PersonalDOX',
  [msgIds.MSG_LOGIN_FAILED_DIALOG_NO_AVAILABLE_BUSINESS_PROFILES]: 'You do not have any profiles for professional use',
  [msgIds.MSG_LOGIN_FAILED_DIALOG_NO_AVAILABLE_CONSUMER_PROFILES]: 'You do not have a private user profile',
  [msgIds.MSG_LOGIN_AS_BUSINESS_USER]: 'Access as professional user',
  [msgIds.MSG_LOGIN_AS_CONSUMER_USER]: 'Access as private user',
  [msgIds.MSG_LOGIN_GUEST_2FA_INFO]:
    'Enter your PIN to access. If you do not have a valid code, ask the person who authorized you to access the reserved area',
  [msgIds.MSG_LOGIN_GUEST_PIN_LABEL]: 'PIN code',
  [msgIds.MSG_OTP_RECOVERY_INFO]:
    'When you activated two-factor authentication with OTP, 10 emergency codes were generated for you. You can use one to access the service. Remember that each code can only be used once.',
  [msgIds.MSG_PASSWORD_RECOVERY_INFO]: 'Type the email used for signup in PersonalDox',
  [msgIds.MSG_PASSWORD_RECOVERY_SEND_EMAIL_BUTTON]: 'Send email',
  [msgIds.MSG_PASSWORD_RECOVERY_LOGIN_LINK]: 'Go to login',
  [msgIds.MSG_CONTRACTS_UPDATE_WARNING_DIALOG_TITLE]: 'Contract Changes',
  [msgIds.MSG_CONTRACTS_UPDATE_WARNING_ACCESS]: 'Sign in',
  [msgIds.MSG_CONTRACTS_UPDATE_WARNING_ACCESS_AND_MUTE_MSG]: "Log in and don't show this warning again",
  [msgIds.MSG_CONTRACTS_UPDATE_WARNING_SHOW_CONTRACTS]: 'Show new conditions',
  [msgIds.MSG_PASSWORD_RECOVERY_MAIL_SUCCESSFULLY]:
    'We will send an email to the address you provided. Follow the instructions inside to generate a new password',
  [msgIds.MSG_NO_OTHER_AVAILABLE_ACCOUNTS]: 'There are no other active profiles',

  // Registration
  [msgIds.MSG_PRIVATE_AREA_SELECTION_TITLE]: 'Private area selection',

  [msgIds.MSG_USER_BUSINESS]: 'Business',
  [msgIds.MSG_USER_PRIVATE]: 'Private user',
  [msgIds.MSG_REGISTER_BUTTON_TITLE]: 'Register',

  [msgIds.MSG_REGISTRATION_PAGE_TITLE_CONTRACT_REVISION]: 'Contracts update for profile {{profileTypeDsc}}',
  [msgIds.MSG_REGISTRATION_PAGE_TITLE_PROFILE_CREATION]: "Creation of a new profile '{{profileTypeDsc}}'",
  [msgIds.MSG_REGISTRATION_PAGE_TITLE_USER_REGISTRATION]: 'New account creation',
  [msgIds.MSG_REGISTRATION_PAGE_TITLE_CHANGE_PLAN]: 'Setting up billing information',

  [msgIds.MSG_REGISTRATION_CONTRACT_PRIVACY_POLICY_TITLE]: 'Privacy policy',
  [msgIds.MSG_REGISTRATION_CONTRACT_TERMS_POLICY_TITLE]: 'Terms and conditions',
  [msgIds.MSG_REGISTRATION_CONTRACT_DATA_PROCESSOR]: 'Data processing',
  [msgIds.MSG_REGISTRATION_PERSON_IDENTITY_TITLE]: 'Personal data',
  [msgIds.MSG_REGISTRATION_BILLING_TITLE]: 'Billing information',
  [msgIds.MSG_REGISTRATION_COMPANY_IDENTITY_TITLE]: 'Company',
  [msgIds.MSG_REGISTRATION_CREDENTIALS_TITLE]: 'Credentials',
  [msgIds.MSG_REGISTRATION_SUMMARY_TITLE]: 'Riepilogo',
  [msgIds.MSG_REGISTRATION_CONFIRM_DIALOG_TITLE]: 'Conclusion of the procedure',
  [msgIds.MSG_REGISTRATION_CONFIRM_DIALOG_BODY]: 'Do you confirm that you wish to proceed?',
  [msgIds.MSG_REGISTRATION_SUCCESS_MESSAGE_NEW_CONSENTS]:
    'Your preferences have been saved\n\nYou will be forwarded to the dashboard in a few moments',
  [msgIds.MSG_REGISTRATION_SUCCESS_MESSAGE_NEW_PROFILE]:
    'Your new profile has been activated\n\nYou will be forwarded to the dashboard in a few moments.',
  [msgIds.MSG_REGISTRATION_SUCCESS_MESSAGE_NEW_ACCOUNT]:
    'The operation was successful.\nWe have sent you an email to the address you provided.\nFollow the instructions inside to complete the registration process',
  [msgIds.MSG_REGISTRATION_SUCCESS_MESSAGE_BILLING_DATA_UPDATED]: 'Billing information has been updated',

  [msgIds.MSG_REGISTRATION_SUMMARY_CONTACT_EMAIL_SECTION_TITLE]: 'Contact email',
  [msgIds.MSG_REGISTRATION_SUMMARY_PERSONAL_IDENTITY_SECTION_TITLE]: 'Personal identity',
  [msgIds.MSG_REGISTRATION_SUMMARY_COMPANY_SECTION_TITLE]: 'Company identity',
  [msgIds.MSG_REGISTRATION_SUMMARY_BILLING_TITLE]: 'Billing information',
  [msgIds.MSG_REGISTRATION_SUMMARY_CONFIRM_FOR_CONSENTS]: "Press 'Confirm' to apply the consents",

  // user activation
  [msgIds.MSG_USER_ACTIVATION_TITLE]: 'Account activation',
  [msgIds.MSG_USER_ACTIVATION_IN_PROGRESS]: 'Activation in progress',
  [msgIds.MSG_USER_ACTIVATION_WAIT]: 'Please wait...',
  [msgIds.MSG_USER_ACTIVATION_INFO1]: 'You will soon be able to log in with your credentials',
  [msgIds.MSG_USER_ACTIVATION_DONE]: 'Activation done',
  [msgIds.MSG_USER_ACTIVATION_FAILED]: 'Activation failed',

  [msgIds.MSG_NEXT_BUTTON_TITLE]: 'Next',
  [msgIds.MSG_PREVIOUS_BUTTON_TITLE]: 'Previous',

  // Billing
  [msgIds.MSG_BILLING_PRODUCT_FEATURE_PRF_SHOWCASE_DSC]: 'Presentation tab within the search engine',
  [msgIds.MSG_BILLING_PRODUCT_FEATURE_TREATMENTS_CONF_DSC]: 'Configuration of treatments',
  [msgIds.MSG_BILLING_PRODUCT_FEATURE_PRV_POLICY_EDITOR_DSC]: 'Editor for creating and downloading the privacy policy',
  [msgIds.MSG_BILLING_PRODUCT_FEATURE_DATA_PROCESSING_MAX_DSC]: 'Maximum number of data treatments',
  [msgIds.MSG_BILLING_PRODUCT_FEATURE_PRV_PLANT_HIST_DSC]: 'History of the privacy system',
  [msgIds.MSG_BILLING_PRODUCT_FEATURE_PRV_POLICY_CONSENTS_DSC]:
    'Collection and historicization of the acknowledgment and consent to the processing',
  [msgIds.MSG_BILLING_PRODUCT_FEATURE_MIN_ANAGRAPHIC_DSC]: 'User data minimized based on data processing and storage',
  [msgIds.MSG_BILLING_PRODUCT_FEATURE_SHARED_DOCS_READ_DSC]: 'Reading documents shared by private users',
  [msgIds.MSG_BILLING_PRODUCT_FEATURE_SHARED_DOCS_DWN_DSC]: 'Download documents shared by private users',
  [msgIds.MSG_BILLING_PRODUCT_FEATURE_COMPANY_ARCHIVES_DSC]:
    'A company archive for each user that can be organized via doxes',
  [msgIds.MSG_BILLING_PRODUCT_FEATURE_DATA_RETENTION_DSC]: 'Data retention management',
  [msgIds.MSG_BILLING_PRODUCT_FEATURE_DOX_AND_DOC_HIST_DSC]: 'History of actions carried out on doxes and documents',
  [msgIds.MSG_BILLING_PRODUCT_FEATURE_USERS_HIST_DSC]: 'History of actions carried out by private users',
  [msgIds.MSG_BILLING_PRODUCT_FEATURE_INT_DOCS_EDITOR_DSC]: 'Creating new documents with the built-in editor',
  [msgIds.MSG_BILLING_PRODUCT_FEATURE_EXT_DOCS_EDITOR_DSC]: 'Loading scanned documents',
  [msgIds.MSG_BILLING_PRODUCT_FEATURE_DOX_DELIVERY_DSC]: 'Delivery of doxes and documents to private users',
  [msgIds.MSG_BILLING_PRODUCT_FEATURE_SEARCH_ENGINE_DSC]:
    'Search for operators and facilities within the search engine',
  [msgIds.MSG_BILLING_PRODUCT_FEATURE_DATA_PROCESSING_DSC]:
    'Activation and monitoring of data processing with the structures',
  [msgIds.MSG_BILLING_PRODUCT_FEATURE_ARCHIVE_DSC]: 'Organization of the personal archive using doxes',
  [msgIds.MSG_BILLING_PRODUCT_FEATURE_DOX_AND_DOCS_SHARING_DSC]: 'Sharing documents with operators and facilities',
  [msgIds.MSG_BILLING_PRODUCT_FEATURE_DOX_AND_DOCS_RECEINVING_DSC]:
    'Reception of doxes and documents delivered by the structures',
  [msgIds.MSG_BILLING_PRODUCT_FEATURE_OPERATOR_HIST_DSC]:
    'Chronology of actions carried out by operators and structures',

  [msgIds.MSG_BILLING_PRODUCT_FEATURE_UNLIMITED]: 'unlimited',
  [msgIds.MSG_BILLING_PRODUCTS_FOR_OPERATOR]: 'Products for the professional',
  [msgIds.MSG_BILLING_PRODUCTS_FOR_USER]: 'Products for the private user',
  [msgIds.MSG_BILLING_PRODUCTS]: 'Products',
  [msgIds.MSG_BILLING_TRIAL_FOR_DAYS]: "Try the '{{name}}' plan for free for {{trialDays}} days",
  [msgIds.MSG_BILLING_PLAN_ACTIVATE]: 'ACTIVATE',
  [msgIds.MSG_BILLING_PLAN_VERIFY]: 'VERIFY',
  [msgIds.MSG_BILLING_PRICE_FOR_USER]: 'for user',
  [msgIds.MSG_BILLING_PRICE_VAT_EXCLUDED]: '* vat excluded',
  [msgIds.MSG_BILLING_TRIAL_STARTED_SUCCESSFULLY]: 'The trial period has been activated',

  // Subscriptions
  [msgIds.MSG_SUBSCRIPTION_STATE_ACTIVE]: 'active',
  [msgIds.MSG_SUBSCRIPTION_STATE_NOT_ACTIVE]: 'not active',
  [msgIds.MSG_SUBSCRIPTION_STATE_TRIALING]: 'trialing',
  [msgIds.MSG_SUBSCRIPTION_USE_PLAN_NOT_ACTIVE]: 'Plan in use\n({{name}} not active)',
  [msgIds.MSG_SUBSCRIPTION_USE_PLAN_IN_TRIAL]: 'Plan in use\n(on trial)',
  [msgIds.MSG_SUBSCRIPTION_USE_PLAN_IN_USE]: 'Plan in use',
  [msgIds.MSG_SUBSCRIPTION_ACTIVATION_TITLE]: 'Subscription activation',
  [msgIds.MSG_SUBSCRIPTION_ACTIVATION_BODY1]: 'Choose one of the following options to proceed correctly',
  [msgIds.MSG_SUBSCRIPTION_ACTIVATION_BODY2]:
    'To activate your subscription we must first authenticate you. Choose one of the following options to proceed correctly',
  [msgIds.MSG_SUBSCRIPTION_ACTIVATION_ACTIVATE_NEW_PROFILE]: 'I want to activate a new profile',
  [msgIds.MSG_SUBSCRIPTION_ACTIVATION_CHANGE_PROFILE_PLAN]: 'I want to change my profile plan',
  [msgIds.MSG_SUBSCRIPTION_ACTIVATION_CREATE_NEW_ACCOUNT]: 'Register me in PersonalDOX',
  [msgIds.MSG_SUBSCRIPTION_ACTIVATION_REGISTERED_ACTIVATE_NEW_PROFILE]:
    'I am already registered, I want to activate a new profile',
  [msgIds.MSG_SUBSCRIPTION_ACTIVATION_REGISTERED_CHANGE_PROFILE_PLAN]:
    'I am already registered, I want to change the plan of my profile',

  // Subscriptions
  [msgIds.MSG_SUBSCRIPTION_INFO_STATUS_TITLE]: 'State',
  [msgIds.MSG_SUBSCRIPTION_INFO_START_AT_TITLE]: 'Start of current period',
  [msgIds.MSG_SUBSCRIPTION_INFO_END_AT_TITLE]: 'End of current period',
  [msgIds.MSG_SUBSCRIPTION_INFO_AMOUNT_TITLE]: 'Amount',
  [msgIds.MSG_SUBSCRIPTION_INFO_DISCOUNT_TITLE]: 'Discount',
  [msgIds.MSG_SUBSCRIPTION_INFO_STATUS_ACTIVE]: 'Active',
  [msgIds.MSG_SUBSCRIPTION_INFO_STATUS_PAST_DUE]: 'Incomplete',
  [msgIds.MSG_SUBSCRIPTION_INFO_STATUS_UNPAID]: 'Unpaid',
  [msgIds.MSG_SUBSCRIPTION_INFO_STATUS_CANCELED]: 'Canceled',
  [msgIds.MSG_SUBSCRIPTION_INFO_STATUS_INCOMPLETE]: 'Incomplete',
  [msgIds.MSG_SUBSCRIPTION_INFO_STATUS_INCOMPLETE_EXPIRED]: 'Incomplete - expired',
  [msgIds.MSG_SUBSCRIPTION_INFO_STATUS_TRIALING]: 'Trialing',
  [msgIds.MSG_SUBSCRIPTION_INFO_COMMAND_INVOICES]: 'Invoices',
  [msgIds.MSG_SUBSCRIPTION_INFO_COMMAND_PAYMENT_METHOD]: 'Payment method',
  [msgIds.MSG_SUBSCRIPTION_INFO_COMMAND_CHANGE_PLAN]: 'change plan',
  [msgIds.MSG_SUBSCRIPTION_CHANGE_ALREADY_IN_USE]: 'The plan you choose corresponds to the one currently in use',
  [msgIds.MSG_SUBSCRIPTION_USER_ALREADY_REGISTERED]: 'I already have an account to enter PersonalDOX',
  [msgIds.MSG_SUBSCRIPTION_USER_NOT_REGISTERED]: "I don't have an account to log in to PersonalDOX",

  // Check Legal Status
  [msgIds.MSG_CHECL_LEGAL_STATUS_DIALOG_INTRO_HEAVY_CHANGES]:
    'Substantial changes have been made to the following contracts:',
  [msgIds.MSG_CHECL_LEGAL_STATUS_DIALOG_INTRO_OPTIONAL_CONSENTS_REQUESTED]:
    'Substantial changes have been made to the following contracts for which new consents are required:',
  [msgIds.MSG_CHECL_LEGAL_STATUS_DIALOG_INTRO_LIGHT_CHANGES]:
    '**Non-substantial** changes have been made to the following contracts:',
  [msgIds.MSG_CHECL_LEGAL_STATUS_DIALOG_INTRO_PRIVACY_POLICY]: '\n1. **privacy policy**',
  [msgIds.MSG_CHECL_LEGAL_STATUS_DIALOG_INTRO_TERMS_OF_USE]: '\n1. **Terms of Use**',
  [msgIds.MSG_CHECL_LEGAL_STATUS_DIALOG_INTRO_DATA_PROCESSOR]: '\n1. **appointment as data controller**',
  [msgIds.MSG_CHECL_LEGAL_STATUS_DIALOG_INTRO_REDIRECT_TO_CONTRACTS]:
    'You will be forwarded to the page where you can view the new conditions.',
  [msgIds.MSG_CHECL_LEGAL_STATUS_DIALOG_INTRO_CONSENTS_VALID]: 'The consents previously provided are still valid.',

  // Payment method
  [msgIds.MSG_PAYMENT_METHOD_PAGE_TITLE]: 'Payment method',
  [msgIds.MSG_PAYMENT_METHOD_PAGE_INFO1]: 'For payment management we use the platform',
  [msgIds.MSG_PAYMENT_METHOD_PAGE_INFO2]:
    'Your payment method information does not pass through our servers or be saved in our archives.',
  [msgIds.MSG_PAYMENT_METHOD_CURRENT_PLAN]: 'Current plan',
  [msgIds.MSG_PAYMENT_METHOD_NEW_PLAN]: 'New plan',
  [msgIds.MSG_PAYMENT_METHOD_DISCOUNT_CODE]: 'Discount code',
  [msgIds.MSG_PAYMENT_METHOD_INSERT_DISCOUNT_CODE]: 'Enter the code',
  [msgIds.MSG_PAYMENT_METHOD_CHOOSE_PAYMENT_METHOD]: 'Select a payment method',
  [msgIds.MSG_PAYMENT_METHOD_YOUR_CARDS]: 'Your credit and debit cards',
  [msgIds.MSG_PAYMENT_METHOD_ADD_NEW_CARD]: 'Add a new card',
  [msgIds.MSG_PAYMENT_METHOD_SUBSCRIPTION_TO_PRODUCT]: 'Subscribe to the service',
  [msgIds.MSG_PAYMENT_METHOD_PAYMENT_INTERVAL]: 'Payment recurrence',
  [msgIds.MSG_PAYMENT_METHOD_AMOUNT]: 'Amount',
  [msgIds.MSG_PAYMENT_METHOD_CARD_REMOVE_DIALOG_TITLE]: 'Card removal',
  [msgIds.MSG_PAYMENT_METHOD_CARD_REMOVE_DIALOG_BODY]: 'Are you sure you want to remove this card?',

  // Invoices
  [msgIds.MSG_INVOICE_DATA]: 'Date',
  [msgIds.MSG_INVOICE_AMOUNT]: 'Amount',
  [msgIds.MSG_INVOICE_STATE]: 'State',
  [msgIds.MSG_INVOICE_STATUS_PENDING]: 'Pending Payment',
  [msgIds.MSG_INVOICE_STATUS_UNCOLLECTABLE]: 'Uncollectable',
  [msgIds.MSG_INVOICE_STATUS_PAID]: 'Paid',
  [msgIds.MSG_INVOICES_SDI_INFO]:
    'If you are eligible to receive the electronic invoice, it will be automatically forwarded to you via the exchange system (SDI)',

  // Contract editor
  [msgIds.MSG_CONTRACT_EDITOR_DIALOG_TITLE]: 'Contract update',
}
