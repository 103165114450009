import { Avatar, Stack, Typography, useTheme } from '@mui/material'
import { ProfileType, isConsumer, isOperator, isStructure } from '../../shared/Constants'
import { IAccountIdentityProps } from './AccountIdentity.types'
import { useTranslation } from 'react-i18next'

import msgIds from '../../locales/msgIds'
import { Utils } from '../../shared/Utils'

export default function AccountIdentity(props: IAccountIdentityProps) {
  const theme = useTheme()
  const { t, i18n } = useTranslation()

  const getProfileDsc = () => {
    if (props.infoToShow.includes('main') && props.account && props.account.profile)
      switch (props.account.profile.type) {
        case ProfileType.customer: {
          return (
            <>
              <i>{t(msgIds.MSG_PRIVATE_USER)}</i>
            </>
          )
        }
        case ProfileType.operatorAdmin: {
          return (
            <>
              {props.account?.linkedAccount?.getIdentityInverse()}
              <i>{` (${t(msgIds.MSG_OPERATOR)} - ${t(msgIds.MSG_ADMIN_LO)})`}</i>
            </>
          )
        }
        case ProfileType.operatorInt: {
          return (
            <>
              {props.account?.linkedAccount?.getIdentityInverse()}
              <i>{` (${t(msgIds.MSG_OPERATOR)} - ${t(msgIds.MSG_INTERNAL_LO)})`}</i>
            </>
          )
        }
        case ProfileType.operatorExt: {
          return (
            <>
              {props.account?.linkedAccount?.getIdentityInverse()}
              <i>{` (${t(msgIds.MSG_OPERATOR)} - ${t(msgIds.MSG_EXTERNAL_LO)})`}</i>
            </>
          )
        }
        case ProfileType.structure: {
          return (
            <>
              <i>{t(msgIds.MSG_ORGANIZATION)}</i>
            </>
          )
        }
        default:
          return ''
      }
  }

  return (
    <Stack direction={'row'} sx={{ marginLeft: 1 }}>
      <Avatar
        sx={{ width: 40, height: 40, border: `1px solid ${theme.palette.common.gray7}` }}
        alt={props.account.getIdentityInverse()}
        src={props.account.profile?.avatarImage}
        onClick={(e: React.MouseEvent<HTMLHeadingElement>) => {
          e.stopPropagation()
          props.avatarClicked(props.account)
        }}
      >
        {props.account.getInitials()}
      </Avatar>

      {props.infoToShow.includes('linked') && props.account.linkedAccount && (
        <Avatar
          sx={{ width: 40, height: 40, marginLeft: 1, border: `1px solid ${theme.palette.common.gray7}` }}
          alt={props.account.getIdentityInverse()}
          src={props.account.linkedAccount?.profile?.avatarImage}
          onClick={(e: React.MouseEvent<HTMLHeadingElement>) => {
            e.stopPropagation()
            props.avatarClicked(props.account.linkedAccount!)
          }}
        >
          {props.account.linkedAccount.getInitials()}
        </Avatar>
      )}

      {props.infoToShow.includes('principal') && props.account.getRelatedAccount() && (
        <Avatar
          sx={{ width: 40, height: 40, marginLeft: 1, border: `1px solid ${theme.palette.common.gray7}` }}
          alt={props.account.getIdentityInverse()}
          src={props.account.getRelatedAccount()!.profile?.avatarImage}
          onClick={(e: React.MouseEvent<HTMLHeadingElement>) => {
            e.stopPropagation()
            props.avatarClicked(props.account.getRelatedAccount()!)
          }}
        >
          {props.account.getRelatedAccount()!.getInitials()}
        </Avatar>
      )}

      <Stack direction={'column'} sx={{ marginLeft: 1 }}>
        <Typography variant="body2">
          <b>{props.account.getIdentityInverse()}</b>
        </Typography>
        {props.showProfileInfo && isConsumer(props.account.profile?.type) && (
          <Typography sx={{ fontSize: 11 }} variant="body2">
            {t(msgIds.MSG_BORN_ON) +
              ': ' +
              (props.account.userDetails?.birthdate
                ? Utils.toLocaleDateString(props.account.userDetails?.birthdate, i18n)
                : '')}
          </Typography>
        )}
        {props.showProfileInfo && isOperator(props.account.profile?.type) && (
          <Typography sx={{ fontSize: 11 }} variant="body2">
            {`${props.account?.linkedAccount?.getIdentityInverse() ?? ''} / ${
              props.account.profileDetailsOwnedBySelf?.phone ??
              props.account.profileDetailsOwnedByOthers?.phone ??
              props.account.profileDetailsOwnedBySelf?.mobilePhone1 ??
              props.account.profileDetailsOwnedByOthers?.mobilePhone1 ??
              props.account.profileDetailsOwnedBySelf?.mobilePhone2 ??
              props.account.profileDetailsOwnedByOthers?.mobilePhone2 ??
              ''
            }`}
          </Typography>
        )}
        {props.showProfileInfo && isStructure(props.account.profile?.type) && (
          <Typography sx={{ fontSize: 11 }} variant="body2">
            {props.account.profileDetailsOwnedBySelf?.getCityInfo() ?? ''}
          </Typography>
        )}
        {props.showProfileDsc && (
          <Typography sx={{ fontSize: 11 }} variant="body2">
            {getProfileDsc()}
          </Typography>
        )}
      </Stack>
    </Stack>
  )
}
