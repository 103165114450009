import { Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import DataEditorHeader from '../dataEditorHeader/DataEditorHeader'
import msgIds from '../../locales/msgIds'
import { EditIco } from '../icons'
import { IBooleanPermissionsManagementFormProps } from './BooleanPermissionsManagementForm.types'
import { BooleanPermissionsManagement } from './BooleanPermissionsManagment'

export function BooleanPermissionsManagementForm(props: IBooleanPermissionsManagementFormProps): JSX.Element {
  const { t } = useTranslation()
  const [isEditMode, setIsEditMode] = useState(false)
  return (
    <Stack paddingBottom={3} spacing={0}>
      <DataEditorHeader
        title={t(props.title)}
        isEditMode={isEditMode}
        viewModeCommands={[
          {
            commandText: '',
            icon: <EditIco />,
            onClick: () => setIsEditMode(true),
            tooltipText: t(msgIds.MSG_MODIFY) ?? undefined,
            disabled: false,
          },
        ]}
        editModeCommands={[]}
      />
      <BooleanPermissionsManagement
        profileId={props.profileId}
        structureProfileId={props.structureProfileId}
        permissions={props.permissions}
        actionsLabels={props.actionsLabels}
        isEditMode={isEditMode}
        onSave={() => setIsEditMode(false)}
        onCancel={() => setIsEditMode(false)}
      />
    </Stack>
  )
}
