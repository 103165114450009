import { Utils } from "../Utils"

export interface DocumentFile {
  data?: ArrayBuffer
  dataUrl?: string
  mimetype: string
  name: string
  size: number
  checksum?: string
}

export function createDocumentFile(file: File, options?: { signal?: AbortSignal }): Promise<DocumentFile> {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.readAsDataURL(file)

    fileReader.onload = function (event) {
      if (options?.signal?.aborted) {
        reject('aborted')
        return
      }
      if (!event.target?.result) {
        reject('missing event.target.result')
        return
      }
      const strBuffer = event.target.result as string
      // extract binary from base64 image
      // TODO: put that computation in a WebWorker to avoid UI freeze
      var BASE64_MARKER = ';base64,'
      var base64Index = strBuffer.indexOf(BASE64_MARKER) + BASE64_MARKER.length
      const strImage = strBuffer.substring(base64Index, strBuffer.length)
      var raw = atob(strImage)
      var rawLength = raw.length
      var array = new Uint8Array(new ArrayBuffer(rawLength))
      for (let i = 0; i < rawLength; i++) {
        array[i] = raw.charCodeAt(i)
      }

      // TODO: put that computation in a WebWorker to avoid UI freeze
      const checksum = Utils.getBinaryMD5Checksum(array)

      const documentFile: DocumentFile = {
        data: array,
        dataUrl: event.target.result as string,
        mimetype: file.type,
        name: file.name,
        size: file.size,
        checksum,
      }
      resolve(documentFile)
    }
  })
}
