import { Box, Divider, Paper, Stack } from '@mui/material'
import { useStackState } from '../../hooks/Stack'
import { PageStepper } from '../pageStepper/PageStepper'
import { PageStep } from '../pageStepper/PageStep'
import { PageStepperNavBar } from '../pageStepper/PageStepperNavBar'
import { IRegistrationProps, RegistrationStepId } from './Registration.types'
import { useEffect, useMemo, useRef, useState } from 'react'
import { Account } from '../../models/Account'
import { TreatedFieldsAcl } from '../../contexts/AccountEditorContext'
import { AccountEditorIdentityFields } from '../accountEditorIdentity/AccountEditorIdentityFields'
import { IIdentityArgs, validateIdentityArgs } from '../../shared/IdentityArgs'
import { ViewActions } from '../viewActions/ViewActions'
import { ViewActionsButton } from '../viewActions/ViewActionsButton'
import { useTranslation } from 'react-i18next'
import msgIds from '../../locales/msgIds'
import * as dalConsent from '../../dal/DalConsent'
import * as dalProfile from '../../dal/DalProfile'
import * as dalAuth from '../../dal/DalAuth'
import * as dalAccount from '../../dal/DalAccount'
import { UpdateUserArgs } from '../../dal/DalAccount'
import { validateBillingUserArgs } from '../../shared/UpdateUserArgs'
import { AccountEditorBillingFields } from '../accountEditorBilling/AccountEditorBillingFields'
import { useLayout } from '../../hooks/Layout'
import {
  AccountType,
  ContractType,
  Gender,
  ProfileType,
  RegistrationPurpose,
  TreatedFieldName,
} from '../../shared/Constants'
import { Profile } from '../../models/Profile'
import { PasswordEditorFields } from '../passwordEditor/PasswordEditorFields'
import { Utils } from '../../shared/Utils'
import {
  UseContractRegistrationForm,
  UseRegistrationForm,
  useContractRegistrationForm,
  useRegistrationForm,
} from './RegistrationHooks'
import { ContractVersionViewerData } from '../contractVersionViewerData/ContractVersionViewerData'
import { RegistrationSummary } from '../registrationSummary/RegistrationSummary'
import { IRegistrationSummarySection } from '../registrationSummary/RegistrationSummary.types'
import { useCommuneOptions } from '../../hooks/CommuneOptions'
import { useAuthContext } from '../../contexts/AuthContext'
import { User } from '../../models/User'
import { UserDetails } from '../../models/UserDetails'
import { useSnackbar } from 'notistack'
import { summarySectionFromBilling, summarySectionFromIdentity } from './Registration.helper'
import { ISimpleDialogData } from '../../dialogs/simpleDialog/SimpleDialog.types'
import SimpleDialog from '../../dialogs/simpleDialog/SimpleDialog'
import { ConsentChange } from '../../dal/DalConsent'
import { useNavigate } from 'react-router'
import { IProductPlanChoice } from '../../pages/ProductsPage/ProductsPage.types'
import { UseContractVersionViewer } from '../contractVersionViewer/ContractVersionViewerHook'
import { TreatedField } from '../../models/TreatedField'

export type RegistrationStep = {
  id: RegistrationStepId
  title: string
}

export type RegistrationStepInfo = {
  errors: any
  changed: boolean
  ready: boolean
}

const allSteps: RegistrationStep[] = [
  { id: 'contractPrivacyPolicy', title: msgIds.MSG_REGISTRATION_CONTRACT_PRIVACY_POLICY_TITLE },
  { id: 'contractTermsOfUse', title: msgIds.MSG_REGISTRATION_CONTRACT_TERMS_POLICY_TITLE },
  { id: 'contractDataProcessor', title: msgIds.MSG_REGISTRATION_CONTRACT_DATA_PROCESSOR },
  { id: 'personIdentity', title: msgIds.MSG_REGISTRATION_PERSON_IDENTITY_TITLE },
  { id: 'companyIdentity', title: msgIds.MSG_REGISTRATION_COMPANY_IDENTITY_TITLE },
  { id: 'billing', title: msgIds.MSG_REGISTRATION_BILLING_TITLE },
  { id: 'credentials', title: msgIds.MSG_REGISTRATION_CREDENTIALS_TITLE },
  { id: 'summary', title: msgIds.MSG_REGISTRATION_SUMMARY_TITLE },
]

function getStepTitle(stepId: RegistrationStepId | undefined) {
  return allSteps.find(({ id }) => stepId === id)?.title
}

export function Registration(props: IRegistrationProps): JSX.Element {
  const { profileType, purpose, productId, priceId, isTrial, isFreePlan, title, steps, sx } = props
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const { isMobile } = useLayout()
  const authContext = useAuthContext()
  const [isLoading, setIsLoading] = useState(false)
  const communes = useCommuneOptions()
  const stack = useStackState<RegistrationStepId>([steps[0]])
  const [treatedFields, setTreatedFields] = useState<TreatedField[]>([])

  // dialogs
  const [simpleDialogData, setSimpleDialogData] = useState<ISimpleDialogData>()
  const [simpleDialogOpen, setSimpleDialogOpen] = useState(false)

  const account = useMemo<Account>(() => {
    switch (purpose) {
      case RegistrationPurpose.userRegistration:
        return newEmptyAccount()
      case RegistrationPurpose.changePlan:
        if (authContext.loggedAccount) {
          return authContext.loggedAccount
        } else {
          throw new Error('logged account cannot be undefined in case of change plan purpose')
        }
      case RegistrationPurpose.contractRevision:
        if (authContext.loggedAccount) {
          return authContext.loggedAccount
        } else {
          throw new Error('logged account cannot be undefined in case of contract revision purpose')
        }
      case RegistrationPurpose.profileActivation:
        return newLoggedAccountWithNewEmptyProfile()
      case RegistrationPurpose.placeholderRegistration:
        return newEmptyAccount()
      default:
        throw new Error('non managed registration purpose')
    }
  }, [profileType])

  function newEmptyAccount() {
    const user = new User()
    user.accountType = AccountType.externalUser
    const profile = new Profile()
    profile.type = profileType
    const account = new Account()
    account.user = user
    account.profile = profile
    return account
  }

  function newLoggedAccountWithNewEmptyProfile() {
    const profile = new Profile()
    profile.type = profileType
    const account = new Account()
    account.user = authContext.loggedAccount?.user ? ({ ...authContext.loggedAccount?.user } as User) : undefined
    account.userDetails = authContext.loggedAccount?.userDetails
      ? ({ ...authContext.loggedAccount?.userDetails } as UserDetails)
      : undefined
    account.profile = authContext.loggedAccount?.profile
      ? ({ ...authContext.loggedAccount?.profile } as Profile)
      : undefined
    account.linkedAccount = new Account()
    account.linkedAccount.user = authContext.loggedAccount?.user
      ? ({ ...authContext.loggedAccount?.linkedAccount?.user } as User)
      : undefined
    account.linkedAccount.profile = authContext.loggedAccount?.profile
      ? ({ ...authContext.loggedAccount?.linkedAccount?.profile } as Profile)
      : undefined

    return account
  }

  const personalDataFieldsAcl: TreatedFieldsAcl = useMemo(() => {
    const fieldsAcl = new TreatedFieldsAcl()
    if (purpose === RegistrationPurpose.placeholderRegistration) {
      fieldsAcl.registrationEmail = 'none'
      fieldsAcl.lastname = treatedFields.find((p) => p.fieldName === TreatedFieldName.lastname) ? 'editable' : 'none'
      fieldsAcl.name = treatedFields.find((p) => p.fieldName === TreatedFieldName.name) ? 'editable' : 'none'
      fieldsAcl.gender = treatedFields.find((p) => p.fieldName === TreatedFieldName.gender) ? 'editable' : 'none'
      fieldsAcl.birthdate = treatedFields.find((p) => p.fieldName === TreatedFieldName.birthdate) ? 'editable' : 'none'
      fieldsAcl.birthplace = treatedFields.find((p) => p.fieldName === TreatedFieldName.birthplace)
        ? 'editable'
        : 'none'
      fieldsAcl.fiscalCode = treatedFields.find((p) => p.fieldName === TreatedFieldName.fiscalCode)
        ? 'editable'
        : 'none'
    } else {
      fieldsAcl.registrationEmail = 'editable'
      fieldsAcl.lastname = 'editable'
      fieldsAcl.name = 'editable'
      fieldsAcl.gender = 'editable'
      fieldsAcl.birthdate = 'editable'
      fieldsAcl.birthplace = 'editable'
      fieldsAcl.fiscalCode = 'editable'
    }
    return fieldsAcl
  }, [account, treatedFields])
  const companyDataFieldsAcl: TreatedFieldsAcl = useMemo(() => {
    const fieldsAcl = new TreatedFieldsAcl()
    fieldsAcl.name = 'editable'
    fieldsAcl.vatNumber = 'editable'
    fieldsAcl.fiscalCode = 'editable'
    return fieldsAcl
  }, [])

  const personalDataForm = useRegistrationForm<IIdentityArgs>({
    initValue: {
      // email: 'testcus6@personaldox.com',
      // name: 'Giovanni',
      // lastname: 'Liechtenstein',
      // gender: Gender.male,
      // birthdate: '1977-12-24',
      // birthplace: 'L483',
      // fiscalCode: 'LCHGNN77T24L483Q',
    },
    validate: (data) => validateIdentityArgs(data, personalDataFieldsAcl, false),
  })
  const companyDataForm = useRegistrationForm<IIdentityArgs>({
    initValue: {
      // name: 'Principato di Liechtenstein',
      // fiscalCode: 'LCHGNN77T24L483Q',
      // vatNumber: '14654140855',
    },
    validate: (data) => validateIdentityArgs(data, companyDataFieldsAcl, true),
  })
  const billingForm = useRegistrationForm<UpdateUserArgs>({
    initValue:
      profileType === ProfileType.operatorAdmin
        ? {
            sdiCode: account?.linkedAccount?.userDetails?.sdiCode,
            pec: account?.linkedAccount?.userDetails?.pec,
            country: account?.linkedAccount?.userDetails?.country,
            city: account?.linkedAccount?.userDetails?.city,
            province: account?.linkedAccount?.userDetails?.province,
            street: account?.linkedAccount?.userDetails?.street,
            streetNumber: account?.linkedAccount?.userDetails?.streetNumber,
            zip: account?.linkedAccount?.userDetails?.zip,
          }
        : {
            // TODO: manage billing data for customer users
          },
    validate: (data) => validateBillingUserArgs(data),
  })
  const credentialsForm = useRegistrationForm<{ password: string; passwordConfirmation: string }>({
    initValue: { password: '', passwordConfirmation: '' },
    validate: (data) => Utils.validatePasswordConfirmation(data.password, data.passwordConfirmation),
  })
  const privacyPolicyForm = useContractRegistrationForm({
    profileType,
    contractType: ContractType.privacyPolicy,
    grantorProfileId: purpose === RegistrationPurpose.contractRevision ? authContext.loggedProfileId : undefined,
    purpose,
  })
  const termsOfUseForm = useContractRegistrationForm({
    profileType,
    contractType: ContractType.termsOfUse,
    grantorProfileId: purpose === RegistrationPurpose.contractRevision ? authContext.loggedProfileId : undefined,
    purpose,
  })
  const dataProcessorForm = useContractRegistrationForm({
    profileType,
    contractType: ContractType.dataProcessor,
    grantorProfileId: purpose === RegistrationPurpose.contractRevision ? authContext.loggedProfileId : undefined,
    purpose,
  })

  const contractsSteps: { [id: string]: UseContractRegistrationForm } = {
    contractPrivacyPolicy: privacyPolicyForm,
    contractTermsOfUse: termsOfUseForm,
    contractDataProcessor: dataProcessorForm,
  }

  function onSave() {
    setSimpleDialogOpen(true)
    setSimpleDialogData({
      title: title,
      content: t(msgIds.MSG_REGISTRATION_CONFIRM_DIALOG_BODY),
      actionsStyle: 'yesNO',
      onClose: async (result) => {
        setSimpleDialogOpen(false)
        if (result.userChoice !== 'yes') return
        await finalizeProcedure()
      },
    })
  }

  async function finalizeProcedure() {
    switch (purpose) {
      case RegistrationPurpose.contractRevision:
        if (steps.includes('contractPrivacyPolicy')) {
          archiveConsents(privacyPolicyForm.contractVersionViewer)
        }
        if (steps.includes('contractTermsOfUse')) {
          archiveConsents(termsOfUseForm.contractVersionViewer)
        }
        if (steps.includes('contractDataProcessor')) {
          archiveConsents(dataProcessorForm.contractVersionViewer)
        }
        break
      case RegistrationPurpose.profileActivation:
        await activateProfile()
        break
      case RegistrationPurpose.userRegistration:
        await createNewAccount()
        break
      case RegistrationPurpose.changePlan:
        await changePlan()
        break
      case RegistrationPurpose.placeholderRegistration:
        await createNewAccount()
        break
      default:
        throw new Error(`Registration purpose non managed: ${purpose}`)
    }
  }

  async function archiveConsents(contractVersionViewer: UseContractVersionViewer) {
    let sectionToGrant = contractVersionViewer.collectConsentsToGrant()
    let sectionToRevoke = contractVersionViewer.collectConsentsToRevoke()

    try {
      setIsLoading(true)
      const abortController = new AbortController()
      await dalConsent.updateConsents(abortController.signal, sectionToRevoke, sectionToGrant)
      showCompleteMessageThenNavigate(t(msgIds.MSG_REGISTRATION_SUCCESS_MESSAGE_NEW_CONSENTS), goToNoticeBoardPage)
    } catch (err) {
      Utils.enqueueSnackbarError2(err, t)
    } finally {
      setIsLoading(false)
    }
  }

  function collectAllConsentsToGrant() {
    let toGrant: ConsentChange[] = []
    if (steps.includes('contractPrivacyPolicy')) {
      toGrant = [...toGrant, ...privacyPolicyForm.contractVersionViewer.collectConsentsToGrant()]
    }
    if (steps.includes('contractTermsOfUse')) {
      toGrant = [...toGrant, ...termsOfUseForm.contractVersionViewer.collectConsentsToGrant()]
    }
    if (steps.includes('contractDataProcessor')) {
      toGrant = [...toGrant, ...dataProcessorForm.contractVersionViewer.collectConsentsToGrant()]
    }
    return toGrant
  }

  async function activateProfile() {
    const sectionToGrant = collectAllConsentsToGrant()
    const sectionsIds = sectionToGrant.map((p) => p.sectionId)
    let parms: any = {
      type: profileType,
      sections: sectionsIds,
      product_price_id: priceId,
      can_trial: isTrial,
      linked_to: undefined,
    }
    if (profileType === ProfileType.operatorAdmin) {
      parms.linked_to = {
        name: companyDataForm.data.name,
        vat_number: companyDataForm.data.vatNumber,
        fiscal_code: companyDataForm.data.fiscalCode,
        sdi_code: billingForm.data.sdiCode,
        pec: billingForm.data.pec,
        country: billingForm.data.country,
        city: billingForm.data.city,
        province: billingForm.data.province,
        street: billingForm.data.street,
        street_number: billingForm.data.streetNumber,
        zip: billingForm.data.zip,
      } as any
    }

    try {
      setIsLoading(true)
      const abortController = new AbortController()
      const newAccount = await dalProfile.activateNewProfile(abortController.signal, parms)
      const newProfileId = newAccount.profile?.profileId
      if (!newProfileId) return
      const activeAccount = await dalAuth.changeActiveProfile(abortController.signal, newProfileId)
      authContext.setLoggedAccount(activeAccount)
      showCompleteMessageThenNavigate(t(msgIds.MSG_REGISTRATION_SUCCESS_MESSAGE_NEW_PROFILE), goToNoticeBoardPage)
    } catch (err) {
      Utils.enqueueSnackbarError2(err, t)
    } finally {
      setIsLoading(false)
    }
  }

  async function createNewAccount() {
    const sectionToGrant = collectAllConsentsToGrant()
    const sectionsIds = sectionToGrant.map((p) => p.sectionId)
    let parms: any = {
      type: profileType,
      sections: sectionsIds,
      product_price_id: priceId,
      can_trial: isTrial,
      // person info
      email: personalDataForm.data.email,
      account_type:
        purpose === RegistrationPurpose.placeholderRegistration
          ? AccountType.placeholderUser
          : AccountType.externalUser,
      name: personalDataForm.data.name ?? null,
      lastname: personalDataForm.data.lastname ?? null,
      gender: personalDataForm.data.gender ?? Gender.none,
      birthdate: personalDataForm.data.birthdate
        ? (Utils.toDate(personalDataForm.data.birthdate, true) as string)
        : null,
      birthplace: personalDataForm.data.birthplace ?? null,
      fiscal_code: personalDataForm.data.fiscalCode ?? null,
      //
      password: credentialsForm.data.password,
      profile: {
        type: profileType,
        sections: sectionsIds,
        linked_to: undefined,
      },
    }
    if (profileType === ProfileType.operatorAdmin) {
      parms.profile.linked_to = {
        name: companyDataForm.data.name,
        vat_number: companyDataForm.data.vatNumber,
        fiscal_code: companyDataForm.data.fiscalCode,
        sdi_code: billingForm.data.sdiCode,
        pec: billingForm.data.pec,
        country: billingForm.data.country,
        city: billingForm.data.city,
        province: billingForm.data.province,
        street: billingForm.data.street,
        street_number: billingForm.data.streetNumber,
        zip: billingForm.data.zip,
      } as any
    }

    try {
      setIsLoading(true)
      const abortController = new AbortController()
      const account = await dalAccount.createNewAccount(abortController.signal, parms)
      if (purpose === RegistrationPurpose.placeholderRegistration) {
        goToAccontEditorPage(account)
      } else {
        showCompleteMessageThenNavigate(t(msgIds.MSG_REGISTRATION_SUCCESS_MESSAGE_NEW_ACCOUNT), goToLoginPage)
      }
    } catch (err) {
      Utils.enqueueSnackbarError2(err, t)
    } finally {
      setIsLoading(false)
    }
  }

  async function changePlan() {
    if (!authContext.linkedStructureAccount?.user?.userId) return

    let parms: any = {
      sdi_code: billingForm.data.sdiCode,
      pec: billingForm.data.pec,
      country: billingForm.data.country,
      city: billingForm.data.city,
      province: billingForm.data.province,
      street: billingForm.data.street,
      street_number: billingForm.data.streetNumber,
      zip: billingForm.data.zip,
    }

    try {
      setIsLoading(true)
      const abortController = new AbortController()
      const newAccount = await dalAccount.updateBillingData(
        abortController.signal,
        authContext.linkedStructureAccount?.user?.userId,
        parms
      )
      if (authContext.linkedStructureAccount && newAccount.linkedAccount?.user) {
        authContext.linkedStructureAccount.user = { ...newAccount.linkedAccount?.user }
      }
      showCompleteMessageThenNavigate(
        t(msgIds.MSG_REGISTRATION_SUCCESS_MESSAGE_BILLING_DATA_UPDATED),
        goToPaymentMethodPage
      )
    } catch (err) {
      Utils.enqueueSnackbarError2(err, t)
    } finally {
      setIsLoading(false)
    }
  }

  function showCompleteMessageThenNavigate(msg: string, navigateTo: () => void) {
    authContext.showCompletePage()
    setSimpleDialogOpen(true)
    setSimpleDialogData({
      title: title,
      content: msg,
      actionsStyle: 'Ok',
      onClose: async (result) => {
        setSimpleDialogOpen(false)
        navigateTo()
      },
    })
  }

  function goToNoticeBoardPage() {
    navigate('/notice_board', {})
  }

  function goToLoginPage() {
    navigate(`/login?profileType=${profileType}`, { state: {}, replace: true })
  }

  function goToPaymentMethodPage() {
    navigate('/paymentMethod', {
      state: {
        profileType: profileType,
        productId: productId,
        priceId: priceId,
        isTrial: isTrial,
        isFreePlan: isFreePlan,
        purpose: purpose,
        hasBillingData: true,
      } as IProductPlanChoice,
    })
  }

  function goToAccontEditorPage(account: Account) {
    navigate(`/account_editor`, {
      state: { detailsOwnerProfileId: authContext.loggedProfileId, profileId: account.profile?.profileId },
      replace: true,
    })
  }

  const formsByStep: { [id: string]: UseRegistrationForm<any> } = {
    contractPrivacyPolicy: privacyPolicyForm,
    contractTermsOfUse: termsOfUseForm,
    contractDataProcessor: dataProcessorForm,
    personIdentity: personalDataForm,
    companyIdentity: companyDataForm,
    billing: billingForm,
    credentials: credentialsForm,
  }

  const stepForm: UseRegistrationForm<any> | undefined = stack.head ? formsByStep[stack.head] : undefined

  const stepIndex = steps.findIndex((sid) => stack.head === sid)
  const nextStepIndex = stepIndex + 1
  const nextStepId = nextStepIndex < steps.length ? steps[nextStepIndex] : steps[stepIndex]
  const isLastStep = nextStepId === stack.head
  const canGoNext = stack.head && !isLastStep && (stepForm ? stepForm.ready : true)
  const canGoBack = stack.length > 1

  const stepTitleMsgId = (stack.head && getStepTitle(stack.head)) || ''
  const stepTitleRaw = stepTitleMsgId ? t(stepTitleMsgId) : ''
  const stepTitle = `(${stepIndex + 1}/${steps.length}) ${stepTitleRaw}`

  function onNext() {
    const step = stack.head
    if (!step) {
      return
    }
    const form = formsByStep[step]
    if (form) {
      const errors = form.validate(form.data)
      form.setErrors(errors)
      if (errors) {
        if (step === 'contractPrivacyPolicy' || step === 'contractTermsOfUse' || step === 'contractDataProcessor') {
          enqueueSnackbar(t(msgIds.MSG_VAL_ERR_SOME_MANDATORY_CONSENTS_ARE_MISSING), { variant: 'error' })
        }
        return
      }
      if (step === 'contractPrivacyPolicy' && purpose === RegistrationPurpose.placeholderRegistration) {
        const treatedFields = (form as UseContractRegistrationForm).contractVersionViewer.collectTreatedFields()
        setTreatedFields(treatedFields)
      }
    }
    const nextStep = allSteps.find(({ id }) => nextStepId === id)
    if (!nextStep) {
      return
    }
    stack.push(nextStep.id)
  }
  function onBack() {
    stack.pop()
  }

  const summarySections: IRegistrationSummarySection[] = useMemo(() => {
    const sections: IRegistrationSummarySection[] = []
    if (steps.includes('personIdentity')) {
      if (purpose !== RegistrationPurpose.placeholderRegistration) {
        const contactEmailSection: IRegistrationSummarySection = {
          title: t(msgIds.MSG_REGISTRATION_SUMMARY_CONTACT_EMAIL_SECTION_TITLE),
          fields: [{ key: t(msgIds.MSG_EMAIL), value: personalDataForm.data.email }],
        }
        sections.push(contactEmailSection)
      }
      const identitySection: IRegistrationSummarySection = {
        title: t(msgIds.MSG_REGISTRATION_SUMMARY_PERSONAL_IDENTITY_SECTION_TITLE),
        fields: summarySectionFromIdentity(personalDataForm.data, personalDataFieldsAcl, profileType, communes, t),
      }
      sections.push(identitySection)
    }
    if (steps.includes('companyIdentity')) {
      const identitySection: IRegistrationSummarySection = {
        title: t(msgIds.MSG_REGISTRATION_SUMMARY_COMPANY_SECTION_TITLE),
        fields: summarySectionFromIdentity(companyDataForm.data, companyDataFieldsAcl, profileType, communes, t),
      }
      sections.push(identitySection)
    }
    if (steps.includes('billing')) {
      const identitySection: IRegistrationSummarySection = {
        title: t(msgIds.MSG_REGISTRATION_SUMMARY_BILLING_TITLE),
        fields: summarySectionFromBilling(billingForm.data, t),
      }
      sections.push(identitySection)
    }

    return sections
  }, [
    personalDataForm.data,
    companyDataForm.data,
    billingForm.data,
    personalDataFieldsAcl,
    companyDataFieldsAcl,
    profileType,
    steps,
    communes,
    t,
  ])

  const rootRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    rootRef.current?.scrollIntoView({ behavior: 'smooth' })
  }, [stack.head])

  return (
    <Stack ref={rootRef} gap={4} maxWidth="md" width="100%" sx={sx}>
      {simpleDialogData && <SimpleDialog {...simpleDialogData} isOpen={simpleDialogOpen}></SimpleDialog>}
      <Paper>
        <PageStepperNavBar
          title={stepTitle}
          backDisabled={!canGoBack}
          onBack={stack.pop}
          nextDisabled={!canGoNext}
          onNext={onNext}
        />
        <Divider />
        <Box p={isMobile ? 2 : 6}>
          <PageStepper activeStep={stack.head}>
            {Object.entries(contractsSteps).map(([stepId, form]) => (
              <PageStep key={stepId} step={stepId.toString()}>
                <ContractVersionViewerData
                  disablePadding={true}
                  consentsSnapshot={form.contractVersionViewer.consentsSnapshot}
                  missingInformations={form.contractVersionViewer.missingInformations}
                  isNewAcknoledgmentRequired={form.contractVersionViewer.isNewAcknoledgmentRequired}
                  acceptedSections={form.contractVersionViewer.acceptedSections}
                  editableSections={form.contractVersionViewer.editableSections}
                  editableSectionsFields={form.contractVersionViewer.editableSectionsFields}
                  contractType={form.contractVersionViewer.contractType}
                  availableMobilePhoneFields={form.contractVersionViewer.availableMobilePhoneFields}
                  availableMailFields={form.contractVersionViewer.availableMailFields}
                  onChangeFieldOption={form.contractVersionViewer.onChangeFieldOption}
                  onAddMissingInfo={() => {}}
                  onChange={form.contractVersionViewer.onChange}
                  isLoading={form.contractVersionViewer.isLoading}
                  simpleDialogData={form.contractVersionViewer.simpleDialogData}
                  simpleDialogOpen={form.contractVersionViewer.simpleDialogOpen}
                  showMissingInfoFields={false}
                />
              </PageStep>
            ))}
            <PageStep step="personIdentity">
              <AccountEditorIdentityFields
                profileType={profileType}
                args={personalDataForm.data}
                fieldsAcl={personalDataFieldsAcl}
                errors={personalDataForm.errors || {}}
                onChangeArgs={personalDataForm.onChangeData}
                disabled={false}
                isEditMode={true}
              />
            </PageStep>
            <PageStep step="companyIdentity">
              <AccountEditorIdentityFields
                profileType={profileType}
                args={companyDataForm.data}
                fieldsAcl={companyDataFieldsAcl}
                errors={companyDataForm.errors || {}}
                onChangeArgs={companyDataForm.onChangeData}
                disabled={false}
                isEditMode={true}
              />
            </PageStep>
            <PageStep step="billing">
              <AccountEditorBillingFields
                args={billingForm.data}
                onChangeArgs={billingForm.onChangeData}
                errors={billingForm.errors || {}}
                disabled={false}
                isEditMode={true}
              />
            </PageStep>
            <PageStep step="credentials">
              <PasswordEditorFields
                errors={credentialsForm.errors || {}}
                password={credentialsForm.data.password || ''}
                passwordConfirmation={credentialsForm.data.passwordConfirmation || ''}
                onChangePassword={(password) =>
                  credentialsForm.onChangeData({ ...credentialsForm.data, password }, 'password')
                }
                onChangePasswordConfirmation={(passwordConfirmation) =>
                  credentialsForm.onChangeData(
                    { ...credentialsForm.data, passwordConfirmation },
                    'passwordConfirmation'
                  )
                }
              />
            </PageStep>
            <PageStep step="summary">
              <RegistrationSummary sections={summarySections} purpose={purpose ?? RegistrationPurpose.none} />
            </PageStep>
          </PageStepper>
          <ViewActions justifyContent={'center'} sx={{ paddingTop: 6 }}>
            <ViewActionsButton disabled={!canGoBack} onClick={onBack}>
              {t(msgIds.MSG_PREVIOUS_BUTTON_TITLE)}
            </ViewActionsButton>
            {!isLastStep ? (
              <ViewActionsButton autoFocus defaultAction onClick={onNext}>
                {t(msgIds.MSG_NEXT_BUTTON_TITLE)}
              </ViewActionsButton>
            ) : (
              <ViewActionsButton autoFocus defaultAction onClick={onSave}>
                {t(msgIds.MSG_CONFIRM)}
              </ViewActionsButton>
            )}
          </ViewActions>
        </Box>
      </Paper>
    </Stack>
  )
}
