import { useTranslation } from 'react-i18next'
import { IContractVersionViewerDataProps } from '../contractVersionViewerData/ContractVersionViewerData.types'
import { AcceptedSections, IContractVersionViewerProps, SectionState } from './ContractVersionViewer.types'
import { useSnackbar } from 'notistack'
import { useAuthContext } from '../../contexts/AuthContext'
import { useEffect, useMemo, useState } from 'react'
import { Account } from '../../models/Account'
import { ContractVersion } from '../../models/ContractVersion'
import { ContractSection } from '../../models/ContractSection'
import { SelectableFieldOption } from '../../pages/ContractVersionViewerPage/ContractVersionViewerPage.types'
import { Permission } from '../../models/Permission'
import * as dalAccount from '../../dal/DalAccount'
import * as dalPermission from '../../dal/DalPermission'
import * as dalContract from '../../dal/DalContract'
import * as dalConsent from '../../dal/DalConsent'
import { ConsentChange } from '../../dal/DalConsent'
import {
  AccountType,
  ConsentMode,
  ContractType,
  ContractVersionState,
  TreatedFieldName,
  TreatedFieldsTranslationMap,
  isConsumer,
  isOperator,
} from '../../shared/Constants'
import { ISimpleDialogData } from '../../dialogs/simpleDialog/SimpleDialog.types'
import { Utils } from '../../shared/Utils'
import { ProfileDetails } from '../../models/ProfileDetails'
import { TreatedField } from '../../models/TreatedField'
import msgIds from '../../locales/msgIds'
import { Profile } from '../../models/Profile'

export type UseContractVersionViewerArgs = Omit<IContractVersionViewerProps, 'onAddMissingInfo'>

export type UseContractVersionViewer = Omit<
  IContractVersionViewerDataProps,
  'onAddMissingInfo' | 'showMissingInfoFields'
> & {
  areUnsavedChanges: boolean
  onGrantorInfoUpdated: () => void
  canSave: boolean
  onSave: () => void
  onCancel: () => void
  collectConsentsToRevoke: () => ConsentChange[]
  collectConsentsToGrant: () => ConsentChange[]
  collectTreatedFields: () => TreatedField[]
}

export function useContractVersionViewerHook(props: UseContractVersionViewerArgs): UseContractVersionViewer {
  const { contractType } = props
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const authContext = useAuthContext()
  const profileType = props.profileType ?? authContext.loggedProfileType
  const profileId = props.profileId ?? authContext.loggedProfileId
  const structureProfileId = props.structureProfileId ?? authContext.linkedStructureProfileId
  const [isLoading, setIsLoading] = useState(false)
  const [grantorAccount, setGrantorAccount] = useState<Account>()
  const [consentsHistory, setConsentsHistory] = useState<ContractVersion[]>([])
  const [consentsSnapshot, setConsentsSnapshot] = useState<ContractVersion | undefined | null>()
  const [dataProcessingPermission, setDataProcessingPermission] = useState<Permission | undefined>()
  const [isNewAcknoledgmentRequired, setIsNewAcknoledgmentRequired] = useState(false)
  const [editableSections, setEditableSections] = useState<ContractSection[]>([])
  const [editableSectionsFields, setEditableSectionsFields] = useState<ContractSection[]>([])
  const [acceptedSections, setAcceptedSections] = useState<AcceptedSections>({})
  const [areUnsavedChanges, setAreUnsavedChanges] = useState(false)
  const [availableMailFields, setAvailableMailFields] = useState<SelectableFieldOption[]>([])
  const [availableMobilePhoneFields, setAvailableMobilePhoneFields] = useState<SelectableFieldOption[]>([])

  // dialogs
  const [simpleDialogData, setSimpleDialogData] = useState<ISimpleDialogData>()
  const [simpleDialogOpen, setSimpleDialogOpen] = useState(false)

  const contractOwnerProfileId = props.contractOwnerProfileId
  const grantorProfileId = props.grantorProfileId
  const targetProfileType = props.targetProfileType

  const onGrantorInfoUpdated = async () => {
    if (grantorProfileId) {
      const abortController = new AbortController()
      const _grantorAccount = await dalAccount.getAccountFromProfileId(
        abortController.signal,
        grantorProfileId,
        false,
        false
      )
      setGrantorAccount(_grantorAccount)
    }
  }

  useEffect(() => {
    checkFieldsToSelect(acceptedSections)
  }, [grantorAccount])

  useEffect(() => {
    const loadConsentsSnapshots = async (
      abortSignal: AbortSignal,
      contractOwnerProfileId: number,
      grantorProfileId: number
    ) => {
      try {
        let grantorAccount: Account | undefined
        let sortedConsentsHistory: ContractVersion[] = []
        let consentsSnapshot: ContractVersion | undefined
        let dataProcessingPermission: Permission | undefined

        setIsLoading(true)
        if (grantorProfileId === profileId) {
          grantorAccount = authContext.loggedAccount
        } else {
          grantorAccount = await dalAccount.getAccountFromProfileId(abortSignal, grantorProfileId, false, false)
        }

        if (isConsumer(grantorAccount?.profile?.type) && contractOwnerProfileId > 0) {
          dataProcessingPermission = await dalPermission.getDataProcessingPermission(
            abortSignal,
            contractOwnerProfileId
          )
        }

        const contracts = await dalContract.getPublishedContracts(abortSignal, {
          targetProfileType: targetProfileType,
          ownerProfileId: contractOwnerProfileId,
          getSections: false,
          ignoreSectionRead: false,
          contractType: contractType,
        })

        // TODO: in the future there may be more contracts of the same type
        const contract = contracts[0]
        if (contract) {
          const publishedVersion = ContractVersion.sortByPublishedAt(contract.versions)[0]
          if (publishedVersion) {
            const consentsHistory = await dalContract.getContractConsents(abortSignal, {
              grantorProfileId: grantorProfileId,
              contractId: contract.id,
              versionId: publishedVersion.id,
              getSectionsText: true,
            })
            sortedConsentsHistory = consentsHistory.sort(ContractVersion.compareConsentsDateTime)
            consentsSnapshot = sortedConsentsHistory[0]
          }
        }
        setGrantorAccount(grantorAccount)
        setDataProcessingPermission(dataProcessingPermission)
        setConsentsHistory(sortedConsentsHistory)
        setConsentsSnapshot(consentsSnapshot ? consentsSnapshot : null)
        props.onConsentsSnapshotShowed && consentsSnapshot && props.onConsentsSnapshotShowed(consentsSnapshot)
        props.onContractLoaded && props.onContractLoaded(contract)
      } catch (err) {
        Utils.enqueueSnackbarError2(err, t)
      } finally {
        setIsLoading(false)
      }
    }

    const abortController = new AbortController()
    if (contractOwnerProfileId !== undefined && grantorProfileId) {
      loadConsentsSnapshots(abortController.signal, contractOwnerProfileId, grantorProfileId)
    }

    return () => {
      abortController.abort()
    }
  }, [contractOwnerProfileId, grantorProfileId, contractType])

  useEffect(() => {
    setConsentsSnapshot(props.consentsSnapshot)
    setConsentsHistory(props.consentsHistory ?? [])
  }, [props.consentsSnapshot, props.consentsHistory])

  useEffect(() => {
    if (!consentsSnapshot) return

    // if due to last contract publication a new data_processing acknowledgment is required
    // reset current consent in UI so user can release new consent
    const isNewAcknoledgmentRequired =
      !!dataProcessingPermission &&
      !!dataProcessingPermission.contractVersionConfirmed &&
      !!dataProcessingPermission.contractVersionToConfirm &&
      dataProcessingPermission.contractVersionConfirmed != dataProcessingPermission.contractVersionToConfirm
    if (isNewAcknoledgmentRequired) {
      const dataProcessingSection = consentsSnapshot.getDataProcessingSection()
      if (dataProcessingSection) {
        dataProcessingSection.consent = undefined
      }
    }
    setIsNewAcknoledgmentRequired(isNewAcknoledgmentRequired)

    let acceptedSections = computeAcceptedSections(consentsSnapshot)
    setAcceptedSections(acceptedSections)

    // section consent is editable only if:
    // - the version of the contract is the one currently published
    // - it is the most recent consensus version
    // - the contract viewer is not read-only
    // - basing of case: allExceptNotRevocable if consent is not revocable
    // - basing of case: allExceptMandatory if consent is not mandatory
    const editableSections = consentsSnapshot.sections.filter((p) => {
      if (props.sectionsEditability === 'all') {
        return true
      }

      const isPublished = consentsSnapshot?.state === ContractVersionState.published

      const isLast =
        consentsHistory[0] &&
        consentsSnapshot.grantedConsentsDateTime?.getTime() === consentsHistory[0].grantedConsentsDateTime?.getTime() &&
        consentsSnapshot.revokedConsentsDateTime?.getTime() === consentsHistory[0].revokedConsentsDateTime?.getTime()

      const isEditable =
        props.isEditable &&
        (grantorProfileId === profileId ||
          (isOperator(profileType) && grantorAccount?.user?.accountType === AccountType.placeholderUser))

      let res = isPublished && isLast && isEditable

      if (props.sectionsEditability === 'allExceptNotRevocable') {
        let isConsentAbsentOrRevocable = !p.consent || !p.consent.isGrantorRevokeDisabled
        res &&= isConsentAbsentOrRevocable
      }

      if (props.sectionsEditability === 'allExceptMandatory') {
        let isNotMandatory = p.consentMode !== ConsentMode.mandatory
        res &&= isNotMandatory
      }

      return res
    })
    setEditableSections(editableSections)

    // section consent is editable only if:
    // - the version of the contract is the one currently published
    // - it is the most recent consensus version
    // - the contract viewer is not read-only
    const editableSectionsFields = consentsSnapshot.sections.filter((p) => {
      if (props.sectionsEditability === 'all') {
        return true
      }

      const isPublished = consentsSnapshot?.state === ContractVersionState.published

      const isLast =
        consentsHistory[0] &&
        consentsSnapshot.grantedConsentsDateTime?.getTime() === consentsHistory[0].grantedConsentsDateTime?.getTime() &&
        consentsSnapshot.revokedConsentsDateTime?.getTime() === consentsHistory[0].revokedConsentsDateTime?.getTime()

      const isEditable =
        props.isEditable &&
        (grantorProfileId === profileId ||
          (isOperator(profileType) && grantorAccount?.user?.accountType === AccountType.placeholderUser))

      let res = isPublished && isLast && isEditable

      return res
    })
    setEditableSectionsFields(editableSectionsFields)
  }, [consentsSnapshot, consentsSnapshot?.sections])

  useEffect(() => {
    if (!consentsSnapshot) return
    const check = !!consentsSnapshot?.sections.find((p) => {
      const isAcceptedChanged = p.isAccepted !== acceptedSections[p.id].granted
      let areSharedFieldsChanged = false
      if (p.isAccepted && p.consent?.sharedFields) {
        if (acceptedSections[p.id]) {
          if (acceptedSections[p.id].selectedMailOptionRequired) {
            const selectedMailOption = acceptedSections[p.id].selectedMailOption
            if (selectedMailOption) {
              areSharedFieldsChanged ||= !p.consent.sharedFields.includes(selectedMailOption.name)
            }
          }
          if (acceptedSections[p.id].selectedMobilePhoneOptionRequired) {
            const selectedMobilePhoneOption = acceptedSections[p.id].selectedMobilePhoneOption
            if (selectedMobilePhoneOption) {
              areSharedFieldsChanged ||= !p.consent.sharedFields.includes(selectedMobilePhoneOption.name)
            }
          }
        }
      }
      return isAcceptedChanged || areSharedFieldsChanged
    })

    setAreUnsavedChanges(check)
  }, [acceptedSections])

  function computeAcceptedSections(consentsSnapshot: ContractVersion) {
    let acceptedSections: AcceptedSections = {}
    consentsSnapshot.sections.forEach((p) => {
      const sectionState: SectionState = {
        granted: p.isAccepted,
        selectedMailOptionRequired: p.treatments
          .flatMap((o) => o.dataTypes?.fields)
          .some((p) => p?.fieldName === TreatedFieldName.email),
        selectedMobilePhoneOptionRequired: p.treatments
          .flatMap((o) => o.dataTypes?.fields)
          .some((p) => p?.fieldName === TreatedFieldName.mobilePhone),
      }
      acceptedSections[p.id] = sectionState
    })

    checkFieldsToSelect(acceptedSections)
    return acceptedSections
  }

  /**
   * test whether the user can select multiple choice fields
   */
  function checkFieldsToSelect(acceptedSections: AcceptedSections) {
    const { availableMailFields, availableMobilePhoneFields } = checkSelectableAvailableFields()
    const trueSectionIds = Object.keys(acceptedSections)
      .filter((id) => acceptedSections[parseInt(id)])
      .map((id) => parseInt(id))

    consentsSnapshot?.sections
      .filter((p) => trueSectionIds.includes(p.id))
      .forEach((p) => {
        const sectionState = acceptedSections[p.id]
        if (sectionState.selectedMailOptionRequired || sectionState.selectedMobilePhoneOptionRequired) {
          if (sectionState.selectedMailOptionRequired && availableMailFields.length > 0) {
            if (p.consent && p.consent.sharedFields) {
              const email1: string = 'email1'
              const email2: string = 'email2'
              const email3: string = 'email3'
              const email4: string = 'email4'

              if (p.consent.sharedFields.includes(email1)) {
                sectionState.selectedMailOption = availableMailFields.find((o) => o.name === email1)
              } else if (p.consent.sharedFields.includes(email2)) {
                sectionState.selectedMailOption = availableMailFields.find((o) => o.name === email2)
              } else if (p.consent.sharedFields.includes(email3)) {
                sectionState.selectedMailOption = availableMailFields.find((o) => o.name === email3)
              } else if (p.consent.sharedFields.includes(email4)) {
                sectionState.selectedMailOption = availableMailFields.find((o) => o.name === email4)
              }
              // if there is no consent yet but there is an option selected I check if the user has
              // just made some changes that make the current selection invalid
              else if (
                sectionState.selectedMailOption &&
                availableMailFields.some((o) => o.name === sectionState.selectedMailOption?.name)
              ) {
                sectionState.selectedMailOption = undefined
              }

              // if (!sectionState.selectedMailOption && availableMailFields.length > 0) {
              //   sectionState.selectedMailOption = availableMailFields[0]
              // }
            }
          } else {
            sectionState.selectedMailOption = undefined
          }

          if (sectionState.selectedMobilePhoneOptionRequired && availableMobilePhoneFields.length > 0) {
            if (p.consent && p.consent.sharedFields) {
              const mobilePhone1: string = 'mobile_phone1'
              const mobilePhone2: string = 'mobile_phone2'

              if (p.consent.sharedFields.includes(mobilePhone1)) {
                sectionState.selectedMobilePhoneOption = availableMobilePhoneFields.find((o) => o.name === mobilePhone1)
              } else if (p.consent.sharedFields.includes(mobilePhone2)) {
                sectionState.selectedMobilePhoneOption = availableMobilePhoneFields.find((o) => o.name === mobilePhone2)
              }
              // if there is no consent yet but there is an option selected I check if the user has
              // just made some changes that make the current selection invalid
              else if (
                sectionState.selectedMobilePhoneOption &&
                availableMobilePhoneFields.some((o) => o.name === sectionState.selectedMobilePhoneOption?.name)
              ) {
                sectionState.selectedMobilePhoneOption = undefined
              }

              // if (!sectionState.selectedMobilePhoneOption && availableMobilePhoneFields.length > 0) {
              //   sectionState.selectedMobilePhoneOption = availableMobilePhoneFields[0]
              // }
            }
          } else {
            sectionState.selectedMobilePhoneOption = undefined
          }
        }
      })
  }

  function checkSelectableAvailableFields() {
    const availableMailFields: SelectableFieldOption[] = []
    const availableMobilePhoneFields: SelectableFieldOption[] = []
    let details: ProfileDetails | undefined
    if (grantorAccount) {
      if (grantorAccount.user?.accountType === AccountType.externalUser) {
        details = grantorAccount.profileDetails?.find((p) => p.ownerProfileId === grantorAccount.profile?.profileId)
      } else if (grantorAccount.user?.accountType === AccountType.placeholderUser) {
        details = grantorAccount.profileDetails?.find((p) => p.ownerProfileId === structureProfileId)
      }
    }

    if (details) {
      if (details.email1) {
        availableMailFields.push({ name: 'email1', value: details.email1 })
      }
      if (details.email2) {
        availableMailFields.push({ name: 'email2', value: details.email2 })
      }
      if (details.email3) {
        availableMailFields.push({ name: 'email3', value: details.email3 })
      }
      if (details.email4) {
        availableMailFields.push({ name: 'email4', value: details.email4 })
      }

      if (details.mobilePhone1) {
        availableMobilePhoneFields.push({ name: 'mobile_phone1', value: details.mobilePhone1 })
      }
      if (details.mobilePhone2) {
        availableMobilePhoneFields.push({ name: 'mobile_phone2', value: details.mobilePhone2 })
      }
    }

    setAvailableMailFields(availableMailFields)
    setAvailableMobilePhoneFields(availableMobilePhoneFields)

    return { availableMailFields, availableMobilePhoneFields }
  }

  function onChange(section: ContractSection, value: boolean) {
    setAcceptedSections((s) => {
      const state: SectionState = acceptedSections[section.id] ?? {}
      state.granted = value
      return { ...s, [section.id]: state }
    })
  }

  function onChangeFieldOption(section: ContractSection, optionName: string, field: SelectableFieldOption) {
    setAcceptedSections((s) => {
      const state: SectionState = acceptedSections[section.id] ?? {}
      if (optionName === TreatedFieldName.email) {
        state.selectedMailOption = {
          name: field.name,
          value: field.value,
        }
      } else if (optionName === TreatedFieldName.mobilePhone) {
        state.selectedMobilePhoneOption = {
          name: field.name,
          value: field.value,
        }
      }
      return { ...s, [section.id]: state }
    })
  }

  /**
   * check whether the user has completed all the mandatory fields indicated by the owner
   */
  const missingInformations = useMemo(() => {
    if (!consentsSnapshot) return

    const missingMandatoryFields: string[] = []
    const missingOptionalFields: string[] = []
    const grantedSectionIds: number[] = []
    for (const sectionId in acceptedSections) {
      if (acceptedSections[sectionId].granted) {
        grantedSectionIds.push(parseInt(sectionId))
      }
    }
    const sections = (consentsSnapshot.sections || []).filter((p) => grantedSectionIds.includes(p.id)) || []
    sections.forEach((p) => {
      if (grantorAccount && p.treatments.length > 0) {
        const mandatoryFields = p.treatments
          .flatMap((p) => (p.dataTypes ? p.dataTypes.fields : []))
          .filter((p) => p.mandatory)
          .reduce((acc: TreatedField[], curr: TreatedField) => {
            if (!acc.some((item: TreatedField) => item.fieldName === curr.fieldName)) {
              acc.push(curr)
            }
            return acc
          }, [])

        const optionalFields = p.treatments
          .flatMap((p) => (p.dataTypes ? p.dataTypes.fields : []))
          .filter((p) => !p.mandatory)
          .reduce((acc: TreatedField[], curr: TreatedField) => {
            if (!acc.some((item: TreatedField) => item.fieldName === curr.fieldName)) {
              acc.push(curr)
            }
            return acc
          }, [])

        const fields = [
          TreatedFieldName.avatar,
          TreatedFieldName.phone,
          TreatedFieldName.mobilePhone,
          TreatedFieldName.email,
          TreatedFieldName.residenceCountry,
          TreatedFieldName.residenceProvince,
          TreatedFieldName.residenceCity,
          TreatedFieldName.residenceStreet,
          TreatedFieldName.residenceStreetNumber,
          TreatedFieldName.residenceZip,
        ]

        fields.forEach((field) => {
          isFieldMissingFromDetails(
            profileId || 0,
            grantorAccount,
            mandatoryFields,
            optionalFields,
            field,
            missingMandatoryFields,
            missingOptionalFields
          )
        })
      }
    })

    const missingMandatoryFieldsNamesDistinct = Array.from(new Set(missingMandatoryFields))
    const missingOptionalFieldsNamesDistinct = Array.from(
      new Set(missingOptionalFields.filter((p) => !missingMandatoryFields.includes(p)))
    )
    const areMissingFields =
      missingMandatoryFieldsNamesDistinct.length > 0 || missingOptionalFieldsNamesDistinct.length > 0

    if (Object.keys(editableSections).length > 0 && areMissingFields) {
      const missingMandatoryFieldsNamesLocalized = translateListOfFields(missingMandatoryFieldsNamesDistinct, t)
      const missingOptionalFieldsNamesLocalized = translateListOfFields(missingOptionalFieldsNamesDistinct, t)
      return {
        mandatoryFieldsNames: missingMandatoryFieldsNamesDistinct,
        optionalFieldsNames: missingOptionalFieldsNamesDistinct,
        mandatoryFieldsNamesLocalized: missingMandatoryFieldsNamesLocalized,
        optionalFieldsNamesLocalized: missingOptionalFieldsNamesLocalized,
      }
    } else {
      return undefined
    }
  }, [consentsSnapshot, editableSections, t, grantorAccount, acceptedSections])

  const consentsState = useMemo(() => {
    const areAllMandatorySectionsAccepted =
      acceptedSections && consentsSnapshot ? areMandatorySectionsAccepted(consentsSnapshot, acceptedSections) : false
    if (areAllMandatorySectionsAccepted) {
      return {
        areAllMandatorySectionsAccepted,
        areAnyMandatoryConsentRevoked: false,
        areAnyMandatoryConsentMissing: false,
      }
    }
    const areAnyMandatoryConsentRevoked = consentsSnapshot?.sections
      .filter((p) => p.consentMode === ConsentMode.mandatory)
      .find((p) => !p.isAccepted && p.isAccepted !== acceptedSections[p.id]?.granted)

    const areAnyMandatoryConsentMissing = consentsSnapshot?.sections
      .filter((p) => p.consentMode === ConsentMode.mandatory)
      .find((p) => !acceptedSections[p.id]?.granted)

    return {
      areAllMandatorySectionsAccepted,
      areAnyMandatoryConsentRevoked,
      areAnyMandatoryConsentMissing,
    }
  }, [consentsSnapshot, acceptedSections])

  const canSave = useMemo(() => {
    return consentsState.areAllMandatorySectionsAccepted
  }, [consentsState.areAllMandatorySectionsAccepted])

  async function onSave() {
    const { areAllMandatorySectionsAccepted, areAnyMandatoryConsentRevoked, areAnyMandatoryConsentMissing } =
      consentsState
    if (areAllMandatorySectionsAccepted) {
      await save()
    } else if (areAnyMandatoryConsentRevoked) {
      setSimpleDialogOpen(true)
      setSimpleDialogData({
        title: t(msgIds.MSG_CONSENT_CHANGE),
        content:
          contractType === ContractType.privacyPolicy
            ? t(msgIds.MSG_CONSENT_CHANGE_REVOKE_ALL_PRIVACY_POLICY_CONSENTS_CONFIRM)
            : t(msgIds.MSG_CONSENT_CHANGE_REVOKE_ALL_CONTRACT_CONSENTS_CONFIRM),
        actionsStyle: 'yesNO',
        onClose: async (result) => {
          if (result.userChoice !== 'yes') return
          setSimpleDialogOpen(false)
          await save()
        },
      })
    } else if (areAnyMandatoryConsentMissing) {
      setSimpleDialogOpen(true)
      setSimpleDialogData({
        title: t(msgIds.MSG_CONSENT_CHANGE),
        content: t(msgIds.MSG_CONSENT_CHANGE_ALL_MANDATORY_CONSENTS_NEEDED),
        actionsStyle: 'Ok',
        onClose: async (result) => {
          setSimpleDialogOpen(false)
        },
      })
    }
  }

  function collectConsentsToRevoke() {
    const consentsToRevoke =
      consentsSnapshot?.sections
        .filter((p) => !acceptedSections[p.id].granted && p.isAccepted !== acceptedSections[p.id].granted)
        .map((p) => {
          return {
            sectionId: p.id,
          } as ConsentChange
        }) || []
    return consentsToRevoke
  }

  function collectConsentsToGrant() {
    const consentsToGrant =
      consentsSnapshot?.sections
        .filter(
          (p) =>
            (acceptedSections[p.id].granted && p.isAccepted !== acceptedSections[p.id].granted) ||
            (p.consent &&
              acceptedSections[p.id].selectedMailOption &&
              !p.consent?.sharedFields.includes(acceptedSections[p.id].selectedMailOption!.name)) ||
            (acceptedSections[p.id].selectedMobilePhoneOption &&
              !p.consent?.sharedFields.includes(acceptedSections[p.id].selectedMobilePhoneOption!.name))
        )
        .map((p) => {
          const sharedFields = []
          if (acceptedSections[p.id].selectedMailOption) {
            sharedFields.push(acceptedSections[p.id].selectedMailOption?.name)
          }
          if (acceptedSections[p.id].selectedMobilePhoneOption) {
            sharedFields.push(acceptedSections[p.id].selectedMobilePhoneOption?.name)
          }
          return {
            sectionId: p.id,
            sharedFields: sharedFields,
          } as ConsentChange
        }) || []
    return consentsToGrant
  }

  function collectTreatedFields() {
    const treatedFields =
      consentsSnapshot?.sections
        .filter((p) => acceptedSections[p.id].granted)
        .flatMap((section) => section.treatments.flatMap((treatment) => treatment.dataTypes?.fields ?? [])) ?? []
    const uniqueTreatedFields = treatedFields.reduce((acc, field) => {
      if (!acc.some((existingField) => existingField.fieldName === field.fieldName)) {
        acc.push(field)
      }
      return acc
    }, [] as TreatedField[])
    return uniqueTreatedFields
  }

  function getGrantorProfileId() {
    const grantorProfileId =
      grantorAccount?.user?.accountType === AccountType.placeholderUser ? grantorAccount.profile?.profileId : undefined
    return grantorProfileId
  }

  async function save() {
    const consentsToRevoke = collectConsentsToRevoke()
    const consentsToGrant = collectConsentsToGrant()
    const grantorProfileId = getGrantorProfileId()

    try {
      const abortController = new AbortController()
      await dalConsent.updateConsents(abortController.signal, consentsToRevoke, consentsToGrant, grantorProfileId)
      enqueueSnackbar(t(msgIds.MSG_CONSENTS_UPDATED_SUCCESSFULLY), {
        variant: 'success',
      })
      setAreUnsavedChanges(false)
      props.onSaved && props.onSaved()
    } catch (err) {
      Utils.enqueueSnackbarError2(err, t)
    }
  }

  function onCancel() {
    if (!consentsSnapshot) return
    let acceptedSections = computeAcceptedSections(consentsSnapshot)
    setAcceptedSections(acceptedSections)
    props.onCanceled && props.onCanceled()
  }
  return {
    ...props,
    areUnsavedChanges,
    consentsSnapshot: consentsSnapshot || undefined,
    missingInformations,
    isNewAcknoledgmentRequired,
    acceptedSections,
    editableSections,
    editableSectionsFields,
    contractType,
    availableMailFields,
    availableMobilePhoneFields,
    onGrantorInfoUpdated,
    onChangeFieldOption,
    onChange,
    canSave,
    onSave,
    onCancel,
    isLoading,
    simpleDialogOpen,
    simpleDialogData,
    collectConsentsToRevoke,
    collectConsentsToGrant,
    collectTreatedFields,
  }
}

function isFieldMissingFromDetails(
  viewerProfileId: number,
  account: Account,
  mandatoryFields: TreatedField[],
  optionalFields: TreatedField[],
  fieldName: string,
  mandatoryMissingFields: string[],
  optionalMissingFields: string[]
) {
  if (
    mandatoryFields.findIndex((p) => p.fieldName === fieldName) === -1 &&
    optionalFields.findIndex((p) => p.fieldName === fieldName) === -1
  ) {
    return false
  }

  const propertyNames: string[] = []
  switch (fieldName) {
    case TreatedFieldName.avatar: {
      propertyNames.push(nameof<Profile>('avatarImage'))
      break
    }
    case TreatedFieldName.phone: {
      propertyNames.push(nameof<ProfileDetails>('phone'))
      break
    }
    case TreatedFieldName.mobilePhone: {
      propertyNames.push(nameof<ProfileDetails>('mobilePhone1'))
      propertyNames.push(nameof<ProfileDetails>('mobilePhone2'))
      break
    }
    case TreatedFieldName.email: {
      propertyNames.push(nameof<ProfileDetails>('email1'))
      propertyNames.push(nameof<ProfileDetails>('email2'))
      propertyNames.push(nameof<ProfileDetails>('email3'))
      propertyNames.push(nameof<ProfileDetails>('email4'))
      break
    }
    case TreatedFieldName.residenceCountry: {
      propertyNames.push(nameof<ProfileDetails>('country'))
      break
    }
    case TreatedFieldName.residenceProvince: {
      propertyNames.push(nameof<ProfileDetails>('province'))
      break
    }
    case TreatedFieldName.residenceCity: {
      propertyNames.push(nameof<ProfileDetails>('city'))
      break
    }
    case TreatedFieldName.residenceStreet: {
      propertyNames.push(nameof<ProfileDetails>('street'))
      break
    }
    case TreatedFieldName.residenceStreetNumber: {
      propertyNames.push(nameof<ProfileDetails>('streetNumber'))
      break
    }
    case TreatedFieldName.residenceZip: {
      propertyNames.push(nameof<ProfileDetails>('zip'))
      break
    }
  }

  if (fieldName === TreatedFieldName.avatar) {
    if (!!account.profile?.avatarImage) {
      return false
    }
  } else {
    const details = account.profileDetails?.find((p) => p.ownerProfileId === viewerProfileId)
    if (details) {
      let propertyValue: string
      for (const propertyName of propertyNames) {
        propertyValue = (details as any)[propertyName] as string
        if (propertyValue !== null && propertyValue !== undefined && propertyValue !== '') {
          return false
        }
      }
    }
  }

  if (mandatoryFields.findIndex((p) => p.fieldName === fieldName) !== -1) {
    mandatoryMissingFields.push(fieldName)
  } else if (optionalFields.findIndex((p) => p.fieldName === fieldName) !== -1) {
    optionalMissingFields.push(fieldName)
  }

  return true
}

function nameof<T>(name: keyof T): string {
  return name as string
}

function translateListOfFields(fields: string[], t: any) {
  return fields
    .map((p) =>
      !!t(TreatedFieldsTranslationMap.fields[p]).toString() ? t(TreatedFieldsTranslationMap.fields[p]).toString() : p
    )
    .join(', ')
}

export function areMandatorySectionsAccepted(
  contractVersion: ContractVersion,
  acceptedSections: AcceptedSections
): boolean {
  return contractVersion.sections
    .filter((p) => p.consentMode === ConsentMode.mandatory)
    .reduce((res, current) => {
      return res && Boolean(acceptedSections[current.id]?.granted)
    }, true)
}
