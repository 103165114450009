import msgIds from '../../locales/msgIds'
import { History } from '../../models/History'
import {
  ActionType,
  ActionTypeTranslationMap,
  ContractType,
  HistoryEventType,
  InvitationPurposeType,
  RetentionDuration,
  RetentionEndBehavior,
  RetentionEndBehaviorTranslationMap,
  TreatmentLegalBaseTranslationMap,
} from '../../shared/Constants'
import { Utils, dateShortOptions, dateTimeShortOptions } from '../../shared/Utils'

export function GetContextDscForPermission(historyEvent: History, t: any, i18n: any) {
  const permissionType = historyEvent.subjectPermissionType
  let dsc = ''
  switch (permissionType) {
    case ActionType.dataProcessing:
      dsc += `${t(msgIds.MSG_HISTORY_BODY_PERMISSION_DATA_PROCESSING)}`
      break
    case ActionType.viewDocuments:
      dsc += `${t(msgIds.MSG_HISTORY_BODY_PERMISSION_VIEW_DOCUMENTS)}`
      break
    case ActionType.viewDox:
      dsc += `${t(msgIds.MSG_HISTORY_BODY_PERMISSION_VIEW_DOX)}`
      break
    case ActionType.inviteProfiles:
      dsc += `${t(msgIds.MSG_HISTORY_BODY_PERMISSION_INVITE_PROFILES)}`
      break
    case ActionType.viewProfiles:
      dsc += `${t(msgIds.MSG_HISTORY_BODY_PERMISSION_VIEW_PROFILE)}`
      break
    default:
      console.log(`history event permission type '${historyEvent.subjectType}' unknowed!!`)
      dsc += ''
  }

  if (historyEvent.contextPermission) {
    const context = historyEvent.contextPermission
    if (context.startAt) {
      dsc += ` ${t(msgIds.MSG_FROM).toLowerCase()} ${Utils.toLocaleDateString(context.startAt, i18n, dateShortOptions)}`
    }
    if (context.endAt) {
      dsc += ` ${t(msgIds.MSG_TO).toLowerCase()} ${Utils.toLocaleDateString(context.endAt, i18n, dateShortOptions)}`
    }

    dsc += `\n${t(msgIds.MSG_HISTORY_BODY_PERMISSION_PIN_SECURITY)} ${
      context.hasPin ? t(msgIds.MSG_YES) : t(msgIds.MSG_NO)
    }`

    if (!!context.dependentActions) {
      dsc += `\n${t(msgIds.MSG_HISTORY_BODY_PERMISSION_ALLOWED_ACTIONS)}`
      dsc += `\n• ${t(msgIds.MSG_ACTION_TYPE_VIEW_DOX)}`
      dsc += `\n• ${t(msgIds.MSG_ACTION_TYPE_VIEW_DOCUMENTS)}`
      context.dependentActions.forEach((actionType) => {
        if (actionType in ActionTypeTranslationMap) {
          const typedAction = actionType as keyof typeof ActionTypeTranslationMap
          dsc += `\n• ${t(ActionTypeTranslationMap[typedAction])}`
        }
      })
    }
  }

  const isAutogenerated = historyEvent.contextPermission?.isAutoGenerated
  const isAutoDeleted = historyEvent.contextPermission?.isAutoDeleted

  switch (historyEvent.type) {
    case HistoryEventType.EVT_PERMISSION_CREATED:
      return isAutogenerated ? `${dsc}. ${t(msgIds.MSG_HISTORY_BODY_PERMISSION_CREATED)}` : `${dsc}`
    case HistoryEventType.EVT_PERMISSION_UPDATED:
      return `${dsc}`
    case HistoryEventType.EVT_PERMISSION_SUSPENDED:
      return `${dsc}`
    case HistoryEventType.EVT_PERMISSION_RESTORED:
      return `${dsc}`
    case HistoryEventType.EVT_PERMISSION_UPCOMING_EXPIRATION:
      return `${dsc}`
    case HistoryEventType.EVT_PERMISSION_EXPIRED:
      return `${dsc}`
    case HistoryEventType.EVT_PERMISSION_DELETED:
      return `${dsc}`
    case HistoryEventType.EVT_PERMISSION_SENDED:
      return `${dsc}`
    default:
      return ''
  }
}

export function HistoryTypeToDsc(historyEvent: History, t: any) {
  let subjectTypeDsc = ''
  switch (historyEvent.subjectPermissionType) {
    case 'data_processing':
      subjectTypeDsc = t(msgIds.MSG_HISTORY_EVENT_SUBTYPE_DSC_DATA_PROCESSING)
      break
    case 'always_download':
      subjectTypeDsc = t(msgIds.MSG_HISTORY_EVENT_SUBTYPE_DSC_ALWAYS_DOWNLOAD)
      break
    case 'view_dox':
      subjectTypeDsc = t(msgIds.MSG_HISTORY_EVENT_SUBTYPE_DSC_VIEW_DOX)
      break
    case 'view_documents':
      subjectTypeDsc = t(msgIds.MSG_HISTORY_EVENT_SUBTYPE_DSC_VIEW_DOCUMENTS)
      break
  }

  switch (historyEvent.type) {
    case HistoryEventType.EVT_USER_CREATED:
      return t(msgIds.MSG_HISTORY_EVENT_TYPE_DSC_USER_CREATED)
    case HistoryEventType.EVT_USER_UPDATED:
      return t(msgIds.MSG_HISTORY_EVENT_TYPE_DSC_USER_UPDATED)
    case HistoryEventType.EVT_USER_ACTIVATED:
      return t(msgIds.MSG_HISTORY_EVENT_TYPE_DSC_USER_ACTIVATED)
    case HistoryEventType.EVT_USER_SUSPENDED:
      return t(msgIds.MSG_HISTORY_EVENT_TYPE_DSC_USER_SUSPENDED)
    case HistoryEventType.EVT_USER_UPCOMING_DELETION:
      return t(msgIds.MSG_HISTORY_EVENT_TYPE_DSC_USER_UPCOMING_DELETION)
    case HistoryEventType.EVT_USER_DELETED:
      return t(msgIds.MSG_HISTORY_EVENT_TYPE_DSC_USER_DELETED)

    case HistoryEventType.EVT_PROFILE_CREATED:
      return t(msgIds.MSG_HISTORY_EVENT_TYPE_DSC_PROFILE_CREATED)
    case HistoryEventType.EVT_PROFILE_ATTACHED:
      return t(msgIds.MSG_HISTORY_EVENT_TYPE_DSC_PROFILE_ATTACHED)
    case HistoryEventType.EVT_PROFILE_DETACHED:
      return t(msgIds.MSG_HISTORY_EVENT_TYPE_DSC_PROFILE_DETACHED)
    case HistoryEventType.EVT_PROFILE_UPCOMING_SUSPENSION:
      return t(msgIds.MSG_HISTORY_EVENT_TYPE_DSC_PROFILE_UPCOMING_SUSPENSION)
    case HistoryEventType.EVT_PROFILE_SUSPENDED:
      return t(msgIds.MSG_HISTORY_EVENT_TYPE_DSC_PROFILE_SUSPENDED)
    case HistoryEventType.EVT_PROFILE_UPCOMING_DELETION:
      return t(msgIds.MSG_HISTORY_EVENT_TYPE_DSC_PROFILE_UPCOMING_DELETION)
    case HistoryEventType.EVT_PROFILE_DELETED:
      return t(msgIds.MSG_HISTORY_EVENT_TYPE_DSC_PROFILE_DELETED)

    case HistoryEventType.EVT_INVITATION_CREATED:
      return t(msgIds.MSG_HISTORY_EVENT_TYPE_DSC_INVITATION_CREATED)
    case HistoryEventType.EVT_INVITATION_REVOKED:
      return t(msgIds.MSG_HISTORY_EVENT_TYPE_DSC_INVITATION_REVOKED)
    case HistoryEventType.EVT_INVITATION_ACCEPTED:
      return t(msgIds.MSG_HISTORY_EVENT_TYPE_DSC_INVITATION_ACCEPTED)

    case HistoryEventType.EVT_PERMISSION_CREATED:
      return t(msgIds.MSG_HISTORY_EVENT_TYPE_DSC_PERMISSION_CREATED, { subjectTypeDsc: subjectTypeDsc })
    case HistoryEventType.EVT_PERMISSION_UPDATED:
      return t(msgIds.MSG_HISTORY_EVENT_TYPE_DSC_PERMISSION_UPDATED, { subjectTypeDsc: subjectTypeDsc })
    case HistoryEventType.EVT_PERMISSION_SUSPENDED:
      return t(msgIds.MSG_HISTORY_EVENT_TYPE_DSC_PERMISSION_SUSPENDED, { subjectTypeDsc: subjectTypeDsc })
    case HistoryEventType.EVT_PERMISSION_RESTORED:
      return t(msgIds.MSG_HISTORY_EVENT_TYPE_DSC_PERMISSION_RESTORED, { subjectTypeDsc: subjectTypeDsc })
    case HistoryEventType.EVT_PERMISSION_UPCOMING_EXPIRATION:
      return t(msgIds.MSG_HISTORY_EVENT_TYPE_DSC_PERMISSION_UPCOMING_EXPIRATION, { subjectTypeDsc: subjectTypeDsc })
    case HistoryEventType.EVT_PERMISSION_EXPIRED:
      return t(msgIds.MSG_HISTORY_EVENT_TYPE_DSC_PERMISSION_EXPIRED, { subjectTypeDsc: subjectTypeDsc })
    case HistoryEventType.EVT_PERMISSION_DELETED:
      return t(msgIds.MSG_HISTORY_EVENT_TYPE_DSC_PERMISSION_DELETED, { subjectTypeDsc: subjectTypeDsc })
    case HistoryEventType.EVT_PERMISSION_SENDED:
      return t(msgIds.MSG_HISTORY_EVENT_TYPE_DSC_PERMISSION_SENDED, { subjectTypeDsc: subjectTypeDsc })

    case HistoryEventType.EVT_TREATMENT_CREATED:
      return t(msgIds.MSG_HISTORY_EVENT_TYPE_DSC_TREATMENT_CREATED)
    case HistoryEventType.EVT_TREATMENT_UPDATED:
      return t(msgIds.MSG_HISTORY_EVENT_TYPE_DSC_TREATMENT_UPDATED)
    case HistoryEventType.EVT_TREATMENT_DELETED:
      return t(msgIds.MSG_HISTORY_EVENT_TYPE_DSC_TREATMENT_DELETED)
    case HistoryEventType.EVT_TREATMENT_ENABLED:
      return t(msgIds.MSG_HISTORY_EVENT_TYPE_DSC_TREATMENT_ENABLED)
    case HistoryEventType.EVT_TREATMENT_DISABLED:
      return t(msgIds.MSG_HISTORY_EVENT_TYPE_DSC_TREATMENT_DISABLED)

    case HistoryEventType.EVT_CONTRACT_VERSION_CREATED:
      return t(msgIds.MSG_HISTORY_EVENT_TYPE_DSC_CONTRACT_VERSION_CREATED)
    case HistoryEventType.EVT_CONTRACT_VERSION_UPDATED:
      return t(msgIds.MSG_HISTORY_EVENT_TYPE_DSC_CONTRACT_VERSION_UPDATED)
    case HistoryEventType.EVT_CONTRACT_VERSION_PUBLISHED:
      return t(msgIds.MSG_HISTORY_EVENT_TYPE_DSC_CONTRACT_VERSION_PUBLISHED)
    case HistoryEventType.EVT_CONTRACT_VERSION_DELETED:
      return t(msgIds.MSG_HISTORY_EVENT_TYPE_DSC_CONTRACT_VERSION_DELETED)

    case HistoryEventType.EVT_CONSENT_GRANTED: {
      const actions = historyEvent.contextConsent?.actions
      if (actions?.find((p) => p.action === ActionType.dataProcessing)) {
        return t(msgIds.MSG_HISTORY_EVENT_TYPE_DSC_DATA_PROCESSING_SECTION_VIEWED)
      } else {
        return t(msgIds.MSG_HISTORY_EVENT_TYPE_DSC_CONSENT_GRANTED)
      }
    }
    case HistoryEventType.EVT_CONSENT_REVOKED:
      return t(msgIds.MSG_HISTORY_EVENT_TYPE_DSC_CONSENT_REVOKED)

    case HistoryEventType.EVT_DOCUMENT_REVISION_CREATED:
      return t(msgIds.MSG_HISTORY_EVENT_TYPE_DSC_DOCUMENT_REVISION_CREATED)
    case HistoryEventType.EVT_DOCUMENT_REVISION_UPDATED:
      return t(msgIds.MSG_HISTORY_EVENT_TYPE_DSC_DOCUMENT_REVISION_UPDATED)
    case HistoryEventType.EVT_DOCUMENT_REVISION_ARCHIVED:
      return t(msgIds.MSG_HISTORY_EVENT_TYPE_DSC_DOCUMENT_ARCHIVED)
    case HistoryEventType.EVT_DOCUMENT_REVISION_DELETED:
      return t(msgIds.MSG_HISTORY_EVENT_TYPE_DSC_DOCUMENT_REVISION_DELETED)

    case HistoryEventType.EVT_DOCUMENT_RETENTION_UPCOMING_EXPIRATION:
      return t(msgIds.MSG_HISTORY_EVENT_TYPE_DSC_RETENTION_DEADLINE_REMINDER)
    case HistoryEventType.EVT_DOCUMENT_RETENTION_EXPIRED:
      return t(msgIds.MSG_HISTORY_EVENT_TYPE_DSC_RETENTION_EXPIRED)

    case HistoryEventType.EVT_DOCUMENT_REVISION_DOWNLOADED:
      return t(msgIds.MSG_HISTORY_EVENT_TYPE_DSC_DOCUMENT_REVISION_DOWNLOADED)
    case HistoryEventType.EVT_DOCUMENT_REVISION_VIEWED:
      return t(msgIds.MSG_HISTORY_EVENT_TYPE_DSC_DOCUMENT_REVISION_VIEWED)
    case HistoryEventType.EVT_DOCUMENT_CREATED:
      return t(msgIds.MSG_HISTORY_EVENT_TYPE_DSC_DOCUMENT_CREATED)
    case HistoryEventType.EVT_DOCUMENT_RENAMED:
      return t(msgIds.MSG_HISTORY_EVENT_TYPE_DSC_DOCUMENT_RENAMED)
    case HistoryEventType.EVT_DOCUMENT_DELETED:
      return t(msgIds.MSG_HISTORY_EVENT_TYPE_DSC_DOCUMENT_DELETED)
    case HistoryEventType.EVT_DOCUMENT_DELIVERED:
      return t(msgIds.MSG_HISTORY_EVENT_TYPE_DSC_DOCUMENT_DELIVERED)
    case HistoryEventType.EVT_DOCUMENT_MARKED_AS_OBSOLETE:
      return t(msgIds.MSG_HISTORY_EVENT_TYPE_DSC_DOCUMENT_MARKED_AS_OBSOLETE)
    case HistoryEventType.EVT_DOCUMENT_MARKED_AS_IMPORTANT:
      return t(msgIds.MSG_HISTORY_EVENT_TYPE_DSC_DOCUMENT_MARKED_AS_IMPORTANT)

    case HistoryEventType.EVT_DOX_CREATED:
      return t(msgIds.MSG_HISTORY_EVENT_TYPE_DSC_DOX_CREATED)
    case HistoryEventType.EVT_DOX_UPDATED:
      return t(msgIds.MSG_HISTORY_EVENT_TYPE_DSC_DOX_UPDATED)
    case HistoryEventType.EVT_DOX_DELETED:
      return t(msgIds.MSG_HISTORY_EVENT_TYPE_DSC_DOX_DELETED)
    case HistoryEventType.EVT_DOX_DOWNLOADED:
      return t(msgIds.MSG_HISTORY_EVENT_TYPE_DSC_DOX_DOWNLOADED)
    case HistoryEventType.EVT_DOX_VIEWED:
      return t(msgIds.MSG_HISTORY_EVENT_TYPE_DSC_DOX_VIEWED)
    case HistoryEventType.EVT_DOX_RETENTION_UPCOMING_EXPIRATION:
      return t(msgIds.MSG_HISTORY_EVENT_TYPE_DSC_RETENTION_DEADLINE_REMINDER)
    case HistoryEventType.EVT_DOX_RETENTION_EXPIRED:
      return t(msgIds.MSG_HISTORY_EVENT_TYPE_DSC_RETENTION_EXPIRED)
    case HistoryEventType.EVT_DOX_DELIVERED:
      return t(msgIds.MSG_HISTORY_EVENT_TYPE_DSC_DOX_DELIVERED)
    case HistoryEventType.EVT_DOX_RECEIVED:
      return t(msgIds.MSG_HISTORY_EVENT_TYPE_DSC_DOX_DELIVERED)

    case HistoryEventType.EVT_INVOICE_FINALIZED:
      return t(msgIds.MSG_HISTORY_EVENT_TYPE_DSC_INVOICE_FINALIZED)

    case HistoryEventType.EVT_ACTIVITY_CREATED:
      return t(msgIds.MSG_HISTORY_EVENT_TYPE_DSC_ACTIVITY_CREATED)
    case HistoryEventType.EVT_NOTIFICATION_CREATED:
      return t(msgIds.MSG_HISTORY_EVENT_TYPE_DSC_NOTIFICATION_CREATED)
  }
}

export function HistoryContextToDsc(historyEvent: History, t: any, i18n: any) {
  switch (historyEvent.subjectType) {
    case 'permissions':
      return GetContextDscForPermission(historyEvent, t, i18n)
    case 'dox':
      return GetContextDscForDox(historyEvent, t)
    case 'documents':
      return GetContextDscForDocument(historyEvent, t)
    case 'treatments':
      return GetContextDscForTreatment(historyEvent, t, i18n)
    case 'contracts':
      return GetContextDscForContractVersion(historyEvent, t, i18n)
    case 'consents':
      return GetContextDscForConsent(historyEvent, t, i18n)
    case 'profiles':
      return GetContextDscForProfile(historyEvent, t, i18n)
    case 'invitations':
      return GetContextDscForInvitation(historyEvent, t, i18n)
    default: {
      console.log(`history event subject type '${historyEvent.subjectType}' unknowed!!`)
      return ''
    }
  }
}

export function GetContextDscForDox(historyEvent: History, t: any) {
  switch (historyEvent.type) {
    case HistoryEventType.EVT_DOX_CREATED:
      return `${t(msgIds.MSG_HISTORY_BODY_DOX_NAME)}: '${historyEvent.contextDox?.name}`
    case HistoryEventType.EVT_DOX_DELETED:
      return `${t(msgIds.MSG_HISTORY_BODY_DOX_NAME)}: '${historyEvent.contextDox?.name}`
    case HistoryEventType.EVT_DOX_DOWNLOADED:
      return `${t(msgIds.MSG_HISTORY_BODY_DOX_NAME)}: '${historyEvent.contextDox?.name}`
    case HistoryEventType.EVT_DOX_VIEWED:
    case HistoryEventType.EVT_DOX_RETENTION_UPCOMING_EXPIRATION: {
      return `${
        (t(msgIds.MSG_HISTORY_BODY_RETENTION_UPCOMING_EXPIRATION),
        {
          daysCountdown: historyEvent.contextDox?.daysCountdown,
        })
      }`
    }
    case HistoryEventType.EVT_DOX_RETENTION_EXPIRED:
      return `${t(msgIds.MSG_HISTORY_BODY_RETENTION_EXPIRED)}`
    case HistoryEventType.EVT_DOX_DELIVERED:
      return `${t(msgIds.MSG_HISTORY_BODY_DOX_DELIVERED)}`
    case HistoryEventType.EVT_DOX_RECEIVED:
      return `${t(msgIds.MSG_HISTORY_BODY_DOX_RECEIVED)}`
    default:
      return ''
  }
}

export function GetContextDscForDocument(historyEvent: History, t: any) {
  let dsc = ''
  if (historyEvent.contextDocument) {
    dsc =
      `${t(msgIds.MSG_HISTORY_BODY_DOCUMENT_NAME)}: ` +
      (historyEvent.contextDocument?.lastArchivedRevisionId
        ? historyEvent.contextDocument?.lastArchivedRevision?.name
        : t(msgIds.MSG_HISTORY_BODY_DOCUMENT_NOT_AVAILABLE_DRAFT))
  }
  if (historyEvent.contextDocumentRevision) {
    dsc =
      `${t(msgIds.MSG_HISTORY_BODY_DOCUMENT_NAME)}: ` +
      (historyEvent.contextDocumentRevision
        ? historyEvent.contextDocumentRevision?.name
        : t(msgIds.MSG_HISTORY_BODY_DOCUMENT_NOT_AVAILABLE_DRAFT))
  }

  switch (historyEvent.type) {
    case HistoryEventType.EVT_DOCUMENT_REVISION_CREATED:
      return `${t(msgIds.MSG_HISTORY_BODY_DOCUMENT_NAME)}: ${historyEvent.contextDocument?.name}`
    case HistoryEventType.EVT_DOCUMENT_REVISION_UPDATED:
      return `${t(msgIds.MSG_HISTORY_BODY_DOCUMENT_NAME)}: ${historyEvent.contextDocument?.name}`
    case HistoryEventType.EVT_DOCUMENT_REVISION_ARCHIVED:
      return `${t(msgIds.MSG_HISTORY_BODY_DOCUMENT_NAME)}: ${historyEvent.contextDocument?.name}`
    case HistoryEventType.EVT_DOCUMENT_REVISION_DELETED:
      return `${t(msgIds.MSG_HISTORY_BODY_DOCUMENT_NAME)}: ${historyEvent.contextDocument?.name}`
    case HistoryEventType.EVT_DOCUMENT_REVISION_DOWNLOADED:
      return dsc
    case HistoryEventType.EVT_DOCUMENT_REVISION_VIEWED:
      return dsc
    case HistoryEventType.EVT_DOCUMENT_CREATED:
      return dsc
    case HistoryEventType.EVT_DOCUMENT_RENAMED:
      return `${t(msgIds.MSG_HISTORY_BODY_DOCUMENT_NAME)}: '${historyEvent.contextDocument?.newRevision?.name}' - ${t(
        msgIds.MSG_HISTORY_BODY_DOCUMENT_PREVIOUS_NAME
      )}: '${historyEvent.contextDocument?.oldRevision?.name}'`
    case HistoryEventType.EVT_DOCUMENT_DELETED:
      return dsc
    case HistoryEventType.EVT_DOCUMENT_DELIVERED:
      return dsc
    case HistoryEventType.EVT_DOCUMENT_MARKED_AS_OBSOLETE:
      return dsc
    case HistoryEventType.EVT_DOCUMENT_MARKED_AS_IMPORTANT:
      return dsc
    case HistoryEventType.EVT_DOCUMENT_RETENTION_UPCOMING_EXPIRATION:
      return `${
        (t(msgIds.MSG_HISTORY_BODY_RETENTION_UPCOMING_EXPIRATION),
        {
          daysCountdown: historyEvent.contextDocument?.daysCountdown,
        })
      }`
    case HistoryEventType.EVT_DOCUMENT_RETENTION_EXPIRED:
      return `${t(msgIds.MSG_HISTORY_BODY_RETENTION_EXPIRED)}`
    default:
      return ''
  }
}

export function GetContextDscForTreatment(historyEvent: History, t: any, i18n: any) {
  switch (historyEvent.type) {
    case HistoryEventType.EVT_TREATMENT_CREATED:
    case HistoryEventType.EVT_TREATMENT_UPDATED:
    case HistoryEventType.EVT_TREATMENT_DELETED:
    case HistoryEventType.EVT_TREATMENT_ENABLED:
    case HistoryEventType.EVT_TREATMENT_DISABLED:
      const legalBaseDsc = historyEvent.contextTreatment?.legalBase
        ? t(TreatmentLegalBaseTranslationMap[historyEvent.contextTreatment?.legalBase])
        : ''
      let dsc = `${t(msgIds.MSG_FINALITY)}: ${historyEvent.contextTreatment?.code} - ${
        historyEvent.contextTreatment?.shortDescription
      }\n${t(msgIds.MSG_TREATMENT_FIELD_DESCRIPTION)}: ${historyEvent.contextTreatment?.description}\n${t(
        msgIds.MSG_TREATMENT_LEGAL_BASE
      )}: ${legalBaseDsc}`

      if (historyEvent.contextTreatment?.dataTypes) {
        dsc += `\n${t(msgIds.MSG_TREATMENT_MANDATORY_FIELDS)}: ${Utils.collectTreatedFields(
          t,
          historyEvent.contextTreatment?.dataTypes,
          true
        )}\n${t(msgIds.MSG_TREATMENT_OPTIONAL_FIELDS)}: ${Utils.collectTreatedFields(
          t,
          historyEvent.contextTreatment?.dataTypes,
          false
        )}`
      }

      if (
        historyEvent.contextTreatment &&
        (historyEvent.contextTreatment?.retentionDurationBase !== RetentionDuration.none ||
          historyEvent.contextTreatment?.retentionDurationCount !== 0)
      ) {
        dsc += `\n${t(msgIds.MSG_TREATMENT_RETENTION)}: ${Utils.formatRetentionDuration(
          t,
          historyEvent.contextTreatment?.retentionDurationBase,
          historyEvent.contextTreatment?.retentionDurationCount
        )}`
      }

      if (historyEvent.contextTreatment?.disabledAt) {
        dsc += `\n${t(msgIds.MSG_DISABLED)}: ${Utils.toLocaleDateString(
          historyEvent.contextTreatment?.disabledAt,
          i18n,
          dateTimeShortOptions
        )}`
      }

      if (historyEvent.contextTreatment?.retentionNoticeDaysBeforeExpiration) {
        dsc += `\n${t(msgIds.MSG_HISTORY_BODY_TREATMENT_RETENTION_NOTICE_DAYS_BEFORE_EXPIRATION_YES)}: ${
          historyEvent.contextTreatment?.retentionNoticeDaysBeforeExpiration
        }`
      } else {
        dsc += `\n${t(msgIds.MSG_HISTORY_BODY_TREATMENT_RETENTION_NOTICE_DAYS_BEFORE_EXPIRATION_NO)}`
      }

      if (
        historyEvent.contextTreatment &&
        historyEvent.contextTreatment?.retentionEndBehavior !== RetentionEndBehavior.none
      ) {
        dsc += `\n${t(msgIds.MSG_HISTORY_BODY_TREATMENT_RETENTION_END_BEHAVIOR)}: ${t(
          RetentionEndBehaviorTranslationMap[historyEvent.contextTreatment?.retentionEndBehavior]
        )}`
      }

      return dsc
    default:
      return ''
  }
}

export function GetContextDscForContractVersion(historyEvent: History, t: any, i18n: any) {
  let contractTypeDsc = ''
  if (historyEvent.contextContractVersion?.contract) {
    switch (historyEvent.contextContractVersion?.contract?.type) {
      case ContractType.privacyPolicy:
        contractTypeDsc += `${t(msgIds.MSG_CONTRACT_TYPE_PRIVACY_INFO)}`
        break
      case ContractType.termsOfUse:
        contractTypeDsc += `${t(msgIds.MSG_CONTRACT_TYPE_TERMS_OF_USE)}`
        break
      case ContractType.dataProcessor:
        contractTypeDsc += `${t(msgIds.MSG_CONTRACT_TYPE_DATA_PROCESSOR)}`
        break
    }
  }

  let versionDsc = ''
  if (
    historyEvent.contextContractVersion &&
    historyEvent.contextContractVersion?.minorVersion !== undefined &&
    historyEvent.contextContractVersion?.majorVersion !== undefined
  ) {
    versionDsc = `${historyEvent.contextContractVersion?.majorVersion}.${historyEvent.contextContractVersion?.minorVersion}`
  }

  switch (historyEvent.type) {
    case HistoryEventType.EVT_CONTRACT_VERSION_CREATED:
      return `${contractTypeDsc} ${versionDsc}`
    case HistoryEventType.EVT_CONTRACT_VERSION_UPDATED:
      return `${contractTypeDsc} ${versionDsc}`
    case HistoryEventType.EVT_CONTRACT_VERSION_PUBLISHED:
      const publishedAtDsc = historyEvent.contextContractVersion?.publishedAt
        ? `\n${t(msgIds.MSG_HISTORY_BODY_CONTRACT_PUBLISHED_AT)} ${Utils.toLocaleDateString(
            historyEvent.contextContractVersion?.publishedAt,
            i18n,
            dateShortOptions
          )}`
        : ''

      const isNewAcknowledgmentRequiredDsc = historyEvent.contextContractVersion?.isNewAcknowledgmentRequired
        ? `\n${t(msgIds.MSG_HISTORY_BODY_NEW_ACKNOWLEDGMENT_REQUIRED)}`
        : `\n${t(msgIds.MSG_HISTORY_BODY_NEW_ACKNOWLEDGMENT_NOT_REQUIRED)}`

      const areNewConsentsRequiredDsc = historyEvent.contextContractVersion?.areNewConsentsRequired
        ? `\n${t(msgIds.MSG_HISTORY_BODY_NEW_CONSENTS_REQUIRED)}`
        : `\n${t(msgIds.MSG_HISTORY_BODY_NEW_CONSENTS_NOT_REQUIRED)}`

      return `${contractTypeDsc} ${versionDsc} ${publishedAtDsc} ${isNewAcknowledgmentRequiredDsc} ${areNewConsentsRequiredDsc}`
    case HistoryEventType.EVT_CONTRACT_VERSION_DELETED: {
      return `${contractTypeDsc} ${versionDsc}`
    }
    case HistoryEventType.EVT_PROFILE_ATTACHED: {
      let dsc = ''
      return dsc
    }
    default:
      return ''
  }
}

export function GetContextDscForConsent(historyEvent: History, t: any, i18n: any) {
  let contractTypeDsc = ''
  if (historyEvent.contextConsent?.contract) {
    switch (historyEvent.contextConsent?.contract?.type) {
      case ContractType.privacyPolicy:
        contractTypeDsc += `${t(msgIds.MSG_CONTRACT_TYPE_PRIVACY_INFO)}`
        break
      case ContractType.termsOfUse:
        contractTypeDsc += `${t(msgIds.MSG_CONTRACT_TYPE_TERMS_OF_USE)}`
        break
      case ContractType.dataProcessor:
        contractTypeDsc += `${t(msgIds.MSG_CONTRACT_TYPE_DATA_PROCESSOR)}`
        break
    }
  }

  let versionDsc = ''
  if (
    historyEvent.contextConsent?.version &&
    historyEvent.contextConsent?.version?.minorVersion &&
    historyEvent.contextConsent?.version?.majorVersion
  ) {
    versionDsc = `${t(msgIds.MSG_CONTRACT_VERSION).toLowerCase()}: ${
      historyEvent.contextConsent?.version?.majorVersion
    }.${historyEvent.contextConsent?.version?.minorVersion}`
  }

  const publishedAtDsc = historyEvent.contextContractVersion?.publishedAt
    ? Utils.toLocaleDateString(historyEvent.contextContractVersion?.publishedAt, i18n, dateShortOptions)
    : ''

  let grantedAutomaticallyDsc = ''
  if (historyEvent.contextConsent) {
    grantedAutomaticallyDsc = historyEvent.contextConsent?.isAutoGenerated
      ? `${t(msgIds.MSG_HISTORY_BODY_CONSENT_RENEWED_FOR)}`
      : `${t(msgIds.MSG_HISTORY_BODY_CONSENT_GRANTED_FOR)}`
  }

  switch (historyEvent.type) {
    case HistoryEventType.EVT_CONSENT_GRANTED:
      return historyEvent.contextConsent?.isAutoGenerated
        ? `${grantedAutomaticallyDsc} ${contractTypeDsc} ${versionDsc} ${publishedAtDsc}. ${
            historyEvent.contextConsent?.isAutoGenerated ? t(msgIds.MSG_HISTORY_BODY_CONSENT_RENEWED_AUTOMATICALLY) : ''
          }`
        : `${grantedAutomaticallyDsc} ${contractTypeDsc} ${versionDsc} ${publishedAtDsc}`
    case HistoryEventType.EVT_CONSENT_REVOKED:
      return `${t(msgIds.MSG_HISTORY_BODY_CONSENT_REVOKED_FOR)} ${contractTypeDsc} ${versionDsc} ${publishedAtDsc}`
    default:
      return ''
  }
}

export function GetContextDscForProfile(historyEvent: History, t: any, i18n: any) {
  return ''
}

export function GetContextDscForInvitation(historyEvent: History, t: any, i18n: any) {
  let prposeTypeDsc = ''
  if (historyEvent.contextInvitation) {
    switch (historyEvent.contextInvitation?.purpose) {
      case InvitationPurposeType.collaborate:
        prposeTypeDsc += `${t(msgIds.MSG_HISTORY_BODY_INVITATION_PURPOSE_COLLABORATE)}`
        break
    }
  }

  switch (historyEvent.type) {
    case HistoryEventType.EVT_INVITATION_CREATED:
    case HistoryEventType.EVT_INVITATION_REVOKED:
    case HistoryEventType.EVT_INVITATION_ACCEPTED:
      return prposeTypeDsc
    default:
      return ''
  }
}
