import { ProfileType } from '../shared/Constants'
import { themeOperatorLight } from './ThemeOperatorLight'
import { themeTutorLight } from './ThemeTutorLight'
import { themeStructureLight } from './ThemeStructureLight'
import { themeCustomerLight } from './ThemeCustomerLight'

export const getProfileBasedTheme = (profileType: ProfileType | undefined) => {
  switch (profileType) {
    case 1:
      return themeCustomerLight
    case 2:
      return themeOperatorLight
    case 3:
      return themeStructureLight
    case 4:
      return themeTutorLight
    case 6:
      return themeOperatorLight
    case 7:
      return themeOperatorLight
    default:
      return themeTutorLight
  }
}
