import { Stack } from '@mui/material'
import { IDoxesTemplatesFormProps } from './DoxesTemplatesForm.types'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import DataEditorHeader from '../dataEditorHeader/DataEditorHeader'
import msgIds from '../../locales/msgIds'
import { EditIco } from '../icons'
import { DoxesTemplates } from './DoxesTemplates'

export function DoxesTemplatesForm(props: IDoxesTemplatesFormProps): JSX.Element {
  const { account } = props
  const { t } = useTranslation()
  const [isEditMode, setIsEditMode] = useState(false)
  return (
    <Stack paddingBottom={3} spacing={0}>
      <DataEditorHeader
        title={t(msgIds.MSG_DOXES_TEMPLATES_TITLE)}
        isEditMode={isEditMode}
        viewModeCommands={[
          {
            commandText: '',
            icon: <EditIco />,
            onClick: () => setIsEditMode(true),
            tooltipText: t(msgIds.MSG_MODIFY) ?? undefined,
            disabled: false,
          },
        ]}
        editModeCommands={[]}
      />
      <DoxesTemplates
        account={account}
        isEditMode={isEditMode}
        onSave={() => setIsEditMode(false)}
        onCancel={() => setIsEditMode(false)}
      />
    </Stack>
  )
}
