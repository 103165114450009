import { useTranslation } from 'react-i18next'
import msgIds from '../../locales/msgIds'
import { IAccountEditorResidenceFormProps } from './AccountEditorResidenceForm.types'
import { AccountEditorResidence } from './AccountEditorResidence'
import { Stack } from '@mui/material'
import DataEditorHeader from '../dataEditorHeader/DataEditorHeader'
import { useState } from 'react'
import { EditIco } from '../icons'
import { isStructure } from '../../shared/Constants'

export function AccountEditorResidenceForm(props: IAccountEditorResidenceFormProps): JSX.Element {
  const { t } = useTranslation()
  const [isEditMode, setIsEditMode] = useState(false)

  return (
    <Stack paddingBottom={3} spacing={0}>
      <DataEditorHeader
        title={
          isStructure(props.account.profile?.type)
            ? t(msgIds.MSG_PROFILE_EDITOR_RESIDENCE_STRUCTURE_TITLE)
            : t(msgIds.MSG_PROFILE_EDITOR_RESIDENCE_TITLE)
        }
        isEditMode={isEditMode}
        viewModeCommands={[
          {
            commandText: '',
            icon: <EditIco />,
            onClick: () => setIsEditMode(true),
            tooltipText: t(msgIds.MSG_MODIFY) ?? undefined,
            disabled: false,
          },
        ]}
        editModeCommands={[]}
      />
      <AccountEditorResidence
        account={props.account}
        detailsOwnerProfileId={props.detailsOwnerProfileId}
        mandatoryFieldsNames={props.mandatoryFieldsNames}
        optionalFieldsNames={props.optionalFieldsNames}
        isEditMode={isEditMode}
        disabled={false}
        onSave={() => setIsEditMode(false)}
        onCancel={() => setIsEditMode(false)}
      />
    </Stack>
  )
}
