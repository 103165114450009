import { Button, Card, Divider, IconButton, Stack, TextField, Typography, useTheme } from '@mui/material'
import { IDoxesTemplatesProps } from './DoxesTemplates.types'
import { useTranslation } from 'react-i18next'
import msgIds from '../../locales/msgIds'
import { EditorFormContainer } from '../editorFormContainer/EditorFormContainer'
import { MouseEvent, useEffect, useState } from 'react'
import { DeleteIco } from '../icons'
import { ViewActions } from '../viewActions/ViewActions'
import { ViewActionsButton } from '../viewActions/ViewActionsButton'
import * as dalAccount from '../../dal/DalAccount'
import { Utils } from '../../shared/Utils'
import { FePreferences } from '../../models/FePreferences'
import SimpleDialog from '../../dialogs/simpleDialog/SimpleDialog'
import { OrganizedDoxTemplate } from '../../models/OrganizedDoxTemplate'
import { DoxTemplate } from '../../models/DoxTemplate'
import { DoxesTemplatesEditor } from './DoxesTemplatesEditor'
import { RetentionRules } from '../../shared/Constants'

export function DoxesTemplates(props: IDoxesTemplatesProps): JSX.Element {
  const { account, isEditMode } = props
  const profileId = account.profile?.profileId
  const { t } = useTranslation()
  const theme = useTheme()
  const [templates, setTemplates] = useState<OrganizedDoxTemplate[]>(
    account.profile?.fePreferences?.archiveCfg?.organizedDoxTemplates || []
  )
  const [selectedTemplateIndex, setSelectedTemplateIndex] = useState<number>(0)
  const template: OrganizedDoxTemplate | undefined = templates[selectedTemplateIndex]
  const [changed, setChanged] = useState(false)
  const [toDeleteIndex, setToDeleteIndex] = useState<number>(-1)

  function initFields() {
    setChanged(false)
    setSelectedTemplateIndex(0)
    setToDeleteIndex(-1)
    setTemplates(account.profile?.fePreferences?.archiveCfg?.organizedDoxTemplates || [])
  }

  useEffect(() => {
    initFields()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account])

  function onCancel() {
    initFields()
    props.onCancel && props.onCancel()
  }

  function onAdd() {
    setTemplates((ts) => {
      return [
        {
          name: t(msgIds.MSG_DOXES_TEMPLATES_TEMPLATE_NAME_DEFAULT),
          dsc: '',
          doxTemplates: [],
        } as OrganizedDoxTemplate,
        ...ts,
      ]
    })
    setSelectedTemplateIndex(0)
    setChanged(true)
  }

  function onSelectTemplate(index: number) {
    setSelectedTemplateIndex(index)
  }

  function isTemplateSelected(index: number) {
    return index === selectedTemplateIndex
  }

  function onChangeTemplate(key: string, value: string) {
    if (selectedTemplateIndex < 0 || selectedTemplateIndex >= templates.length) {
      return
    }
    setTemplates((ts) => {
      const copy = [...ts]
      const template = copy[selectedTemplateIndex]
      copy.splice(selectedTemplateIndex, 1, { ...template, [key]: value })
      return copy
    })
    setChanged(true)
  }

  function onAddDox() {
    if (selectedTemplateIndex < 0 || selectedTemplateIndex >= templates.length) {
      return
    }
    setTemplates((ts) => {
      const copy = [...ts]
      const template = { ...copy[selectedTemplateIndex] }
      const doxTemplates = [...template.doxTemplates]
      const name = Utils.computeDefaultDoxName(doxTemplates, t)
      doxTemplates.push({
        name,
        treatmentId: undefined,
        retentionRules: RetentionRules.none,
        automaticallySetEndRetentionDate: true,
        children: [],
      } as DoxTemplate)
      template.doxTemplates = doxTemplates
      copy.splice(selectedTemplateIndex, 1, template)
      return copy
    })
    setChanged(true)
  }

  function onChangeDoxes(doxes: DoxTemplate[]) {
    if (selectedTemplateIndex < 0 || selectedTemplateIndex >= templates.length) {
      return
    }
    setTemplates((ts) => {
      const copy = [...ts]
      const template = { ...copy[selectedTemplateIndex] }
      template.doxTemplates = doxes
      copy.splice(selectedTemplateIndex, 1, template)
      return copy
    })
    setChanged(true)
  }

  async function onSave() {
    if (!profileId) {
      return
    }
    try {
      const organizedDoxTemplates = templates
      const fePreferences = account.profile?.fePreferences
      const fePreferencesUpdated: FePreferences = {
        ...fePreferences,
        archiveCfg: { ...fePreferences?.archiveCfg, organizedDoxTemplates },
      }
      console.log({ organizedDoxTemplates, fePreferences })
      const abortController = new AbortController()
      const updatedAccount = await dalAccount.updateProfile(abortController.signal, profileId, {
        fePreferences: fePreferencesUpdated,
      })
      account.profile = updatedAccount.profile
      initFields()
      props.onSave && props.onSave()
    } catch (error) {
      console.log({ error })
      Utils.enqueueSnackbarError2(error, t)
    }
  }

  function onDelete(index: number, event: MouseEvent) {
    event.stopPropagation()
    setToDeleteIndex(index)
  }

  function onDeleteConfirmation(index: number) {
    setTemplates((ts) => {
      const copy = [...ts]
      copy.splice(index, 1)
      return copy
    })
    setChanged(true)
  }

  const count = templates?.length || 0
  const backgroundColor = theme.palette.common.white
  const borderColor = theme.palette.common.gray6

  return (
    <EditorFormContainer>
      <SimpleDialog
        title={t(msgIds.MSG_DOXES_TEMPLATES_DELETE_MODEL_DIALOG_CONFIRM_TITLE)}
        content={t(msgIds.MSG_DOXES_TEMPLATES_DELETE_MODEL_DIALOG_CONFIRM_BODY)}
        actionsStyle="yesNO"
        isOpen={toDeleteIndex >= 0}
        onClose={(result) => {
          if (result.userChoice === 'yes') {
            onDeleteConfirmation(toDeleteIndex)
          }
          setToDeleteIndex(-1)
        }}
      />
      <Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography py={1}>{t(msgIds.MSG_DOXES_TEMPLATES_LIST_LABEL, { count })}</Typography>
          {isEditMode && <Button onClick={onAdd}>{t(msgIds.MSG_DOXES_TEMPLATES_LIST_ADD_BUTTON)}</Button>}
        </Stack>
        <Card
          variant="outlined"
          sx={{
            height: 300,
            borderColor,
            overflow: 'auto',
          }}
        >
          {Array.isArray(templates) &&
            templates.map((template, i) => (
              <Stack
                key={i}
                flex={1}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                gap={2}
                px={2}
                py={1}
                sx={{
                  backgroundColor: isTemplateSelected(i) ? theme.palette.primary.lighter : backgroundColor,
                  minHeight: 44,
                }}
                onClick={() => onSelectTemplate(i)}
              >
                <Stack justifyContent="flex-start" alignSelf="flex-start">
                  <Typography>{template.name}</Typography>
                  <Typography variant="caption" color={theme.palette.text.secondary}>
                    {template.dsc}
                  </Typography>
                </Stack>
                {isEditMode && (
                  <IconButton onClick={(e) => onDelete(i, e)}>
                    <DeleteIco />
                  </IconButton>
                )}
              </Stack>
            ))}
        </Card>
      </Stack>
      <Divider />
      <TextField
        disabled={!isEditMode || !template}
        label={t(msgIds.MSG_DOXES_TEMPLATES_TEMPLATE_NAME_LABEL)}
        value={template?.name || ''}
        onChange={(e) => onChangeTemplate('name', e.target.value)}
      />
      <TextField
        disabled={!isEditMode || !template}
        label={t(msgIds.MSG_DOXES_TEMPLATES_TEMPLATE_DESCRIPTION_LABEL)}
        multiline
        maxRows={4}
        value={template?.dsc || ''}
        onChange={(e) => onChangeTemplate('dsc', e.target.value)}
      />
      <Divider />
      <Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography py={1}>{t(msgIds.MSG_DOXES_TEMPLATES_DOXES_LABEL)}</Typography>
          {isEditMode && <Button onClick={onAddDox}>{t(msgIds.MSG_DOXES_TEMPLATES_DOX_ADD_BUTTON)}</Button>}
        </Stack>
        <Card
          variant="outlined"
          sx={{
            p: 1.5,
            height: 300,
            borderColor,
            overflow: 'auto',
          }}
        >
          <DoxesTemplatesEditor
            isEditMode={isEditMode && !!template}
            doxes={template?.doxTemplates || []}
            onChangeDoxes={onChangeDoxes}
          />
        </Card>
      </Stack>
      {isEditMode && (
        <ViewActions justifyContent={'center'} sx={{ paddingTop: 6 }}>
          <ViewActionsButton autoFocus defaultAction disabled={!changed} onClick={onSave}>
            {t(msgIds.MSG_SAVE)}
          </ViewActionsButton>
          <ViewActionsButton onClick={onCancel}>{t(msgIds.MSG_CANCEL)}</ViewActionsButton>
        </ViewActions>
      )}
    </EditorFormContainer>
  )
}
