import { ActionType, PDX_API_URL } from '../shared/Constants'
import log from '../shared/Logger'
import { axiosInstance } from '../contexts/AuthContext'
import { UriComposer } from '../shared/types/UriComposer'
import { Authorization, IAuthorizationJson } from '../models/Authorization'
import { IPermissionJson, Permission } from '../models/Permission'
import { AxiosResponse } from 'axios'
import { PaginatedResponse } from '../shared/types/PaginatedResponse'
import { IPermissionCommandJson, PermissionCommand } from '../models/PermissionCommand'

// ********************
// GET

function deserializeResponse(resp: AxiosResponse<any, any>): PaginatedResponse<Permission> {
  let desResp = new PaginatedResponse<Permission>()
  desResp.rows = resp.data.rows.map((json: IPermissionJson) => {
    const data = Permission.deserialize(json as IPermissionJson)
    return data
  })
  desResp.page = resp.data.page
  desResp.offset = resp.data.offset
  desResp.limit = resp.data.limit
  desResp.total = resp.data.total
  return desResp
}

export function getDataProcessingPermission(abortSignal: AbortSignal, structureProfileId: number) {
  const funcName = 'getDataProcessingPermission'
  const url = UriComposer.create(`${PDX_API_URL}/v1/permissions`)
    .addParamNumber('offset', 0)
    .addParamNumber('limit', 1)
    .addParamBool('granted', true)
    .addParamNumber('profile_id', structureProfileId)
    .addParamString('action', ActionType.dataProcessing)
    .addParamBool('include_suspended', true)
    .addParamBool('include_expired', true)
    .addParamBool('include_inherited', true)
    .addParamBool('with_profile_details', false)
    .getCompleteUri()

  return axiosInstance
    .get(url, {
      headers: {
        'Accept-Version': '1.1.x',
      },
      signal: abortSignal,
    })
    .then((resp) => {
      const reply = deserializeResponse(resp)
      return reply.rows.length > 0 ? reply.rows[0] : undefined
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

export function getDoxAuthorizations(
  abortSignal: AbortSignal,
  id: number,
  profileIds: number[]
): Promise<Authorization[]> {
  const funcName = 'getDoxAuthorizations'
  const url = UriComposer.create(`${PDX_API_URL}/v1/dox/${id}/permissions`)
    .addParamIntArray('profile_id', profileIds)
    .getCompleteUri()

  return axiosInstance
    .get(url, {
      headers: {
        'Accept-Version': '1.0.x',
      },
      signal: abortSignal,
    })
    .then((resp) => {
      const reply = Authorization.deserializeArray(resp.data as IAuthorizationJson[])
      return reply
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

export function getDocumentAuthorizations(
  abortSignal: AbortSignal,
  id: number,
  profileIds: number[]
): Promise<Authorization[]> {
  const funcName = 'getDocumentAuthorizations'
  const url = UriComposer.create(`${PDX_API_URL}/v1/documents/${id}/permissions`)
    .addParamIntArray('profile_id', profileIds)
    .getCompleteUri()

  return axiosInstance
    .get(url, {
      headers: {
        'Accept-Version': '1.0.x',
      },
      signal: abortSignal,
    })
    .then((resp) => {
      const reply = Authorization.deserializeArray(resp.data as IAuthorizationJson[])
      return reply
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

// pageNum and pageSize are not passed so all collaborator permissions, a part excluded, are returned in one page
export function getCollaboratorPermissions(abortSignal: AbortSignal, operatorProfileId: number) {
  const funcName = 'getCollaboratorPermissions'
  const url = UriComposer.create(`${PDX_API_URL}/v1/permissions`)
    .addParamBool('granted', true)
    .addParamNumber('profile_id', operatorProfileId)
    .addParamBool('include_suspended', true)
    .addParamBool('include_expired', true)
    .addParamBool('include_inherited', false)
    .addParamBool('with_profile_details', false)
    .addParamString('exclude_actions', `${ActionType.viewProfiles},${ActionType.viewDataTreatedFor}`)
    .getCompleteUri()

  return axiosInstance
    .get(url, {
      headers: {
        'Accept-Version': '1.1.x',
      },
      signal: abortSignal,
    })
    .then((resp) => {
      const reply = deserializeResponse(resp).rows
      return reply
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

export function getCollaboratorPermissionsByAction(
  abortSignal: AbortSignal,
  pageNum: number,
  pageSize: number,
  structureProfileId: number,
  operatorProfileId: number,
  action: string
) {
  const funcName = 'getCollaboratorPermissionsByAction'
  const url = UriComposer.create(`${PDX_API_URL}/v1/permissions`)
    .addParamNumber('offset', pageNum * pageSize)
    .addParamNumber('limit', pageSize)
    .addParamNumber('owner_profile_id', structureProfileId)
    .addParamNumber('profile_id', operatorProfileId)
    .addParamString('action', action)
    .addParamBool('include_suspended', false)
    .addParamBool('include_expired', false)
    .addParamBool('include_inherited', false)
    .addParamBool('with_profile_details', false)
    .addParamBool('with_target_details', true)
    .getCompleteUri()

  return axiosInstance
    .get(url, {
      headers: {
        'Accept-Version': '1.1.x',
      },
      signal: abortSignal,
    })
    .then((resp) => {
      const reply = deserializeResponse(resp)
      return reply
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

export function getInviteProfilePermissions(abortSignal: AbortSignal, structureProfileId: number) {
  const funcName = 'getInviteProfilePermissions'
  const url = UriComposer.create(`${PDX_API_URL}/v1/permissions`)
    .addParamNumber('offset', 0)
    .addParamNumber('limit', 1)
    .addParamBool('granted', true)
    .addParamString('action', ActionType.inviteProfiles)
    .addParamNumber('profile_id', structureProfileId)
    .addParamBool('include_inherited', true)
    .addParamBool('include_suspended', true)
    .addParamBool('include_expired', true)
    .addParamBool('with_profile_details', false)
    .getCompleteUri()

  return axiosInstance
    .get(url, {
      headers: {
        'Accept-Version': '1.1.x',
      },
      signal: abortSignal,
    })
    .then((resp) => {
      const reply = deserializeResponse(resp)
      return reply.rows.length > 0 ? reply.rows[0] : undefined
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

export function getCustomerGuestPermissionsByAction(
  abortSignal: AbortSignal,
  pageNum: number,
  pageSize: number,
  structureProfileId: number,
  customerGuestProfileId: number, // profile id of placeholder
  action: string
) {
  const funcName = 'getCustomerGuestPermissionsByAction'
  const url = UriComposer.create(`${PDX_API_URL}/v1/permissions`)
    .addParamNumber('offset', pageNum * pageSize)
    .addParamNumber('limit', pageSize)
    .addParamNumber('owner_profile_id', structureProfileId)
    .addParamNumber('profile_id', customerGuestProfileId)
    .addParamString('action', action)
    .addParamBool('include_suspended', true)
    .addParamBool('include_expired', true)
    .addParamBool('include_inherited', false)
    .addParamBool('with_profile_details', false)
    .addParamBool('with_target_details', true)
    .getCompleteUri()

  return axiosInstance
    .get(url, {
      headers: {
        'Accept-Version': '1.1.x',
      },
      signal: abortSignal,
    })
    .then((resp) => {
      const reply = deserializeResponse(resp)
      return reply
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

export function getPermission(abortSignal: AbortSignal, permissionRes: string) {
  const funcName = 'getPermission'
  const url = UriComposer.create(`${PDX_API_URL}/v1/permissions/${permissionRes}`).getCompleteUri()

  return axiosInstance
    .get(url, {
      headers: {
        'Accept-Version': '1.0.x',
      },
      signal: abortSignal,
    })
    .then((resp) => {
      const reply = Permission.deserialize(resp.data)
      return reply
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

// ********************
// POST

export type CreatePermissionArgs = {
  profileId: number
  action: string
  targetId: number
  startAt: Date
  endAt?: Date
  consentId?: number
  dependendActions?: string[]
}

export function createPermission(abortSignal: AbortSignal, args: CreatePermissionArgs): Promise<Permission> {
  const funcName = 'createPermission'
  const url = `${PDX_API_URL}/v1/permissions`

  const parms = {
    profile_id: args.profileId,
    action: args.action,
    target_id: args.targetId,
    start_at: args.startAt.toISOString(),
    end_at: args.endAt?.toISOString(),
    consent_id: args.consentId,
    dependend_actions: args.dependendActions,
  }
  return axiosInstance
    .post(url, parms, {
      headers: {
        'Accept-Version': '1.0.x',
      },
      signal: abortSignal,
    })
    .then((resp) => {
      const reply = Permission.deserialize(resp.data as IPermissionJson)
      return reply
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

export function setPermissionActions(
  abortSignal: AbortSignal,
  permissionId: number,
  email: string,
  pin?: boolean
): Promise<PermissionCommand> {
  const funcName = 'setPermissionActions'
  const url = `${PDX_API_URL}/v1/permissions/${permissionId}`

  const parms = {
    email_action: {
      send: true,
      address: email,
    },
    pin_action: pin ? { generate: true } : undefined,
  }
  return axiosInstance
    .post(url, parms, {
      headers: {
        'Accept-Version': '1.0.x',
      },
      signal: abortSignal,
    })
    .then((resp) => {
      const reply = PermissionCommand.deserialize(resp.data as IPermissionCommandJson)
      return reply
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

// ********************
// PATCH

export function updatePermissionSuspensionState(abortSignal: AbortSignal, permissionId: number, state: boolean) {
  const funcName = 'updatePermissionSuspensionState'
  let url = `${PDX_API_URL}/v1/permissions/${permissionId}`

  const parms = {
    suspend: state,
  }

  return axiosInstance
    .patch(url, parms, {
      headers: {
        'Accept-Version': '1.0.x',
      },
      signal: abortSignal,
    })
    .then((resp) => {
      const reply = Permission.deserialize(resp.data as IPermissionJson)
      return reply
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

export function updatePermission(
  abortSignal: AbortSignal,
  permissionId: number,
  startAt: Date,
  endAt: Date,
  dependendActions?: string[]
) {
  const funcName = 'updatePermission'
  let url = `${PDX_API_URL}/v1/permissions/${permissionId}`

  const parms = {
    start_at: startAt.toISOString(),
    end_at: endAt.toISOString(),
    dependent_actions: dependendActions?.join(','),
  }

  return axiosInstance
    .patch(url, parms, {
      headers: {
        'Accept-Version': '1.0.x',
      },
      signal: abortSignal,
    })
    .then((resp) => {
      const reply = Permission.deserialize(resp.data as IPermissionJson)
      return reply
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

// ********************
// DELETE

export function deletePermission(abortSignal: AbortSignal, permissionId: number): Promise<number> {
  const funcName = 'deletePermission'
  const url = `${PDX_API_URL}/v1/permissions/${permissionId}`

  return axiosInstance
    .delete(url, {
      headers: {
        'Accept-Version': '1.0.x',
      },
      signal: abortSignal,
    })
    .then((resp) => {
      const reply = resp.data.id
      return reply
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}
